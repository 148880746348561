import "react-native-gesture-handler";
import * as React from "react";
import { StatusBar } from "expo-status-bar";
import { NavigationContainer } from "@react-navigation/native";
import LoginScreen from "./src/screens/account/LoginScreen";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeFirebase } from "./src/utils/Firebase";
import SignUpScreen from "./src/screens/account/SignUpScreen";
import ForgotPasswordScreen from "./src/screens/account/ForgotPasswordScreen";
import PasswordResetScreen from "./src/screens/account/PasswordResetScreen";
import NewPasswordScreen from "./src/screens/account/NewPasswordScreen";
import TeacherNavigator from "./src/components/navigation/TeacherNavigator";
import { Provider } from "@react-native-material/core";
import { NativeBaseProvider } from "native-base";
import StudentNavigator from "./src/components/navigation/StudentNavigator";
import * as ScreenOrientation from "expo-screen-orientation";
import { loadFonts } from "./src/utils/loadFonts";
import { useEffect } from "react";
import ChooseRoleScreen from "./src/screens/account/ChooseRoleSceen";
import { DeviceEventEmitter, Dimensions, Platform, View } from "react-native";
import LoadingIndicator from "./src/components/animations/loading/LoadingIndicator";
import {
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { SettingsProvider } from "./src/components/contexts/SettingsContext";

initializeFirebase();
const Stack = createNativeStackNavigator();

export default function App() {
  const [user, setUser] = React.useState();
  const [userType, setUserType] = React.useState(null);
  const [orientation, setOrientation] = React.useState(4);
  const [defaultTeacherRoute, setDefaultTeacherRoute] =
    React.useState("Admin Console");
  const [defaultStudentRoute, setDefaultStudentRoute] =
    React.useState("Classes");

  const auth = getAuth();

  useEffect(() => {
    const loadCustomFonts = async () => {
      await loadFonts();
    };

    loadCustomFonts();

    DeviceEventEmitter.addListener("user-role-changed", (role) => {
      setDefaultTeacherRoute("Account");
      setUserType(role);
    });
  }, []);

  onAuthStateChanged(auth, async (user) => {
    if (!user) DeviceEventEmitter.emit("hideLoadingIndicator");
    setUser(user);

    if (user && userType == null) {
      DeviceEventEmitter.emit("showLoadingIndicator");

      //get user role from database
      setUserType("loading");

      const db = getFirestore();
      const auth = getAuth();
      const uid = auth.currentUser.uid;
      const userRef = doc(db, "users", uid);

      const userDoc = await getDoc(userRef);

      updateDoc(userRef, { lastSignInTime: serverTimestamp() });

      if (userDoc.data().role == null) {
        setUserType("NULL");
      } else setUserType(userDoc.data().role);

      DeviceEventEmitter.emit("hideLoadingIndicator");
    }
  });

  useEffect(() => {
    if (Platform.OS == "ios" || Platform.OS == "android") {
      ScreenOrientation.unlockAsync(); // ensures that the orientation is not locked

      ScreenOrientation.getOrientationAsync().then((orientation) => {
        setOrientation(orientation);
      });

      const subscription = ScreenOrientation.addOrientationChangeListener(
        ({ orientationInfo: { orientation } }) => {
          // Do something when the orientation changes
          setOrientation(orientation);
          DeviceEventEmitter.emit("showLoadingIndicator");
          setTimeout(() => {
            DeviceEventEmitter.emit("hideLoadingIndicator");
          }, 200);
          // you can update your UI based on new orientation
        }
      );

      return () => {
        ScreenOrientation.removeOrientationChangeListener(subscription); // remove listener when unmount
      };
    }
  }, []);

  return (
    <SettingsProvider>
      <Provider>
        <NativeBaseProvider>
          {orientation == 4 && <View style={{ width: "100%", height: 0 }} />}
          <NavigationContainer>
            {user ? (
              userType == "NULL" ? (
                <Stack.Navigator
                  screenOptions={{
                    headerShown: "false",
                    headerTitleStyle: { color: "#444444", fontWeight: "200" },
                  }}
                  initialRouteName="Login"
                >
                  <Stack.Screen
                    options={{ headerShown: false }}
                    name="Choose Account Type"
                    component={ChooseRoleScreen}
                  />
                </Stack.Navigator>
              ) : !userType ? (
                <Stack.Navigator>
                  <Stack.Screen
                    name="Loading..."
                    options={{ headerShown: false }}
                  >
                    {() => <View></View>}
                  </Stack.Screen>
                </Stack.Navigator>
              ) : userType == "Teacher" || userType == "Admin" ? (
                <TeacherNavigator
                  userType={userType}
                  orientation={orientation}
                  defaultRoute={defaultTeacherRoute}
                  isAdmin={userType == "Admin"}
                />
              ) : userType == "Student" ? (
                <StudentNavigator
                  userType={userType}
                  orientation={orientation}
                  defaultRoute={defaultStudentRoute}
                />
              ) : (
                <Stack.Navigator>
                  <Stack.Screen
                    name="Loading..."
                    options={{ headerShown: false }}
                  >
                    {() => <View></View>}
                  </Stack.Screen>
                </Stack.Navigator>
              )
            ) : (
              <Stack.Navigator
                screenOptions={{
                  headerShown: "false",
                  headerTitleStyle: { color: "#444444", fontWeight: "200" },
                }}
                initialRouteName="Login"
              >
                <Stack.Screen
                  name="Login"
                  options={{ headerShown: false }}
                  component={LoginScreen}
                />
                <Stack.Screen name="Register" component={SignUpScreen} />
                <Stack.Screen
                  name="Forgot Password"
                  component={ForgotPasswordScreen}
                />
                <Stack.Screen
                  name="Reset Password"
                  component={PasswordResetScreen}
                />
                <Stack.Screen
                  name="New Password"
                  component={NewPasswordScreen}
                />
              </Stack.Navigator>
            )}
            <StatusBar style="auto" />
            <LoadingIndicator />
          </NavigationContainer>
        </NativeBaseProvider>
      </Provider>
    </SettingsProvider>
  );
}
