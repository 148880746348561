import {
  Flex,
  HStack,
  Surface,
  VStack,
  TextInput,
  Dialog,
  DialogContent,
  Box,
  Button,
  IconButton,
  Pressable,
} from "@react-native-material/core";
import * as React from "react";
import { View, Text, ScrollView } from "react-native";
import {
  useFilters,
  useSortBy,
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import SortIcon from "../../graphics/Icons/SortIcon";
import { SelectList } from "react-native-dropdown-select-list";
import BasicComponent from "../wrappers/BasicComponent";
import Tooltip from "../wrappers/Tooltip";
import { heavyHaptic } from "../../../utils/hapticUtils";

const pageSizeData = [
  { key: "10", value: "10 / page" },
  { key: "15", value: "15 / page" },
  { key: "25", value: "25 / page" },
  { key: "50", value: "50 / page" },
  { key: "100", value: "100 / page" },
];

export default function BasicTable(props) {
  const { columns, data } = props;

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const [selected, setSelected] = React.useState(false);
    const count = preFilteredRows.length;

    return (
      <View>
        <MaterialCommunityIcons
          style={{ padding: 5 }}
          onPress={() => setSelected(true)}
          name="magnify"
          size={16}
          color={!filterValue ? "#333333" : "#eb9940"}
        />
        <Dialog visible={selected} onDismiss={() => setSelected(false)}>
          <DialogContent>
            <View style={{ paddingTop: 25 }}>
              <TextInput
                autoFocus
                color="#eb9940"
                style={{ margin: -10 }}
                inputStyle={{ fontSize: 14 }}
                value={filterValue || ""}
                onEndEditing={() => setSelected(false)}
                onChangeText={(value) => {
                  setFilter(value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} records...`}
                placeholderTextColor={"#aaa"}
              />
            </View>
          </DialogContent>
        </Dialog>
      </View>
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      width: 125,
    }),
    []
  );

  // Define a default UI for filtering
  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    return (
      <TextInput
        style={{ flex: 1 }}
        variant="standard"
        color="#eb9940"
        inputStyle={{ fontSize: 14 }}
        leading={
          !globalFilter ? (
            <MaterialCommunityIcons name="magnify" size={16} />
          ) : (
            <MaterialCommunityIcons
              onPress={() => setGlobalFilter("")}
              name="close-box"
              size={16}
            />
          )
        }
        placeholder={globalFilter ? globalFilter : `Search ${count} rows...`}
        placeholderTextColor={"#aaa"}
        onSubmitEditing={(e) => {
          setGlobalFilter(e.nativeEvent.text);
        }}
      />
    );
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    rows,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns: columns,
      data: data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <BasicComponent
      {...props}
      canZoom={false}
      hideScrollView={true}
      subComponent={
        <View style={{ marginTop: -12 }}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </View>
      }
    >
      <ScrollView
        contentContainerStyle={{ minWidth: "100%" }}
        horizontal
        bouncesZoom={true}
        style={{ backgroundColor: "#fafafa" }}
        minimumZoomScale={0.99}
      >
        <Flex id="table" {...getTableProps()} minW="100%">
          <Flex
            id="tableHead"
            style={{
              backgroundColor: "#f8f8f8",
              borderBottomWidth: 2,
              borderBottomColor: "#e4e4e4",
            }}
          >
            {headerGroups.map((headerGroup) => (
              <HStack id="tableRow" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <HStack
                    items="center"
                    p={8}
                    {...column.getHeaderProps({
                      style: {
                        width: column.width,
                      },
                    })}
                    id="columnHeader"
                  >
                    <Text
                      selectable
                      style={
                        {
                          //maxWidth: column.width - 30,
                        }
                      }
                      numberOfLines={1}
                    >
                      {column.render("Header")}
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 1,
                        flex: 1,
                        justifyContent:"flex-end"
                      }}
                    >
                      <View style={{}}>
                        {column.canFilter ? column.render("Filter") : null}
                      </View>
                      <SortIcon
                        toggleSort={column.toggleSortBy}
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                      />
                    </View>
                    <Box
                      h={15}
                      style={{
                        borderRightWidth:
                          i == headerGroup.headers.length - 1 ? 0 : 1.5,
                        borderColor: "#ddd",
                      }}
                    ></Box>
                  </HStack>
                ))}
              </HStack>
            ))}
          </Flex>
          <VStack id="tableBody" {...getTableBodyProps()} style={{}}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <HStack
                  id="tableRow"
                  {...row.getRowProps()}
                  style={{
                    borderBottomWidth: 2,
                    borderBottomColor: "#f5f5f5",
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <View
                        style={{ flex: 1, padding: 8, width: cell.width }}
                        id="tableCell"
                        {...cell.getCellProps({
                          style: {
                            width: cell.column.width,
                            padding: 8,
                          },
                        })}
                      >
                        <Text numberOfLines={3} selectable>
                          {cell.render("Cell")}
                        </Text>
                      </View>
                    );
                  })}
                </HStack>
              );
            })}
            {rows.length == 0 && (
              <Flex center p={32}>
                <MaterialCommunityIcons
                  color={"#888888"}
                  name="database-off"
                  size={32}
                />
                <Text style={{ color: "#888888" }}>No data to display</Text>
              </Flex>
            )}
          </VStack>
          <HStack
            className="pagination"
            items="flex-start"
            justify="flex-start"
            spacing={4}
            pt={8}
            pl={8}
            w={"100%"}
          >
            <SelectList
              boxStyles={{ borderRadius: 5 }}
              search={false}
              setSelected={(value) => {
                setPageSize(Number(value));
              }}
              data={pageSizeData}
              save="key"
              placeholder="10 / page"
            />
            <HStack spacing={4} pl={16} items="center">
              <Text>{rows.length} total row(s)</Text>
              <IconButton
                disabled={!canPreviousPage}
                onPress={() => previousPage()}
                icon={
                  <MaterialCommunityIcons
                    color={canPreviousPage ? "#333333" : "#bbbbbb"}
                    name="chevron-left"
                    size={24}
                  />
                }
              />
            </HStack>
            <HStack spacing={4} pt={14}>
              <Text>Page</Text>
              <Text style={{ fontWeight: "bold" }}>{pageIndex + 1}</Text>{" "}
              <Text>of</Text>
              <Text style={{ fontWeight: 500 }}>{pageOptions.length}</Text>
            </HStack>
            <IconButton
              disabled={!canNextPage}
              onPress={() => nextPage()}
              icon={
                <MaterialCommunityIcons
                  color={canNextPage ? "#333333" : "#bbbbbb"}
                  name="chevron-right"
                  size={24}
                />
              }
            />
          </HStack>
        </Flex>
      </ScrollView>
    </BasicComponent>
  );
}
