import * as React from "react";
import {
  Dimensions,
  View,
  Platform,
  TouchableOpacity,
  DeviceEventEmitter,
} from "react-native";
import Carousel from "react-native-reanimated-carousel";
import { useEffect } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Surface } from "@react-native-material/core";
import { useIsFocused } from "@react-navigation/native";

function BasicCarousel({
  data,
  renderItem,
  height = 410,
  hide = false,
  maxWidth,
  autoPlay = true,
  webArrowYPos = 150,
  loop = true,
}) {
  const [width, setWidth] = React.useState(Dimensions.get("window").width - 32);
  const [mode, setMode] = React.useState(
    Platform.OS === "web" ? "parallax" : "horizontal-stack"
  );
  const [isVisible, setIsVisible] = React.useState(false);
  const [snapDirection, setSnapDirection] = React.useState("left");
  const [pagingEnabled, setPagingEnabled] = React.useState(true);
  const [snapEnabled, setSnapEnabled] = React.useState(true);
  const viewCount = 5;

  const webCarouselRef = React.useRef(null);

  useEffect(() => {
    !hide &&
      setTimeout(() => {
        setIsVisible(true);
      }, 200);
  }, [hide]);

  return (
    <View
      onLayout={(e) => {
        setWidth(e.nativeEvent.layout.width);
      }}
    >
      {Platform.OS !== "web" && width && (
        <Carousel
          style={{
            //: maxWidth ? (width > maxWidth ? maxWidth : width) : width,
            height: height,
            zIndex: 999,
            overflow: "visible",
          }}
          width={maxWidth ? (width > maxWidth ? maxWidth : width) : width}
          height={height}
          pagingEnabled={pagingEnabled}
          snapEnabled={snapEnabled}
          mode={mode}
          loop={loop}
          autoPlay={autoPlay}
          autoPlayInterval={10000}
          data={data}
          modeConfig={{
            snapDirection,
            stackInterval: 1,
            //parallaxScrollingScale: 1,
            //parallaxAdjacentItemScale: 0.9,
            parallaxScrollingOffset: 200,
            parallaxScrollingScale: 1,
          }}
          renderItem={({ index, item }) => renderItem(item, index)}
          panGestureHandlerProps={{
            activeOffsetX: [-10, 10],
          }}
          scrollAnimationDuration={1000}
        />
      )}
      {Platform.OS == "web" && width && (
        <>
          <Carousel
            id="carousel"
            key="carousel"
            ref={webCarouselRef}
            scrollAnimationDuration={1000}
            style={{
              width: width,
              overflow: "visible",
              height: height,
            }}
            width={maxWidth ? (width > maxWidth ? maxWidth : width) : width}
            height={height}
            loop={loop}
            pagingEnabled={true}
            snapEnabled={true}
            autoPlay={autoPlay}
            autoPlayInterval={10000}
            mode="parallax"
            modeConfig={{
              parallaxScrollingScale: 1,
              parallaxScrollingOffset: 20,
              parallaxAdjacentItemScale: 0.9,
            }}
            data={data}
            renderItem={({ index, item }) => renderItem(item, index)}
            panGestureHandlerProps={{
              activeOffsetX: [-5, 5],
            }}
          />
          <TouchableOpacity
            onPress={() => {
              webCarouselRef.current.prev();
            }}
            style={{ position: "absolute", bottom: webArrowYPos, left: -10 }}
          >
            <Surface
              style={{
                borderRadius: 50,
                backgroundColor: "rgba(255,255,255,0.75)",
                padding: 20,
              }}
              elevation={5}
            >
              <MaterialCommunityIcons
                color={"black"}
                name="arrow-left"
                size={40}
              />
            </Surface>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              webCarouselRef.current.next();
            }}
            style={{ position: "absolute", bottom: webArrowYPos, right: -10 }}
          >
            <Surface
              style={{
                borderRadius: 50,
                backgroundColor: "rgba(255,255,255,0.75)",
                padding: 20,
              }}
              elevation={5}
            >
              <MaterialCommunityIcons
                color={"black"}
                name="arrow-right"
                size={40}
              />
            </Surface>
          </TouchableOpacity>
        </>
      )}
    </View>
  );
}

export default BasicCarousel;
