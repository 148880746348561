import React from "react";

import { ScrollView } from "react-native";
import SettingsCard from "../../components/cards/SettingsCard";
import SettingsGroup from "../../components/cards/SettingsGroup";
import { useSettings } from "../../components/contexts/SettingsContext";

export default function StaffSettingsScreen({ navigation }) {
  const { settings, setSettings } = useSettings();

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: "#f0eff4",
      }}
    >
      <SettingsGroup title="GENERAL">
        <SettingsCard
          title="Board tile size"
          type="number"
          minimumValue={0.6}
          maximumValue={1.5}
          step={0.05}
          value={settings.boardTileSize}
          setValue={(value) =>
            setSettings((prev) => ({ ...prev, boardTileSize: value }))
          }
        />
        <SettingsCard
          title="Class tile size"
          type="number"
          minimumValue={0.6}
          maximumValue={1.5}
          step={0.05}
          value={settings.classTileSize}
          setValue={(value) =>
            setSettings((prev) => ({ ...prev, classTileSize: value }))
          }
        />
      </SettingsGroup>
      <SettingsGroup
        value={settings.getLitHidden}
        setValue={(value) =>
          setSettings((prev) => ({ ...prev, getLitHidden: value }))
        }
        title="BOARD OPTIONS"
      >
        <SettingsCard
          value={settings.animationsEnabled}
          setValue={(value) =>
            setSettings((prev) => ({ ...prev, animationsEnabled: value }))
          }
          title="GetLit animations enabled"
        />
      </SettingsGroup>
    </ScrollView>
  );
}
