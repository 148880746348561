import { Surface } from "@react-native-material/core";
import React from "react";
import { TouchableOpacity, Text, View, StyleSheet } from "react-native";

const CustomSegmentedControl = ({
  values,
  selectedIndex,
  onValueChange,
  color = "#ed9b40",
  textColor = "white",
  inactiveBackgroundColor = "white",
  inactiveTextColor = "black",
}) => {
  return (
    <Surface
      elevation={2}
      style={{
        borderRadius: 5,
        backgroundColor: inactiveBackgroundColor,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <View style={styles.segmentedControlContainer}>
        {values.map((value, index) => (
          <TouchableOpacity
            key={index}
            style={[
              styles.segment,
              index === selectedIndex
                ? { backgroundColor: color }
                : { backgroundColor: inactiveBackgroundColor },

              index == values.length - 1 && { borderBottomRightRadius: 5 },
              index == 0 && { borderBottomLeftRadius: 5 },
            ]}
            onPress={() => onValueChange(index)}
          >
            <Text
              numberOfLines={1}
              style={[
                styles.segmentText,
                index === selectedIndex
                  ? { color: textColor, fontWeight: "700" }
                  : { color: inactiveTextColor, fontWeight: "500" },
              ]}
            >
              {value}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </Surface>
  );
};

const styles = StyleSheet.create({
  segmentedControlContainer: {
    flexDirection: "row",
    // overflow: "hidden",
    borderRadius: 5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  segment: {
    flex: 1,
    padding: 8,
  },
  activeSegment: {
    backgroundColor: "#ed9b40",
  },
  segmentText: {
    textAlign: "center",
  },
  activeText: {
    color: "white",
    fontWeight: "600",
  },
  inactiveText: {
    color: "#ed9b40",
    fontWeight: "500",
  },
});

export default CustomSegmentedControl;
