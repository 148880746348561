import React, { useCallback, useEffect, useRef, useState } from "react";
import { Image, StyleSheet, Text, TextInput, View } from "react-native";
import algoliasearch from "algoliasearch/lite";
import {
  Configure,
  InstantSearch,
  useSearchBox,
} from "react-instantsearch-hooks";
import { InfiniteHits } from "./InfiniteHits";

function AlgoliaSearch({
  indexName = "boardNameSearch",
  filters,
  Hit = defaultHit,
}) {
  const [canShowSearch, setCanShowSearch] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCanShowSearch(true);
    }, 100);
  }, []);

  const listRef = useRef(null);

  const searchClient = algoliasearch(
    "5ZH0G39E54",
    "6945c6e3bc0af5e1422b58a4ca93309e"
  );

  const scrollToTop = useCallback(() => {
    listRef.current?.scrollToOffset({ animated: false, offset: 0 });
  });

  return (
    <View style={styles.container}>
      {canShowSearch && (
        <InstantSearch searchClient={searchClient} indexName={indexName}>
          <Configure filters={filters} />
          <SearchBox onChange={scrollToTop} />
          <InfiniteHits hitComponent={Hit} />
        </InstantSearch>
      )}
    </View>
  );
}

function defaultHit({ hit }) {
  return (
    <Text style={{}}>
      {hit.boardName ? hit.boardName : hit.firstName + " " + hit.lastName}
    </Text>
  );
}

export function SearchBox(props) {
  const { query, refine } = useSearchBox(props);
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef(null);

  function setQuery(newQuery) {
    setInputValue(newQuery);
    refine(newQuery);
  }

  // Track when the InstantSearch query changes to synchronize it with
  // the React state.
  // We bypass the state update if the input is focused to avoid concurrent
  // updates when typing.
  if (query !== inputValue && !inputRef.current?.isFocused()) {
    setInputValue(query);
  }

  return (
    <TextInput
      ref={inputRef}
      style={[
        {
          height: 40,
          borderRadius: 5,
          paddingLeft: 10,
          borderBottomWidth: 2,
          borderBottomColor: "#aaa",
        },
      ]}
      placeholderTextColor={"#aaa"}
      placeholder="Search..."
      value={inputValue}
      onChangeText={setQuery}
      clearButtonMode="while-editing"
      autoCapitalize="none"
      autoCorrect={false}
      spellCheck={false}
      autoCompleteType="off"
    />
  );
}

const styles = StyleSheet.create({
  input: {
    height: 48,
    padding: 12,
    fontSize: 16,
    backgroundColor: "#fff",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ddd",
  },
});

export default React.memo(AlgoliaSearch);
