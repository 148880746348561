import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  TextInput,
  FlatList,
  TouchableOpacity,
  Text,
} from "react-native";

const SearchBarHeader = ({ data, onSearch }) => {
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    if (query.trim() === "") {
      setFilteredData(data);
      return;
    }

    const filtered = data.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  }, [query, data]);

  const handleSearch = (text) => {
    setQuery("");
    setShowSuggestions(false);
    onSearch(text);
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={styles.suggestionItem}
      onPress={() => {
        handleSearch(item);
      }}
    >
      <Text>{item}</Text>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <MaterialCommunityIcons color={"#9e9ea7"} name="magnify" size={20} />
      <TextInput
        style={styles.searchInput}
        value={query}
        onChangeText={(text) => setQuery(text)}
        placeholder="Search..."
        placeholderTextColor={"#9e9ea7"}
        onFocus={() => setShowSuggestions(true)}
        onBlur={() => {
          setTimeout(() => {
            setShowSuggestions(false);
          }, 2000);
        }}
      />
      {filteredData.length > 0 && showSuggestions && (
        <View style={styles.suggestionContainer}>
          <FlatList
            data={filteredData}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
            style={styles.suggestionList}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    maxWidth: 300,
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
    alignItems: "center",
  },
  searchInput: {
    width: "100%",
    borderRadius: 5,
    paddingLeft: 4,
    paddingRight: 10,
  },
  suggestionContainer: {
    position: "absolute",
    top: 20,
    left: 20,
    right: 20,
    width: "auto",
    maxWidth: "94%",
    backgroundColor: "white",
    borderRadius: 5,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderColor: "#ddd",
    borderWidth: 1,
    zIndex: 1,
  },
  suggestionList: {},
  suggestionItem: {
    padding: 8,
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
  },
});

export default SearchBarHeader;
