import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import StaffClassesScreen from "../../screens/staff/StaffClasses";
import StaffBoardScreen from "../../screens/staff/StaffBoard";
import StaffClassScreen from "../../screens/staff/StaffClass";

const Stack = createNativeStackNavigator();

export default function StaffClassesNavigator({ navigation }) {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="My Classes"
        options={{ headerShown: false }}
        component={StaffClassesScreen}
      />
      <Stack.Screen
        name="Class"
        options={({ route }) => ({ title: route.params.name })}
        component={StaffClassScreen}
      ></Stack.Screen>
      <Stack.Screen
        name="Board"
        options={({ route }) => ({ title: route.params.name })}
        component={StaffBoardScreen}
      ></Stack.Screen>
    </Stack.Navigator>
  );
}
