import { Flex, HStack, Pressable } from "@react-native-material/core";
import { VStack } from "@react-native-material/core";
import * as React from "react";
import BasicComponent from "./BasicComponent";

import { Text, ScrollView, TouchableOpacity } from "react-native";

export default function BasicTabContainer({
  accentColor = "#eb9940",
  style = {},
  ...props
}) {
  const [current, setCurrent] = React.useState(0);

  const { screens } = props;

  return (
    <BasicComponent
      hideScrollView={true}
      zoomable={false}
      title={props.title}
      elevation={2}
      {...props}
    >
      <VStack spacing={8} style={style}>
        <ScrollView horizontal style={{ maxHeight: 40 }}>
          <HStack spacing={4} style={{}}>
            {screens &&
              screens.map((screen, i) => {
                return (
                  <TouchableOpacity
                    key={i}
                    style={{
                      paddingHorizontal: 5,
                      paddingVertical: 5,
                      backgroundColor: "white",
                      borderBottomColor: accentColor,
                      borderBottomWidth: i === current ? 2 : 0,
                    }}
                    onPress={() => {
                      setCurrent(i);
                    }}
                  >
                    <Text
                      style={{
                        paddingHorizontal: 5,
                        paddingVertical: 2,
                        fontWeight: i == current ? "700" : "600",
                        color: i === current ? accentColor : "#a1a1a1",
                      }}
                    >
                      {screen.name}
                    </Text>
                  </TouchableOpacity>
                );
              })}
          </HStack>
        </ScrollView>
        {screens.map((tab, i) => {
          return (
            <Flex
              key={i}
              ml={-12}
              mr={-12}
              style={{ flex: 1, display: i == current ? "flex" : "none" }}
            >
              {tab.screen}
            </Flex>
          );
        })}
      </VStack>
    </BasicComponent>
  );
}
