export function intToRoman(num) {
  const romanNumerals = {
    1000: "M",
    900: "CM",
    500: "D",
    400: "CD",
    100: "C",
    90: "XC",
    50: "L",
    40: "XL",
    10: "X",
    9: "IX",
    5: "V",
    4: "IV",
    1: "I",
  };

  let romanNumeral = "";
  const keys = Object.keys(romanNumerals)
    .map(Number)
    .sort((a, b) => b - a);

  for (let value of keys) {
    while (num >= value) {
      romanNumeral += romanNumerals[value];
      num -= value;
    }
  }

  return romanNumeral;
}

export function timeAgo(timestamp) {
  const now = new Date();
  const past = timestamp.toDate();
  const secondsPassed = Math.floor((now - past) / 1000); // Convert milliseconds to seconds

  if (secondsPassed < 10) {
    return "just now";
  } else if (secondsPassed < 60) {
    return (
      secondsPassed + (secondsPassed === 1 ? " second ago" : " seconds ago")
    );
  } else if (secondsPassed < 3600) {
    const minutes = Math.floor(secondsPassed / 60);
    return minutes + (minutes === 1 ? " minute ago" : " minutes ago");
  } else if (secondsPassed < 86400) {
    const hours = Math.floor(secondsPassed / 3600);
    return hours + (hours === 1 ? " hour ago" : " hours ago");
  } else if (secondsPassed < 604800) {
    // 7 days
    const days = Math.floor(secondsPassed / 86400);
    return days + (days === 1 ? " day ago" : " days ago");
  } else if (secondsPassed < 2592000) {
    // 30 days
    const weeks = Math.floor(secondsPassed / 604800);
    return weeks + (weeks === 1 ? " week ago" : " weeks ago");
  } else if (secondsPassed < 31536000) {
    // 365 days
    const months = Math.floor(secondsPassed / 2592000);
    return months + (months === 1 ? " month ago" : " months ago");
  } else {
    const years = Math.floor(secondsPassed / 31536000);
    return years + (years === 1 ? " year ago" : " years ago");
  }
}


export function formatDate(date) {
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // months are 0-indexed in JavaScript
  const day = ("0" + date.getDate()).slice(-2);
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}
