import * as React from "react";
import { useWindowDimensions, ScrollView } from "react-native";

import { Flex } from "@react-native-material/core";

import LoginHero from "./LoginHero";
import NewPasswordForm from "../../components/account/NewPasswordForm";

export default function NewPasswordScreen({ navigation }) {
  const { height, width } = useWindowDimensions();
  const medium = width < 1008 && width >= 641;
  const small = width < 641;

  return (
    <ScrollView style={{ backgroundColor: "white" }}>
      <Flex
        p={18}
        minH={height - 64}
        bg={"white"}
        direction={medium ? "column" : "row"}
      >
        <NewPasswordForm medium={medium} small={small} navigation={navigation} />
        {!small && <LoginHero />}
      </Flex>
    </ScrollView>
  );
}
