import React from "react";

import { HStack, Surface } from "@react-native-material/core";

import { TouchableOpacity } from "react-native";
import PopIn from "../../animations/visibility/PopIn";
import { useIsFocused } from "@react-navigation/native";
import { lightHaptic } from "../../../utils/hapticUtils";
import Tooltip from "../../data/wrappers/Tooltip";

export default function FabGroup({
  buttons = [],
  bottom = 20,
  top,
  right = 20,
  left,
}) {
  const isFocused = useIsFocused();

  return (
    <HStack
      spacing={12}
      style={{
        zIndex: 200,
        position: "absolute",
        bottom: bottom,
        right: right,
        left: left,
        top: top,
      }}
    >
      {buttons.map((button, index) => {
        if (button)
          return (
            <Surface
              key={index}
              style={{ borderRadius: 5, backgroundColor: button.color }}
              elevation={5}
            >
              <PopIn isVisible={isFocused} delay={100}>
                <TouchableOpacity
                  style={{
                    backgroundColor: button.color,
                    borderRadius: 5,
                    width: button.width ? button.width : 60,
                    height: button.height ? button.height : 60,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onPress={() => {
                    button.onPress();
                    lightHaptic();
                  }}
                >
                  {button.icon}
                </TouchableOpacity>
              </PopIn>
            </Surface>
          );
      })}
    </HStack>
  );
}
