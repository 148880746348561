import { Octicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import {
  Timestamp,
  collection,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { Linking, Text, TouchableOpacity } from "react-native";

export async function getAdminDashboardViewTimesBarData(
  db,
  startTime,
  endTime,
  bars = 10
) {
  const boardViewTimesQ = query(
    collection(db, "boardViewTimes"),
    where("timeStamp", ">=", startTime),
    where("timeStamp", "<=", endTime)
  );

  const boardViewTimesSnapshot = await getDocs(boardViewTimesQ);

  const timeDiff = endTime - startTime;
  const interval = timeDiff / bars;

  // Initialize an array with 12 elements to store the total viewTime for each interval
  const intervals = Array(bars).fill(0);
  const intervalsCount = Array(bars).fill(0);

  boardViewTimesSnapshot.docs.forEach((doc) => {
    const data = doc.data();

    const timeStamp = new Timestamp(data.timeStamp).seconds.seconds * 1000;
    const viewTime = data.viewTime;

    // Determine which interval this doc falls into
    const index = Math.floor((timeStamp - startTime.getTime()) / interval);

    // Add the viewTime to the corresponding interval
    intervals[index] += viewTime;

    intervalsCount[index] += 1;
  });

  // Convert the intervals to the desired format
  const result = intervals.map((value, index) => {
    // Calculate the time for this interval
    const time = new Date(startTime.getTime() + interval * index);

    // Format the time as needed, e.g., using time.getHours() for hours or time.toDateString() for days
    let formattedTime = "";
    if (timeDiff <= 24 * 60 * 60 * 1000) {
      // If within 24 hours, show hours
      formattedTime = time.getHours() % 12 || 12; // Get the hour in 12-hour format
      formattedTime += ":00 "; // Add minutes (always 00 in this case)
      formattedTime += time.getHours() < 12 ? "AM" : "PM"; // Add AM/PM    } else {
    } else {
      // Otherwise, show date
      formattedTime = time.toLocaleDateString();
    }

    return { x: formattedTime, y: value / 1000 / 60 / 60 };
  });

  // Convert the intervals to the desired format (averages)
  const avgResult = intervals.map((value, index) => {
    // Calculate the time for this interval
    const time = new Date(startTime.getTime() + interval * index);

    // Format the time as needed, e.g., using time.getHours() for hours or time.toDateString() for days
    let formattedTime = "";
    if (timeDiff <= 24 * 60 * 60 * 1000) {
      // If within 24 hours, show hours
      formattedTime = time.getHours() % 12 || 12; // Get the hour in 12-hour format
      formattedTime += ":00 "; // Add minutes (always 00 in this case)
      formattedTime += time.getHours() < 12 ? "AM" : "PM"; // Add AM/PM    } else {
    } else {
      // Otherwise, show date
      formattedTime = time.toLocaleDateString();
    }

    return {
      x: formattedTime,
      y: Number(
        intervalsCount[index] == 0
          ? 0
          : value / 1000 / 60 / intervalsCount[index]
      ),
    };
  });

  return [result, avgResult];
}

export async function getAdminDashboardReboardsBarData(
  db,
  startTime,
  endTime,
  bars = 10
) {
  const reboardsQ = query(
    collection(db, "reboards"),
    where("timeStamp", ">=", startTime),
    where("timeStamp", "<=", endTime)
  );

  const reboardsSnapshot = await getDocs(reboardsQ);

  const timeDiff = endTime - startTime;
  const interval = timeDiff / bars;

  const intervals = Array(bars).fill(0);

  reboardsSnapshot.docs.forEach((doc) => {
    const data = doc.data();

    const timeStamp = new Timestamp(data.timeStamp).seconds.seconds * 1000;

    // Determine which interval this doc falls into
    const index = Math.floor((timeStamp - startTime.getTime()) / interval);

    intervals[index] += 1;
  });

  // Convert the intervals to the desired format
  const result = intervals.map((value, index) => {
    // Calculate the time for this interval
    const time = new Date(startTime.getTime() + interval * index);

    // Format the time as needed, e.g., using time.getHours() for hours or time.toDateString() for days
    let formattedTime = "";
    if (timeDiff <= 24 * 60 * 60 * 1000) {
      // If within 24 hours, show hours
      formattedTime = time.getHours() % 12 || 12; // Get the hour in 12-hour format
      formattedTime += ":00 "; // Add minutes (always 00 in this case)
      formattedTime += time.getHours() < 12 ? "AM" : "PM"; // Add AM/PM    } else {
    } else {
      // Otherwise, show date
      formattedTime = time.toLocaleDateString();
    }

    return { x: formattedTime, y: value };
  });

  return result;
}

export async function getAdminDashboardViewsBarData(
  db,
  startTime,
  endTime,
  bars = 10
) {
  const boardViewsQ = query(
    collection(db, "boardViews"),
    where("timeStamp", ">=", startTime),
    where("timeStamp", "<=", endTime)
  );

  const boardViewsSnapshot = await getDocs(boardViewsQ);

  const timeDiff = endTime - startTime;
  const interval = timeDiff / bars;

  const intervals = Array(bars).fill(0);

  boardViewsSnapshot.docs.forEach((doc) => {
    const data = doc.data();

    const timeStamp = new Timestamp(data.timeStamp).seconds.seconds * 1000;

    // Determine which interval this doc falls into
    const index = Math.floor((timeStamp - startTime.getTime()) / interval);

    intervals[index] += 1;
  });

  // Convert the intervals to the desired format
  const result = intervals.map((value, index) => {
    // Calculate the time for this interval
    const time = new Date(startTime.getTime() + interval * index);

    // Format the time as needed, e.g., using time.getHours() for hours or time.toDateString() for days
    let formattedTime = "";
    if (timeDiff <= 24 * 60 * 60 * 1000) {
      // If within 24 hours, show hours
      formattedTime = time.getHours() % 12 || 12; // Get the hour in 12-hour format
      formattedTime += ":00 "; // Add minutes (always 00 in this case)
      formattedTime += time.getHours() < 12 ? "AM" : "PM"; // Add AM/PM    } else {
    } else {
      // Otherwise, show date
      formattedTime = time.toLocaleDateString();
    }

    return { x: formattedTime, y: value };
  });

  return result;
}

export async function getAdminDashboardDiscussionBarData(
  db,
  startTime,
  endTime,
  bars = 10,
  _data
) {
  const getDiscussionDocs = async () => {
    if (_data) {
      return _data;
    } else {
      const commentsQ = query(
        collection(db, "comments"),
        where("timeStamp", ">=", startTime),
        where("timeStamp", "<=", endTime)
      );
      const repliesQ = query(
        collection(db, "replies"),
        where("timeStamp", ">=", startTime),
        where("timeStamp", "<=", endTime)
      );

      const commentsSnapshot = await getDocs(commentsQ);
      const repliesSnapshot = await getDocs(repliesQ);

      return [...commentsSnapshot.docs, ...repliesSnapshot.docs];
    }
  };

  const discussionDocs = await getDiscussionDocs();

  const timeDiff = endTime - startTime;
  const interval = timeDiff / bars;

  const intervals = Array(bars).fill(0);

  discussionDocs.forEach((doc) => {
    const data = doc.data();

    const timeStamp = new Timestamp(data.timeStamp).seconds.seconds * 1000;

    // Determine which interval this doc falls into
    const index = Math.floor((timeStamp - startTime.getTime()) / interval);

    intervals[index] += 1;
  });

  // Convert the intervals to the desired format
  const result = intervals.map((value, index) => {
    // Calculate the time for this interval
    const time = new Date(startTime.getTime() + interval * index);

    // Format the time as needed, e.g., using time.getHours() for hours or time.toDateString() for days
    let formattedTime = "";
    if (timeDiff <= 24 * 60 * 60 * 1000) {
      // If within 24 hours, show hours
      formattedTime = time.getHours() % 12 || 12; // Get the hour in 12-hour format
      formattedTime += ":00 "; // Add minutes (always 00 in this case)
      formattedTime += time.getHours() < 12 ? "AM" : "PM"; // Add AM/PM    } else {
    } else {
      // Otherwise, show date
      formattedTime = time.toLocaleDateString();
    }

    return { x: formattedTime, y: value };
  });

  return result;
}

export async function getAdminDashboardUsersBarData(
  db,
  startTime,
  endTime,
  bars = 10,
  _role = "Student"
) {
  const role = _role == "Admin" ? "Teacher" : _role;

  const studentsQ = query(
    collection(db, "users"),
    where("creationTime", ">=", startTime),
    where("creationTime", "<=", endTime)
  );

  const studentsSnapshot = await getDocs(studentsQ);

  const studentDocs =
    _role == "Student"
      ? studentsSnapshot.docs.filter((doc) => doc.data().role == role)
      : studentsSnapshot.docs.filter(
          (doc) => doc.data().role == "Admin" || doc.data().role == "Teacher"
        );

  const timeDiff = endTime - startTime;
  const interval = timeDiff / bars;

  const intervals = Array(bars).fill(0);

  studentDocs.forEach((doc) => {
    const data = doc.data();

    const timeStamp = data.creationTime.seconds * 1000;

    // Determine which interval this doc falls into
    const index = Math.floor((timeStamp - startTime.getTime()) / interval);

    intervals[index] += 1;
  });

  // Convert the intervals to the desired format
  const result = intervals.map((value, index) => {
    // Calculate the time for this interval
    const time = new Date(startTime.getTime() + interval * index);

    // Format the time as needed, e.g., using time.getHours() for hours or time.toDateString() for days
    let formattedTime = "";
    if (timeDiff <= 24 * 60 * 60 * 1000) {
      // If within 24 hours, show hours
      formattedTime = time.getHours() % 12 || 12; // Get the hour in 12-hour format
      formattedTime += ":00 "; // Add minutes (always 00 in this case)
      formattedTime += time.getHours() < 12 ? "AM" : "PM"; // Add AM/PM
    } else {
      // Otherwise, show date
      formattedTime = time.toLocaleDateString();
    }

    return { x: formattedTime, y: value };
  });

  return result;
}

export async function getAdminDashboardData(
  db,
  teacherIndex,
  studentIndex,
  boardIndex
) {
  //board data table
  const boardsQ = query(collection(db, "boards"));
  const teachersQ = query(
    collection(db, "users"),
    where("role", "==", "Teacher")
  );
  const studentsQ = query(
    collection(db, "users"),
    where("role", "==", "Student")
  );
  const adminsQ = query(collection(db, "users"), where("role", "==", "Admin"));
  const commentsQ = query(collection(db, "comments"));
  const repliesQ = query(collection(db, "replies"));
  const modalitiesQ = query(collection(db, "modalities"));
  const modalityVotesQ = query(collection(db, "modalityVotes"));
  const modalityFavesQ = query(collection(db, "modalityFavorites"));
  const reboardsQ = query(collection(db, "reboards"));

  const boardsSnapshot = await getDocs(boardsQ);
  const teacherSnapshot = await getDocs(teachersQ);
  const studentSnapshot = await getDocs(studentsQ);
  const adminSnapshot = await getDocs(adminsQ);
  const repliesSnapshot = await getDocs(repliesQ);
  const commentsSnapshot = await getDocs(commentsQ);
  const modalitiesSnapshot = await getDocs(modalitiesQ);
  const modalityVotesSnapshot = await getDocs(modalityVotesQ);
  const modalityFavesSnapshot = await getDocs(modalityFavesQ);
  const reboardsSnapshot = await getDocs(reboardsQ);

  const discussionDocs = [...commentsSnapshot.docs, ...repliesSnapshot.docs];
  const teacherDocs = [...teacherSnapshot.docs, ...adminSnapshot.docs];
  const studentDocs = studentSnapshot.docs;
  const modalityDocs = modalitiesSnapshot.docs;
  const modalityVotesDocs = modalityVotesSnapshot.docs;
  const modalityFavesDocs = modalityFavesSnapshot.docs;
  const reboardDocs = reboardsSnapshot.docs;

  let studentTableData = studentDocs.reduce((obj, doc) => {
    const data = doc.data();
    const key = doc.id;

    obj[key] = {
      name: data.firstName + " " + data.lastName, // Adding a space between first and last name
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      ethnicity: data.ethnicity,
      gender: data.gender,
      phone: data.phoneNumber,
      boardViews: data.boardViews,
      age: data.dob ? calculateAge(data.dob) : null,
      viewTime: data.engagement
        ? Number(data.engagement / 1000 / 60).toFixed(2)
        : null,
      joined: new Date(data.creationTime.seconds * 1000).toLocaleDateString(),
    };

    return obj;
  }, {});

  let teacherTableData = teacherDocs.reduce((obj, doc) => {
    const data = doc.data();
    const key = doc.id;

    obj[key] = {
      name: data.firstName + " " + data.lastName, // Adding a space between first and last name
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      ethnicity: data.ethnicity,
      gender: data.gender,
      phone: data.phoneNumber,
      age: data.dob ? calculateAge(data.dob) : null,
      joined: new Date(data.creationTime.seconds * 1000).toLocaleDateString(),
      reboards: data.reboards,
    };

    return obj;
  }, {});

  let publicBoards = 0;
  let privateBoards = 0;

  const boardTableData = boardsSnapshot.docs.map((doc) => {
    const data = doc.data();

    //public/private pie chart
    if (data.isPublic) publicBoards++;
    else privateBoards++;

    teacherTableData[data.teacherId.id].boards =
      (teacherTableData[data.teacherId.id].boards || 0) + 1;

    teacherTableData[data.teacherId.id].sections =
      (teacherTableData[data.teacherId.id].sections || 0) + data.sections;

    teacherTableData[data.teacherId.id].boardFavorites =
      (teacherTableData[data.teacherId.id].boardFavorites || 0) +
      data.favorites;

    teacherTableData[data.teacherId.id].boardViews =
      (teacherTableData[data.teacherId.id].boardViews || 0) + data.views;

    teacherTableData[data.teacherId.id].engagement = Number(
      (teacherTableData[data.teacherId.id].engagement || 0) +
        (data.engagement / 1000 / 60 || 0)
    ).toFixed(2);

    return {
      id: doc.id,
      views: data.views,
      favorites: data.favorites,
      replies: data.replies,
      board: data.boardName,
      modalityCount: data.modalityCount ? data.modalityCount : 0,
      key: doc.id,
      created: data.creationTimeStamp.toDate().toLocaleString(),
      isPublic: data.isPublic ? "Yes" : "No",
      lastAccessed: data.lastAccessed
        ? data.lastAccessed.toDate().toLocaleString()
        : data.creationTimeStamp.toDate().toLocaleString(),
      reboards: data.reboards ? data.reboards : 0,
      teacherId: data.teacherId.id,
      sections: data.sections ? data.sections : 0,
    };
  });

  //public/private boards ratio pie data
  const publicPrivateRatioPieData = [
    { x: "Public", y: publicBoards },
    { x: "Private", y: privateBoards },
  ];

  let totalReplies = 0;
  let totalComments = 0;

  //discussion data table
  const discussionTableData = discussionDocs.map((doc) => {
    const data = doc.data();

    if (data.replies == null) totalReplies++;
    else totalComments++;
    try {
      //students
      studentTableData[data.userId.id].comments =
        (studentTableData[data.userId.id].comments || 0) + 1;

      studentTableData[data.userId.id].commentLikes =
        (studentTableData[data.userId.id].commentLikes || 0) + data.likeCount;

      //teachers
      teacherTableData[data.teacherId.id].studentComments =
        (teacherTableData[data.teacherId.id].studentComments || 0) + 1;

      teacherTableData[data.teacherId.id].studentCommentLikes =
        (teacherTableData[data.teacherId.id].studentCommentLikes || 0) +
        data.likeCount;

      teacherTableData[data.teacherId.id].comments =
        (teacherTableData[data.teacherId.id].comments || 0) + 1;
    } catch (error) {
      console.error(error);
    }

    return {
      id: doc.id,
      text: data.comment,
      board: data.boardId.id,
      user: data.userId.id,
      replies: data.replies,
      likes: data.likeCount,
      isTeacher: data.isTeacher ? "Yes" : "No",
      type: data.replies == null ? "Reply" : "Comment",
      created: data.timeStamp.toDate().toLocaleString(),
    };
  });

  //comment / reply ratio pie data
  const commentsRepliesRatioPieData = [
    { x: "Comments", y: totalComments },
    { x: "Replies", y: totalReplies },
  ];

  //modality data
  let tikttokCount = 0;
  let youtubeCount = 0;
  let webCount = 0;
  const modalityTableData = modalityDocs.map((doc) => {
    const data = doc.data();

    teacherTableData[data.teacherId.id].modalities =
      (teacherTableData[data.teacherId.id].modalities || 0) + 1;

    if (data.type == "tiktok") tikttokCount++;
    else if (data.type == "youtube") youtubeCount++;
    else webCount++;

    return {
      id: doc.id,
      title: data.title ? data.title : "N/A",
      board: data.boardId.id,
      teacher: data.teacherId ? data.teacherId.id : "N/A",
      type: data.type ? data.type : "web",
      likes: data.likeCount,
      dislikes: data.dislikeCount,
      favorites: data.favCount,
      link: data.link,
      created: data.timeStamp.toDate().toLocaleString(),
      rating: data.likeCount - data.dislikeCount,
    };
  });

  const modalityTypesPieData = [
    { x: "Tiktok", y: tikttokCount },
    { x: "Youtube", y: youtubeCount },
    { x: "Web", y: webCount },
  ];

  let modalityLikes = 0;
  let modalityDislikes = 0;
  let tiktokLikes = 0;
  let tiktokDislikes = 0;
  let youtubeLikes = 0;
  let youtubeDislikes = 0;
  let webLikes = 0;
  let webDislikes = 0;

  modalityVotesDocs.forEach((doc) => {
    const data = doc.data();

    if (data.type == "like") {
      modalityLikes++;

      studentTableData[data.userId.id].modalityLikes =
        (studentTableData[data.userId.id].modalityLikes || 0) + 1;

      teacherTableData[data.teacherId.id].modalityLikes =
        (teacherTableData[data.teacherId.id].modalityLikes || 0) + 1;

      if (data.modalityType == "tiktok") tiktokLikes++;
      else if (data.modalityType == "youtube") youtubeLikes++;
      else webLikes++;
    } else {
      modalityDislikes++;

      studentTableData[data.userId.id].modalityDislikes =
        (studentTableData[data.userId.id].modalityDislikes || 0) + 1;

      console.log(teacherTableData);

      if (data.teacherId.id)
        teacherTableData[data.teacherId.id].modalityDislikes =
          (teacherTableData[data.teacherId.id].modalityDislikes || 0) + 1;

      if (data.modalityType == "tiktok") tiktokDislikes++;
      else if (data.modalityType == "youtube") youtubeDislikes++;
      else webDislikes++;
    }
  });

  const modalityLRPieData = [
    { x: "Likes", y: modalityLikes },
    { x: "Dislikes", y: modalityDislikes },
  ];

  const tiktokLRPieData = [
    { x: "Likes", y: tiktokLikes },
    { x: "Dislikes", y: tiktokDislikes },
  ];

  const youtubeLRPieData = [
    { x: "Likes", y: youtubeLikes },
    { x: "Dislikes", y: youtubeDislikes },
  ];

  const webLRPieData = [
    { x: "Likes", y: webLikes },
    { x: "Dislikes", y: webDislikes },
  ];

  let tiktokFaves = 0;
  let youtubeFaves = 0;
  let webFaves = 0;

  modalityFavesDocs.forEach((doc) => {
    const data = doc.data();

    try {
      studentTableData[data.userId.id].modalityFaves =
        (studentTableData[data.userId.id].modalityFaves || 0) + 1;
    } catch (error) {
      console.error(error);
    }

    try {
      teacherTableData[data.teacherId.id].modalityFaves =
        (teacherTableData[data.teacherId.id].modalityFaves || 0) + 1;
    } catch (error) {
      console.error(error);
    }

    if (data.modalityType == "tiktok") tiktokFaves++;
    else if (data.modalityType == "youtube") youtubeFaves++;
    else webFaves++;
  });

  const modalityFavePieData = [
    { x: "Tiktok", y: tiktokFaves },
    { x: "Youtube", y: youtubeFaves },
    { x: "Web", y: webFaves },
  ];

  reboardDocs.forEach((doc) => {
    const data = doc.data();

    teacherTableData[data.teacherId.id].reboarded =
      (teacherTableData[data.teacherId.id].reboarded || 0) + 1;
  });

  // Objects to keep count of different ethnicities, genders, and ages
  const studentEthnicityCount = {};
  const teacherEthnicityCount = {};
  const studentGenderCount = {};
  const teacherGenderCount = {};
  const studentAgeCount = {};
  const teacherAgeCount = {};

  const processDocuments = (docs, ethnicityCount, genderCount, ageCount) => {
    docs.forEach((doc) => {
      const data = doc.data();
      const ethnicity = data.ethnicity || "None selected";
      const gender = data.gender || "None selected";
      const dob = data.dob || null;
      const age = dob ? calculateAge(dob) : "Unknown";

      ethnicityCount[ethnicity] = (ethnicityCount[ethnicity] || 0) + 1;
      genderCount[gender] = (genderCount[gender] || 0) + 1;
      ageCount[age] = (ageCount[age] || 0) + 1;
    });
  };

  const transformCountToObject = (count) => {
    return Object.keys(count).map((key) => {
      return { x: key, y: count[key] };
    });
  };

  processDocuments(
    studentDocs,
    studentEthnicityCount,
    studentGenderCount,
    studentAgeCount
  );
  processDocuments(
    teacherDocs,
    teacherEthnicityCount,
    teacherGenderCount,
    teacherAgeCount
  );

  const studentEthnicityPieData = transformCountToObject(studentEthnicityCount);
  const teacherEthnicityPieData = transformCountToObject(teacherEthnicityCount);
  const studentGenderPieData = transformCountToObject(studentGenderCount);
  const teacherGenderPieData = transformCountToObject(teacherGenderCount);
  const studentAgePieData = transformCountToObject(studentAgeCount);
  const teacherAgePieData = transformCountToObject(teacherAgeCount);

  const boardTimes = getTimes(boardIndex);

  let boardStartTime = boardTimes.startTime;
  let boardEndTime = boardTimes.endTime;

  const timeViewedOverTimeData = await getAdminDashboardViewTimesBarData(
    db,
    boardStartTime,
    boardEndTime
  );

  const viewsOverTimeData = await getAdminDashboardViewsBarData(
    db,
    boardStartTime,
    boardEndTime
  );

  const reboardsOverTimeData = await getAdminDashboardReboardsBarData(
    db,
    boardStartTime,
    boardEndTime
  );

  const discussionOverTimeData = await getAdminDashboardDiscussionBarData(
    db,
    boardStartTime,
    boardEndTime,
    10,
    discussionDocs
  );

  const studentTimes = getTimes(studentIndex);

  let studentStartTime = studentTimes.startTime;
  let studentEndTime = studentTimes.endTime;

  const studentsOverTimeData = await getAdminDashboardUsersBarData(
    db,
    studentStartTime,
    studentEndTime,
    10,
    "Student"
  );

  const teacherTimes = getTimes(teacherIndex);

  let teacherStartTime = teacherTimes.startTime;
  let teacherEndTime = teacherTimes.endTime;

  const teachersOverTimeData = await getAdminDashboardUsersBarData(
    db,
    teacherStartTime,
    teacherEndTime,
    10,
    "Teacher"
  );

  studentTableData = Object.values(studentTableData);

  teacherTableData = Object.values(teacherTableData);

  return {
    boardTableData,
    discussionTableData,
    modalityTableData,
    modalityTypesPieData,
    modalityLRPieData,
    tiktokLRPieData,
    youtubeLRPieData,
    webLRPieData,
    modalityFavePieData,
    publicPrivateRatioPieData,
    commentsRepliesRatioPieData,
    studentEthnicityPieData,
    teacherEthnicityPieData,
    studentGenderPieData,
    teacherGenderPieData,
    studentAgePieData,
    teacherAgePieData,
    timeViewedOverTimeData,
    viewsOverTimeData,
    reboardsOverTimeData,
    discussionOverTimeData,
    studentsOverTimeData,
    teachersOverTimeData,
    studentTableData,
    teacherTableData,
  };

  //teacher data table
}

const today = new Date();
today.setHours(0, 0, 0, 0);

const endOfToday = new Date();
endOfToday.setHours(23, 59, 59, 59);

const sevenDaysAgo = new Date(today - 7 * 24 * 60 * 60 * 1000);
const thirtyDaysAgo = new Date(today - 30 * 24 * 60 * 60 * 1000);
const oneYearAgo = new Date(today - 365 * 24 * 60 * 60 * 1000);

export const getTimes = (index) => {
  switch (index) {
    case 0:
      return { startTime: today, endTime: endOfToday };
    case 1:
      return { startTime: sevenDaysAgo, endTime: endOfToday };
    case 2:
      return { startTime: thirtyDaysAgo, endTime: endOfToday };
    case 3:
      return { startTime: oneYearAgo, endTime: endOfToday };
  }
};

const calculateAge = (dob) => {
  const [month, day, year] = dob.split("/");
  const dobDate = new Date(year, month - 1, day);
  const ageDifMs = Date.now() - dobDate.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const renderBoardLink = ({ data, row, value }) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={{ flexDirection: "row", gap: 4, alignItems: "flex-end" }}
      onPress={() =>
        navigation.navigate("Board", {
          name: value,
          boardId: data[row.index].id,
        })
      }
    >
      <Text style={{ color: "#6395f9" }}>{value}</Text>
      <Octicons
        name="link-external"
        size={10}
        style={{ paddingBottom: 2 }}
        color="#6395f9"
      />
    </TouchableOpacity>
  );
};

const renderModalityLink = ({ data, row, value }) => {
  return (
    <TouchableOpacity
      style={{ flexDirection: "row", gap: 4, alignItems: "flex-end" }}
      onPress={() => Linking.openURL(data[row.index].link)}
    >
      <Text style={{ color: "#6395f9" }}>{value}</Text>
      <Octicons
        name="link-external"
        size={10}
        style={{ paddingBottom: 2 }}
        color="#6395f9"
      />
    </TouchableOpacity>
  );
};

function SelectStudentButton(props) {
  const studentId = `${props.data[props.row.index].id}`;

  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        gap: 5,
        alignItems: "center",
      }}
    >
      {props.data[props.row.index].profilePicture && (
        <Image
          style={{
            height: 25,
            width: 25,
            borderRadius: 25,
          }}
          source={{ uri: props.data[props.row.index].profilePicture }}
        />
      )}
      <Text style={{ color: "#6395f9" }}>{props.value}</Text>
    </TouchableOpacity>
  );
}

export const boardTableColumns = [
  {
    Header: "Board",
    accessor: "board", // accessor is the "key" in the data
    width: 300,
    Cell: renderBoardLink,
  },
  {
    Header: "Teacher",
    accessor: "teacherId",
  },
  {
    Header: "Views",
    accessor: "views",
    disableFilters: true,
  },
  {
    Header: "Replies",
    accessor: "replies",
    disableFilters: true,
  },
  {
    Header: "Reboards",
    accessor: "reboards",
    disableFilters: true,
  },
  {
    Header: "Favorites",
    accessor: "favorites",
    disableFilters: true,
  },
  {
    Header: "Modalities",
    accessor: "modalityCount",
    disableFilters: true,
  },
  {
    Header: "Sections",
    accessor: "sections",
    disableFilters: true,
  },
  {
    Header: "Public",
    accessor: "isPublic",
  },
  {
    Header: "Last Accessed",
    accessor: "lastAccessed",
    disableFilters: true,
  },
  {
    Header: "Created",
    accessor: "created",
  },
];

export const modalityDataTableColumns = [
  {
    Header: "Board",
    accessor: "board",
    width: 300,
    Cell: renderBoardLink,
  },
  {
    Header: "Title",
    accessor: "title",
    Cell: renderModalityLink,
  },
  {
    Header: "Favorites",
    accessor: "favorites",
    disableFilters: true,
  },
  {
    Header: "Rating",
    accessor: "rating",
    disableFilters: true,
  },
  {
    Header: "Likes",
    accessor: "likes",
    disableFilters: true,
  },
  {
    Header: "Dislikes",
    accessor: "dislikes",
    disableFilters: true,
  },
  {
    Header: "Created",
    accessor: "created",
    disableFilters: true,
  },
];

export const discussionTableColumns = [
  {
    Header: "Text",
    accessor: "text",
    width: 400,
  },
  {
    Header: "Board",
    accessor: "board",
    width: 400,
    Cell: renderBoardLink,
  },
  {
    Header: "User",
    accessor: "user",
  },
  {
    Header: "Likes",
    accessor: "likes",
    disableFilters: true,
  },
  {
    Header: "Replies",
    accessor: "replies",
    disableFilters: true,
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Teacher",
    accessor: "isTeacher",
  },
  {
    Header: "Created",
    accessor: "created",
    disableFilters: true,
  },
];

export const studentTableColumns = [
  {
    Header: "Student",
    accessor: "name",
    Cell: SelectStudentButton,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    width: 200,
  },
  {
    Header: "First Name",
    accessor: "firstName",
    width: 200,
  },
  {
    Header: "Gender",
    accessor: "gender",
  },
  {
    Header: "Ethnicity",
    accessor: "ethnicity",
    width: 150,
  },
  {
    Header: "Age",
    accessor: "age",
    width: 100,
  },
  {
    Header: "Email",
    accessor: "email",
    width: 200,
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Comments",
    accessor: "comments",
    disableFilters: true,
  },
  {
    Header: "Modality Favorites",
    accessor: "modalityFaves",
    disableFilters: true,
    width: 150,
  },
  {
    Header: "Total Comment Likes",
    accessor: "commentLikes",
    disableFilters: true,
    width: 200,
  },
  {
    Header: "Board Views",
    accessor: "boardViews",
    disableFilters: true,
    width: 150,
  },
  {
    Header: "Modality Likes",
    accessor: "modalityLikes",
    disableFilters: true,
    width: 200,
  },
  {
    Header: "Modality Dislikes",
    accessor: "modalityDislikes",
    disableFilters: true,
    width: 200,
  },
  {
    Header: "Engagement (m)",
    accessor: "viewTime",
    disableFilters: true,
    width: 150,
  },
  {
    Header: "Joined",
    accessor: "joined",
    disableFilters: true,
  },
];

export const teacherTableColumns = [
  {
    Header: "Teacher",
    accessor: "name",
    Cell: SelectStudentButton,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    width: 200,
  },
  {
    Header: "First Name",
    accessor: "firstName",
    width: 200,
  },
  {
    Header: "Gender",
    accessor: "gender",
  },
  {
    Header: "Ethnicity",
    accessor: "ethnicity",
    width: 150,
  },
  {
    Header: "Age",
    accessor: "age",
    width: 100,
  },
  {
    Header: "Email",
    accessor: "email",
    width: 200,
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Boards",
    accessor: "boards",
    disableFilters: true,
  },
  {
    Header: "Modalities",
    accessor: "modalities",
    disableFilters: true,
  },
  {
    Header: "Modality Favorites",
    accessor: "modalityFaves",
    disableFilters: true,
    width: 150,
  },
  {
    Header: "Modality Likes",
    accessor: "modalityLikes",
    disableFilters: true,
    width: 200,
  },
  {
    Header: "Modality Dislikes",
    accessor: "modalityDislikes",
    disableFilters: true,
    width: 200,
  },
  {
    Header: "Sections",
    accessor: "sections",
    disableFilters: true,
  },
  {
    Header: "Reboards",
    accessor: "reboards",
    disableFilters: true,
  },
  {
    Header: "Boards reboarded",
    accessor: "reboarded",
    disableFilters: true,
    width: 150,
  },
  {
    Header: "Comments",
    accessor: "comments",
    disableFilters: true,
  },
  {
    Header: "Student Comments",
    accessor: "studentComments",
    disableFilters: true,
    width: 200,
  },
  {
    Header: "Student Comment Likes",
    accessor: "studentCommentLikes",
    disableFilters: true,
    width: 200,
  },
  {
    Header: "Board Favorites",
    accessor: "boardFavorites",
    disableFilters: true,
    width: 150,
  },
  {
    Header: "Board Views",
    accessor: "boardViews",
    disableFilters: true,
    width: 150,
  },
  {
    Header: "Student Engagement (m)",
    accessor: "engagement",
    disableFilters: true,
    width: 200,
  },
  {
    Header: "Muted Students",
    accessor: "mutedStudents",
    disableFilters: true,
    width: 150,
  },
  {
    Header: "Joined",
    accessor: "joined",
    disableFilters: true,
  },
];
