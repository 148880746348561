import * as React from "react";
import { useWindowDimensions, ScrollView } from "react-native";

import { Flex } from "@react-native-material/core";

import LoginHero from "./LoginHero";

import PasswordResetForm from "../../components/account/PasswordResetForm";

export default function PasswordResetScreen({ navigation, route }) {
  const { height, width } = useWindowDimensions();
  const medium = width < 1008 && width >= 641;
  const small = width < 641;

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      style={{ backgroundColor: "white" }}
    >
      <Flex
        p={18}
        bg={"white"}
        direction={medium ? "column" : "row"}
        style={{ height: "100%" }}
      >
        <PasswordResetForm
          email={route.params.email}
          medium={medium}
          small={small}
          navigation={navigation}
        />
        {!small && <LoginHero />}
      </Flex>
    </ScrollView>
  );
}
