import React from "react";
import {
  ScrollView,
  Alert,
  TouchableOpacity,
  Platform,
  Text,
} from "react-native";

import { Flex, HStack } from "@react-native-material/core";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Fragment } from "react";
import FabGroup from "../../components/general/fabs/FabGroup";
import SquareGrid from "../../components/cards/Grid/SquareGrid";
import AddClassPopup, {
  createUniqueClassCode,
} from "../../components/data/input/Modals/AddClassPopup";
import SimpleToast from "../../components/animations/toasts/SimpleToast";
import { useEffect } from "react";

import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  onSnapshot,
  getDoc,
  deleteDoc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useState } from "react";
import { generatePastelColor, getPastelColor } from "../../utils/generateColor";
import CustomAlertDialog from "../../components/data/input/Modals/CustomAlertDialog";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { DeviceEventEmitter } from "react-native";
import { useSettings } from "../../components/contexts/SettingsContext";

export default function StaffClassesScreen({ navigation }) {
  const [showAddClass, setShowAddClass] = React.useState(false);
  const [toastVisible, setToastVisible] = React.useState(0);
  const [toastMessage, setToastMessage] = React.useState("");
  const [classesData, setClassesData] = React.useState([]);
  const [filteredClassesData, setFilteredClassesData] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [initalClassName, setInitalClassName] = React.useState("");
  const [initalColor, setInitalColor] = React.useState("");
  const [initalStudentCount, setInitalStudentCount] = React.useState("");
  const [editClassId, setEditClassId] = React.useState("");
  const [currentClassId, setCurrentClassID] = React.useState("");
  const [showArchived, setShowArchived] = useState(false);
  const [alertPromptVisible, setAlertPromptVisible] = useState(false);

  
  useEffect(() => {
    fetchData();

    DeviceEventEmitter.addListener("showAddNewClass", () => {
      setEdit(false);
      setShowAddClass(true);
    });

    return () => {
      DeviceEventEmitter.removeAllListeners("showAddNewClass");
    };
  }, []);

  useEffect(() => {
    if (showArchived) {
      setFilteredClassesData(classesData.filter((item) => item.archived));
    } else {
      setFilteredClassesData(classesData.filter((item) => !item.archived));
    }
  }, [showArchived]);

  const fetchData = async () => {
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const db = getFirestore();

    // Get a reference to the teacher document
    const teacherRef = doc(db, "users", userId);

    // Query for classes documents where the teacherId field is a reference to the teacher document
    const q = query(
      collection(db, "classes"),
      where("teacherId", "==", teacherRef)
    );

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      // Create an array of promises for each document in the querySnapshot
      const classDataPromises = querySnapshot.docs.map(async (doc) => {
        // Get the classRef
        const classRef = doc.ref;

        // Query for boards where the classIds array contains the classRef
        const boardsQuery = query(
          collection(db, "boards"),
          where("classIds", "array-contains", classRef)
        );

        // Get the snapshot
        const boardsSnapshot = await getDocs(boardsQuery);

        // The number of boards for this class is the size of the snapshot
        const boardCount = boardsSnapshot.size;

        return {
          title: doc.data().className,
          tags: [
            `${doc.data().activeStudents ? doc.data().activeStudents : 0} / ${
              doc.data().studentCount
            } students`,
            `${boardCount} boards`,
            `#${doc.data().code}$$800`,
          ],
          notificationCount: 0,
          backgroundColor: doc.data().color,
          id: doc.id,
          archived: doc.data().status === "archived" ? true : false,
        };
      });

      // Resolve all promises
      const _classData = await Promise.all(classDataPromises);

      setClassesData(_classData);

      if (showArchived) {
        setFilteredClassesData(_classData.filter((item) => item.archived));
      } else {
        setFilteredClassesData(_classData.filter((item) => !item.archived));
      }
    });

    return unsubscribe;
  };

  const editClassSelected = async (id) => {
    const db = getFirestore();

    setEdit(true);

    const classRef = doc(db, "classes", id);

    // Get a reference to the class document
    const classDoc = await getDoc(classRef);

    setInitalClassName(classDoc.data().className);
    setInitalStudentCount(classDoc.data().studentCount);
    setInitalColor(classDoc.data().color);
    setEditClassId(id);

    setShowAddClass(true);
  };

  const confirmDelete = (id) => {
    setAlertPromptVisible(true);
    setCurrentClassID(id);
  };

  const deleteClassSelected = async (id) => {
    const db = getFirestore();
    const classRef = doc(db, "classes", id);

    // Query for documents in studentClasses where classId references classRef
    const studentClassesQ = query(
      collection(db, "studentClasses"),
      where("classId", "==", classRef)
    );

    // Get the snapshot
    const studentClassesSnapshot = await getDocs(studentClassesQ);

    // Create an array of promises for each document in the snapshot
    const deletePromises = studentClassesSnapshot.docs.map((doc) =>
      // Delete the document
      deleteDoc(doc.ref)
    );

    // Delete all the documents in studentClasses that reference classRef
    await Promise.all(deletePromises);

    // Delete the class document
    await deleteDoc(classRef);

    setToastMessage("Class deleted");
    setToastVisible(Math.random());
  };

  const archiveClassSelected = async (id) => {
    const db = getFirestore();

    await updateDoc(doc(db, "classes", id), {
      status: showArchived ? "active" : "archived",
    });

    setToastMessage(
      `Class successfully ${showArchived ? "restored" : "archived"}`
    );
    setToastVisible(Math.random());
  };

  const toggleShowArchivedPressed = () => {
    setShowArchived(!showArchived);

    if (!showArchived) setToastMessage("Now showing all archived classes");
    else setToastMessage("Now showing all active classes");
    setToastVisible(Math.random());
  };

  const refreshCodePressed = async (id) => {
    const db = getFirestore();

    const code = await createUniqueClassCode(db);

    await updateDoc(doc(db, "classes", id), {
      code,
    });
  };

  const { settings } = useSettings();
  const classTileSize = settings.classTileSize ? settings.classTileSize : 1;

  return (
    <Fragment>
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, backgroundColor: "#f0eff4" }}
      >
        <Flex fill p={16} style={{ gap: 16, paddingBottom: 91 }}>
          <AddClassPopup
            onClose={() => setShowAddClass(false)}
            onSubmit={() => setShowAddClass(false)}
            visible={showAddClass}
            edit={edit}
            setToastMessage={setToastMessage}
            setToastVisible={setToastVisible}
            initalClassName={edit ? initalClassName : ""}
            initialStudentCount={edit ? initalStudentCount : "30"}
            initialColor={edit ? initalColor : getPastelColor()}
            editClassId={edit ? editClassId : ""}
          />
          <SquareGrid
            key={classTileSize}
            onAddCardPress={() => {
              setEdit(false);
              setShowAddClass(true);
            }}
            columnWidth={200 * classTileSize}
            onCardPress={(item, e) => {
              navigation.navigate("Class", {
                name: item.title + " " + item.tags[2].split("$")[0],
                classId: item.id,
                color: item.backgroundColor,
              });
            }}
            data={filteredClassesData}
            numberOfLines={3}
            extraFilter={
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  width: 40,
                  borderRadius: 5,
                }}
                onPress={toggleShowArchivedPressed}
              >
                <MaterialCommunityIcons
                  name={
                    showArchived
                      ? "archive-eye-outline"
                      : "archive-cancel-outline"
                  }
                  size={24}
                  color="black"
                />
              </TouchableOpacity>
            }
            options={[
              {
                label: "Edit class",
                icon: (
                  <MaterialCommunityIcons
                    name="pencil-plus-outline"
                    size={20}
                    color="black"
                  />
                ),
                onSelect: editClassSelected,
              },
              {
                label: "Refresh code",
                icon: (
                  <MaterialCommunityIcons
                    name="refresh-auto"
                    size={20}
                    color="black"
                  />
                ),
                onSelect: refreshCodePressed,
              },
              {
                icon: (
                  <MaterialCommunityIcons
                    name={
                      showArchived
                        ? "archive-eye-outline"
                        : "archive-lock-outline"
                    }
                    size={20}
                    color="black"
                  />
                ),
                label: showArchived ? "Restore class" : "Archive class",
                onSelect: archiveClassSelected,
              },
              {
                icon: (
                  <MaterialCommunityIcons
                    name="delete-forever-outline"
                    size={20}
                    color="black"
                  />
                ),
                label: "Delete class",
                onSelect: confirmDelete,
              },
            ]}
          />
        </Flex>
      </KeyboardAwareScrollView>
      <Fragment>
        <FabGroup
          buttons={[
            {
              icon: (
                <MaterialCommunityIcons
                  name="keyboard-return"
                  size={24}
                  color="black"
                />
              ),
              color: "white",
              onPress: () => navigation.goBack(),
            },
            {
              icon: <MaterialCommunityIcons name="bank" size={32} />,
              color: "white",
              onPress: () => navigation.navigate("Vault"),
            },
            {
              icon: <MaterialCommunityIcons name="table" size={32} />,
              color: "white",
              onPress: () => navigation.navigate("Boards"),
            },
          ]}
        />
        <SimpleToast message={toastMessage} visible={toastVisible} />
        <CustomAlertDialog
          setModalVisible={setAlertPromptVisible}
          onConfirm={() => archiveClassSelected(currentClassId)}
          modalVisible={alertPromptVisible}
          title="Are you sure?"
          message="This will permenatly delete this class and all its data."
          buttons={[
            <TouchableOpacity
              key={"cancel"}
              onPress={() => setAlertPromptVisible(false)}
            >
              <Text style={{ color: "#1677ff" }}>Cancel</Text>
            </TouchableOpacity>,
            <TouchableOpacity
              key={"delete"}
              style={{
                backgroundColor: "#f5222d",
                borderRadius: 5,
                paddingHorizontal: 14,
                paddingVertical: 10,
              }}
              onPress={() => {
                deleteClassSelected(currentClassId);
                setAlertPromptVisible(false);
              }}
            >
              <Text style={{ color: "white" }}>Delete class</Text>
            </TouchableOpacity>,
          ]}
        />
      </Fragment>
    </Fragment>
  );
}
