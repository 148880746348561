import BasicComponent from "../wrappers/BasicComponent";
import {
  Dimensions,
  StyleSheet,
  ScrollView,
  View,
  useWindowDimensions,
  Text,
} from "react-native";
import { useState, useRef, useEffect } from "react";

import Lottie from "lottie-react-native";
import {
  VictoryTheme,
  VictoryPie,
  VictoryLabel,
  VictoryTooltip,
} from "victory-native";
import PopIn from "../../animations/visibility/PopIn";

const noDataFoundAnimation = require("../../../assets/animations/no-data-found.json");

const CustomTooltip = (props) => {
  const { x, y, datum, text } = props;

  return (
    <>
      <VictoryTooltip
        {...props}
        x={x}
        y={y}
        orientation="top"
        pointerLength={0}
        cornerRadius={0}
        flyoutStyle={{ fill: "none", stroke: "none" }}
        renderInPortal={false}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fill: "#333" }}
        x={x}
        y={y + datum.y / 2}
        text={text}
      />
    </>
  );
};

export default function BasicPieChart({ colorScale, data = [], ...props }) {
  const [width, setWidth] = useState(450);
  const [hasWidth, setHasWidth] = useState(false);
  const [showNoData, setShowNoData] = useState(false);

  const animationRef = useRef(null);

  const onLayout = (e) => {
    setHasWidth(true);
    setWidth(e.nativeEvent.layout.width - 32);
  };

  useEffect(() => {
    if (data.length === 0) {
      setShowNoData(true);
      setTimeout(() => {
        if (animationRef.current) {
          animationRef.current?.reset();
          animationRef.current?.play();
        }
      }, 100);
    } else {
      setShowNoData(false);
    }
  }, [data]);

  if (!showNoData)
    return (
      <BasicComponent onLayout={onLayout} {...props}>
        <View style={{ height: 300 }}>
          <PopIn isVisible={hasWidth}>
            {width > 0 && (
              <VictoryPie
                colorScale={colorScale}
                theme={VictoryTheme.material}
                width={width}
                height={300}
                data={data}
                labels={({ datum }) => `${datum.x} (${Math.round(datum.y)})`}
              />
            )}
          </PopIn>
        </View>
      </BasicComponent>
    );
  else
    return (
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Lottie
          style={{
            height: 300,
            width: 300,
          }}
          autoPlay
          loop={true}
          source={noDataFoundAnimation}
          ref={animationRef}
        />
      </View>
    );
}
