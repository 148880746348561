import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useWindowDimensions } from "react-native";
import StaffBoardScreen from "../../screens/staff/StaffBoard";
import StaffBoardsScreen from "../../screens/staff/StaffBoards";
import Animated from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Button } from "react-native";
import { Text } from "react-native";

const Stack = createNativeStackNavigator();

export default function StaffBoardsNavigator({ navigation }) {
  const { height, width } = useWindowDimensions();
  const large = width >= 1008;
  const medium = width < 1008 && width >= 641;
  const small = width < 641;

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="My Boards"
        options={{ headerShown: false }}
        component={StaffBoardsScreen}
      />
      <Stack.Screen
        name="Board"
        options={({ route }) => ({ title: route.params.name })}
        component={StaffBoardScreen}
      ></Stack.Screen>
    </Stack.Navigator>
  );
}
