import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";

const SettingsContext = createContext();

export const useSettings = () => {
  return useContext(SettingsContext);
};

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    boardTileSize: 1,
    getLitHidden: true,
    animationsEnabled: true,
    publicProfile: true,
    boardPublicByDefault: true,
    maxCommentCharCount: 500,
    maxReplyCharCount: 500,
    classTileSize: 0.75,
  });
  const [saving, setSaving] = useState(false);

  const db = getFirestore();
  const auth = getAuth();
  const uid = auth?.currentUser?.uid;

  useEffect(() => {
    fetchSettings();
  }, [uid]);

  async function fetchSettings() {
    setSaving(true);

    if (!auth.currentUser) {
      setSaving(false);
      return;
    }

    const settingsRef = doc(db, "userSettings", uid);

    const settingsDoc = await getDoc(settingsRef);

    if (settingsDoc.exists()) {
      setSettings(settingsDoc.data());
      setSaving(false);
    } else {
      saveSettings(settings);
    }
  }

  const [debouncedSettings, setDebouncedSettings] = useState(settings);

  // Debounce saveSettings with a delay of 1000ms (1 second)
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSettings(settings);
    }, 1000);

    return () => clearTimeout(timerId); // Clear the timer if the settings change
  }, [settings]);

  useEffect(() => {
    saveSettings(debouncedSettings);
  }, [debouncedSettings]);

  async function saveSettings(_settings) {
    setSaving(true);

    const auth = getAuth();

    if (!auth.currentUser) {
      setSaving(false);
      return;
    }

    const settingsRef = doc(db, "userSettings", uid);

    await setDoc(settingsRef, _settings);

    setSaving(false);
  }

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
