import React, { useState, useEffect } from "react";

import { ActivityIndicator, Platform, TextInput, View } from "react-native";

import {
  Box,
  Text,
  Button,
  Flex,
  HStack,
  VStack,
  Surface,
} from "@react-native-material/core";

import GoogleLogo from "../graphics/GoogleLogo";
import Logo from "../graphics/Logo";

import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithCredential,
} from "firebase/auth";

import { getFirestore, doc, setDoc, serverTimestamp } from "firebase/firestore";

import { StyleSheet, DeviceEventEmitter, TouchableOpacity } from "react-native";

import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";
import CustomAlertDialog from "../data/input/Modals/CustomAlertDialog";

WebBrowser.maybeCompleteAuthSession();

export default function LoginForm({ small, ...props }) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordIncorrect, setPasswordIncorrect] = React.useState(false);
  const [emailIncorrect, setEmailIncorrect] = React.useState(false);
  const [emailSignInLoading, setEmailSignInLoading] = React.useState(false);
  const [overrideDisabled, setOverrideDisabled] = useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");

  const [token, setToken] = useState("");

  const [request, response, promptAsync] = Google.useAuthRequest({
    androidClientId: "GOOGLE_GUID.apps.googleusercontent.com",
    iosClientId:
      "475932163263-0co5rjdlmd7fpgaoli26i3c9nkn7oao8.apps.googleusercontent.com",
    expoClientId:
      "475932163263-191sahf99iftkur3ne612mbf2v6nav2u.apps.googleusercontent.com",
    webClientId:
      "475932163263-svabmfdc4gvejk795ovsjp2gp2bo2r4q.apps.googleusercontent.com",
  });

  const auth = getAuth();
  const db = getFirestore();

  //to fix browser autofill not triggering react
  useEffect(() => {
    if (Platform.OS == "web") {
      var input = document.getElementById("emailInput");

      setTimeout(() => {
        if (input && input.matches(":-internal-autofill-selected")) {
          setOverrideDisabled(true);
        }
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (response?.type === "success") {
      DeviceEventEmitter.emit("showLoadingIndicator");
      setToken(response.authentication.accessToken);
      try {
        const credential = GoogleAuthProvider.credential(
          response.authentication.idToken,
          response.authentication.accessToken
        );

        signInWithCredential(auth, credential)
          .then((userCredential) => {
            if (userCredential._tokenResponse.isNewUser) {
              // The user is new, update their information in your database
              const { email, uid } = userCredential.user;
              const firstName = userCredential._tokenResponse.firstName;
              const lastName = userCredential._tokenResponse.lastName;

              const userRef = doc(db, "users", uid);
              setDoc(userRef, {
                firstName,
                lastName,
                creationTime: serverTimestamp(),
                lastSignInTime: serverTimestamp(),
                email,
                profilePicture: userCredential.user.photoURL,
                role: null,
              });
            } else {
              // The user is not new, they're signing in again
            }
          })
          .catch((error) => {
            // Handle Errors here.
            const errorMessage = error.message;
            console.error(errorMessage);
            DeviceEventEmitter.emit("hideLoadingIndicator");
          });
      } catch (error) {
        console.error(error);
        DeviceEventEmitter.emit("hideLoadingIndicator");
      }
    } else {
      DeviceEventEmitter.emit("hideLoadingIndicator");
    }
  }, [response, token]);

  const passwordRef = React.useRef(null);
  const emailRef = React.useRef(null);

  const loginClicked = async () => {
    setEmailSignInLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        //alert(`Successfully signed in as ${user.email}`);
        setEmailSignInLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        setEmailSignInLoading(false);

        switch (errorCode) {
          case `auth/wrong-password`:
            setDialogMessage(
              "It seems like the password you've entered is incorrect. Please try again, making sure your Caps Lock is off and your spelling is correct. If you've forgotten your password, you can use the 'Forgot Password' link to reset it."
            );
            setPasswordIncorrect(true);
            setEmailIncorrect(false);
            passwordRef.current.focus();
            break;
          case `auth/user-not-found`:
            setDialogMessage(
              "We couldn't find an account associated with the email address you've entered. Please double-check for any typos. If you're new here, feel free to create a new account. If you've forgotten which email you used to sign up, please contact our support team for assistance.No account with that email was found."
            );
            setEmailIncorrect(true);
            setPasswordIncorrect(false);
            emailRef.current.focus();
            break;
          case `auth/too-many-requests`:
            setDialogMessage(
              "Sorry, but due to multiple unsuccessful login attempts, your account has been temporarily locked to protect your security. Please try again in a few minutes. If you've forgotten your password, consider using the 'Forgot Password' feature to reset it."
            );
            break;
          default:
            setDialogMessage("An error occured, please try again");
        }
        setShowDialog(true);
      });
  };

  const signInWithGoogleClicked = async () => {
    await promptAsync({ useProxy: false });
  };

  return (
    <>
      <View
        style={{
          backgroundColor: "#ffecd9",
          flex: 1,
          borderRadius: 5,
          borderBottomRightRadius: small ? 5 : 0,
          borderTopRightRadius: small ? 5 : 0,
        }}
      >
        <View style={{ margin: 32, gap: 16 }}>
          <Flex>
            <Logo />
          </Flex>
          <Text
            color="#333333"
            style={{
              fontWeight: "bold",
              fontSize: 44,
              paddingTop: 0,
              paddingBottom: 8,
            }}
          >
            Login
          </Text>
          <Text
            color="#333333"
            style={{
              marginTop: -16,
              fontWeight: "500",
              paddingBottom: 16,
            }}
          >
            Unlock your literary potential
          </Text>
          <Text style={[styles.label, emailIncorrect && styles.incorrectLabel]}>
            Email
          </Text>
          <Surface
            style={{ borderRadius: 5, backgroundColor: "white" }}
            elevation={1}
          >
            <TextInput
              key={"emailInput"}
              nativeID="emailInput"
              id="emailInput"
              inputMode="email"
              style={[
                { height: 40, borderRadius: 5, paddingLeft: 10 },
                emailIncorrect && styles.incorrect,
              ]}
              ref={emailRef}
              label="Email"
              autoComplete="email"
              placeholderTextColor={"#aaa"}
              value={email}
              onChangeText={(value) => {
                setEmail(value);
                if (overrideDisabled) setOverrideDisabled(false);
              }}
              placeholder="mail@website.com"
              secureTextEntry={false}
            ></TextInput>
          </Surface>
          <Text
            style={[styles.label, passwordIncorrect && styles.incorrectLabel]}
          >
            Password
          </Text>
          <Surface
            style={{ borderRadius: 5, backgroundColor: "white" }}
            elevation={1}
          >
            <TextInput
              style={[
                { height: 40, borderRadius: 5, paddingLeft: 10 },
                passwordIncorrect && styles.incorrect,
              ]}
              ref={passwordRef}
              label="Password"
              placeholderTextColor={"#aaa"}
              secureTextEntry
              autoComplete="password"
              value={password}
              onChangeText={(value) => {
                setPassword(value);
                if (overrideDisabled) setOverrideDisabled(false);
              }}
              placeholder="Minimum 8 characters"
            ></TextInput>
          </Surface>
          <Flex style={{ alignItems: "flex-end" }}>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Forgot Password");
              }}
            >
              <Text style={{ color: "#eb9940", fontWeight: "bold" }}>
                Forgot password?
              </Text>
            </TouchableOpacity>
          </Flex>
          <TouchableOpacity
            disabled={password.length <= 0 && !overrideDisabled}
            onPress={loginClicked}
            style={[
              {
                padding: 16,
                backgroundColor: "#eb9940",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 4,
              },
              ((password.length <= 0 && !overrideDisabled) ||
                emailSignInLoading) && {
                opacity: 0.5,
              },
            ]}
          >
            <Text
              style={{
                textAlign: "center",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Login
            </Text>
            {emailSignInLoading && (
              <ActivityIndicator size={"small"} color={"white"} />
            )}
          </TouchableOpacity>
          <Flex
            direction="row"
            center
            style={{ gap: 8, paddingTop: 0, paddingBottom: 0 }}
          >
            <Box
              style={{
                flex: 1,
                borderBottomWidth: 1,
                borderBottomColor: "#ac9e91",
              }}
            />
            <Text style={{}} color="#ac9e91">
              OR
            </Text>
            <Box
              style={{
                flex: 1,
                borderBottomWidth: 1,
                borderBottomColor: "#ac9e91",
              }}
            />
          </Flex>
          <TouchableOpacity
            onPress={signInWithGoogleClicked}
            style={[
              {
                padding: 16,
                backgroundColor: "white",
                justifyContent: "center",
                flexDirection: "row",
                gap: 10,
              },
            ]}
          >
            <GoogleLogo />
            <Text style={{ textAlign: "center", color: "#666" }}>
              Login with Google
            </Text>
          </TouchableOpacity>
          <HStack style={{ justifyContent: "center" }}>
            <Text style={{ paddingTop: 0 }} color="#ac9e91">
              New to GetLit?{" "}
            </Text>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Register");
              }}
            >
              <Text style={{ color: "#eb9940", fontWeight: "bold" }}>
                Register
              </Text>
            </TouchableOpacity>
          </HStack>
        </View>
      </View>
      <CustomAlertDialog
        setModalVisible={showDialog}
        onConfirm={() => {}}
        modalVisible={showDialog}
        title={"Whoops!"}
        message={dialogMessage}
        buttons={[
          <TouchableOpacity
            key={"cancel"}
            onPress={() => {
              setShowDialog(false);
            }}
          >
            <Text style={{ color: "#1677ff" }}>Cancel</Text>
          </TouchableOpacity>,
          <TouchableOpacity
            key={"submit"}
            style={[
              {
                backgroundColor: "#1677ff",
                borderRadius: 5,
                paddingHorizontal: 14,
                paddingVertical: 10,
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
              },
            ]}
            onPress={() => setShowDialog(false)}
          >
            <Text style={{ color: "white" }}>Retry</Text>
          </TouchableOpacity>,
        ]}
      />
    </>
  );
}

const styles = StyleSheet.create({
  input: {
    backgroundColor: "white",
    height: 40,
    borderColor: "#d9d9d9",
    borderBottomWidth: 2,
    borderRadius: 4,
    marginBottom: 15,
    paddingLeft: 5,
    marginTop: -5,
  },
  incorrect: {
    borderColor: "#ff0000",
    borderBottomWidth: 2,
  },
  label: {
    color: "black",
    fontWeight: "500",
  },
  incorrectLabel: {
    fontWeight: "500",
    color: "#ff0000",
  },
});
