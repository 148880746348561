import { useWindowDimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { CustomDrawerContent } from "./CustomDrawerContent";
import CustomHeaderRightStudents from "./CustomHeaderRightStudents";

import React from "react";
import StudentClassesNavigator from "./StudentClassesNavigator";
import StudentSettingsScreen from "../../screens/students/StudentSettings";
import StudentDashboardNavigator from "./StudentDashboardNavigator";
import StaffAccountScreen from "../../screens/staff/StaffAcount";
import { View } from "react-native";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { Text } from "react-native";
import { changeColorAlpha } from "../../utils/generateColor";

const Drawer = createDrawerNavigator();

export default function StudentNavigator({
  userType,
  orientation,
  defaultRoute = "Classes",
}) {
  const { height, width } = useWindowDimensions();
  const large = width >= 1008;
  const medium = width < 1008 && width >= 641;
  const small = width < 641;

  return (
    <Drawer.Navigator
      screenOptions={({ navigation, route }) => ({
        drawerStyle: { width: 250, backgroundColor: "white" },
        drawerLabelStyle: { fontWeight: "500", color: "black" },
        drawerInactiveTintColor: "rgba(255,255,255,0.66)",
        drawerActiveBackgroundColor: changeColorAlpha("#ed9b40", 0.1),
        drawerType: large ? "permanent" : "front",
        headerLeftContainerStyle: {
          display: large ? "none" : "flex",
          maxWidth: 30,
        },
        headerRight: (props) => (
          <CustomHeaderRightStudents
            {...props}
            navigation={navigation}
            userType={userType}
          />
        ),
        drawerLabel: ({ focused, color }) => {
          const focusedColor = "#ed9b40"; // change to your bright color
          const unfocusedColor = "grey"; // change to your default color
          return (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: 220,
                position: "relative",
                marginLeft: -20,
              }}
            >
              <Text
                style={{
                  color: focused ? focusedColor : unfocusedColor,
                  fontWeight: focused ? "500" : "600",
                }}
              >
                {route.name}
              </Text>
              {focused && (
                <View
                  style={{
                    height: 50,
                    width: 3,
                    backgroundColor: focusedColor,
                    position: "absolute",
                    right: 0,
                  }}
                />
              )}
            </View>
          );
        },
        headerStyle: {
          height: orientation == 4 ? 58 : 100,
          backgroundColor: "white",
        },
      })}
      drawerContent={CustomDrawerContent}
      initialRouteName={defaultRoute}
    >
      <Drawer.Screen
        name="Dashboard"
        component={StudentDashboardNavigator}
        options={{
          drawerIcon: ({ focused, size, color }) => (
            <MaterialCommunityIcons
              color={focused ? "#ed9b40" : "grey"}
              name="monitor-dashboard"
              size={size}
            />
          ),
        }}
      />
      <Drawer.Screen
        name="Classes"
        component={StudentClassesNavigator}
        options={{
          drawerIcon: ({ focused, size, color }) => (
            <Ionicons
              color={focused ? "#ed9b40" : "grey"}
              name="people"
              size={size}
            />
          ),
        }}
      />
      <Drawer.Screen
        name="Account"
        component={StaffAccountScreen}
        options={{
          drawerIcon: ({ focused, size, color }) => (
            <MaterialCommunityIcons
              color={focused ? "#ed9b40" : "grey"}
              name="account"
              size={size}
            />
          ),
        }}
      />
      <Drawer.Screen
        name="Settings"
        component={StudentSettingsScreen}
        options={{
          drawerIcon: ({ focused, size, color }) => (
            <MaterialCommunityIcons
              color={focused ? "#ed9b40" : "grey"}
              name="cog"
              size={size}
            />
          ),
        }}
      />
    </Drawer.Navigator>
  );
}
