import React, { useEffect, useRef } from "react";
import { Animated, StyleSheet, Text } from "react-native";

const SimpleToast = ({ message, visible }) => {
  const opacityAnim = useRef(new Animated.Value(0)).current;
  const [display, setDisplay] = React.useState("none");

  const animation = Animated.sequence([
    Animated.timing(opacityAnim, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }),
    Animated.delay(6000),
    Animated.timing(opacityAnim, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }),
    {
      start: (cb) => {
        setDisplay("none");
        cb({ finished: true });
      },
    },
  ]);

  useEffect(() => {
    if (!visible) return;
    setDisplay("flex");

    animation.reset();
    animation.start();
  }, [visible]);

  return (
    <Animated.View
      style={[
        styles.toastContainer,
        {
          opacity: opacityAnim,
          display: display,
          zIndex: visible ? 500 : 9999,
        },
      ]}
    >
      <Text style={styles.toastText}>{message}</Text>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  toastContainer: {
    position: "absolute",
    bottom: 25,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 20,
    alignSelf: "center",
    zIndex: 9999,
  },
  toastText: {
    color: "white",
    fontSize: 16,
  },
});

export default SimpleToast;
