import React, { useEffect } from "react";

import { Flex, HStack, Surface, VStack } from "@react-native-material/core";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Fragment } from "react";
import FabGroup from "../../components/general/fabs/FabGroup";
import SquareGrid from "../../components/cards/Grid/SquareGrid";
import {
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Image, Platform, RefreshControl } from "react-native";
import { Spinner } from "native-base";

import { useRoute } from "@react-navigation/native";
import AutoPopIn from "../../components/animations/visibility/AutoPopIn";
import { Text } from "react-native";
import { useSettings } from "../../components/contexts/SettingsContext";

export default function StaffVaultTeacherScreen({ navigation }) {
  const [boardData, setBoardData] = React.useState([]);
  const [filteredBoardData, setFilteredBoardData] = React.useState([]);
  const [dataLoading, setDataLoading] = React.useState(true);
  const [refreshing, setRefreshing] = React.useState(false);
  const [reboards, setReboards] = React.useState("...");
  const [authorJoinDate, setAuthorJoinDate] = React.useState("");
  const [authorAvatar, setAuthorAvatar] = React.useState("loading");

  const db = getFirestore();
  const route = useRoute();
  const authorId = route.params.authorId;
  const authorRef = doc(db, "users", authorId);

  const authorDateObject = new Date(authorJoinDate).toLocaleDateString();

  useEffect(() => {
    setDataLoading(true);
    fetchData();
    setAuthorAvatar(route.params.authorAvatar ? route.params.authorAvatar : "");
  }, []);

  const fetchData = async () => {
    setRefreshing(true);

    try {
      const boardQuery = query(
        collection(db, "boards"),
        where("isPublic", "==", true),
        where("teacherId", "==", authorRef),
        orderBy("creationTimeStamp", "asc")
      );

      const authorQuery = await getDoc(
        doc(db, "publicUsers", route.params.authorId)
      );

      setReboards(
        authorQuery.data().reboards ? authorQuery.data().reboards : "0"
      );
      setAuthorJoinDate(
        authorQuery.data()?.creationTime
          ? authorQuery.data()?.creationTime
          : Date()
      );
      setAuthorAvatar(authorQuery.data()?.profilePicture);
      navigation.setOptions({
        title:
          authorQuery.data()?.firstName + " " + authorQuery.data()?.lastName,
      });

      const boardsSnapshot = await getDocs(boardQuery);

      const boards = boardsSnapshot.docs.map((doc) => {
        let tags = [
          `${doc.data().reboards ? doc.data().reboards : 0} reboard${
            doc.data().reboards !== 1 ? "s" : ""
          }$black$bold`,
          `${doc.data().vaultViews ? doc.data().vaultViews : 0} view${
            doc.data().vaultViews !== 1 ? "s" : ""
          }$black$300`,
          `${doc.data().sections ? doc.data().sections : 0} section${
            doc.data().sections !== 1 ? "s" : ""
          }$black$300`,
          `${doc.data().creationTimeStamp?.toDate().toDateString()}`,
        ];

        if (doc.data().modalityCount) {
          tags = [
            ...tags.slice(0, 3),
            `${doc.data().modalityCount} modalities$#666$300`,
            ...tags.slice(3),
          ];
        }

        return {
          title: doc.data().boardName,
          id: doc.id,
          backgroundColor: doc.data().color,
          tags,
          authorId: doc.data().teacherId.id,
        };
      });

      setBoardData(boards);
      setFilteredBoardData(boards);
    } catch (e) {
      console.error(e);
    }

    setRefreshing(false);
    setDataLoading(false);
  };

  const renderProfilePicture = React.useMemo(() => {
    if (authorAvatar !== null || "")
      return (
        <Image
          source={{ uri: authorAvatar }}
          style={{ width: 32, height: 32, borderRadius: 32 }}
        />
      );
    else
      return (
        <MaterialCommunityIcons
          name="account-circle"
          size={44}
          color={"#ccc"}
          style={{ marginLeft: 0 }}
        />
      );
  }, [authorAvatar]);

  const { settings } = useSettings();
  const boardTileSize = settings.boardTileSize ? settings.boardTileSize : 1;

  return (
    <Fragment>
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, backgroundColor: "#f0eff4" }}
        refreshControl={
          !dataLoading && (
            <RefreshControl refreshing={refreshing} onRefresh={fetchData} />
          )
        }
      >
        <Flex fill p={16} style={{ gap: 16, paddingBottom: 91 }}>
          {
            <AutoPopIn>
              <Surface
                elevation={2}
                style={{
                  padding: 10,
                  backgroundColor: "white",
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                  alignItems: "center",
                  opacity: 1,
                  marginBottom: 0,
                  marginHorizontal: 5,
                }}
              >
                <HStack style={{ alignItems: "center", gap: 4 }}>
                  <VStack>
                    {authorJoinDate && (
                      <Text
                        numberOfLines={1}
                        style={{
                          fontSize: 12,
                          color: "#666",
                          fontStyle: "italic",
                        }}
                      >
                        Joined {authorDateObject}
                      </Text>
                    )}
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "right",
                        fontSize: 12,
                        color: "#444",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                    >
                      {reboards} reboards
                    </Text>
                  </VStack>
                  {renderProfilePicture}
                </HStack>
              </Surface>
            </AutoPopIn>
          }
          {Platform.OS !== "web" && dataLoading && (
            <Spinner size={"lg"} color={"black"} />
          )}
          {Platform.OS === "web" && refreshing && (
            <Spinner size={"lg"} color={"black"} />
          )}
          <SquareGrid
            canAdd={false}
            key={boardTileSize}
            columnWidth={200 * boardTileSize}
            numberOfLines={200 * boardTileSize < 170 ? 1 : 3}
            onCardPress={(item, e) => {
              navigation.push("Board", {
                name: item.title,
                boardId: item.id,
                authorId: item.authorId,
                color: item.backgroundColor,
              });
            }}
            data={filteredBoardData}
          />
        </Flex>
      </KeyboardAwareScrollView>
      <Fragment>
        <FabGroup
          buttons={[
            {
              icon: (
                <MaterialCommunityIcons
                  name="keyboard-return"
                  size={24}
                  color="black"
                />
              ),
              color: "white",
              onPress: () => navigation.goBack(),
            },
          ]}
        />
      </Fragment>
    </Fragment>
  );
}
