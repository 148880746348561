import React from "react";
import {
  ScrollView,
  TouchableOpacity,
  Text,
  TextInput,
  Keyboard,
  ActivityIndicator,
  DeviceEventEmitter,
  View,
} from "react-native";

import { Flex, HStack } from "@react-native-material/core";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Fragment } from "react";
import FabGroup from "../../components/general/fabs/FabGroup";
import SquareGrid from "../../components/cards/Grid/SquareGrid";

import SimpleToast from "../../components/animations/toasts/SimpleToast";
import { useEffect } from "react";

import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  onSnapshot,
  getDocs,
  documentId,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  runTransaction,
  orderBy,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useState } from "react";
import CustomAlertDialog from "../../components/data/input/Modals/CustomAlertDialog";
import { heavyHaptic } from "../../utils/hapticUtils";
import { RotateInUpLeft } from "react-native-reanimated";
import { useRoute } from "@react-navigation/native";
import { useSettings } from "../../components/contexts/SettingsContext";

export default function StudentClassesScreen({
  navigation,
  showJoin = true,
  hidePadding = false,
}) {
  const [toastVisible, setToastVisible] = React.useState(0);
  const [toastMessage, setToastMessage] = React.useState("");
  const [classesData, setClassesData] = React.useState([]);
  const [filteredClassesData, setFilteredClassesData] = React.useState([]);
  const [showHidden, setShowHidden] = useState(false);
  const [joinClassVisible, setJoinClassVisible] = useState(false);
  const [joinClassCode, setJoinClassCode] = useState("");
  const [showJoinLoading, setShowJoinLoading] = useState(false);

  const { settings } = useSettings();
  const classTileSize = settings.classTileSize ? settings.classTileSize : 1;

  useEffect(() => {
    fetchData();

    DeviceEventEmitter.addListener("showJoinCode", () => {
      setJoinClassVisible(true);
    });

    return () => {
      DeviceEventEmitter.removeAllListeners("showJoinCode");
    };
  }, []);

  useEffect(() => {
    if (showHidden) {
      setFilteredClassesData(classesData.filter((item) => item.hidden));
    } else {
      setFilteredClassesData(classesData.filter((item) => !item.hidden));
    }
  }, [showHidden, classesData]);

  const fetchData = async () => {
    try {
      const auth = getAuth();
      const userId = auth.currentUser.uid;
      const db = getFirestore();

      // Get a reference to the student document
      const userRef = doc(db, "users", userId);

      // Query for classes documents where the studentId field is a reference to the student document
      const studentClassesQ = query(
        collection(db, "studentClasses"),
        where("studentId", "==", userRef),
        orderBy("timeStamp", "asc")
      );

      const handleSnapshot = async (querySnapshot) => {
        const _classRefData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          classId: doc.data().classId.id,
          hidden: doc.data().hidden,
        }));
        const chunks = [];
        for (let i = 0; i < _classRefData.length; i += 10) {
          chunks.push(_classRefData.slice(i, i + 10));
        }

        let results = [];

        let promises = [];

        for (const chunk of chunks) {
          const classesQ = query(
            collection(db, "classes"),
            where(
              documentId(),
              "in",
              chunk.map((classRef) => classRef.classId)
            )
          );

          promises.push(
            getDocs(classesQ).then(async (querySnapshot) => {
              const boardCounts = await Promise.all(
                querySnapshot.docs.map(async (doc) => {
                  const boardsQuery = query(
                    collection(db, "boards"),
                    where("classIds", "array-contains", doc.ref)
                  );
                  const boardsSnapshot = await getDocs(boardsQuery);
                  return boardsSnapshot.size;
                })
              );

              const teacherLastNames = await Promise.all(
                querySnapshot.docs.map(async (_doc) => {
                  const teacherRef = _doc.data().teacherId;
                  const teacherDoc = await getDoc(
                    doc(db, "publicUsers", teacherRef.id)
                  );

                  return teacherDoc.data()?.lastName;
                })
              );

              let classCount = 0;

              querySnapshot.docs.forEach((document, i) => {
                classCount++;
                document.exists() &&
                  results.push({
                    hidden: chunk[i].hidden,
                    classId: document.id,
                    id: chunk[i].id,
                    boardCount: boardCounts[i],
                    teacherLastName: teacherLastNames[i],
                    ...document.data(),
                  });
              });
            })
          );
        }

        await Promise.all(promises);

        const _classData = results.map((_class) => {
          let tags = [
            `${_class.teacherLastName}$$600$italic`,
            `${_class.activeStudents ? _class.activeStudents : 1} / ${
              _class.studentCount
            } students`,
            `${_class.boardCount} board${_class.boardCount == 1 ? "" : "s"}`,
            "#" + _class.code,
          ];

          if (_class.hidden) tags.push("hidden$#aaa$300$italic");
          return {
            title: _class.className,
            tags,
            notificationCount: 0,
            backgroundColor: _class.color,
            id: _class.id,
            classId: _class.classId,
            hidden: _class.hidden,
          };
        });

        setClassesData(_classData);
      };

      const snapShot = onSnapshot(studentClassesQ, (querySnapshot) => {
        handleSnapshot(querySnapshot);
      });
      return snapShot;
    } catch (error) {
      console.error(error);
    }
  };

  const toggleShowHiddenPressed = () => {
    setShowHidden(!showHidden);

    if (!showHidden) setToastMessage("Now showing all hidden classes");
    else setToastMessage("Now showing all active classes");
    setToastVisible(Math.random());
  };

  const joinClassPressed = async () => {
    setShowJoinLoading(true);

    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const db = getFirestore();

    try {
      const classDocs = await getDocs(
        query(
          collection(db, "classes"),
          where("code", "==", joinClassCode.toUpperCase())
        )
      );

      if (classDocs.empty) {
        setToastMessage("Invalid class code");
        setToastVisible(Math.random());
        setJoinClassVisible(false);
        setTimeout(() => {
          setJoinClassCode("");
        }, 100);
        heavyHaptic();
        setShowJoinLoading(false);

        return;
      }

      if (
        classesData.filter((item) => item.classId === classDocs.docs[0].id)
          .length > 0
      ) {
        setToastMessage("You are already in this class");
        setToastVisible(Math.random());
        setJoinClassVisible(false);
        setTimeout(() => {
          setJoinClassCode("");
        }, 100);
        heavyHaptic();
        setShowJoinLoading(false);

        return;
      }

      const userRef = doc(db, "users", userId);

      const studentClassRef = doc(collection(db, "studentClasses"));

      const studentClassData = {
        classId: classDocs.docs[0].ref,
        studentId: userRef,
        hidden: false,
        timeStamp: serverTimestamp(),
        teacherId: classDocs.docs[0].data().teacherId,
      };

      try {
        setDoc(studentClassRef, studentClassData);

        //run transaction (add 1 to active students in class)
        try {
          runTransaction(db, async (transaction) => {
            const classesDoc = await transaction.get(classDocs.docs[0].ref);
            if (!classesDoc.exists()) {
              throw "Document does not exist!";
            }

            const newActiveCount = classesDoc.data().activeStudents
              ? classesDoc.data().activeStudents + 1
              : 1;
            transaction.update(classDocs.docs[0].ref, {
              activeStudents: newActiveCount,
            });
          });
        } catch (e) {
          console.error("Transaction failed 1: ", e);
        }
      } catch (error) {
        setToastMessage(error.message);
        setToastVisible(Math.random());
      }
    } catch (e) {
      console.error(e);
    }

    setShowJoinLoading(false);
    setJoinClassCode("");
    setJoinClassVisible(false);
    setToastMessage("Successfully joined class");
    setToastVisible(Math.random());
    heavyHaptic(true);
  };

  const hideClassSelected = async (id) => {
    const db = getFirestore();

    const docRef = doc(db, "studentClasses", id);

    const _doc = await getDoc(docRef);

    await updateDoc(docRef, { hidden: !_doc.data().hidden });
  };

  const route = useRoute();

  return (
    <Fragment>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, backgroundColor: "#f0eff4" }}
      >
        <Flex
          fill
          p={hidePadding ? 0 : 16}
          pt={16}
          style={{ gap: 16, paddingBottom: hidePadding ? 0 : 91 }}
        >
          <SquareGrid
            key={classTileSize}
            columnWidth={200 * classTileSize}
            numberOfLines={200 * classTileSize < 170 ? 2 : 3}
            onAddCardPress={() => {
              setJoinClassVisible(true);
            }}
            onCardPress={(item, e) => {
              navigation.navigate("Class", {
                name: item.title + " " + item.tags[3].split("$")[0],
                classId: item.classId,
              });
            }}
            extraFilter={
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  width: 40,
                  borderRadius: 5,
                }}
                onPress={toggleShowHiddenPressed}
              >
                <MaterialCommunityIcons
                  name={showHidden ? "eye-off-outline" : "eye-outline"}
                  size={24}
                  color="black"
                />
              </TouchableOpacity>
            }
            options={[
              {
                icon: (
                  <MaterialCommunityIcons
                    name={showHidden ? "eye-outline" : "eye-off-outline"}
                    size={20}
                    color="black"
                  />
                ),
                label: showHidden ? "Resore class" : "Hide class",
                onSelect: (item) => hideClassSelected(item),
              },
            ]}
            data={filteredClassesData}
          />
        </Flex>
      </ScrollView>
      <Fragment>
        {showJoin && (
          <FabGroup
            buttons={[
              {
                icon: (
                  <MaterialCommunityIcons
                    name="keyboard-return"
                    size={24}
                    color="black"
                  />
                ),
                color: "white",
                onPress: () => navigation.goBack(),
              },
            ]}
          />
        )}
        <SimpleToast message={toastMessage} visible={toastVisible} />
        <CustomAlertDialog
          setModalVisible={setJoinClassVisible}
          onConfirm={() => {}}
          modalVisible={joinClassVisible}
          title="Join a new class"
          customMessageComponent={
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 15,
                width: "100%",
                borderWidth: 1,
                borderColor: "#eee",
                borderRadius: 3,
              }}
            >
              <Text style={{ paddingLeft: 10, fontSize: 20, color: "#333" }}>
                #
              </Text>
              <TextInput
                maxLength={6}
                onChangeText={(text) => setJoinClassCode(text.toUpperCase())}
                value={joinClassCode}
                style={{
                  height: 50,
                  padding: 10,
                  paddingLeft: 3,
                  width: "100%",
                  fontSize: 20,
                }}
                placeholder="Enter six digit class code"
                placeholderTextColor={"#aaa"}
                onBlur={() => Keyboard.dismiss()}
                autoFocus={true}
              />
            </View>
          }
          buttons={[
            <TouchableOpacity
              key={"cancel"}
              onPress={() => setJoinClassVisible(false)}
            >
              <Text style={{ color: "#1677ff" }}>Cancel</Text>
            </TouchableOpacity>,
            <TouchableOpacity
              key={"join"}
              disabled={joinClassCode.length !== 6}
              style={[
                {
                  backgroundColor: "#1677ff",
                  borderRadius: 5,
                  paddingHorizontal: 14,
                  paddingVertical: 10,
                  flexDirection: "row",
                  gap: 4,
                  alignItems: "center",
                },
                (joinClassCode.length !== 6 || showJoinLoading) && {
                  opacity: 0.5,
                },
              ]}
              onPress={() => {
                joinClassPressed();
              }}
            >
              <Text style={{ color: "white" }}>Join class</Text>
              {showJoinLoading && (
                <ActivityIndicator color="white" size="small" />
              )}
            </TouchableOpacity>,
          ]}
        />
      </Fragment>
    </Fragment>
  );
}
