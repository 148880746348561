import React, { useEffect, useRef } from "react";
import { Animated, Dimensions, Easing } from "react-native";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";

const { height, width } = Dimensions.get("window");

const FlameAnimation = ({ triggered }) => {
  const scaleAnim = useRef(new Animated.Value(0)).current;
  const opacityAnim = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    if (triggered) {
      Animated.parallel([
        Animated.timing(scaleAnim, {
          toValue: 2,
          duration: 500,
          useNativeDriver: true,
          easing: Easing.elastic(2),
        }),
        Animated.timing(opacityAnim, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      scaleAnim.setValue(0);
      opacityAnim.setValue(1);
    }
  }, [triggered]);

  return (
    <Animated.View
      pointerEvents={"none"}
      style={{
        position: "absolute",
        top: height / 2 - 150,
        left: width / 2 - 150,
        transform: [{ scale: scaleAnim }],
        opacity: opacityAnim? opacityAnim: 0,
      }}
    >
      <Icon name="fire" size={300} color="#ba3b46" />
    </Animated.View>
  );
};

export default FlameAnimation;
