import React, { useMemo } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import DropdownMenu from "../data/input/DropdownMenu";
import { getAuth, signOut } from "firebase/auth";
import { Image } from "react-native";
import { Surface } from "@react-native-material/core";

const HeaderAvatarDropdown = ({ navigation }) => {
  const auth = getAuth();

  const options = [
    {
      label: "Account",
      onSelect: () => navigation.navigate("Account"),
    },
    {
      label: "Settings",
      onSelect: () => navigation.navigate("Settings"),
    },
    {
      label: "Logout",
      onSelect: () => signOut(auth),
    },
  ];
  const userAvatar = { uri: auth.currentUser?.photoURL };

  const renderProfilePicture = () => {
    if (userAvatar.uri !== null)
      return (
        <Surface elevation={3} style={{ borderRadius: 15 }}>
          <Image
            source={userAvatar}
            style={{
              width: 30,
              height: 30,
              borderRadius: 15,
            }}
          />
        </Surface>
      );
    else
      return (
        <MaterialCommunityIcons
          name="account-circle"
          size={30}
          color={"#ccc"}
        />
      );
  };

  return (
    <DropdownMenu
      optionWidth={100}
      icon={renderProfilePicture()}
      options={options}
    />
  );
};

export default HeaderAvatarDropdown;
