import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Flex, HStack } from "@react-native-material/core";
import { VStack } from "@react-native-material/core";
import { Row } from "native-base";
import * as React from "react";

import { Text, ScrollView } from "react-native";
import { TouchableOpacity } from "react-native";

export default function AdvancedTabContainer(props) {
  const [current, setCurrent] = React.useState(0);
  const [tabs, setTabs] = React.useState(
    props.initialTabs ? props.initialTabs : []
  );
  const [lastTab, setLastTab] = React.useState();

  React.useEffect(() => {
    if (props.addTab) {
      addTab(props.addTab);
    }
    return () => {};
  }, [props.addTab]);

  function addTab(tab) {
    //check if tab already exists
    var existsIndex = -1;
    tabs.forEach((t, i) => {
      if (t.id == tab.id) {
        existsIndex = i;
      }
    });

    if (existsIndex !== -1) {
      setCurrent(existsIndex);
      return;
    }

    if (lastTab !== tab) {
      var arr = [...tabs];
      arr.push(tab);
      setTabs(arr);
      setLastTab(tab);

      props.selectNewTab && setCurrent(arr.length - 1);
    }
  }

  function closeTab(index) {
    if (tabs.length > index) {
      var arr = [...tabs];
      arr.splice(index, 1);
      setTabs(arr);

      var newCurrent = current;
      if (newCurrent >= arr.length - 1) newCurrent = arr.length - 1;
      else if (newCurrent > index) newCurrent--;
      else if (newCurrent == index) newCurrent--;

      if (newCurrent <= 0) newCurrent = 0;

      setCurrent(newCurrent);
    }
  }

  return (
    <VStack spacing={8} mb={-10} mt={-10} mh={5}>
      <ScrollView horizontal style={{ maxHeight: 40 }}>
        <HStack spacing={4} style={{}}>
          {tabs &&
            tabs.map((screen, i) => {
              return (
                <TouchableOpacity
                  key={i}
                  style={{
                    paddingHorizontal: 5,
                    paddingVertical: 5,
                    backgroundColor: "white",
                    borderBottomColor: "#eb9940",
                    borderBottomWidth: i === current ? 2 : 0,
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 0,
                  }}
                  onPress={() => {
                    setCurrent(i);
                  }}
                >
                  <Text
                    style={{
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                      fontWeight: i == current ? "700" : "600",
                      color: i === current ? "#eb9940" : "#a1a1a1",
                    }}
                  >
                    {screen.name}
                  </Text>
                  <TouchableOpacity
                    style={{
                      width: 50,
                      height: 50,
                      marginHorizontal: -10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => closeTab(i)}
                  >
                    <MaterialCommunityIcons
                      name="close"
                      color={i === current ? "#eb9940" : "#717171"}
                      size={16}
                      style={{ marginTop: 0, marginLeft: 0 }}
                    />
                  </TouchableOpacity>
                </TouchableOpacity>
              );
            })}
        </HStack>
      </ScrollView>
      {tabs &&
        tabs.map((tab, i) => {
          return (
            <Flex
              key={i}
              style={{
                flex: 1,
                display: i == current ? "flex" : "none",
              }}
            >
              {tab.screen}
            </Flex>
          );
        })}
      {(!tabs || tabs.length == 0) && (
        <Flex>
          <Text style={{ textAlign: "center" }}>
            {props.noDataText ? props.noDataText : "No tabs to display"}
          </Text>
        </Flex>
      )}
    </VStack>
  );
}
