import BasicComponent from "../wrappers/BasicComponent";
import { View, Text } from "react-native";
import React, { useState, useRef } from "react";

import Lottie from "lottie-react-native";

import {
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTheme,
} from "victory-native";
import PopIn from "../../animations/visibility/PopIn";
import { useEffect } from "react";

const noDataFoundAnimation = require("../../../assets/animations/no-data-found.json");

export default function BasicBarChart({
  minBarWidth,
  barPadding = 0,
  data = [],
  colorScale,
  title,
  ...props
}) {
  const [width, setWidth] = useState(null);
  const [hasWidth, setHasWidth] = useState(false);
  const [actualWidth, setAcutalWidth] = useState(0);

  const [showNoData, setShowNoData] = useState(false);

  const animationRef = useRef(null);

  const numEntries = data.length;

  useEffect(() => {
    onLayout(actualWidth);
    if (data.length === 0) {
      setShowNoData(true);
      setTimeout(() => {
        if (animationRef.current) {
          animationRef.current?.reset();
          animationRef.current?.play();
        }
      }, 100);
    } else {
      setShowNoData(false);
    }
  }, [data]);

  const onLayout = (inputwidth) => {
    let _width = inputwidth;

    let _barWidth = _width / numEntries;

    if (_barWidth < minBarWidth) _barWidth = minBarWidth;

    let _barWidthPadded = _barWidth + barPadding;

    if (numEntries * _barWidthPadded > _width) {
      _width = numEntries * _barWidthPadded;

      setWidth(_width);
      setHasWidth(true);
    } else {
      setWidth(_width);
      setHasWidth(true);
    }
  };

  const domainPadding = { x: (width / numEntries) * 0.5 };

  if (!showNoData && data && data.length > 0)
    return (
      <BasicComponent
        onLayout={(e) => {
          if (e) {
            setAcutalWidth(e.nativeEvent.layout.width);
            onLayout(e.nativeEvent.layout.width - 32);
          }
        }}
        {...props}
      >
        <View style={{ height: 300, alignItems: "center" }}>
          <PopIn isVisible={actualWidth > 0}>
            {actualWidth > 0 && (
              <VictoryChart
                colorScale={colorScale && colorScale}
                theme={VictoryTheme.material}
                width={width}
                height={300}
                domainPadding={domainPadding.x !== 0 && domainPadding}
              >
                <VictoryBar
                  animate={{ duration: 1000 }}
                  labels={({ datum }) => Number(datum?.y).toFixed(2)}
                  barRatio={1}
                  alignment="middle"
                  data={data}
                />
                {title && (
                  <VictoryLabel
                    text={title}
                    x={40}
                    y={35}
                    style={{ fontSize: 10 }}
                    textAnchor="middle"
                  />
                )}
              </VictoryChart>
            )}
          </PopIn>
        </View>
      </BasicComponent>
    );
  else
    return (
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Lottie
          style={{
            height: 300,
            width: 300,
          }}
          autoPlay
          loop={true}
          source={noDataFoundAnimation}
          ref={animationRef}
        />
      </View>
    );
}
