import * as React from "react";
import {
  Text,
  DeviceEventEmitter,
  Platform,
  Image,
  RefreshControl,
} from "react-native";

import { Flex, Surface, VStack } from "@react-native-material/core";
import BoardSection from "../../components/discussion/BoardSection";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import FabGroup from "../../components/general/fabs/FabGroup";
import { Dimensions } from "react-native";
import SimpleToast from "../../components/animations/toasts/SimpleToast";
import AddBoardPopup from "../../components/data/input/Modals/AddBoardPopup";
import { useRoute } from "@react-navigation/native";
import { useEffect } from "react";

import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  getDocs,
  orderBy,
  getDoc,
  updateDoc,
  runTransaction,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { HStack, Spinner } from "native-base";
import { KeyboardAwareFlatList } from "react-native-keyboard-aware-scroll-view";
import CustomAlertDialog from "../../components/data/input/Modals/CustomAlertDialog";
import { TouchableOpacity } from "react-native";
import { View } from "react-native";
import AutoPopIn from "../../components/animations/visibility/AutoPopIn";

export default function StaffVaultBoardScreen({ navigation }) {
  const [dataLoading, setDataLoading] = React.useState(true);
  const [sectionData, setSectionData] = React.useState([]);
  const [classesData, setClassesData] = React.useState([]);
  const [boardColor, setBoardColor] = React.useState("");
  const [initialSelectedClasses, setInitialSelectedClasses] = React.useState(
    []
  );
  const [initialSections, setInitialSections] = React.useState([]);
  const [initalColor, setInitalColor] = React.useState("");
  const [initalBoardName, setInitalBoardName] = React.useState("");
  const [refreshing, setRefreshing] = React.useState(false);
  const [teacherId, setTeacherId] = React.useState(null);
  const [showReboardDialog, setShowReboardDialog] = React.useState(false);
  const [reboardLoading, setReboardLoading] = React.useState(false);
  const [isStaff, setIsStaff] = React.useState(false);
  const [showAddBoardPopup, setShowAddBoardPopup] = React.useState(false);
  const [toastVisible, setToastVisible] = React.useState(0);
  const [toastMessage, setToastMessage] = React.useState("");
  const [parentWidth, setParentWidth] = React.useState(
    Dimensions.get("window").width
  );
  const [authorName, setAuthorName] = React.useState("");
  const [authorAvatar, setAuthorAvatar] = React.useState();
  const [boardCreationDate, setBoardCreationDate] = React.useState("");
  const [reboards, setReboards] = React.useState(0);
  const [reboarded, setReboarded] = React.useState(false);
  const [authorJoinDate, setAuthorJoinDate] = React.useState();
  const [isAuthor, setIsAuthor] = React.useState(false);

  const updateLayout = (e) => {
    setParentWidth(e.nativeEvent.layout.width);
  };

  const route = useRoute();

  useEffect(() => {
    setDataLoading(true);
    fetchData();

    if (route.params.showReboard) {
      setTimeout(() => {
        reboardClicked();
      }, 1000);
    }
  }, []);

  const fetchData = async () => {
    try {
      setRefreshing(true);

      await new Promise((resolve) => setTimeout(resolve, 500));

      setSectionData([]);

      const db = getFirestore();

      const auth = getAuth();
      const uid = auth.currentUser.uid;

      // Get a reference to the teacher document
      const boardRef = doc(db, "boards", route.params.boardId);
      const userRef = doc(db, "users", uid);
      const authorRef = doc(db, "publicUsers", route.params.authorId);

      const boardDoc = await getDoc(boardRef);

      const authorDoc = await getDoc(authorRef);

      const reboardDocs = await getDocs(
        query(
          collection(db, "reboards"),
          where("boardId", "==", boardRef),
          where("userId", "==", userRef)
        )
      );

      if (!reboardDocs.empty) setReboarded(true);

      if (authorDoc.exists()) {
        setAuthorAvatar(authorDoc.data()?.profilePicture);
        setAuthorName(
          authorDoc.data()?.firstName + " " + authorDoc.data()?.lastName
        );

        const _joinDate = new Date(authorDoc.data()?.creationTime);

        setAuthorJoinDate(_joinDate.toLocaleDateString());

        if (authorDoc.id == uid) setIsAuthor(true);
      }

      setReboards(boardDoc.data().reboards ? boardDoc.data().reboards : 0);
      setBoardCreationDate(
        boardDoc.data().creationTimeStamp.toDate().toDateString()
      );

      setBoardColor(boardDoc.data().color);
      setTeacherId(boardDoc.data().teacherId.id);

      if (boardDoc.data().teacherId.id == uid) setIsStaff(true);

      //get section modalities and data
      const sectionQ = query(
        collection(db, "sections"),
        where("boardId", "==", boardRef),
        orderBy("index", "asc")
      );

      const modalitiesQ = query(
        collection(db, "modalities"),
        where("boardId", "==", boardRef)
      );

      const modalityVotesQ = query(
        collection(db, "modalityVotes"),
        where("boardId", "==", boardRef),
        where("userId", "==", userRef)
      );

      const modalityFavoritesQ = query(
        collection(db, "modalityFavorites"),
        where("boardId", "==", boardRef),
        where("userId", "==", userRef)
      );

      const sectionData = await getDocs(sectionQ);
      const modalitiesData = await getDocs(modalitiesQ);
      const modalityVotesData = await getDocs(modalityVotesQ);
      const modalityFavoritesData = await getDocs(modalityFavoritesQ);

      const _sections = await Promise.all(
        sectionData.docs.map(async (section, i) => {
          return {
            text: section.data().text,
            title: section.data().title,
            modalities: await Promise.all(
              modalitiesData.docs
                .filter(
                  (modality) => modality.data().sectionId.id == section.id
                )
                .sort((a, b) => {
                  const valueA = a.data().likeCount - a.data().dislikeCount;
                  const valueB = b.data().likeCount - b.data().dislikeCount;
                  return valueB - valueA;
                })
                .map(async (modality) => {
                  const modalityVote = modalityVotesData.docs.find(
                    (modalityVote) =>
                      modalityVote.data().modalityId.id == modality.id
                  );

                  let status = null;
                  if (modalityVote)
                    if (modalityVote.data().type == "like") {
                      status = "caret-up";
                    } else if (modalityVote.data().type == "dislike") {
                      status = "caret-down";
                    }

                  const modalityFavorite = modalityFavoritesData.docs.find(
                    (modalityFavorite) =>
                      modalityFavorite.data().modalityId.id == modality.id
                  );

                  let initalfavorited = false;
                  if (modalityFavorite) initalfavorited = true;

                  let imgLink = modality.data().imgLink;

                  if (imgLink.includes("tiktokcdn")) {
                    const response = await fetch(
                      `https://www.tiktok.com/oembed?url=${
                        modality.data().link
                      }`
                    ).catch((error) => console.error(error));
                    const data = await response
                      .json()
                      .catch((error) => console.error(error));

                    imgLink = data.thumbnail_url;
                  }

                  return {
                    id: modality.id,
                    link: modality.data().link,
                    imgLink,
                    favCount: modality.data().favCount,
                    initialDislikeCount: modality.data().dislikeCount,
                    initialLikeCount: modality.data().likeCount,
                    status,
                    initalfavorited,
                    boardId: boardRef.id,
                    title: modality.data().title,
                    type: modality.data().type,
                  };
                })
            ),
            index: i,
            isNew: false,
            id: section.id,
          };
        })
      );

      // Get a reference to the teacher document

      const classesQ = query(
        collection(db, "classes"),
        where("teacherId", "==", userRef),
        where("status", "==", "active")
      );

      const classesDocs = await getDocs(classesQ);

      const _classData = classesDocs.docs.map((doc) => ({
        title: doc.data().className,
        id: doc.id,
      }));

      updateDoc(boardRef, { seenReplies: boardDoc.data().replies });

      setClassesData(_classData);
      setSectionData(_sections);

      if (boardDoc.data().teacherId.id !== uid)
        try {
          runTransaction(db, async (transaction) => {
            const _boardDoc = await transaction.get(boardRef);
            if (!boardDoc.exists()) {
              throw "Document does not exist!";
            }
            const newViewCount = _boardDoc.data().vaultViews
              ? _boardDoc.data().vaultViews + 1
              : 1;
            transaction.update(boardRef, { vaultViews: newViewCount });
          });
        } catch (e) {
          console.error("Transaction failed 0: ", e);
        }
    } catch (e) {
      console.error(e);
    }
    setDataLoading(false);
    setRefreshing(false);
  };

  const reboardClicked = async () => {
    try {
      DeviceEventEmitter.emit("showLoadingIndicator");
      const db = getFirestore();

      const boardRef = doc(db, "boards", route.params.boardId);

      // Get a reference to the board document
      const boardDoc = await getDoc(boardRef);

      setInitalBoardName(boardDoc.data().boardName);
      setInitalColor(boardDoc.data().color);
      setInitialSections(boardDoc.data().sections);

      //get section modalities and data
      const sectionQ = query(
        collection(db, "sections"),
        where("boardId", "==", boardRef),
        orderBy("index", "asc")
      );

      const modalitiesQ = query(
        collection(db, "modalities"),
        where("boardId", "==", boardRef)
      );

      const sectionData = await getDocs(sectionQ);
      const modalitiesData = await getDocs(modalitiesQ);

      const _sections = sectionData.docs.map((section, i) => {
        return {
          text: section.data().text,
          title: section.data().title,
          modalities: modalitiesData.docs
            .filter((modality) => modality.data().sectionId.id == section.id)
            .sort((a, b) => {
              const valueA = a.data.likeCount - a.data.dislikeCount;
              const valueB = b.data.likeCount - b.data.dislikeCount;
              return valueB - valueA;
            })
            .map((modality) => {
              return {
                id: modality.id,
                link: modality.data().link,
                imgLink: modality.data().imgLink,
                favCount: modality.data().favCount,
                initialDislikeCount: modality.data().dislikeCount,
                initialLikeCount: modality.data().likeCount,
                title: modality.data().title,
                isNew: false,
                type: modality.data().type ? modality.data().type : "web",
              };
            }),
          index: i,
          isNew: false,
          id: doc(collection(db, "sections")).id,
        };
      });

      setInitialSections(_sections);

      setShowAddBoardPopup(true);
    } catch (e) {
      console.error(e);
    }
    DeviceEventEmitter.emit("hideLoadingIndicator");
  };

  const renderProfilePicture = React.useMemo(() => {
    if (authorAvatar !== null)
      return (
        <Image
          source={{ uri: authorAvatar }}
          style={{ width: 32, height: 32, borderRadius: 32 }}
        />
      );
    else
      return (
        <MaterialCommunityIcons
          name="account-circle"
          size={44}
          color={"#ccc"}
          style={{ marginLeft: 0 }}
        />
      );
  }, [authorAvatar]);

  const showEditBoardClicked = async () => {
    try {
      navigation.navigate("Boards", {
        screen: "Board",
        params: {
          name: route.params.name,
          boardId: route.params.boardId,
          showEdit: true,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Flex
      fill
      style={{
        backgroundColor: "#f0eff4",
        width: "100%",
        overflow: "hidden",
      }}
      onLayout={updateLayout}
    >
      {Platform.OS === "web" && refreshing && (
        <Spinner marginTop={5} size={"lg"} color={"black"} />
      )}
      <KeyboardAwareFlatList
        contentContainerStyle={{
          padding: 16,
          gap: 16,
          display: "flex",
          flexDirection: "column",
          columnGap: 16,
          width: "100%",
          marginHorizontal: "auto",
          paddingBottom: 91,
        }}
        refreshControl={
          !dataLoading && (
            <RefreshControl refreshing={refreshing} onRefresh={fetchData} />
          )
        }
        extraHeight={80}
        enableAutomaticScroll={true}
        extraScrollHeight={80}
        refreshing={dataLoading}
        data={sectionData}
        keyExtractor={(item) => item.id}
        renderItem={({ item: section, index }) => (
          <BoardSection
            boardColor={boardColor}
            key={section.id}
            index={index + 1}
            getLitOn={true}
            name={section.title}
            mediaData={section.modalities}
            initialComments={section.comments}
            id={section.id}
            boardId={route.params.boardId}
            isStaff={isStaff}
            teacherId={teacherId}
            isPublic={true}
          >
            <Text>{section.text}</Text>
          </BoardSection>
        )}
        ListHeaderComponent={
          <View>
            {Platform.OS !== "web" && dataLoading && (
              <Spinner
                style={{ marginBottom: 10 }}
                size={"lg"}
                color={"black"}
              />
            )}
            {!dataLoading && (
              <AutoPopIn>
                <TouchableOpacity
                  onPress={() =>
                    navigation.push("Teacher", {
                      name: authorName,
                      authorId: route.params.authorId,
                      authorReboards: reboards,
                      authorAvatar: authorAvatar,
                      authorJoinDate,
                      authorName: authorName,
                    })
                  }
                >
                  <Surface
                    elevation={2}
                    style={{
                      padding: 10,
                      backgroundColor: "white",
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                      alignItems: "center",
                      opacity: 1,
                      marginBottom: -6,
                    }}
                  >
                    <Text
                      style={{
                        marginBottom: 0,
                        color: "#666",
                        textDecorationLine: "underline",
                        fontStyle: "italic",
                        fontWeight: "500",
                        textAlign: "center",
                        paddingBottom: 5,
                      }}
                    >
                      {route.params.name}
                    </Text>
                    <HStack style={{ alignItems: "center", gap: 4 }}>
                      <VStack>
                        <Text
                          numberOfLines={1}
                          style={{
                            fontSize: 12,
                            color: "#666",
                            fontStyle: "italic",
                          }}
                        >
                          {"Created by " +
                            authorName +
                            " on " +
                            boardCreationDate}
                        </Text>
                        <Text
                          style={{
                            width: "100%",
                            textAlign: "right",
                            fontSize: 12,
                            color: "#444",
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }}
                        >
                          {reboards} reboards
                        </Text>
                      </VStack>
                      {renderProfilePicture}
                    </HStack>
                  </Surface>
                </TouchableOpacity>
              </AutoPopIn>
            )}
          </View>
        }
      />
      <>
        <FabGroup
          buttons={[
            {
              icon: (
                <MaterialCommunityIcons
                  name="keyboard-return"
                  size={24}
                  color="black"
                />
              ),
              onPress: () => navigation.goBack(),
              color: "white",
            },
            !dataLoading &&
              isAuthor && {
                icon: (
                  <MaterialCommunityIcons
                    name="pencil"
                    size={32}
                    color="black"
                  />
                ),
                onPress: () => showEditBoardClicked(),
                color: "white",
              },
            !dataLoading && {
              icon: (
                <MaterialCommunityIcons
                  name={
                    reboarded
                      ? "briefcase-download-outline"
                      : "briefcase-download"
                  }
                  size={24}
                  color={reboarded ? "red" : "#1890ff"}
                />
              ),
              onPress: () => reboardClicked(),
              color: "white",
            },
          ]}
        />
        <CustomAlertDialog
          setModalVisible={setShowReboardDialog}
          onConfirm={reboardClicked}
          modalVisible={showReboardDialog}
          title={"Are you sure?"}
          message={
            reboarded
              ? "You have all ready reboarded this submission before. This may add a duplicate board to your boards page."
              : "This will add this board to your boards page."
          }
          buttons={[
            <TouchableOpacity
              key={"cancel"}
              onPress={() => {
                setShowReboardDialog(false);
              }}
            >
              <Text style={{ color: "#1677ff" }}>Cancel</Text>
            </TouchableOpacity>,
            <TouchableOpacity
              key={"submit"}
              style={[
                {
                  backgroundColor: reboarded ? "#f5222d" : "#1677ff",
                  borderRadius: 5,
                  paddingHorizontal: 14,
                  paddingVertical: 10,
                  flexDirection: "row",
                  gap: 4,
                  alignItems: "center",
                },
              ]}
              onPress={() => {
                setShowReboardDialog(false);
                reboardClicked();
              }}
            >
              <Text style={{ color: "white" }}>Proceed</Text>
              {reboardLoading && <Spinner size={"sm"} color={"white"} />}
            </TouchableOpacity>,
          ]}
        />
        <AddBoardPopup
          onClose={() => setShowAddBoardPopup(false)}
          onSubmit={() => {
            setShowAddBoardPopup(false);
            setReboarded(true);
          }}
          visible={showAddBoardPopup}
          classes={classesData}
          setToastMessage={setToastMessage}
          setToastVisible={setToastVisible}
          initialColor={initalColor}
          initialBoardName={initalBoardName}
          initialClasses={initialSelectedClasses}
          initialSections={initialSections}
          initialBoardId={route.params.boardId}
          reboard={true}
          reboardId={route.params.boardId}
          reboardAuthorId={route.params.authorId}
          duplicateReboard={reboarded}
        />
        <SimpleToast message={toastMessage} visible={toastVisible} />
      </>
    </Flex>
  );
}
