import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import StaffBoardScreen from "../../screens/staff/StaffBoard";
import AdminDashboardScreen from "../../screens/admin/AdminDashboard";

const Stack = createNativeStackNavigator();

export default function AdminDashboardNavigator({ navigation }) {
  return (
    <Stack.Navigator screenOptions={{ headerShown: true }}>
      <Stack.Screen
        name="Home"
        options={{ headerShown: false, title: "Admin Console" }}
        component={AdminDashboardScreen}
      />
      <Stack.Screen
        name="Board"
        options={({ route }) => ({ title: route.params.name })}
        component={StaffBoardScreen}
      ></Stack.Screen>
    </Stack.Navigator>
  );
}
