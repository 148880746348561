import { VStack } from "@react-native-material/core";
import Accordion from "../wrappers/BasicAccordion";
import BasicTabContainer from "../wrappers/BasicTabContainer";
import BasicTable from "../tables/BasicTable";
import { useState } from "react";
import SquareGrid from "../../cards/Grid/SquareGrid";
import BasicBarChart from "../graphs/BasicBarChart";
import BasicPieChart from "../graphs/BasicPieChart";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useEffect } from "react";
import { Spinner } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { changeColorAlpha } from "../../../utils/generateColor";

import { Image, Linking } from "react-native";
import CustomSegmentedControl from "../input/CustomSegmentedControl";
import { View } from "react-native";

//--time settings
const now = new Date();

// get start of week (Sunday)
const day = now.getDay();
const startOfDay = new Date(now);
startOfDay.setHours(0, 0, 0, 0);

const startOfWeek = new Date(now);
startOfWeek.setDate(now.getDate() - day);
startOfWeek.setHours(0, 0, 0, 0);

// get end of week (Saturday)
const endOfWeek = new Date(startOfWeek);
endOfWeek.setDate(startOfWeek.getDate() + 6);
endOfWeek.setHours(23, 59, 59, 999);

// get start of month
const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

// get end of month
const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
endOfMonth.setHours(23, 59, 59, 999);

// get end of day
const endOfDay = new Date(now);
endOfDay.setHours(23, 59, 59, 999);

//----column data
const repiesColumn = [
  {
    Header: "Board",
    accessor: "boardName",
    width: 200,
  },
  {
    Header: "Reply",
    accessor: "reply",
    width: 400,
  },
  {
    Header: "Likes",
    accessor: "likes",
  },
  {
    Header: "Replies",
    accessor: "replies",
  },
  {
    Header: "Created",
    accessor: "created",
  },
];
const boardsColumn = [
  {
    Header: "Board",
    accessor: "boardName",
    width: 300,
  },
  {
    Header: "Favorited",
    accessor: "favorited",
    width: 125,
  },
  {
    Header: "Engagement (minutes)",
    accessor: "timeSpent",
    width: 200,
  },
  {
    Header: "Unique sessions",
    accessor: "views",
    width: 175,
  },
  {
    Header: "Average session length (minutes)",
    accessor: "averageTimeSpent",
    width: 300,
  },
];

//--fetchData functions

async function getStudentRepliesTableData(teacherRef, studentRef) {
  const db = getFirestore();
  const commentsQ = query(
    collection(db, "comments"),
    where("userId", "==", studentRef),
    where("teacherId", "==", teacherRef)
  );

  const commentsSnapshot = await getDocs(commentsQ);
  const boardCollection = collection(db, "boards");

  const promises = commentsSnapshot.docs.map(async (commentDoc) => {
    const comment = commentDoc.data();
    const boardSnapshot = await getDoc(
      doc(boardCollection, comment.boardId.id)
    );
    const board = boardSnapshot.data();

    return {
      id: comment.boardId,
      boardName: board.boardName,
      reply: comment.comment,
      likes: comment.likeCount,
      replies: comment.replies,
      created: comment.timeStamp.toDate().toLocaleString(),
    };
  });

  const tableData = await Promise.all(promises);

  return tableData;
}

async function getStudentBoardTableData(teacherRef, studentRef) {
  const db = getFirestore();

  // Get all classes the user is in
  const studentClassesQ = query(
    collection(db, "studentClasses"),
    where("studentId", "==", studentRef),
    where("teacherId", "==", teacherRef)
  );

  const classesSnapshot = await getDocs(studentClassesQ);
  const classIds = classesSnapshot.docs.map((_doc) => _doc.data().classId); // Assuming classId is the document id

  const boardPromises = classIds.map((classId) => {
    // Get all boards that are related to the found class
    const boardsQ = query(
      collection(db, "boards"),
      where("classIds", "array-contains", classId)
    );

    return getDocs(boardsQ);
  });

  const boardSnapshots = await Promise.all(boardPromises);

  // Flatten the snapshots into one array
  const boardDocs = boardSnapshots.flatMap((snapshot) => snapshot.docs);

  const boardFavoritesCollection = collection(db, "boardFavorites");
  const boardViewTimesCollection = collection(db, "boardViewTimes");

  // Map over each board and retrieve additional information (favorite status and view times)
  const promises = boardDocs.map(async (boardDoc) => {
    const board = boardDoc.data();

    // Check if the board is favorited
    const favoriteSnapshot = await getDocs(
      query(
        boardFavoritesCollection,
        where("userId", "==", studentRef),
        where("boardId", "==", boardDoc.id)
      )
    );
    const favorited = !favoriteSnapshot.empty;

    // Get total view times for the board
    const viewTimesSnapshot = await getDocs(
      query(
        boardViewTimesCollection,
        where("userId", "==", studentRef),
        where("boardId", "==", boardDoc.ref)
      )
    );
    let totalViewTime = 0;
    viewTimesSnapshot.forEach((doc) => {
      totalViewTime += doc.data().viewTime; // assuming viewTime field represents the viewing duration
    });

    // Return data for table row
    return {
      id: boardDoc.id,
      boardName: board.boardName,
      favorited: favorited ? "Yes" : "No",
      timeSpent: Number(totalViewTime / 60000).toFixed(2),
      averageTimeSpent:
        viewTimesSnapshot.size !== 0
          ? Number(totalViewTime / viewTimesSnapshot.size / 60000).toFixed(2)
          : 0,
      views: viewTimesSnapshot.size,
    };
  });

  const tableData = await Promise.all(promises);

  return tableData;
}

async function getStudentBoardFavorites(studentRef) {
  const db = getFirestore();

  // Get all favorite board documents for this student
  const favoritesQ = query(
    collection(db, "boardFavorites"),
    where("userId", "==", studentRef)
  );

  const favoritesSnapshot = await getDocs(favoritesQ);
  const boardIds = favoritesSnapshot.docs.map((doc) => doc.data().boardId); // assuming boardId is a field in the favorite document

  // Fetch all the favorited boards data
  const promises = boardIds.map((boardId) => {
    return getDoc(boardId);
  });

  const boardSnapshots = await Promise.all(promises);

  // Map the data into the desired format for the card grid
  const tableData = boardSnapshots.map((boardSnapshot) => {
    const board = boardSnapshot.data();
    return {
      id: boardSnapshot.id,
      title: board.boardName,
      tags: [
        `${board.favorites} faves`,
        `${board.views} views`,
        `${board.replies} replies`,
      ],
      notificationCount: 0,
      backgroundColor: board.color,
      showCustomBadge: true,
    };
  });

  return tableData;
}

async function getStudentModalityFavorites(studentRef) {
  const db = getFirestore();

  // Get all favorite board documents for this student
  const favoritesQ = query(
    collection(db, "modalityFavorites"),
    where("userId", "==", studentRef)
  );

  const favoritesSnapshot = await getDocs(favoritesQ);
  const modalityIds = favoritesSnapshot.docs.map(
    (doc) => doc.data().modalityId
  );

  // Fetch all the favorited boards data
  const promises = modalityIds.map((modalityId) => {
    return getDoc(modalityId);
  });

  const modalitySnapshots = await Promise.all(promises);

  // Map the data into the desired format for the card grid
  const tableData = modalitySnapshots.map((modalitySnapshot) => {
    const modality = modalitySnapshot.data();
    return {
      id: modalitySnapshot.id,
      tags: [modality.imgLink],
      title: "",
      showCustomBadge: true,
      link: modality.link,
      imgLink: modality.imgLink,
    };
  });

  return tableData;
}

async function getSessionLengthBarChartData(teacherRef, studentRef, timeIndex) {
  const db = getFirestore();

  let startTime;
  let endTime;

  if (timeIndex === 0) {
    startTime = startOfDay;
    endTime = endOfDay;
  } else if (timeIndex === 1) {
    startTime = startOfWeek;
    endTime = endOfWeek;
  } else if (timeIndex === 2) {
    startTime = startOfMonth;
    endTime = endOfMonth;
  }

  const startTimestamp = Timestamp.fromDate(startTime);
  const endTimestamp = Timestamp.fromDate(endTime);

  const boardViewsQuery = query(
    collection(db, "boardViewTimes"),
    where("teacherId", "==", teacherRef),
    where("userId", "==", studentRef),
    where("timeStamp", ">=", startTimestamp),
    where("timeStamp", "<=", endTimestamp)
  );

  const boardViewsSnapshot = await getDocs(boardViewsQuery);

  let totalTimes = {};
  let sessionCounts = {};

  boardViewsSnapshot.docs.forEach((doc) => {
    const data = doc.data();

    let date;
    if (timeIndex === 0) {
      const dateObj = data.timeStamp.toDate();
      let hour = dateObj.getHours();
      const minutes = dateObj.getMinutes();

      if (minutes >= 30) {
        hour++;
      }

      const period = hour >= 12 ? "PM" : "AM";
      const hour12 = hour % 12 || 12; // convert 0 to 12 for AM
      date = hour12 + " " + period;
    } else {
      const dateObj = data.timeStamp.toDate();
      date =
        dateObj.getMonth() +
        1 +
        "/" +
        dateObj.getDate() +
        "/" +
        dateObj.getFullYear();
    }

    totalTimes[date] = (totalTimes[date] || 0) + data.viewTime / 60000; // convert milliseconds to minutes
    sessionCounts[date] = (sessionCounts[date] || 0) + 1;
  });

  let averageSessionTimeData = Object.keys(totalTimes)
    .sort((a, b) => {
      if (timeIndex === 0) {
        const [hourA, periodA] = a.split(" ");
        const [hourB, periodB] = b.split(" ");

        if (periodA === periodB) {
          return parseInt(hourA) - parseInt(hourB);
        } else {
          return periodA === "AM" ? -1 : 1;
        }
      } else {
        return (
          new Date(a.split("/").reverse().join("-")) -
          new Date(b.split("/").reverse().join("-"))
        );
      }
    })
    .map((date) => {
      return { x: date, y: totalTimes[date] / sessionCounts[date] };
    });

  return averageSessionTimeData;
}

async function getTimeSpentBarChartData(teacherRef, studentRef, timeIndex) {
  const db = getFirestore();

  let startTime;
  let endTime;

  if (timeIndex === 0) {
    startTime = startOfDay;
    endTime = endOfDay;
  } else if (timeIndex === 1) {
    startTime = startOfWeek;
    endTime = endOfWeek;
  } else if (timeIndex === 2) {
    startTime = startOfMonth;
    endTime = endOfMonth;
  }

  const startTimestamp = Timestamp.fromDate(startTime);
  const endTimestamp = Timestamp.fromDate(endTime);

  const boardViewsQuery = query(
    collection(db, "boardViewTimes"),
    where("teacherId", "==", teacherRef),
    where("userId", "==", studentRef),
    where("timeStamp", ">=", startTimestamp),
    where("timeStamp", "<=", endTimestamp)
  );

  const boardViewsSnapshot = await getDocs(boardViewsQuery);

  let totalTimes = {};

  boardViewsSnapshot.docs.forEach((doc) => {
    const data = doc.data();

    let date;
    if (timeIndex === 0) {
      const dateObj = data.timeStamp.toDate();
      let hour = dateObj.getHours();
      const minutes = dateObj.getMinutes();

      if (minutes >= 30) {
        hour++;
      }

      const period = hour >= 12 ? "PM" : "AM";
      const hour12 = hour % 12 || 12; // convert 0 to 12 for AM
      date = hour12 + " " + period;
    } else {
      const dateObj = data.timeStamp.toDate();
      date =
        dateObj.getMonth() +
        1 +
        "/" +
        dateObj.getDate() +
        "/" +
        dateObj.getFullYear();
    }

    totalTimes[date] = (totalTimes[date] || 0) + data.viewTime / 60000; // convert milliseconds to minutes
  });

  let totalSessionTimeData = Object.keys(totalTimes)
    .sort((a, b) => {
      if (timeIndex === 0) {
        const [hourA, periodA] = a.split(" ");
        const [hourB, periodB] = b.split(" ");

        if (periodA === periodB) {
          return parseInt(hourA) - parseInt(hourB);
        } else {
          return periodA === "AM" ? -1 : 1;
        }
      } else {
        return (
          new Date(a.split("/").reverse().join("-")) -
          new Date(b.split("/").reverse().join("-"))
        );
      }
    })
    .map((date) => {
      return { x: date, y: totalTimes[date] };
    });

  return totalSessionTimeData;
}

async function getModalityFeedbackPieData(teacherRef, studentRef) {
  const db = getFirestore();

  const modalityVotesQuery = query(
    collection(db, "modalityVotes"),
    where("teacherId", "==", teacherRef),
    where("userId", "==", studentRef)
  );

  const modalityVotesSnapshot = await getDocs(modalityVotesQuery);

  let likeCount = 0;
  let dislikeCount = 0;

  modalityVotesSnapshot.docs.forEach((doc) => {
    const data = doc.data();
    if (data.type === "like") {
      likeCount++;
    } else if (data.type === "dislike") {
      dislikeCount++;
    }
  });

  const voteData = [
    { x: "Likes", y: likeCount },
    { x: "Dislikes", y: dislikeCount },
  ];

  if (likeCount != 0 || dislikeCount != 0) {
    return voteData;
  } else return [];
}

function StudentDataTab({ teacherId, studentId, navigation }) {
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedBarTimeRangeIndex, setBarSelectedTimeRangeIndex] = useState(1);
  const [barChartDataLoading, setBarChartDataLoading] = useState(false);
  const [repliesTableData, setRepliesTableData] = useState([]);
  const [boardTableData, setBoardTableData] = useState([]);
  const [boardFavoriteData, setBoardFavoriteData] = useState([]);
  const [modalityFavoriteData, setModalityFavoriteData] = useState([]);
  const [modalityFeedbackPieData, setModalityFeedbackPieData] = useState([]);
  const [timeSpentBarChartData, setTimeSpentBarChartData] = useState([]);
  const [sessionLengthBarChartData, setSessionLengthBarChartData] = useState(
    []
  );

  const db = getFirestore();
  const teacherRef = doc(db, "users", teacherId);
  const studentRef = doc(db, "users", studentId);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    setDataLoading(true);

    try {
      setRepliesTableData(
        await getStudentRepliesTableData(teacherRef,studentRef)
      );
      setBoardTableData(await getStudentBoardTableData(teacherRef, studentRef));
      setBoardFavoriteData(
        await getStudentBoardFavorites(studentRef)
      );
      setModalityFavoriteData(await getStudentModalityFavorites(studentRef));
      setTimeSpentBarChartData(
        await getTimeSpentBarChartData(
          teacherRef,
          studentRef,
          selectedBarTimeRangeIndex
        )
      );
      setSessionLengthBarChartData(
        await getSessionLengthBarChartData(
          teacherRef,
          studentRef,
          selectedBarTimeRangeIndex
        )
      );
      setModalityFeedbackPieData(
        await getModalityFeedbackPieData(teacherRef, studentRef)
      );
    } catch (error) {
      console.error(error);
    }
    setDataLoading(false);
  }

  useEffect(() => {
    async function getBarChartData() {
      setBarChartDataLoading(true);
      try {
        setTimeSpentBarChartData(
          await getTimeSpentBarChartData(
            teacherRef,
            studentRef,
            selectedBarTimeRangeIndex
          )
        );
        setSessionLengthBarChartData(
          await getSessionLengthBarChartData(
            teacherRef,
            studentRef,
            selectedBarTimeRangeIndex
          )
        );
      } catch (error) {
        console.error(error);
      }

      setBarChartDataLoading(false);
    }

    getBarChartData();
  }, [selectedBarTimeRangeIndex]);

  if (dataLoading) {
    return <Spinner color={"black"} />;
  }

  return (
    <VStack style={{ gap: 4, paddingTop: 4 }}>
      <Accordion
        title="Student Log"
        color="#001F3F"
        textColor={"white"}
        innerStyle={{ height: 30 }}
        content={
          <BasicTabContainer
            hideAnim={true}
            accentColor="#001F3F"
            screens={[
              {
                name: "Board Metrics",
                screen: (
                  <BasicTable
                    hideAnim={true}
                    columns={boardsColumn}
                    data={boardTableData}
                  ></BasicTable>
                ),
              },
              {
                name: "Replies",
                screen: (
                  <BasicTable
                    hideAnim={true}
                    columns={repiesColumn}
                    data={repliesTableData}
                  ></BasicTable>
                ),
              },
            ]}
            elevation={0}
          />
        }
      />
      <Accordion
        title="Student Favorites"
        color="#ba3b46"
        textColor={"white"}
        innerStyle={{ height: 30 }}
        content={
          <BasicTabContainer
            accentColor="#ba3b46"
            hideAnim={true}
            screens={[
              {
                name: "Boards",
                screen: (
                  <SquareGrid
                    hideOverflow
                    canAdd={false}
                    columnWidth={170}
                    onCardPress={(item) =>
                      navigation.navigate("Boards", {
                        screen: "Board",
                        params: {
                          name: item.title,
                          boardId: item.id,
                        },
                      })
                    }
                    data={boardFavoriteData}
                    customBadge={
                      <>
                        <MaterialCommunityIcons
                          name="fire"
                          size={40}
                          style={{ position: "absolute", right: -6, top: -7 }}
                          color={changeColorAlpha("#5c0011", 0.75)}
                        />
                        <MaterialCommunityIcons
                          name="fire"
                          size={36}
                          style={{ position: "absolute", right: -5, top: -6.5 }}
                          color={changeColorAlpha("#f5222d", 0.75)}
                        />
                      </>
                    }
                  />
                ),
              },
              {
                name: "Modalities",
                screen: (
                  <SquareGrid
                    hideOverflow
                    canAdd={false}
                    columnWidth={170}
                    onCardPress={(item) => Linking.openURL(item.link)}
                    data={modalityFavoriteData}
                    hideSearch
                    customBadge={
                      <>
                        <MaterialCommunityIcons
                          name="fire"
                          size={40}
                          style={{ position: "absolute", right: -6, top: -7 }}
                          color={changeColorAlpha("#5c0011", 0.75)}
                        />
                        <MaterialCommunityIcons
                          name="fire"
                          size={36}
                          style={{ position: "absolute", right: -5, top: -6.5 }}
                          color={changeColorAlpha("#f5222d", 0.75)}
                        />
                      </>
                    }
                    customRender={(item) => {
                      return (
                        <Image
                          source={{ uri: item }}
                          style={{
                            height: 150,
                            width: 150,
                            flexGrow: 1,
                            borderRadius: 5,
                            zIndex: 0,
                          }}
                        />
                      );
                    }}
                  />
                ),
              },
            ]}
            elevation={0}
          />
        }
      />
      <Accordion
        title="Bar Charts"
        color="#4B0082"
        textColor={"white"}
        innerStyle={{ height: 30 }}
        content={
          <View style={{ flexDirection: "column" }}>
            <View style={{ marginVertical: 10 }}>
              <CustomSegmentedControl
                color="#ed9b40"
                textColor="white"
                values={["Today", "This Week", "This Month"]}
                selectedIndex={selectedBarTimeRangeIndex}
                onValueChange={(index) => {
                  setBarSelectedTimeRangeIndex(index);
                }}
              />
            </View>
            {barChartDataLoading && (
              <Spinner
                style={{ marginBottom: 5 }}
                size={"sm"}
                color={"black"}
              />
            )}
            <BasicTabContainer
              hideAnim={true}
              screens={[
                {
                  name: "Average Session Length",
                  screen: (
                    <BasicBarChart
                      minBarWidth={100}
                      title={"(minutes)"}
                      hideAnim={true}
                      data={sessionLengthBarChartData}
                    />
                  ),
                },
                {
                  name: "Total Time Spent",
                  screen: (
                    <BasicBarChart
                      minBarWidth={100}
                      title={"(minutes)"}
                      hideAnim={true}
                      data={timeSpentBarChartData}
                    />
                  ),
                },
              ]}
              elevation={0}
            />
          </View>
        }
      />
      <Accordion
        innerStyle={{ height: 30 }}
        title="Pie Charts"
        color="#228B22"
        textColor={"white"}
        content={
          <BasicTabContainer
            hideAnim={true}
            screens={[
              {
                name: "Modality Feedback",
                screen: (
                  <BasicPieChart
                    hideAnim={true}
                    data={modalityFeedbackPieData}
                    colorScale={["#ed9b40", "#ba3b46"]}
                  />
                ),
              },
              {
                name: "Modality Usage",
                screen: (
                  <BasicPieChart
                    data={[]}
                    colorScale={["#ba3b46", "#ed9b40"]}
                  />
                ),
              },
            ]}
            elevation={0}
          />
        }
      />
    </VStack>
  );
}

export default StudentDataTab;
