import React from "react";
import { Flex, Surface, VStack } from "@react-native-material/core";
import { ScrollView, Text, useWindowDimensions } from "react-native";
import PopIn from "../../animations/visibility/PopIn";
import { useEffect } from "react";

export default function BasicComponent({
  hideAnim = false,
  onLayout,
  ...props
}) {
  const [isVisible, setIsVisible] = React.useState(true);
  const { width } = useWindowDimensions();
  const zoomable = props.zoomable == null ? true : props.zoomable;
  let usedWidth = width;
  if (usedWidth >= 1008) usedWidth -= 300;
  usedWidth -= 32;

  return (
    <PopIn isVisible={hideAnim ? true : isVisible}>
      <Surface
        onLayout={onLayout}
        elevation={props.elevation ? props.elevation : -1}
        style={{ padding: 16, backgroundColor: "white" }}
      >
        <VStack>
          {props.title && (
            <Text
              style={{ paddingBottom: 16, fontWeight: "bold", fontSize: 16 }}
            >
              {props.title}
            </Text>
          )}
          {props.subComponent}
          {!props.hideScrollView ? (
            <ScrollView
              horizontal
              contentContainerStyle={{ minWidth: "100%" }}
              bouncesZoom={true}
              zoomScale={(value) => {
                if (!zoomable) return 1;

                if (value < 1.25 && value > 0.75 && value !== 1) return 1;
                else return value;
              }}
              maximumZoomScale={zoomable ? 1.33 : 1}
              minimumZoomScale={zoomable ? 0.66 : 1}
              style={{ backgroundColor: "#fafafa" }}
            >
              <Flex w={"100%"} bg={"white"}>
                {props.children}
              </Flex>
            </ScrollView>
          ) : (
            <Flex w={"100%"} bg={"white"}>
              {props.children}
            </Flex>
          )}
        </VStack>
      </Surface>
    </PopIn>
  );
}
