import React from "react";
import { View, StyleSheet, DeviceEventEmitter } from "react-native";
import SearchBarHeader from "../data/input/SearchBarheader";
import HeaderAvatarDropdown from "./HeaderAvatarDropdown";

function CustomHeaderRight({ navigation }) {
  const data = [
    "Create a new board",
    "Create a new class",
    "Go to my boards",
    "Go to my classes",
    "Go to dashboard",
    "Change settings",
  ];

  const handleSearch = (searchTerm) => {
    // Perform an action based on the search term
    switch (searchTerm.toLowerCase()) {
      case "create a new board":
        navigation.navigate("My Boards");
        setTimeout(() => {
          DeviceEventEmitter.emit("showAddNew");
        }, 1000);
        break;
      case "create a new class":
        navigation.navigate("My Classes", { isNew: true });
        setTimeout(() => {
          DeviceEventEmitter.emit("showAddNewClass");
        }, 1000);
        break;
      case "go to my boards":
        navigation.navigate("Boards");
        break;
      case "go to my classes":
        navigation.navigate("My Classes");
        break;
      case "go to dashboard":
        navigation.navigate("Dashboard");
        break;
      case "change settings":
        navigation.navigate("Settings");
        break;
      default:
        break;
    }
  };

  return (
    <View style={styles.header}>
      <SearchBarHeader data={data} onSearch={handleSearch} />
      <HeaderAvatarDropdown navigation={navigation} />
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    width: "100%",
    justifyContent: "flex-end",
    paddingLeft: 12,
    paddingRight: 12,
    alignItems: "center",
    gap: 8,
    flexDirection: "row",
  },
});

export default CustomHeaderRight;
