import React, { useEffect } from "react";
import { HStack, VStack, Box, Surface } from "@react-native-material/core";

import { Text, TouchableOpacity, View, Share, Platform } from "react-native";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Image } from "react-native";
import { LikeDislike } from "../../discussion/IconCount";

export default function CarouselModalityReviewCard({
  borderRadius = 5,
  onCardPress,
  link,
  imgLink,
  index,
  onCardDeletePress,
  onCardApprovePress,
  section,
  initialDislikeCount = 0,
  initialLikeCount = 0,
  id,
  isNew,
  title,
  type,
  showApprove = true,
  needsReview = false,
}) {
  const [loading, setLoading] = React.useState(true);

  return (
    <Surface
      key={index}
      elevation={2}
      style={{
        borderRadius: borderRadius,
        backgroundColor: "white",
        color: "black",
      }}
    >
      <VStack id="content">
        <TouchableOpacity
          style={{ zIndex: 999 }}
          onLongPress={() => {
            if (Platform.OS !== "web") onCardPress(link);
          }}
        >
          <Box mb={8} bg={imgLink ? "white" : "#aaaaaa"}>
            {imgLink && (
              <Image
                source={{ uri: imgLink }}
                style={{
                  width: "100%",
                  height: 400,
                  backgroundColor: "#eee",
                  borderRadius: 5,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  opacity: loading ? 0 : 1,
                }}
                resizeMode="contain"
                onLoadEnd={() => setLoading(false)}
              />
            )}
          </Box>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => onCardDeletePress(section, id, needsReview)}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 999,
            backgroundColor: "white",
            borderRadius: 5,
          }}
        >
          <MaterialCommunityIcons
            name="close-box"
            size={36}
            color={"#f5222d"}
          />
        </TouchableOpacity>
        {showApprove && (
          <TouchableOpacity
            onPress={() => onCardApprovePress(section, id)}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 999,
              backgroundColor: "white",
              borderRadius: 5,
            }}
          >
            <MaterialCommunityIcons
              name="checkbox-marked"
              size={36}
              color={"#52c41a"}
            />
          </TouchableOpacity>
        )}
        <HStack style={{ paddingHorizontal: 8, gap: 12 }} items="center">
          {!isNew && (
            <LikeDislike
              modalityType={type}
              initialDislikeCount={initialDislikeCount}
              initialLikeCount={initialLikeCount}
              spacing={8}
              size={40}
            />
          )}
          <VStack
            style={[
              isNew && { paddingHorizontal: 15, paddingVertical: 15 },
              { gap: 5, maxWidth: "90%" },
            ]}
          >
            <TouchableOpacity onPress={() => onCardPress(link)}>
              <Text
                selectable
                numberOfLines={2}
                style={{ fontSize: 12, color: "blue" }}
              >
                {link}
              </Text>
            </TouchableOpacity>
            <Text
              style={{
                fontWeight: "500",
                fontStyle: "italic",
              }}
              numberOfLines={2}
            >
              {title}
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </Surface>
  );
}
