import React, { useRef, useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Animated,
  Dimensions,
} from "react-native";
import { FontAwesome as Icon } from "@expo/vector-icons";
import { Box } from "@react-native-material/core";

const SocialMediaIconConfetti = ({ icon, bg, ...props }) => {
  return (
    <Box
      style={{ alignItems: "center", justifyContent: "center" }}
      w={32}
      h={32}
      bg={bg}
      p={8}
      radius={50}
    >
      <Icon {...props} name={icon} color={"white"} size={16} />
    </Box>
  );
};

const ConfettiPiece = ({
  icon,
  bg = "blue",
  duration = 3000,
  durationRandomOffset = 2000,
  startingYOffset = 50,
  startingYRandomOffset = 100,
}) => {
  const translateX = useRef(new Animated.Value(0)).current;
  const translateY = useRef(
    new Animated.Value(-startingYOffset - Math.random() * startingYRandomOffset)
  ).current;
  const rotation = useRef(new Animated.Value(0)).current;
  const opacity = useRef(new Animated.Value(1)).current;
  const { width, height } = Dimensions.get("window");

  useEffect(() => {
    translateX.setValue(Math.random() * width);
    translateY.setValue(
      -startingYOffset - Math.random() * startingYRandomOffset
    );

    Animated.parallel([
      Animated.timing(translateX, {
        toValue:
          translateX._value + (Math.random() * width * 0.5 - width * 0.25),
        duration: duration + Math.random() * durationRandomOffset,
        useNativeDriver: true,
      }),
      Animated.timing(translateY, {
        toValue: height - 150,
        duration: duration + Math.random() * durationRandomOffset,
        useNativeDriver: true,
      }),
      Animated.timing(rotation, {
        toValue: 1,
        duration: duration + Math.random() * durationRandomOffset,
        useNativeDriver: true,
      }),
      Animated.timing(opacity, {
        delay: duration,
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start();
  }, []);

  const rotationValue = rotation.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });

  return (
    <Animated.View
      style={[
        styles.piece,
        {
          transform: [
            { translateX: translateX },
            { translateY: translateY },
            { rotate: rotationValue },
          ],
          opacity: opacity? opacity: 0,
        },
      ]}
    >
      <SocialMediaIconConfetti bg={bg} icon={icon} />
    </Animated.View>
  );
};

export default function CustomConfettiCannon({
  fireConfetti,
  duration,
  durationRandomOffset,
  startingYOffset,
  startingYRandomOffset,
}) {
  const [confettiPieces, setConfettiPieces] = useState([]);

  useEffect(() => {
    if (fireConfetti) {
      handlePress();
    }

    return () => {};
  }, [fireConfetti]);

  const handlePress = () => {
    const socialMediaIcons = [
      "youtube",
      "twitter",
      "instagram",
      "facebook",
      "heart",
      "comment",
      "thumbs-o-up",
      "trophy",
      "bell",
      "globe",
      "cloud",
      "paper-plane",
      "commenting",
      "handshake-o",
      "user-circle",
    ];
    const newPieces = [...Array(24)].map(() => {
      const icon =
        socialMediaIcons[Math.floor(Math.random() * socialMediaIcons.length)];

      let bg;

      if (icon == "heart") bg = "#FF0000";
      else if (icon == "comment") bg = "#007BFF";
      else if (icon == "thumbs-o-up") bg = "#28A745";
      else if (icon == "trophy") bg = "#FFD700";
      else if (icon == "bell") bg = "#FFC107";
      else if (icon == "globe") bg = "#007BFF";
      else if (icon == "cloud") bg = "#17A2B8";
      else if (icon == "paper-plane") bg = "#343A40";
      else if (icon == "commenting") bg = "#007BFF";
      else if (icon == "handshake-o") bg = "#FD7E14";
      else if (icon == "user-circle") bg = "#20C997";
      else if (icon == "youtube") bg = "#cd1f04";
      else if (icon == "twitter") bg = "#00b9ef";
      else if (icon == "instagram") bg = "#6091b2";
      else if (icon == "facebook") bg = "#3b5998";

      return (
        <ConfettiPiece
          key={Math.random().toString()}
          icon={icon}
          bg={bg}
          duration={duration}
          durationRandomOffset={durationRandomOffset}
          startingYOffset={startingYOffset}
          startingYRandomOffset={startingYRandomOffset}
        />
      );
    });
    setConfettiPieces(newPieces);

    setTimeout(() => {
      setConfettiPieces([]);
    }, duration + durationRandomOffset + 500);
  };

  return (
    <View
      style={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 0 }}
    >
      {confettiPieces}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  piece: {
    position: "absolute",
  },
});
