export function sortByProperty(array, propertyName) {
    return array.sort((a, b) => {
      if (a[propertyName] < b[propertyName]) {
        return -1;
      } else if (a[propertyName] > b[propertyName]) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);