import React from "react";
import { StyleSheet, Text, View, Modal } from "react-native";

const CustomAlertDialog = ({
  modalVisible,
  setModalVisible,
  title,
  message,
  buttons,
  customMessageComponent,
  transparent = true,
}) => {
  return (
    <Modal
      transparent={transparent}
      animationType="fade"
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.title}>{title}</Text>
          {customMessageComponent ? (
            customMessageComponent
          ) : (
            <Text style={styles.message}>{message}</Text>
          )}

          <View style={styles.buttonContainer}>
            {buttons.map((button) => button)}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    margin: "auto",
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    elevation: 5,
    width: "90%",
    maxWidth: 800,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  buttonText: {
    color: "2196F3",
    textAlign: "center",
  },
  buttonSubmitText: {
    color: "white",
    textAlign: "center",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  title: {
    marginBottom: 10,
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
  },
  message: {
    marginBottom: 20,
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 20,
    width: "100%",
  },
});

export default CustomAlertDialog;
