import React, { forwardRef } from "react";
import { StyleSheet, View, FlatList } from "react-native";
import { useInfiniteHits, In } from "react-instantsearch-hooks";

export const InfiniteHits = forwardRef(
  ({ hitComponent: Hit, ...props }, ref) => {
    const { hits, isLastPage, showMore } = useInfiniteHits(props);

    return (
      <FlatList
        ref={ref}
        data={hits}
        style={{ maxHeight: 400, paddingTop:5 }}
        keyExtractor={(item) => item.objectID}
        onEndReached={() => {
          if (!isLastPage) {
            showMore();
          }
        }}
        renderItem={({ item }) => <Hit hit={item} />}
      />
    );
  }
);