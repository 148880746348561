import React from "react";

import { Text, Button, Flex, Surface } from "@react-native-material/core";

import { View, TextInput } from "react-native";

import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import Logo from "../graphics/Logo";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { TouchableOpacity } from "react-native";
import CustomAlertDialog from "../data/input/Modals/CustomAlertDialog";

import { heavyHaptic, lightHaptic } from "../../utils/hapticUtils";

export default function ForgotPasswordForm({ small, ...props }) {
  const [email, setEmail] = React.useState("");
  const [showDialog, setShowDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");

  const auth = getAuth();

  return (
    <>
      <View
        style={{
          backgroundColor: "#ffecd9",
          flex: 1,
          maxWidth: 400,
          borderRadius: 5,
          borderBottomRightRadius: small ? 5 : 0,
          borderTopRightRadius: small ? 5 : 0,
        }}
      >
        <View style={{ margin: 32, gap: 16 }}>
          <Flex mt={16}>
            <Logo />
          </Flex>
          <Text
            color="#333333"
            style={{
              fontWeight: "bold",
              fontSize: 44,
              paddingTop: 16,
              paddingBottom: 8,
            }}
          >
            Forgot Password?
          </Text>
          <Text
            color="#333333"
            style={{
              marginTop: -16,
              fontWeight: "500",
              paddingBottom: 16,
            }}
          >
            No worries, we'll send you reset instructions.
          </Text>
          <Surface
            style={{ borderRadius: 5, backgroundColor: "white" }}
            elevation={1}
          >
            <TextInput
              style={{ height: 40, borderRadius: 5, paddingLeft: 10 }}
              autoComplete="email"
              value={email}
              placeholderTextColor={"#aaa"}
              onChangeText={(value) => setEmail(value)}
              placeholder="mail@website.com"
            ></TextInput>
          </Surface>
          <TouchableOpacity
            onPress={() => {
              lightHaptic();

              sendPasswordResetEmail(auth, email)
                .then(() => {
                  setDialogMessage(
                    "Hey there! If the email you've entered is registered with us, it should be receiving a password reset message shortly. Check your inbox (and maybe your spam/junk folder, just in case)."
                  );
                  setShowDialog(true);
                })
                .catch((error) => {
                  if (
                    error.message == "Firebase: Error (auth/invalid-email)."
                  ) {
                    setDialogMessage("Whoops! That's not a valid email.");
                    setShowDialog(true);
                  } else {
                    setDialogMessage(
                      "Hey there! If the email you've entered is registered with us, it should be receiving a password reset message shortly. Check your inbox (and maybe your spam/junk folder, just in case)."
                    );
                    setShowDialog(true);
                  }
                });
            }}
            style={[
              {
                padding: 16,
                backgroundColor: "#eb9940",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 4,
              },
              email.length <= 5 && {
                opacity: 0.5,
              },
            ]}
            disabled={email.length <= 5}
          >
            <Text style={{ color: "white", fontWeight: "bold" }}>
              Send Email
            </Text>
          </TouchableOpacity>

          <Button
            leading={<MaterialCommunityIcons name="arrow-left" size={18} />}
            titleStyle={{ fontSize: 12, color: "black" }}
            color="#eb9940"
            variant="text"
            title="Back to login"
            onPress={() => {
              props.navigation.navigate("Login");
            }}
          ></Button>
        </View>
      </View>
      <CustomAlertDialog
        setModalVisible={showDialog}
        onConfirm={() => {}}
        modalVisible={showDialog}
        title={"Alert"}
        message={dialogMessage}
        buttons={[
          <TouchableOpacity
            key={"submit"}
            style={[
              {
                backgroundColor: "#1677ff",
                borderRadius: 5,
                paddingHorizontal: 14,
                paddingVertical: 10,
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
              },
            ]}
            onPress={() => {
              setShowDialog(false);
              lightHaptic();
            }}
          >
            <Text style={{ color: "white" }}>Okay</Text>
          </TouchableOpacity>,
        ]}
      />
    </>
  );
}
