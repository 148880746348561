import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Animated,
  TextInput,
  Modal,
  Dimensions,
  TouchableWithoutFeedback,
} from "react-native";
import {
  changeColorAlpha,
  getBlackWhiteTextColor,
} from "../../../utils/generateColor";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Surface } from "@react-native-material/core";
import NoDataFound from "../../animations/data/NoDataFound";
import { lightHaptic, mediumHaptic } from "../../../utils/hapticUtils";

const SCREEN_WIDTH = Dimensions.get("window").width;
const SCREEN_HEIGHT = Dimensions.get("window").height;
const MODAL_WIDTH = 150;
const OPTION_HEIGHT = 40; // You can adjust this constant according to the height of your options.

/**
 * SquareCard is an internal component used in SquareGrid to render individual cards
 * @param {string} title The text to be displayed on the card
 * @param {array} tags An array of tags to be displayed on the card
 * @param {function} onPress Function to handle when the card is pressed
 * @param {string} backgroundColor Background color for the card
 * @param {number} width Width for the card
 * @param {number} index Index of the card, used for animation delay
 * @param {boolean} isAddButton If true, the Card will show a plus ('+') icon
 * @param {boolean} notificationCount A number to be shown as notification on top-right of the card
 * @param {boolean} options Options to be shown when card is long pressed
 * @param {string} id Unique id for the card
 * @param {React Component} customBadge A component that will replace the default numeral notification badge on top-right of the card
 * @param {React Component} customRender Function returning a react component that will replace the default view inside the card
 * @param {number} numberOfLines Number of lines for the excerpt text in each card
 */
const SquareCard = ({
  title,
  tags,
  onPress,
  backgroundColor = "#ffffff",
  width,
  notificationCount,
  isAddButton = false,
  index,
  showAnimation = true,
  options,
  id,
  customBadge,
  customRender,
  item,
  numberOfLines = 3,
}) => {
  const [position, setPosition] = useState(
    new Animated.ValueXY({ x: 0, y: -600 + -index * width })
  );
  const [scale] = useState(new Animated.Value(1));
  const [addCardScale] = useState(new Animated.Value(1)); // Add this line
  const [modalVisible, setModalVisible] = useState(false);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [fontSize, setFontSize] = React.useState(16); // Initial font size

  const dynamicModalHeight = options
    ? options.length * OPTION_HEIGHT + options.length - 1
    : 0;

  React.useEffect(() => {
    // If the content length is too big, decrease the font size

    setFontSize(16);
  }, [title]);

  const handleLongPress = (event) => {
    mediumHaptic();
    let x = event.nativeEvent.pageX;
    let y = event.nativeEvent.pageY;

    if (x + MODAL_WIDTH / 2 > SCREEN_WIDTH) {
      x = SCREEN_WIDTH - MODAL_WIDTH / 2;
    } else if (x - MODAL_WIDTH / 2 < 0) {
      x = MODAL_WIDTH / 2;
    }

    if (y + dynamicModalHeight > SCREEN_HEIGHT) {
      y = SCREEN_HEIGHT - dynamicModalHeight;
    }

    setModalPosition({
      x: x - MODAL_WIDTH / 2,
      y: y - dynamicModalHeight / 2, // Adjusted the y-coordinate
    });
    setModalVisible(true);
  };

  useEffect(() => {
    if (showAnimation)
      Animated.timing(position, {
        toValue: { x: 0, y: 0 },
        duration: 500,
        delay: index * 0.8 * 100,
        useNativeDriver: true,
      }).start();
    else {
      setPosition({ x: 0, y: 0 });
    }
  }, []);

  const cardStyle = [
    styles.card,
    { backgroundColor, width, height: width },
    {
      transform: [
        { translateX: position.x },
        { translateY: position.y },
        { scale: isAddButton ? addCardScale : scale }, // Update this line
      ],
    },
  ];

  const scaleCard = (toValue) => {
    Animated.timing(isAddButton ? addCardScale : scale, {
      // Update this line
      toValue,
      duration: 150,
      useNativeDriver: true,
    }).start();
  };

  if (isAddButton) {
    return (
      <Animated.View style={cardStyle}>
        <TouchableOpacity
          onPress={() => {
            onPress();
            mediumHaptic();
          }}
          onPressIn={() => scaleCard(1.05)}
          onPressOut={() => scaleCard(1)}
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MaterialCommunityIcons name="plus" size={24} color="black" />
        </TouchableOpacity>
      </Animated.View>
    );
  }

  return (
    <Animated.View style={cardStyle}>
      {options && (
        <View style={styles.editContainer}>
          <TouchableOpacity
            onPress={(event) => {
              handleLongPress(event);
            }}
            style={{
              padding: 30,
              borderRadius: 50,
              backgroundColor: "rgba(0,0,0,0)",
            }}
          >
            <MaterialCommunityIcons
              name="dots-vertical"
              size={28}
              color="rgba(255,255,255,0.7)"
            />
          </TouchableOpacity>
        </View>
      )}
      <TouchableOpacity
        onLongPress={(event) => handleLongPress(event)}
        onPress={onPress}
        onPressIn={() => scaleCard(1.05)}
        onPressOut={() => scaleCard(1)}
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {customRender ? (
          customRender(tags[0], title)
        ) : (
          <>
            <Text
              style={[
                styles.cardTitle,
                {
                  fontSize,
                  maxWidth: width - 20,
                  color: getBlackWhiteTextColor(backgroundColor),
                },
              ]}
              numberOfLines={numberOfLines}
            >
              {title}
            </Text>
            <View
              style={{
                maxWidth: width - 20,
                width: "100%",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: 4,
                justifyContent: "center",
              }}
            >
              {tags.map((tag, _index) => (
                <View
                  key={_index}
                  style={{
                    padding: 3,
                    paddingHorizontal: 5,
                    backgroundColor: "rgba(255,255,255,0.9)",
                    borderRadius: 5,
                  }}
                >
                  <Text
                    selectable
                    style={[
                      tag.split("$")[1] && { color: tag.split("$")[1] },
                      tag.split("$")[2] && { fontWeight: tag.split("$")[2] },
                      tag.split("$")[3] && { fontStyle: tag.split("$")[3] },
                    ]}
                  >
                    {tag.split("$")[0]}
                  </Text>
                </View>
              ))}
            </View>
          </>
        )}
      </TouchableOpacity>
      <View style={styles.badgeContainer}>
        {customBadge
          ? customBadge
          : notificationCount > 0 &&
            !customBadge && (
              <View style={styles.badge}>
                <Text style={styles.badgeText}>{notificationCount}</Text>
              </View>
            )}
      </View>
      {!isAddButton && options && (
        <Modal
          animationType="fade"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.modalOverlay}>
            <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
              <View style={styles.modalBackdrop} />
            </TouchableWithoutFeedback>
            <Surface
              elevation={5}
              style={[
                styles.centeredView,
                {
                  top: modalPosition.y,
                  left: modalPosition.x,
                  height: dynamicModalHeight,
                }, // Update this line
              ]}
            >
              {options.map((option, index) => (
                <TouchableOpacity
                  key={index + "z"}
                  style={[
                    styles.optionButton,
                    { borderBottomColor: "#ddd", borderBottomWidth: 0.1 },
                    index == 0 && {
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                    },
                    index == options.length - 1 && {
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                      borderBottomWidth: 0,
                    },
                  ]}
                  onPress={() => {
                    option.onSelect(id, item);
                    setModalVisible(!modalVisible);
                  }}
                >
                  {option.icon ? (
                    option.icon
                  ) : (
                    <View
                      style={{
                        width: 20,
                      }}
                    />
                  )}
                  <Text style={styles.optionText}>{option.label}</Text>
                </TouchableOpacity>
              ))}
            </Surface>
          </View>
        </Modal>
      )}
    </Animated.View>
  );
};

/**
 * SquareGrid Component
 * @param {number} columnWidth The width of each column
 * @param {array} data Data to be presented in the grid
 * @param {function} onCardPress Function to handle when a card is pressed, Receives item and event as parameter
 * @param {function} onAddCardPress Function to handle when the add card (+) is pressed
 * @param {boolean} canAdd If true, an addition card (+) is shown at the beginning of the grid
 * @param {boolean} showAnimation  If true, shows animation when cards appear
 * @param {boolean} hideOverflow If true, overflow is hidden from the parent container
 * @param {array} options Options to be shown when card is long pressed
 * @param {React Component} extraFilter An extra component to be shown beside the search bar
 * @param {React Component} customBadge A component that will replace the default numeral notification badge on top-right of the card
 * @param {React Component} customRender Function returning a react component that will replace the default view inside the card
 * @param {boolean} hideSearch If true, search bar is hidden
 * @param {boolean} showNoData If true and filteredData length is 0 and loading is false, then a No Data Found animation is displayed
 * @param {React Component} headerComponent A component to be shown on top of the grid
 * @param {styles object} noDataStyle Styles to be applied to the No Data Found animation
 * @param {boolean} loading If true, No Data Found animation will not be shown
 * @param {number} numberOfLines Number of lines for the excerpt text in each card
 */
const SquareGrid = ({
  columnWidth,
  data,
  onCardPress,
  onAddCardPress,
  canAdd = true,
  showAnimation = true,
  hideOverflow = false,
  options,
  extraFilter,
  customBadge,
  customRender,
  hideSearch = false,
  showNoData = false,
  headerComponent,
  noDataStyle,
  loading = false,
  numberOfLines = 3,
}) => {
  const [numColumns, setNumColumns] = useState(0);
  const [parentWidth, setParentWidth] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const handleLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setParentWidth(width);
    const columns = Math.floor(width / columnWidth);
    setNumColumns(columns);
  };

  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (showNoData && filteredData.length === 0 && !loading)
    return <NoDataFound customStyle={noDataStyle} delay={500} />;

  return (
    <View
      style={{
        overflow: hideOverflow ? "hidden" : "visible",
      }}
    >
      {!hideSearch && (
        <View style={styles.searchContainer}>
          <TextInput
            style={styles.searchInput}
            placeholder="Search..."
            onChangeText={(text) => setSearchQuery(text)}
            value={searchQuery}
            placeholderTextColor={"#aaa"}
          />
          {extraFilter}
        </View>
      )}
      {headerComponent}
      <View style={styles.grid} onLayout={handleLayout}>
        {parentWidth > 0 && numColumns > 0 && canAdd && (
          <SquareCard
            key={"add"}
            showAnimation={showAnimation}
            onPress={onAddCardPress}
            width={parentWidth / numColumns - 10}
            isAddButton
            index={0}
          />
        )}
        {parentWidth > 0 &&
          numColumns > 0 &&
          filteredData.length !== 0 &&
          filteredData.map((item, index) => {
            return (
              <SquareCard
                item={item}
                notificationCount={item.notificationCount}
                title={item.title}
                tags={item.tags}
                onPress={(e) => {
                  onCardPress(item, e);
                  lightHaptic();
                }}
                backgroundColor={item.backgroundColor}
                width={parentWidth / numColumns - 10}
                index={index + 1}
                key={index + 1}
                showAnimation={showAnimation}
                options={options}
                id={item.id}
                customBadge={item.showCustomBadge ? customBadge : null}
                customRender={customRender}
                numberOfLines={numberOfLines}
              />
            );
          })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  grid: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  card: {
    borderRadius: 5,
    padding: 10,
    margin: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitle: {
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 5,
    textAlign: "center",
    textDecorationLine: "underline",
  },
  tagContainer: {
    flexDirection: "column",
    gap: 8,
    flexWrap: "wrap",
  },
  tag: {
    paddingHorizontal: 6,
    paddingVertical: 2,
    color: "black",
    fontSize: 12,
    marginRight: 5,
    textAlign: "center",
  },
  badgeContainer: {
    position: "absolute",
    top: -4,
    right: -4,
  },
  editContainer: {
    position: "absolute",
    top: -27,
    left: -35,
  },
  badge: {
    backgroundColor: changeColorAlpha("#de2f2f", 0.75),
    borderRadius: 15,
    display: "flex",
    padding: 5,
    minWidth: 25,
    height: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  badgeText: {
    color: "white",
  },
  searchContainer: {
    backgroundColor: "#f2f2f2",
    borderRadius: 5,
    margin: 5,
    marginTop: 0,
    flexDirection: "row",
    gap: 10,
  },
  searchInput: {
    backgroundColor: "white",
    borderRadius: 5,
    paddingHorizontal: 10,
    height: 40,
    flex: 1,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  optionButton: {
    backgroundColor: "white",
    padding: 5,
    paddingHorizontal: 10,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    height: OPTION_HEIGHT,
  },
  optionText: {
    color: "#000",
    textAlign: "left",
    fontSize: 14,
  },
  centeredView: {
    position: "absolute", // Adding absolute position
    width: MODAL_WIDTH, // Specifying width
    borderRadius: 5, // Rounded corners if needed
  },
  modalBackdrop: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default SquareGrid;
