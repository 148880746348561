import React, { useMemo, useState } from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Image,
} from "react-native";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  setDoc,
  startAfter,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useEffect } from "react";

import Comment from "./Comment";
import StaffComment from "./StaffComment";
import { timeAgo } from "../../utils/numberUtils";
import { HStack } from "@react-native-material/core";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const CommentSection = ({
  sectionId,
  boardId,
  isStaff = false,
  classId,
  setCommentCount,
  teacherId,
  commentLimit = 10,
  sectionCommentCount,
  isPublic = false,
  discussionMuted = false,
}) => {
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [commentCharCount, setCommentCharCount] = useState(0);
  const [numLines, setNumLines] = useState(5);
  const [lastVisible, setLastVisible] = useState(null);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [pinnedCommentsFetched, setPinnedCommentsFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [maxCommentCharCount, setMaxCommentCharCount] = useState(500);
  const [maxReplyCharCount, setMaxReplyCharCount] = useState(500);

  const db = getFirestore();
  const auth = getAuth();
  const userAvatar = auth.currentUser.photoURL;
  const uid = auth.currentUser.uid;
  const userRef = doc(db, "users", uid);
  const sectionRef = doc(db, "sections", sectionId);
  const boardRef = doc(db, "boards", boardId);

  useEffect(() => {
    setCommentCount(-1);
    if (!lastVisible) fetchData();
  }, []);

  const fetchData = async () => {
    const commentsCollection = collection(db, "comments");

    //get staff max character settings

    try {
      const authorSettingsRef = doc(db, "userSettings", uid);
      const authorSettingsDoc = await getDoc(authorSettingsRef);

      setMaxCommentCharCount(
        authorSettingsDoc.data().maxCommentCharCount
          ? authorSettingsDoc.data().maxCommentCharCount
          : 500
      );

      setMaxReplyCharCount(
        authorSettingsDoc.data().maxReplyCharCount
          ? authorSettingsDoc.data().maxReplyCharCount
          : 500
      );
    } catch (e) {
      console.error(e);
    }

    // Query to get the pinned comments
    let pinnedCommentsQ = query(
      commentsCollection,
      where("sectionId", "==", sectionRef),
      where("isPublic", "==", isPublic),
      where("pinned", "==", true)
    );

    let pinnedComments = [];

    if (!pinnedCommentsFetched) {
      pinnedComments = await getDocs(pinnedCommentsQ);
      pinnedComments = pinnedComments.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        userId: doc.data().userId.id,
        timeStamp: timeAgo(doc.data().timeStamp),
      }));
      setPinnedCommentsFetched(true);
    }

    let commentsQ = query(
      commentsCollection,
      where("sectionId", "==", sectionRef),
      where("pinned", "==", false),
      where("isPublic", "==", isPublic),
      orderBy("likeCount", "desc"),
      limit(commentLimit)
    );

    if (lastVisible) {
      commentsQ = query(
        commentsCollection,
        where("sectionId", "==", sectionRef),
        where("isPublic", "==", isPublic),
        orderBy("likeCount", "desc"),
        where("pinned", "==", false),
        limit(commentLimit),
        startAfter(lastVisible)
      );
    } else {
      const sectionRef = doc(db, "sections", sectionId);

      const sectionSnapshot = await getDoc(sectionRef);

      const _commentCount = isPublic
        ? sectionSnapshot.data().publicComments
        : sectionSnapshot.data().comments;

      setCommentCount(_commentCount ? _commentCount : 0);
    }

    try {
      const comments = await getDocs(commentsQ);
      let lastVisibleComment = comments.docs[comments.docs.length - 1];
      setLastVisible(lastVisibleComment);

      const _comments = comments.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        userId: doc.data().userId.id,
        timeStamp: timeAgo(doc.data().timeStamp),
      }));

      setComments((prevComments) => [
        ...pinnedComments,
        ...prevComments,
        ..._comments,
      ]);

      if (_comments.length < 10) setShowLoadMore(false);
    } catch (error) {
      console.error("BSJSED", error);
    }

    setLoading(false);
  };

  const handleAddComment = async () => {
    if (discussionMuted) {
      return alert(
        "Your comment cannot be submitted as you have been temporarily restricted from commenting by the instructor"
      );
    }

    try {
      if (commentText === "" || commentText.length > maxCommentCharCount) {
        return alert(
          "Please enter a comment with " +
            maxCommentCharCount +
            " characters or less"
        );
      }
      if (commentText === "") {
        return alert("Please enter a comment");
      }

      const commentsCollection = collection(db, "comments");
      const commentRef = doc(commentsCollection);

      setCommentCount((prev) => prev + 1);

      //add comment to database

      let newComment = {
        userId: userRef,
        comment: commentText,
        likeCount: 0,
        timeStamp: serverTimestamp(),
        isTeacher: isStaff,
        sectionId: sectionRef,
        boardId: boardRef,
        replies: 0,
        teacherId: doc(db, "users", teacherId),
        isPublic,
        pinned: false,
      };

      if (classId) {
        const classRef = doc(db, "classes", classId);
        newComment = { ...newComment, classId: classRef };
      }

      setComments((prev) => [
        ...prev,
        {
          id: commentRef.id,
          userId: uid,
          comment: commentText,
          likeCount: 0,
          isReply: false,
          isTeacher: isStaff,
          sectionId: sectionRef,
          boardId,
          replies: 0,
          isPublic,
          timeStamp: "just now",
          pinned: false,
        },
      ]);
      setCommentText("");
      setCommentCharCount(0);

      await setDoc(commentRef, newComment);

      //run transaction (add 1 to board replies)
      if (!isPublic) {
        try {
          await runTransaction(db, async (transaction) => {
            const boardRef = doc(db, "boards", boardId);

            const boardDoc = await transaction.get(boardRef);
            if (!boardDoc.exists()) {
              throw "Document does not exist!";
            }

            const newReplyCount = boardDoc.data().replies + 1;
            if (!isStaff) {
              transaction.update(boardRef, { replies: newReplyCount });
            } else {
              transaction.update(boardRef, {
                replies: newReplyCount,
                seenReplies: newReplyCount,
              });
            }
          });
        } catch (e) {
          console.error("Transaction failed 1: ", e);
        }
        try {
          await runTransaction(db, async (transaction) => {
            const sectionRef = doc(db, "sections", sectionId);

            const sectionDoc = await transaction.get(sectionRef);
            if (!sectionDoc.exists()) {
              throw "Document does not exist!";
            }

            const newReplyCount = sectionDoc.data().comments
              ? sectionDoc.data().comments + 1
              : 1;
            transaction.update(sectionRef, { comments: newReplyCount });
          });
        } catch (e) {
          console.error("Transaction failed 1: ", e);
        }
      } else {
        try {
          await runTransaction(db, async (transaction) => {
            const boardRef = doc(db, "boards", boardId);

            const boardDoc = await transaction.get(boardRef);
            if (!boardDoc.exists()) {
              throw "Document does not exist!";
            }

            const newReplyCount = boardDoc.data().publicReplies + 1;
            if (!isStaff) {
              transaction.update(boardRef, { publicReplies: newReplyCount });
            } else {
              transaction.update(boardRef, {
                publicReplies: newReplyCount,
                seenPublicReplies: newReplyCount,
              });
            }
          });
        } catch (e) {
          console.error("Transaction failed 1: ", e);
        }
        try {
          await runTransaction(db, async (transaction) => {
            const sectionRef = doc(db, "sections", sectionId);

            const sectionDoc = await transaction.get(sectionRef);
            if (!sectionDoc.exists()) {
              throw "Document does not exist!";
            }

            const newReplyCount = sectionDoc.data().publicComments
              ? sectionDoc.data().publicComments + 1
              : 1;
            transaction.update(sectionRef, { publicComments: newReplyCount });
          });
        } catch (e) {
          console.error("Transaction failed 1: ", e);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const renderProfilePicture = useMemo(() => {
    if (userAvatar !== null)
      return (
        <Image
          source={{ uri: userAvatar }}
          style={{ width: 36, marginLeft: 8, height: 36, borderRadius: 32 }}
        />
      );
    else
      return (
        <MaterialCommunityIcons
          name="account-circle"
          size={44}
          color={"#ccc"}
          style={{ marginLeft: 0 }}
        />
      );
  }, [userAvatar]);

  return (
    <View style={[styles.container, loading && { display: "none" }]}>
      <View style={styles.writeCommentContainer}>
        <HStack spacing={12} style={{ width: "100%" }}>
          {renderProfilePicture}
          <TextInput
            multiline={true}
            numberOfLines={numLines}
            maxLength={maxCommentCharCount}
            placeholder={
              comments.length === 0
                ? "Be the first to comment..."
                : "Add a comment..."
            }
            placeholderTextColor={"#aaa"}
            value={commentText}
            onChangeText={(text) => {
              if (text.length <= maxCommentCharCount) {
                setCommentText(text.replace("\n", " "));
                setCommentCharCount(text.length);
              }
            }}
            onSubmitEditing={handleAddComment}
            style={[
              styles.writeCommentInput,
              {
                minHeight: 70,
                flex: 1,
                padding:5,
                paddingBottom: 15,
                verticalAlign: "top",
              },
            ]}
          />
        </HStack>
        <Text
          style={[
            styles.charCount,
            {
              marginTop: -29,
              marginRight: 6,
              fontSize: 10,
              color: "#999",
              zIndex: 999,
            },
          ]}
        >
          {commentCharCount}/{maxCommentCharCount}
        </Text>
        <TouchableOpacity
          style={
            commentText === ""
              ? styles.commentButton
              : styles.commentButtonActive
          }
          onPress={handleAddComment}
        >
          <Text
            style={
              commentText === ""
                ? styles.commentButtonText
                : styles.commentButtonTextActive
            }
          >
            Comment
          </Text>
        </TouchableOpacity>
      </View>
      {comments.map((comment, i) =>
        isStaff ? (
          <StaffComment
            key={comment.id}
            comment={comment}
            isReply={false}
            sectionId={sectionId}
            boardId={boardId}
            setComments={setComments}
            teacherId={teacherId}
            maxReplyLength={maxReplyCharCount}
            setSectionCommentCount={setCommentCount}
            isPublic={isPublic}
            discussionMuted={discussionMuted}
          />
        ) : (
          <Comment
            key={comment.id}
            comment={comment}
            isReply={false}
            sectionId={sectionId}
            boardId={boardId}
            classId={classId}
            setComments={setComments}
            teacherId={teacherId}
            maxReplyLength={maxReplyCharCount}
            isPublic={isPublic}
            discussionMuted={discussionMuted}
          />
        )
      )}
      {showLoadMore && (
        <TouchableOpacity onPress={fetchData}>
          <HStack
            pl={10}
            items="center"
            style={{
              gap: 3,
              paddingVertical: 10,
              marginBottom: -10,
              marginLeft: 23,
            }}
          >
            <MaterialCommunityIcons
              name={"chevron-down"}
              color={"#1677ff"}
              size={24}
            />
            <Text style={{ color: "#1677ff", fontWeight: "bold" }}>
              load more comment
              {sectionCommentCount - comments.length > 1 ? "s" : ""}
              {" ("}
              {sectionCommentCount - comments.length}
              {")"}
            </Text>
          </HStack>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  writeCommentContainer: {
    flexDirection: "column",
    alignItems: "flex-end",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    paddingBottom: 10,
    paddingTop: 10,
    gap: 8,
  },
  writeCommentInput: {
    width: "100%",
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 8,
    backgroundColor: "#f9f9f9",
    marginVertical: 5,
  },
  commentButton: {
    height: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 8,
    backgroundColor: "#f9f9f9",
    alignItems: "center",
    justifyContent: "center",
  },
  commentButtonActive: {
    height: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 8,
    backgroundColor: "#3b82f6",
    alignItems: "center",
    justifyContent: "center",
  },
  commentButtonText: {
    color: "#999",
    fontWeight: "500",
  },
  commentButtonTextActive: {
    color: "white",
    fontWeight: "500",
  },
  commentContainer: {
    flexDirection: "column",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  replyContainer: {
    backgroundColor: "white",
    flexDirection: "row",
    paddingLeft: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },
  commentContent: {
    marginLeft: 10,
    flex: 1,
    gap: 4,
  },
  commentAuthor: {
    fontWeight: "bold",
    color: "#333",
  },
  commentText: {
    color: "#333",
  },
  commentActions: {
    flexDirection: "row",
    alignItems: "center",
  },
  likeCount: {
    marginLeft: 5,
    color: "#999",
  },
  replyText: {
    marginLeft: 10,
    color: "#999",
  },
  replyDialogTitle: {
    backgroundColor: "white",
    marginTop: 10,
    fontWeight: "bold",
    marginBottom: 10,
  },
  replyTextInput: {
    height: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 8,
    backgroundColor: "#f9f9f9",
    marginBottom: 10,
  },
  replyDialogActions: {
    backgroundColor: "white",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 4,
  },
  dialogCancelButton: {
    height: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 8,
    //backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  commentButtonText: {
    color: "#999",
  },
  charCount: {
    alignSelf: "flex-end",
    fontSize: 12,
    paddingBottom: 6,
    color: "#888",
  },
});

export default CommentSection;
