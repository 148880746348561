import * as React from "react";
import { useWindowDimensions } from "react-native";

import { Surface } from "@react-native-material/core";

import SignUpForm from "../../components/account/SignUpForm";
import LoginHero from "./LoginHero";

import { ScrollView } from "react-native";
import { SafeAreaView } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Platform } from "react-native";

export default function SignUpScreen({ navigation }) {
  const [small, setSmall] = React.useState(false);

  const [height, setHeight] = React.useState(300);

  const handleLayout = (event) => {
    const { width: layoutWidth } = event.nativeEvent.layout;
    setSmall(layoutWidth < 640);
  };

  return (
    <SafeAreaView
      onLayout={handleLayout}
      style={{
        backgroundColor: "white",
        minHeight: height,
      }}
    >
      <KeyboardAwareScrollView
        contentContainerStyle={{
          padding: 24,
          //minHeight: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        style={{overflow: Platform.OS == "android"? "scroll" : "visible"  }}
        persistentScrollbar={false}
      >
        <Surface
          elevation={5}
          style={{
            borderRadius: 5,
            flexDirection: "row",
            maxWidth: 1200,
            width: "100%",
          }}
        >
          <SignUpForm small={small} navigation={navigation} />
          {!small && <LoginHero small={small} />}
        </Surface>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}
