import React, { useRef } from "react";

import BasicAccordion from "../data/wrappers/BasicAccordion";

import { Box, HStack, Surface, VStack } from "@react-native-material/core";

import { View, Text } from "react-native";

import PopIn from "../animations/visibility/PopIn";
import AutoPopIn from "../animations/visibility/AutoPopIn";

import { intToRoman } from "../../utils/numberUtils";
import BasicCarousel from "../cards/Carousel/BasicCarousel";
import CarouselMediaCard from "../cards/Carousel/CarouselMediaCard";
import CommentSection from "./CommentSection";
import { Linking } from "react-native";
import { getContrastTextColor } from "../../utils/generateColor";
import Accordion from "../data/wrappers/BasicAccordion";

export default function BoardSection({
  initialComments,
  id,
  index = 1,
  boardId,
  boardColor,
  isStaff = false,
  classId,
  teacherId,
  setKeyboardAwareEnabled,
  isPublic = false,
  discussionMuted = false,
  ...props
}) {
  const [commentCount, setCommentCount] = React.useState(0);
  function handleCardPress(link) {
    if (Linking.canOpenURL(link)) Linking.openURL(link);
    else alert("Link is not valid.");
  }

  const romanIndex = intToRoman(index);

  const textColor = getContrastTextColor(boardColor);

  return (
    <AutoPopIn>
      <BasicAccordion
        color={boardColor}
        textColor={textColor}
        title={"Section " + romanIndex}
        content={
          <VStack fill pt={10} spacing={16}>
            <Surface
              elevation={2}
              style={{
                borderRadius: 5,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: "white",
                flexDirection: "column",
                gap: 5,
                padding: 15,
                alignItems: "center",
              }}
            >
              <Text
                selectable
                style={{
                  fontWeight: "500",
                  fontSize: 18,
                  textDecorationLine: "underline",
                  textAlign: "center",
                  fontFamily: "eb-garamond",
                }}
              >
                {props.name}
              </Text>
              <Text
                selectable
                style={{
                  maxWidth: 800,
                  textAlign: "justify",
                  paddingBottom: 10,
                }}
              >
                {props.children}
              </Text>
            </Surface>
            <PopIn
              preload={true}
              preloadPadding={16}
              isVisible={props.getLitOn}
            >
              {props.mediaData.length != 0 ? (
                props.mediaData.length > 1 ? (
                  <View
                    style={{
                      width: "100%",
                      alignItems: "center",
                      height: 540,
                    }}
                  >
                    <View style={{ maxWidth: 1000, width: "100%" }}>
                      <BasicCarousel
                        autoPlay={false}
                        height={540}
                        maxWidth={1000}
                        data={props.mediaData}
                        renderItem={(card, i) => (
                          <CarouselMediaCard
                            index={i}
                            id={card.id}
                            {...card}
                            onCardPress={handleCardPress}
                            classId={classId}
                            teacherId={teacherId}
                            total={props.mediaData.length}
                          />
                        )}
                      />
                    </View>
                  </View>
                ) : (
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <View
                      style={{
                        minWidth: 300,
                        maxWidth: 800,
                        marginHorizontal: "auto",
                      }}
                    >
                      <CarouselMediaCard
                        index={0}
                        id={0}
                        {...props.mediaData[0]}
                        section={index}
                        onCardPress={handleCardPress}
                        classId={classId}
                        teacherId={teacherId}
                        total={1}
                      />
                    </View>
                  </View>
                )
              ) : null}
            </PopIn>
            <PopIn isVisible={props.getLitOn}>
              <Accordion
                defaultExpanded
                innerStyle={{}}
                style={{ marginTop: 16 }}
                color="white"
                textColor="black"
                title={commentCount == -1? "Loading..." :  "(" + commentCount + ") Comments"}
                content={
                  <Surface
                    elevation={2}
                    style={{ padding: commentCount == -1? 0: 10, backgroundColor: "white" }}
                  >
                    <CommentSection
                      isStaff={isStaff}
                      sectionId={id}
                      boardId={boardId}
                      classId={classId}
                      setCommentCount={setCommentCount}
                      teacherId={teacherId}
                      setKeyboardAwareEnabled={setKeyboardAwareEnabled}
                      sectionCommentCount={commentCount}
                      isPublic={isPublic}
                      discussionMuted={discussionMuted}
                    />
                  </Surface>
                }
              />
            </PopIn>
          </VStack>
        }
        defaultExpanded
      />
    </AutoPopIn>
  );
}
