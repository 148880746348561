export const generatePastelColor = () => {
  let R = Math.floor(Math.random() * 40 + 200);
  let G = Math.floor(Math.random() * 40 + 200);
  let B = Math.floor(Math.random() * 40 + 200);

  let rgb = (R << 16) + (G << 8) + B;
  return `#${rgb.toString(16)}`;
};

export function changeColorAlpha(color, opacity) {
  //if it has an alpha, remove it
  if (color.length > 7) color = color.substring(0, color.length - 2);

  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
  let opacityHex = _opacity.toString(16).toUpperCase();

  // opacities near 0 need a trailing 0
  if (opacityHex.length == 1) opacityHex = "0" + opacityHex;

  return color + opacityHex;
}

export function getRandomNeonColor() {
  const neonColors = [
    "#39FF14", // Neon Green
    "#0DFEFF", // Neon Blue
    "#FF69B4", // Neon Pink
    "#FFA500", // Neon Orange
    "#FFFF33", // Neon Yellow
  ];

  const randomIndex = Math.floor(Math.random() * neonColors.length);
  return neonColors[randomIndex];
}

export function getContrastTextColor(hex) {
  // Convert hex color to RGB
  let r = parseInt(hex.substr(1, 2), 16); // Red component
  let g = parseInt(hex.substr(3, 2), 16); // Green component
  let b = parseInt(hex.substr(5, 2), 16); // Blue component

  // Calculate the brightness perception
  let brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Calculate opacity based on brightness, and add a constant factor to lessen the opacity
  let opacity =
    (brightness > 128 ? (255 - brightness) / 255 : brightness / 255) + 0.35;

  // Make sure the opacity stays within the valid range [0, 1]
  if (opacity > 1) opacity = 1;
  if (opacity < 0) opacity = 0;

  // Choose white or black text color
  if (brightness > 128) {
    // For light colors, use black text with calculated opacity
    return `rgba(0, 0, 0, ${opacity})`;
  } else {
    // For dark colors, use white text with calculated opacity
    return `rgba(255, 255, 255, ${opacity})`;
  }
}

export const pastelColors = [
  "#957DAD", // Lavender
  "#E0BBe4", // Thistle Pink
  "#FEC8D8", // Candy Pink
  "#FFDFD3", // Misty Rose
  "#FFC4C4", // Pastel Pink
  "#F4CFDF", // Baby Pink
  "#EF7C8E", // Warm Pink
  "#D8A7B1", // Rose Pink
  "#DFC5E8", // Lilac
  "#A084CA", // Medium Purple
  "#645CAA", // Deep Aubergine
  "#7882A4", // Muted Blue
  "#D6D8F2", // Baby Blue
  "#CBC7DD", // Periwinkle
  "#EFEFEF", // Light Gray
  "#D1D1D1", // Medium Gray
  "#F5F3E7", // Ecru
  "#F7ECDE", // Linen
  "#FAE8E0", // Cream
  "#FAF4B7", // Soft Yellow
  "#FFFAB0", // Soft Yellow
  "#F8FFEB", // Palest Yellow
  "#FFDFBD", // Pale Orange
  "#FAC590", // Soft Orange
  "#FFC87C", // Warm Orange"
  "#F28500", // Peach
  "#D37F40", // Warm Sun
  "#E7CBA9", // Light Brandy
  "#C0A080", // Warm Brown
  "#E9DAC1", // Warm Sand
  "#B6E2D3", // Mint Green
  "#CBF2B8", // Tea Green
  "#CCD4BF", // Soft Sage
  "#CDFCF6", // Light Turquoise
  "#BAEEE5", // Pale Sea Blue
  "#94C0D0", // Sea Blue
];
export const getPastelColor = () => {
  return pastelColors[Math.floor(Math.random() * pastelColors.length)];
};

export function getBlackWhiteTextColor(hexColor) {
  // Convert the hex color to RGB values
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);

  // Calculate the perceived brightness of the color
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // If the color is closer to black, return white; if it's closer to white, return black
  return brightness > 128 ? "black" : "white";
}
