import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import StaffBoardScreen from "../../screens/staff/StaffBoard";
import StaffClassScreen from "../../screens/staff/StaffClass";
import StaffDashboardScreen from "../../screens/staff/StaffDashboard";

const Stack = createNativeStackNavigator();

export default function StaffDashboardNavigator({ navigation }) {
  return (
    <Stack.Navigator screenOptions={{ headerShown: true }}>
      <Stack.Screen
        name="Home"
        options={{ headerShown: false, title: "Dashboard" }}
        component={StaffDashboardScreen}
      />
      <Stack.Screen
        name="Class"
        options={({ route }) => ({ title: route.params.name })}
        component={StaffClassScreen}
      ></Stack.Screen>
      <Stack.Screen
        name="Board"
        options={({ route }) => ({ title: route.params.name })}
        component={StaffBoardScreen}
      ></Stack.Screen>
    </Stack.Navigator>
  );
}
