import React, { useEffect, useState } from "react";
import { ScrollView, TouchableOpacity, Text, View } from "react-native";

import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  onSnapshot,
  getDoc,
  deleteDoc,
  updateDoc,
  getDocs,
  orderBy,
  writeBatch,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";

import { Flex } from "@react-native-material/core";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { Fragment } from "react";
import FabGroup from "../../components/general/fabs/FabGroup";
import SquareGrid from "../../components/cards/Grid/SquareGrid";
import AddBoardPopup from "../../components/data/input/Modals/AddBoardPopup";
import SimpleToast from "../../components/animations/toasts/SimpleToast";
import CustomAlertDialog from "../../components/data/input/Modals/CustomAlertDialog";
import { DeviceEventEmitter } from "react-native";
import { useSettings } from "../../components/contexts/SettingsContext";

export default function StaffBoardsScreen({ navigation }) {
  const [showAddBoard, setShowAddBoard] = React.useState(false);
  const [toastVisible, setToastVisible] = React.useState(0);
  const [toastMessage, setToastMessage] = React.useState("");
  const [boardData, setBoardData] = React.useState([]);
  const [classesData, setClassesData] = React.useState([]);
  const [alertPromptVisible, setAlertPromptVisible] = useState(false);
  const [currentBoardId, setCurrentBoardId] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [initalBoardName, setInitalBoardName] = React.useState("");
  const [initalColor, setInitalColor] = React.useState("");
  const [initialSections, setInitialSections] = React.useState([]);
  const [initialSelectedClasses, setInitialSelectedClasses] = React.useState(
    []
  );
  const [initialEditReboard, setInitialEditReboard] = useState(false);
  const [initialIsPublic, setInitialIsPublic] = React.useState(false);
  const [initialTags, setInitialTags] = React.useState([]);

  const { settings } = useSettings();
  const boardTileSize = settings.boardTileSize ? settings.boardTileSize : 1;

  useEffect(() => {
    fetchData();

    DeviceEventEmitter.addListener("showAddNew", (classId) => {
      if (classId) setInitialSelectedClasses([classId]);
      setEdit(false);
      setShowAddBoard(true);
    });

    return () => {
      DeviceEventEmitter.removeAllListeners("showAddNew");
    };
  }, []);

  const fetchData = async () => {
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const db = getFirestore();

    // Get a reference to the teacher document
    const teacherRef = doc(db, "users", userId);

    const classesQ = query(
      collection(db, "classes"),
      where("teacherId", "==", teacherRef),
      where("status", "==", "active")
    );

    onSnapshot(classesQ, (querySnapshot) => {
      const _classData = querySnapshot.docs.map((doc) => ({
        title: doc.data().className,
        id: doc.id,
      }));

      setClassesData(_classData);
    });
    ``;

    const boardsQ = query(
      collection(db, "boards"),
      where("teacherId", "==", teacherRef),
      orderBy("lastAccessed", "desc")
    );

    onSnapshot(boardsQ, (querySnapshot) => {
      const _boardsData = querySnapshot.docs.map((doc) => {
        let tags = [
          `${
            doc.data().views == 1
              ? doc.data().views + " view"
              : doc.data().views + " views"
          }`,
          `${doc.data().replies} ${
            doc.data().replies == 1 ? "reply" : "replies"
          }`,
          `${doc.data().sections ? doc.data().sections : 0} ${
            doc.data().sections == 1 ? "section" : "sections"
          }`,
          `${doc.data().favorites} fave${doc.data.favorites != 1 ? "s" : ""}`,
          `${
            doc.data().creationTimeStamp &&
            doc.data().creationTimeStamp.toDate().toDateString()
          }`,
        ];

        if (doc.data().modalityCount) {
          tags = [
            ...tags.slice(0, 3),
            `${doc.data().modalityCount} modalities`,
            ...tags.slice(3),
          ];
        }

        if (doc.data().isReboard) {
          tags = [`[reboard]$#a8071a$700$italic`, ...tags];
        }

        return {
          title: doc.data().boardName,
          id: doc.id,
          notificationCount: doc.data().replies - doc.data().seenReplies,
          backgroundColor: doc.data().color,
          tags,
        };
      });

      setBoardData(_boardsData);
    });
  };

  const confirmDelete = (id) => {
    setAlertPromptVisible(true);
    setCurrentBoardId(id);
  };

  const deleteSelectedBoard = async (id) => {
    const db = getFirestore();
    const boardRef = doc(db, "boards", id);

    const batch = writeBatch(db);

    //delete sections
    const sectionQ = query(
      collection(db, "sections"),
      where("boardId", "==", boardRef)
    );

    const sectionDocs = await getDocs(sectionQ);
    sectionDocs.docs.forEach((section) => {
      batch.delete(section.ref);
    });

    //delete modalities
    const modalitiesQ = query(
      collection(db, "modalities"),
      where("boardId", "==", boardRef)
    );

    const modalitiesDocs = await getDocs(modalitiesQ);

    modalitiesDocs.docs.forEach((modality) => {
      batch.delete(modality.ref);
    });

    await batch
      .commit()
      .then(() => {
        //console.("Batched write completed successfully");
      })
      .catch((error) => {
        console.error("Error during batch write: ", error);
      });

    await deleteDoc(boardRef);

    setToastMessage("Board successfully deleted");
    setToastVisible(Math.random());
  };

  const editSelectedBoard = async (id) => {
    const db = getFirestore();

    setEdit(true);

    const boardRef = doc(db, "boards", id);

    // Get a reference to the board document
    const boardDoc = await getDoc(boardRef);

    setInitalBoardName(boardDoc.data().boardName);
    setInitalColor(boardDoc.data().color);
    setCurrentBoardId(id);
    setInitialSections(boardDoc.data().sections);
    setInitialIsPublic(
      boardDoc.data().isPublic ? boardDoc.data().isPublic : false
    );
    setInitialEditReboard(
      boardDoc.data().isReboard ? boardDoc.data().isReboard : false
    );
    setInitialTags(boardDoc.data().tags ? boardDoc.data().tags : []);

    //get section modalities and data
    const sectionQ = query(
      collection(db, "sections"),
      where("boardId", "==", boardRef),
      orderBy("index", "asc")
    );

    const modalitiesQ = query(
      collection(db, "modalities"),
      where("boardId", "==", boardRef)
    );

    const sectionData = await getDocs(sectionQ);
    const modalitiesData = await getDocs(modalitiesQ);

    const _sections = sectionData.docs.map((section, i) => {
      return {
        text: section.data().text,
        title: section.data().title,
        modalities: modalitiesData.docs
          .filter((modality) => modality.data().sectionId.id == section.id)
          .map((modality) => {
            return {
              id: modality.id,
              link: modality.data().link,
              imgLink: modality.data().imgLink,
              favCount: modality.data().favCount,
              initialDislikeCount: modality.data().dislikeCount,
              initialLikeCount: modality.data().likeCount,
              title: modality.data().title,
              isNew: false,
              type: modality.data().type ? modality.data().type : "web",
            };
          }),
        index: i,
        isNew: false,
        id: section.id,
      };
    });

    setInitialSections(_sections);

    const _initialSelectedClasses = boardDoc.data().classIds.map((classId) => {
      const _class = classesData.find(
        (classData) => classData.id === classId.id
      );
      return _class;
    });

    setInitialSelectedClasses(
      _initialSelectedClasses.filter((c) => c !== undefined)
    );

    setShowAddBoard(true);
  };

  return (
    <Fragment>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, backgroundColor: "#f0eff4" }}
      >
        <Flex fill p={16} style={{ gap: 16, paddingBottom: 91 }}>
          <AddBoardPopup
            onClose={() => setShowAddBoard(false)}
            onSubmit={() => setShowAddBoard(false)}
            visible={showAddBoard}
            classes={classesData}
            setToastMessage={setToastMessage}
            setToastVisible={setToastVisible}
            initialColor={edit ? initalColor : ""}
            initialBoardName={edit ? initalBoardName : ""}
            initialClasses={initialSelectedClasses}
            initialSections={edit ? initialSections : []}
            initialBoardId={currentBoardId}
            initialIsPublic={initialIsPublic}
            initialTags={initialTags}
            initialEditReboard={initialEditReboard}
            edit={edit}
          />
          <SquareGrid
            onAddCardPress={() => {
              setEdit(false);
              setShowAddBoard(true);
            }}
            numberOfLines={200 * boardTileSize < 170 ? 1 : 3}
            key={boardTileSize}
            columnWidth={200 * boardTileSize}
            onCardPress={(item) =>
              navigation.navigate("Board", {
                name: item.title,
                boardId: item.id,
              })
            }
            data={boardData}
            options={[
              {
                label: "Edit board",
                icon: (
                  <MaterialCommunityIcons
                    name="pencil-plus-outline"
                    size={20}
                    color="black"
                  />
                ),
                onSelect: editSelectedBoard,
              },
              {
                icon: (
                  <MaterialCommunityIcons
                    name="delete-forever-outline"
                    size={20}
                    color="black"
                  />
                ),
                label: "Delete board",
                onSelect: confirmDelete,
              },
            ]}
          />
        </Flex>
      </ScrollView>
      <Fragment>
        <FabGroup
          buttons={[
            {
              icon: (
                <MaterialCommunityIcons
                  name="keyboard-return"
                  size={24}
                  color="black"
                />
              ),
              color: "white",
              onPress: () => navigation.goBack(),
            },
            {
              icon: <MaterialCommunityIcons name="bank" size={32} />,
              color: "white",
              onPress: () => navigation.navigate("Vault"),
            },
            {
              icon: <Ionicons name="people" size={32} />,
              color: "white",
              onPress: () => navigation.navigate("Classes"),
            },
          ]}
        />
        <SimpleToast message={toastMessage} visible={toastVisible} />
        <CustomAlertDialog
          setModalVisible={setAlertPromptVisible}
          onConfirm={() => archiveClassSelected(currentClassId)}
          modalVisible={alertPromptVisible}
          title="Are you sure?"
          message="This will permenatly delete this class and all its data."
          buttons={[
            <TouchableOpacity
              key={"cancel"}
              onPress={() => setAlertPromptVisible(false)}
            >
              <Text style={{ color: "#1677ff" }}>Cancel</Text>
            </TouchableOpacity>,
            <TouchableOpacity
              key={"delete"}
              style={{
                backgroundColor: "#f5222d",
                borderRadius: 5,
                paddingHorizontal: 14,
                paddingVertical: 10,
              }}
              onPress={() => {
                deleteSelectedBoard(currentBoardId);
                setAlertPromptVisible(false);
              }}
            >
              <Text style={{ color: "white" }}>Delete Board</Text>
            </TouchableOpacity>,
          ]}
        />
      </Fragment>
    </Fragment>
  );
}
