import * as React from "react";
import { View, RefreshControl, Platform } from "react-native";

import { Flex, Surface } from "@react-native-material/core";

import BasicTabContainer from "../../components/data/wrappers/BasicTabContainer";

import Accordion from "../../components/data/wrappers/BasicAccordion";
import { useState } from "react";
import { Spinner, VStack } from "native-base";
import FabGroup from "../../components/general/fabs/FabGroup";
import { Fragment } from "react";
import { getFirestore } from "firebase/firestore";
import BasicTable from "../../components/data/tables/BasicTable";
import BasicPieChart from "../../components/data/graphs/BasicPieChart";
import BasicBarChart from "../../components/data/graphs/BasicBarChart";
import {
  boardTableColumns,
  discussionTableColumns,
  getAdminDashboardData,
  getAdminDashboardDiscussionBarData,
  getAdminDashboardReboardsBarData,
  getAdminDashboardStudentsBarData,
  getAdminDashboardUsersBarData,
  getAdminDashboardViewTimesBarData,
  getAdminDashboardViewsBarData,
  getTimes,
  modalityDataTableColumns,
  studentTableColumns,
  teacherTableColumns,
} from "./adminDashboardData";
import { useEffect } from "react";
import CustomSegmentedControl from "../../components/data/input/CustomSegmentedControl";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

export default function AdminDashboardScreen({ navigation }) {
  const [dataLoading, setDataLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  //DATA_TABLES
  const [teacherTableData, setTeacherTableData] = useState([]);
  const [studentTableData, setStudentTableData] = useState([]);
  const [boardTableData, setBoardTableData] = useState([]);
  const [discussionTableData, setDiscussionTableData] = useState([]);
  const [modalityTableData, setModalityTableData] = useState([]);
  //BAR_CHARTS
  const [studentsOverTimeData, setStudentsOverTimeData] = useState([]);
  const [teachersOverTimeData, setTeachersOverTimeData] = useState([]);
  const [discussionOverTimeData, setDiscussionOverTimeData] = useState([]);
  const [viewsOverTimeData, setViewsOverTimeData] = useState([]);
  const [avgSessionOverTimeData, setAvgSessionOverTimeData] = useState([]);
  const [timeViewedOverTimeData, setTimeViewedOverTimeData] = useState([]);
  const [reboardsOverTimeData, setReboardsOverTimeData] = useState([]);
  //BAR_CHART TIME SETTINGS
  const [boardTimeRangeIndex, setBoardTimeRangeIndex] = useState(2);
  const [boardTimeRangeLoading, setBoardTimeRangeLoading] = useState(false);
  const [studentTimeRangeIndex, setStudentTimeRangeIndex] = useState(2);
  const [studentTimeRangeLoading, setStudentTimeRangeLoading] = useState(false);
  const [teacherTimeRangeIndex, setTeacherTimeRangeIndex] = useState(2);
  const [teacherTimeRangeLoading, setTeacherTimeRangeLoading] = useState(false);
  //PIE_CHARTS
  const [studentEthnicityPieData, setStudentEthnicityPieData] = useState([]);
  const [studentGenderPieData, setStudentGenderPieData] = useState([]);
  const [studentAgePieData, setStudentAgePieData] = useState([]);
  const [teacherEthnicityPieData, setTeacherEthnicityPieData] = useState([]);
  const [teacherGenderPieData, setTeacherGenderPieData] = useState([]);
  const [teacherAgePieData, setTeacherAgePieData] = useState([]);
  const [commentsRepliesRatioPieData, setCommentsRepliesRatioPieData] =
    useState([]);
  const [publicPrivateRatioPieData, setPublicPrivateRatioPieData] = useState(
    []
  );
  const [modalityTypesPieData, setModalityTypesPieData] = useState([]);
  const [modalityLRPieData, setModalityLRPieData] = useState([]);
  const [modalityFavePieData, setModalityFavePieData] = useState([]);
  const [tiktokLRPieData, setTiktokLRPieData] = useState([]);
  const [youtubeLRPieData, setYoutubeLRPieData] = useState([]);
  const [webLRPieData, setWebLRPieData] = useState([]);

  const db = getFirestore();

  useEffect(() => {
    const boardTimes = getTimes(boardTimeRangeIndex);
    let boardStartTime = boardTimes.startTime;
    let boardEndTime = boardTimes.endTime;

    async function getData() {
      const viewTimes = await getAdminDashboardViewTimesBarData(
        db,
        boardStartTime,
        boardEndTime
      );
      const views = await getAdminDashboardViewsBarData(
        db,
        boardStartTime,
        boardEndTime
      );

      const reboards = await getAdminDashboardReboardsBarData(
        db,
        boardStartTime,
        boardEndTime
      );

      const discussion = await getAdminDashboardDiscussionBarData(
        db,
        boardStartTime,
        boardEndTime
      );

      setTimeViewedOverTimeData(viewTimes[0]);
      setAvgSessionOverTimeData(viewTimes[1]);
      setViewsOverTimeData(views);
      setReboardsOverTimeData(reboards);
      setDiscussionOverTimeData(discussion);
    }

    getData();
  }, [boardTimeRangeIndex]);

  useEffect(() => {
    const studentTimes = getTimes(studentTimeRangeIndex);
    const studentStartTime = studentTimes.startTime;
    const studentEndTime = studentTimes.endTime;

    async function getData() {
      const students = await getAdminDashboardUsersBarData(
        db,
        studentStartTime,
        studentEndTime,
        10,
        "Student"
      );

      setStudentsOverTimeData(students);
    }

    getData();
  }, [studentTimeRangeIndex]);

  useEffect(() => {
    const teacherTimes = getTimes(teacherTimeRangeIndex);
    let teacherStartTime = teacherTimes.startTime;
    let teacherEndTime = teacherTimes.endTime;

    async function getData() {
      const teachers = await getAdminDashboardUsersBarData(
        db,
        teacherStartTime,
        teacherEndTime,
        10,
        "Teacher"
      );

      setTeachersOverTimeData(teachers);
    }

    getData();
  }, [teacherTimeRangeIndex]);

  useEffect(() => {
    setDataLoading(true);
    fetchData();
  }, []);

  async function fetchData() {
    setRefreshing(true);
    try {
      const data = await getAdminDashboardData(
        db,
        studentTimeRangeIndex,
        teacherTimeRangeIndex,
        boardTimeRangeIndex
      );

      setStudentTableData(data.studentTableData);
      setTeacherTableData(data.teacherTableData);
      setBoardTableData(data.boardTableData);
      setDiscussionTableData(data.discussionTableData);
      setModalityTableData(data.modalityTableData);

      setModalityTypesPieData(data.modalityTypesPieData);
      setModalityLRPieData(data.modalityLRPieData);
      setTiktokLRPieData(data.tiktokLRPieData);
      setYoutubeLRPieData(data.youtubeLRPieData);
      setWebLRPieData(data.webLRPieData);
      setModalityFavePieData(data.modalityFavePieData);
      setPublicPrivateRatioPieData(data.publicPrivateRatioPieData);
      setCommentsRepliesRatioPieData(data.commentsRepliesRatioPieData);

      setStudentEthnicityPieData(data.studentEthnicityPieData);
      setStudentAgePieData(data.studentAgePieData);
      setStudentGenderPieData(data.studentGenderPieData);

      setTeacherEthnicityPieData(data.teacherEthnicityPieData);
      setTeacherAgePieData(data.teacherAgePieData);
      setTeacherGenderPieData(data.teacherGenderPieData);

      setTimeViewedOverTimeData(data.timeViewedOverTimeData);
      setAvgSessionOverTimeData(data.timeViewedOverTimeData[1]);
      setViewsOverTimeData(data.viewsOverTimeData[0]);
      setReboardsOverTimeData(data.reboardsOverTimeData);
      setDiscussionOverTimeData(data.discussionOverTimeData);

      setStudentsOverTimeData(data.studentsOverTimeData);
      setTeachersOverTimeData(data.teachersOverTimeData);
    } catch (error) {
      console.error(error);
    }

    setDataLoading(false);
    setRefreshing(false);
  }

  return (
    <Fragment>
      <KeyboardAwareScrollView
        style={{ flex: 1, backgroundColor: "#f0eff4" }}
        refreshControl={
          !dataLoading && (
            <RefreshControl refreshing={refreshing} onRefresh={fetchData} />
          )
        }
      >
        <Flex fill p={16} style={{ gap: 16, paddingBottom: 91 }}>
          {Platform.OS !== "web" && dataLoading && (
            <Spinner size={"lg"} color={"black"} />
          )}
          {Platform.OS === "web" && refreshing && (
            <Spinner size={"lg"} color={"black"} />
          )}
          {!dataLoading && (
            <VStack style={{ gap: 10 }}>
              <Accordion
                title="Users"
                content={
                  <BasicTabContainer
                    screens={[
                      {
                        name: "Students",
                        screen: (
                          <VStack
                            style={{
                              marginHorizontal: 7,
                              marginBottom: -5,
                              gap: 5,
                            }}
                          >
                            <Accordion
                              title={"Student Data Table"}
                              content={
                                <BasicTable
                                  columns={studentTableColumns}
                                  data={studentTableData}
                                />
                              }
                            />
                            <Accordion
                              title={"Student Bar Charts"}
                              content={
                                <VStack>
                                  <View style={{ marginVertical: 10 }}>
                                    <CustomSegmentedControl
                                      values={[
                                        "Today",
                                        "Week",
                                        "Month",
                                        "Year ",
                                      ]}
                                      selectedIndex={studentTimeRangeIndex}
                                      onValueChange={(index) =>
                                        setStudentTimeRangeIndex(index)
                                      }
                                    />
                                  </View>
                                  {studentTimeRangeLoading && (
                                    <Spinner
                                      style={{ marginBottom: 5 }}
                                      size={"sm"}
                                      color={"black"}
                                    />
                                  )}
                                  <BasicTabContainer
                                    style={{ margin: 16 }}
                                    screens={[
                                      {
                                        name: "New Students",
                                        screen: (
                                          <BasicBarChart
                                            colorScale={["#f7bb51", "#e56853"]}
                                            data={studentsOverTimeData}
                                          />
                                        ),
                                      },
                                    ]}
                                  />
                                </VStack>
                              }
                            />
                            <Accordion
                              title={"Student Pie Charts"}
                              content={
                                <BasicTabContainer
                                  style={{ margin: 16 }}
                                  screens={[
                                    {
                                      name: "Ethnicity",
                                      screen: (
                                        <BasicPieChart
                                          colorScale={["#f7bb51", "#e56853"]}
                                          data={studentEthnicityPieData}
                                        />
                                      ),
                                    },
                                    {
                                      name: "Gender",
                                      screen: (
                                        <BasicPieChart
                                          colorScale={["#f7bb51", "#e56853"]}
                                          data={studentGenderPieData}
                                        />
                                      ),
                                    },
                                    {
                                      name: "Age",
                                      screen: (
                                        <BasicPieChart
                                          colorScale={["#f7bb51", "#e56853"]}
                                          data={studentAgePieData}
                                        />
                                      ),
                                    },
                                  ]}
                                />
                              }
                            />
                          </VStack>
                        ),
                      },
                      {
                        name: "Teachers",
                        screen: (
                          <VStack
                            style={{
                              marginHorizontal: 7,
                              gap: 5,
                              marginBottom: 3,
                            }}
                          >
                            <Accordion
                              title={"Teacher Data Table"}
                              content={
                                <BasicTable
                                  columns={teacherTableColumns}
                                  data={teacherTableData}
                                />
                              }
                            />
                            <Accordion
                              title={"Teacher Bar Charts"}
                              content={
                                <VStack>
                                  <View style={{ marginVertical: 10 }}>
                                    <CustomSegmentedControl
                                      values={[
                                        "Today",
                                        "Week",
                                        "Month",
                                        "Year ",
                                      ]}
                                      selectedIndex={teacherTimeRangeIndex}
                                      onValueChange={(index) =>
                                        setTeacherTimeRangeIndex(index)
                                      }
                                    />
                                  </View>
                                  {teacherTimeRangeLoading && (
                                    <Spinner
                                      style={{ marginBottom: 5 }}
                                      size={"sm"}
                                      color={"black"}
                                    />
                                  )}
                                  <BasicTabContainer
                                    style={{ margin: 16 }}
                                    screens={[
                                      {
                                        name: "New Teachers",
                                        screen: (
                                          <BasicBarChart
                                            colorScale={["#f7bb51", "#e56853"]}
                                            data={teachersOverTimeData}
                                          />
                                        ),
                                      },
                                    ]}
                                  />
                                </VStack>
                              }
                            />
                            <Accordion
                              title={"Teacher Pie Charts"}
                              content={
                                <BasicTabContainer
                                  style={{ margin: 16 }}
                                  screens={[
                                    {
                                      name: "Ethnicity",
                                      screen: (
                                        <BasicPieChart
                                          colorScale={["#f7bb51", "#e56853"]}
                                          data={teacherEthnicityPieData}
                                        />
                                      ),
                                    },
                                    {
                                      name: "Gender",
                                      screen: (
                                        <BasicPieChart
                                          colorScale={["#f7bb51", "#e56853"]}
                                          data={teacherGenderPieData}
                                        />
                                      ),
                                    },
                                    {
                                      name: "Age",
                                      screen: (
                                        <BasicPieChart
                                          colorScale={["#f7bb51", "#e56853"]}
                                          data={teacherAgePieData}
                                        />
                                      ),
                                    },
                                  ]}
                                />
                              }
                            />
                          </VStack>
                        ),
                      },
                    ]}
                  />
                }
                defaultExpanded
              />
              <Accordion
                defaultExpanded
                title="Boards"
                color="#1677ff"
                content={
                  <Surface style={{ padding: 10 }} elevation={1}>
                    <VStack
                      style={{
                        gap: 5,
                        backgroundColor: "white",
                        marginBottom: 3,
                      }}
                    >
                      <Accordion
                        color="#1677ff"
                        title={"Board Data Table"}
                        content={
                          <BasicTable
                            columns={boardTableColumns}
                            data={boardTableData}
                          />
                        }
                      />
                      <Accordion
                        color="#1677ff"
                        title={"Discussion Data Table"}
                        content={
                          <BasicTable
                            columns={discussionTableColumns}
                            data={discussionTableData}
                          />
                        }
                      />
                      <Accordion
                        color="#1677ff"
                        title={"Board Bar Charts"}
                        content={
                          <VStack>
                            <View style={{ marginVertical: 10 }}>
                              <CustomSegmentedControl
                                color="#1677ff"
                                values={[
                                  "Today",
                                  "7 Days",
                                  "30 Days",
                                  "12 Months",
                                ]}
                                selectedIndex={boardTimeRangeIndex}
                                onValueChange={(index) =>
                                  setBoardTimeRangeIndex(index)
                                }
                              />
                            </View>
                            {boardTimeRangeLoading && (
                              <Spinner
                                style={{ marginBottom: 5 }}
                                size={"sm"}
                                color={"black"}
                              />
                            )}
                            <BasicTabContainer
                              style={{ margin: 16 }}
                              accentColor="#1677ff"
                              screens={[
                                {
                                  name: "Discussion",
                                  screen: (
                                    <BasicBarChart
                                      title={"(comments)"}
                                      minBarWidth={75}
                                      colorScale={["#f7bb51", "#e56853"]}
                                      data={discussionOverTimeData}
                                    />
                                  ),
                                },
                                {
                                  name: "Views",
                                  screen: (
                                    <BasicBarChart
                                      title={"(views)"}
                                      minBarWidth={75}
                                      colorScale={["#f7bb51", "#e56853"]}
                                      data={viewsOverTimeData}
                                    />
                                  ),
                                },
                                {
                                  name: "Average Session Durations ",
                                  screen: (
                                    <BasicBarChart
                                      title={"(minutes)"}
                                      minBarWidth={75}
                                      colorScale={["#f7bb51", "#e56853"]}
                                      data={avgSessionOverTimeData}
                                    />
                                  ),
                                },
                                {
                                  name: "Engagement (m)",
                                  screen: (
                                    <BasicBarChart
                                      minBarWidth={75}
                                      title={"(hours)"}
                                      colorScale={["#f7bb51", "#e56853"]}
                                      data={timeViewedOverTimeData}
                                    />
                                  ),
                                },
                                {
                                  name: "Reboards",
                                  screen: (
                                    <BasicBarChart
                                      colorScale={["#f7bb51", "#e56853"]}
                                      data={reboardsOverTimeData}
                                    />
                                  ),
                                },
                              ]}
                            />
                          </VStack>
                        }
                      />
                      <Accordion
                        color="#1677ff"
                        title={"Board Pie Charts"}
                        content={
                          <BasicTabContainer
                            style={{ margin: 16 }}
                            accentColor="#1677ff"
                            screens={[
                              {
                                name: "Comments / Replies",
                                screen: (
                                  <BasicPieChart
                                    colorScale={["#f7bb51", "#e56853"]}
                                    data={commentsRepliesRatioPieData}
                                  />
                                ),
                              },
                              {
                                name: "Public / Private",
                                screen: (
                                  <BasicPieChart
                                    colorScale={["#f7bb51", "#e56853"]}
                                    data={publicPrivateRatioPieData}
                                  />
                                ),
                              },
                            ]}
                          />
                        }
                      />
                    </VStack>
                  </Surface>
                }
              />
              <Accordion
                defaultExpanded
                title="Modalities"
                color="#722ed1"
                content={
                  <Surface style={{ padding: 10 }} elevation={1}>
                    <VStack
                      style={{
                        gap: 5,
                        backgroundColor: "white",
                        marginBottom: 3,
                      }}
                    >
                      <Accordion
                        color="#722ed1"
                        title={"Modality Data Table"}
                        content={
                          <BasicTable
                            columns={modalityDataTableColumns}
                            data={modalityTableData}
                          />
                        }
                      />
                      <Accordion
                        color="#722ed1"
                        title={"Modality Pie Charts"}
                        content={
                          <BasicTabContainer
                            accentColor="#722ed1"
                            style={{ margin: 16 }}
                            screens={[
                              {
                                name: "Types",
                                screen: (
                                  <BasicPieChart
                                    colorScale={[
                                      "#2a0041",
                                      "#ff0000",
                                      "#1677ff",
                                    ]}
                                    data={modalityTypesPieData}
                                  />
                                ),
                              },
                              {
                                name: "Like / Dislike",
                                screen: (
                                  <BasicPieChart
                                    colorScale={["#f7bb51", "#e56853"]}
                                    data={modalityLRPieData}
                                  />
                                ),
                              },
                              {
                                name: "Favorites by Type",
                                screen: (
                                  <BasicPieChart
                                    colorScale={["#f7bb51", "#e56853"]}
                                    data={modalityFavePieData}
                                  />
                                ),
                              },
                              {
                                name: "Tiktok L/D",
                                screen: (
                                  <BasicPieChart
                                    colorScale={["#f7bb51", "#e56853"]}
                                    data={tiktokLRPieData}
                                  />
                                ),
                              },
                              {
                                name: "Youtube L/D",
                                screen: (
                                  <BasicPieChart
                                    colorScale={["#f7bb51", "#e56853"]}
                                    data={youtubeLRPieData}
                                  />
                                ),
                              },
                              {
                                name: "Web L/D",
                                screen: (
                                  <BasicPieChart
                                    colorScale={["#f7bb51", "#e56853"]}
                                    data={webLRPieData}
                                  />
                                ),
                              },
                            ]}
                          />
                        }
                      />
                    </VStack>
                  </Surface>
                }
              />
            </VStack>
          )}
        </Flex>
      </KeyboardAwareScrollView>
      <Fragment>
        <FabGroup buttons={[]} />
      </Fragment>
    </Fragment>
  );
}
