import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  Image,
  Platform,
  Keyboard,
} from "react-native";
import React, { useState } from "react";
import { HStack } from "@react-native-material/core";

import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useEffect } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { TextInput } from "react-native";
import { Spinner } from "native-base";
import { timeAgo } from "../../utils/numberUtils";
import CustomAlertDialog from "../data/input/Modals/CustomAlertDialog";
import { ActivityIndicator } from "react-native";
import { useNavigation } from "@react-navigation/native";

function Comment({
  comment,
  isReply = false,
  sectionId,
  boardId,
  classId = "vault",
  setComments,
  teacherId,
  maxReplyLength = 140,
  parentAuthorName,
  parentCommentId,
  setParentReplies,
  isPublic = false,
  discussionMuted = false,
}) {
  const [color, setColor] = React.useState("#ccc");
  const [replies, setReplies] = useState([]);
  const [replyText, setReplyText] = useState("");
  const [showReplyDialog, setShowReplyDialog] = useState(false);
  const [replyCharCount, setReplyCharCount] = useState(0);
  const [repliesShown, setRepliesShown] = useState(false);
  const [userAvatar, setUserAvatar] = useState({ uri: null });
  const [authorName, setAuthorName] = useState("loading...");
  const [liked, setLiked] = useState(comment.liked ? comment.liked : false);
  const [likeCount, setLikeCount] = useState(
    comment.likeCount ? comment.likeCount : 0
  );
  const [commentLikeRef, setCommentLikeRef] = useState(null);
  const [replyCount, setReplyCount] = useState(
    comment.replies ? comment.replies : 0
  );
  const [repliesLoading, setRepliesLoading] = useState(false);
  const [addReplyLoading, setAddReplyLoading] = useState(false);

  const db = getFirestore();
  const auth = getAuth();
  const uid = auth.currentUser.uid;
  const userRef = doc(db, "users", uid);
  const boardRef = doc(db, "boards", boardId);
  const sectionRef = doc(db, "sections", sectionId);
  let classRef;
  if (classId) classRef = doc(db, "classes", classId);

  const navigation = isPublic && useNavigation();

  const commentsCollection = !isReply
    ? collection(db, "comments")
    : collection(db, "replies");

  const commentRef = doc(commentsCollection, comment.id);

  const containerStyle = isReply
    ? styles.replyContainer
    : styles.commentContainer;

  useEffect(() => {
    fetchData();

    if (Platform.OS == "web") {
      const closeOnEscape = (e) => {
        if (e.key === "Escape") {
          setShowReplyDialog(false);
        }
      };

      window.addEventListener("keydown", closeOnEscape);

      // Cleanup: remove event listener when component unmounts
      return () => {
        window.removeEventListener("keydown", closeOnEscape);
      };
    }
  }, []);

  const fetchData = async () => {
    //get Author name
    if (userRef.id === comment.userId) {
      setAuthorName("You");
      setUserAvatar({ uri: auth.currentUser.photoURL });
    } else {
      const authorRef = doc(db, "publicUsers", comment.userId);
      const authorDoc = await getDoc(authorRef);
      if (authorDoc.exists()) {
        setAuthorName(
          authorDoc.data().firstName + " " + authorDoc.data().lastName
        );
        //get Author avatar
        if (authorDoc.data().profilePicture) {
          setUserAvatar({ uri: authorDoc.data().profilePicture });
        }
      } else {
        setAuthorName("[error]");
      }
    }

    const commentLikeQuery = query(
      collection(db, "commentLikes"),
      where("userId", "==", userRef),
      where("commentId", "==", commentRef)
    );

    const commentLikeDocs = await getDocs(commentLikeQuery);
    if (!commentLikeDocs.empty) {
      setLiked(true);
      setCommentLikeRef(commentLikeDocs.docs[0]);
    }
  };

  useEffect(() => {
    setReplyCount(comment.replies);
  }, [comment.replies]);

  const handleAddReply = async () => {
    if (discussionMuted) {
      return alert(
        "Your comment cannot be submitted as you have been temporarily restricted from commenting by the instructor"
      );
    }

    if (replyText === "" || replyText.length > maxReplyLength) {
      return alert(
        "Please enter a comment with " + maxReplyLength + "characters or less"
      );
    }
    if (replyText === "") {
      return alert("Please enter a comment");
    }

    setAddReplyLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 100));

    const repliesCollection = collection(db, "replies");
    const replyRef = doc(repliesCollection);

    if (!isReply)
      setReplies([
        ...replies,
        {
          id: replyRef.id,
          userId: uid,
          comment: replyText,
          likeCount: 0,
          parentCommentId: comment.id,
          classId,
          boardId,
          sectionId,
          isReply: true,
          timeStamp: "just now",
          teacherId: teacherId,
        },
      ]);
    else
      setParentReplies((prev) => [
        ...prev,
        {
          id: replyRef.id,
          userId: uid,
          comment: replyText,
          likeCount: 0,
          parentCommentId: comment.id,
          classId,
          boardId,
          sectionId,
          isReply: true,
          timeStamp: "just now",
          teacherId: teacherId,
          parentAuthorName: authorName,
          reference: authorName,
        },
      ]);

    setComments((prevComments) =>
      prevComments.map((c) =>
        c.id === !isReply
          ? parentCommentId
          : comment.id
          ? { ...c, replies: c.replies + 1 }
          : c
      )
    );

    //add reply to database

    const parentCommentRef = doc(
      db,
      "comments",
      isReply ? parentCommentId : comment.id
    );

    let newDoc = {
      userId: userRef,
      comment: replyText,
      likeCount: 0,
      timeStamp: serverTimestamp(),
      parentCommentId: parentCommentRef,
      classId: classRef,
      boardId: boardRef,
      sectionId: sectionRef,
      teacherId: doc(db, "users", teacherId),
    };

    if (isReply) {
      newDoc = {
        ...newDoc,
        reference: authorName,
      };
    }

    await setDoc(replyRef, newDoc);

    //run transaction (add 1 to comment replies)
    try {
      await runTransaction(db, async (transaction) => {
        const commentDoc = await transaction.get(parentCommentRef);
        if (!commentDoc.exists()) {
          throw "Document does not exist!";
        }

        const newReplyCount = commentDoc.data().replies + 1;
        transaction.update(parentCommentRef, { replies: newReplyCount });
      });
    } catch (e) {
      console.error("Transaction failed 1: ", e);
    }

    //run transaction (add 1 to board replies)
    try {
      await runTransaction(db, async (transaction) => {
        const boardRef = doc(db, "boards", boardId);

        const boardDoc = await transaction.get(boardRef);
        if (!boardDoc.exists()) {
          throw "Document does not exist!";
        }

        const newReplyCount = boardDoc.data().replies + 1;
        transaction.update(boardRef, { replies: newReplyCount });
      });
    } catch (e) {
      console.error("Transaction failed 2: ", e);
    }

    setAddReplyLoading(false);
    setShowReplyDialog(false);
    setReplyText("");
    setReplyCharCount(0);
    if (!repliesShown) showRepliesClicked();
  };

  const handleLike = async () => {
    let increase = 1;

    if (liked) {
      increase = -1;
      setLiked(false);
      setLikeCount(likeCount - 1);

      if (commentLikeRef) deleteDoc(commentLikeRef.ref);
      setCommentLikeRef(null);
    } else {
      setLiked(true);
      setLikeCount(likeCount + 1);

      const newCommentLikeRef = doc(collection(db, "commentLikes"));

      let newDoc = {
        userId: userRef,
        commentId: commentRef,
        boardId: boardRef,
        sectionId: sectionRef,
        teacherId: doc(db, "users", teacherId),
      };

      if (classRef)
        newDoc = {
          ...newDoc,
          classId: classRef,
        };

      await setDoc(newCommentLikeRef, newDoc);

      setCommentLikeRef(newCommentLikeRef);
    }

    //run transaction (add 1 to comment likeCount)
    try {
      await runTransaction(db, async (transaction) => {
        const commentDoc = await transaction.get(commentRef);
        if (!commentDoc.exists()) {
          throw "Document does not exist!";
        }

        const newLikeCount = commentDoc.data().likeCount + increase;
        transaction.update(commentRef, { likeCount: newLikeCount });
      });
    } catch (e) {
      console.error("Transaction failed 3: ", e);
    }
  };

  const showRepliesClicked = async () => {
    //show replies
    if (!repliesShown) {
      setRepliesLoading(true);
      try {
        if (!isReply) {
          const repliesCollection = collection(db, "replies");
          const repliesQ = query(
            repliesCollection,
            where(
              "parentCommentId",
              "==",
              commentRef,
              orderBy("timeStamp", "asc")
            )
          );
          const repliesDocs = await getDocs(repliesQ);
          const _replies = repliesDocs.docs.map((doc) => ({
            ...doc.data(),
            classId,
            parentCommentId: comment.id,
            userId: doc.data().userId.id,
            id: doc.id,
            isReply: true,
            boardId,
            sectionId,
            teacherId,
            timeStamp: timeAgo(doc.data().timeStamp),
            isTeacher: doc.data().isTeacher,
          }));
          setReplies(_replies);
          setRepliesShown(true);
        }
      } catch (error) {
        console.error("BRO:", error);
      }
      setRepliesLoading(false);
    } else {
      setRepliesShown(false);
    }
  };

  return (
    <View key={comment.id} style={containerStyle}>
      <View style={styles.commentContent}>
        <HStack items="center" style={{ gap: 10 }}>
          {userAvatar.uri ? (
            <TouchableOpacity
              disabled={!isPublic}
              onPress={() => {
                navigation.navigate("Teacher", {
                  authorAvatar: userAvatar.uri,
                  authorName: authorName,
                  authorId: comment.userId,
                });
              }}
            >
              <Image
                source={userAvatar}
                style={isReply ? styles.replyAvatar : styles.commentAvatar}
              />
            </TouchableOpacity>
          ) : (
            <MaterialCommunityIcons
              name="account-circle"
              size={isReply ? 28 : 32}
              color={color}
            />
          )}
          {comment.pinned && (
            <TouchableOpacity style={{}}>
              <MaterialCommunityIcons
                name="pin-outline"
                size={20}
                color={"#f5222d"}
              />
            </TouchableOpacity>
          )}
          <Text
            style={[styles.commentAuthor, comment.pinned && { marginLeft: -8 }]}
          >
            {authorName}
          </Text>
          {comment.isTeacher && (
            <Text style={{ color: "#aaa", fontSize: 12, marginLeft: -8 }}>
              {"(teacher)"}
            </Text>
          )}
          {comment.timeStamp && (
            <Text
              style={{
                color: "#bbb",
                fontSize: 12,
                fontWeight: "300",
                verticalAlign: "middle",
                marginLeft: 5,
                marginTop: 0,
              }}
            >
              {comment.timeStamp}
            </Text>
          )}
        </HStack>
        <Text
          selectable
          style={[
            styles.commentText,
            { marginLeft: 34, marginBottom: 2, marginTop: -4 },
          ]}
        >
          {comment.reference && comment.reference !== "You" && (
            <Text style={{ color: "#3b82f6" }}>@{comment.reference}</Text>
          )}{" "}
          {comment.comment.trim()}
        </Text>
        <View style={[styles.commentActions, { marginLeft: 38 }]}>
          <TouchableOpacity
            onPress={() => handleLike()}
            style={{ flexDirection: "row", alignItems: "flex-end" }}
          >
            <MaterialCommunityIcons
              name={liked ? "thumb-up" : "thumb-up-outline"}
              size={20}
              color={liked ? "#3b82f6" : "#999"}
            />
            <Text style={styles.likeCount}>{likeCount}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setShowReplyDialog(true);
            }}
          >
            <Text style={styles.replyText}>Reply</Text>
          </TouchableOpacity>
        </View>
      </View>
      {replyCount > 0 && (
        <TouchableOpacity onPress={showRepliesClicked}>
          <HStack
            pl={10}
            items="center"
            style={{
              gap: 3,
              paddingVertical: 10,
              marginBottom: -10,
              marginLeft: 33,
            }}
          >
            <MaterialCommunityIcons
              name={!repliesShown ? "chevron-down" : "chevron-up"}
              color={"#1677ff"}
              size={24}
            />
            <Text style={{ color: "#1677ff", fontWeight: "bold" }}>
              {replyCount} {replyCount === 1 ? "reply" : "replies"}
            </Text>
          </HStack>
        </TouchableOpacity>
      )}
      {repliesLoading && (
        <Spinner
          style={{ alignSelf: "flex-start", marginLeft: 55, marginTop: 10 }}
          size={"sm"}
          color={"black"}
        />
      )}
      {repliesShown &&
        replies.map((reply, i) => (
          <Comment
            key={reply.id}
            comment={reply}
            isReply
            setShowReplyDialog={setShowReplyDialog}
            boardId={boardId}
            parentCommentId={comment.id}
            sectionId={sectionId}
            classId={classId}
            setComments={setComments}
            teacherId={teacherId}
            maxReplyLength={maxReplyLength}
            parentAuthorName={authorName}
            reference={reply.reference}
            setParentReplies={setReplies}
            isPublic={isPublic}
            discussionMuted={discussionMuted}
          />
        ))}
      <CustomAlertDialog
        transparent={false}
        setModalVisible={setShowReplyDialog}
        onConfirm={() => {}}
        modalVisible={showReplyDialog}
        title={`Reply to ${isReply ? parentAuthorName : authorName}`}
        customMessageComponent={
          <>
            {isReply && authorName !== "You" && (
              <Text
                style={{
                  textAlign: "left",
                  width: "100%",
                  color: "#1677ff",
                  fontSize: 12,
                  marginBottom: 10,
                }}
              >
                @{authorName}
              </Text>
            )}
            <TextInput
              autoFocus
              multiline
              numberOfLines={5}
              maxLength={maxReplyLength}
              style={{
                paddingLeft: 10,
                borderRadius: 3,
                padding: 10,
                paddingTop: 10,
                borderWidth: 1,
                borderColor: "#eee",
                marginBottom: 15,
                width: "100%",
              }}
              value={replyText}
              placeholder="Write a reply..."
              placeholderTextColor={"#aaa"}
              onChangeText={(text) => {
                if (text.length <= maxReplyLength) {
                  setReplyText(text);
                  setReplyCharCount(text.length);
                }
              }}
            />
          </>
        }
        buttons={[
          <TouchableOpacity
            key={"cancel"}
            onPress={() => {
              try {
                setShowReplyDialog(false);
              } catch (e) {
                console.error("DUDE:", e);
              }
            }}
          >
            <Text style={{ color: "#1677ff" }}>Cancel</Text>
          </TouchableOpacity>,
          <TouchableOpacity
            key={"submit"}
            disabled={replyText.length == 0}
            style={[
              {
                backgroundColor: "#1677ff",
                borderRadius: 5,
                paddingHorizontal: 14,
                paddingVertical: 10,
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
              },
              replyText.length == 0 && {
                opacity: 0.5,
              },
            ]}
            onPress={() => handleAddReply()}
          >
            <Text style={{ color: "white" }}>Reply</Text>
            {addReplyLoading && (
              <ActivityIndicator color="white" size="small" />
            )}
          </TouchableOpacity>,
        ]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  writeCommentContainer: {
    flexDirection: "column",
    alignItems: "flex-end",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    paddingBottom: 10,
    paddingTop: 10,
    gap: 8,
  },
  writeCommentInput: {
    width: "100%",
    flex: 1,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 8,
    backgroundColor: "#f9f9f9",
  },
  commentButton: {
    height: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 8,
    backgroundColor: "#f9f9f9",
    alignItems: "center",
    justifyContent: "center",
  },
  commentButtonActive: {
    height: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 8,
    backgroundColor: "#3b82f6",
    alignItems: "center",
    justifyContent: "center",
  },
  commentButtonText: {
    color: "#999",
    fontWeight: "500",
  },
  commentButtonTextActive: {
    color: "white",
    fontWeight: "500",
  },
  commentContainer: {
    flexDirection: "column",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  replyContainer: {
    backgroundColor: "white",
    flexDirection: "row",
    paddingLeft: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },
  commentContent: {
    marginLeft: 10,
    flex: 1,
    gap: 4,
  },
  commentAuthor: {
    fontWeight: "bold",
    color: "#333",
  },
  commentText: {
    color: "#333",
  },
  commentActions: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  likeCount: {
    marginLeft: 5,
    color: "#999",
  },
  replyText: {
    marginLeft: 10,
    color: "#999",
  },
  replyDialogTitle: {
    backgroundColor: "white",
    marginTop: 10,
    fontWeight: "bold",
    marginBottom: 10,
  },
  replyTextInput: {
    height: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 8,
    backgroundColor: "#f9f9f9",
    marginBottom: 10,
  },
  replyDialogActions: {
    backgroundColor: "white",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 4,
  },
  dialogCancelButton: {
    height: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 8,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  commentButtonText: {
    color: "#999",
  },
  charCount: {
    alignSelf: "flex-end",
    fontSize: 12,
    paddingBottom: 6,
    color: "#888",
  },
  commentAvatar: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginLeft: -5,
  },
  replyAvatar: {
    width: 28,
    height: 28,
    borderRadius: 15,
  },
});

export default React.memo(Comment);
