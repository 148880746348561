import React from "react";

import { AntDesign } from "@expo/vector-icons";
import { VStack } from "@react-native-material/core";
import { TouchableOpacity } from "react-native";

export default function SortIcon(props) {
  const { isSorted, isSortedDesc } = props;

  return (
    <TouchableOpacity
      onPress={() => props.toggleSort()}
      style={{  }}
    >
      <VStack w={30} m={-5} center h={30} spacing={-4}>
        <AntDesign
          color={isSorted && isSortedDesc ? "#eb9940" : "black"}
          name="caretup"
          size={9}
        />
        <AntDesign
          color={isSorted && !isSortedDesc ? "#eb9940" : "black"}
          name="caretdown"
          size={9}
        />
      </VStack>
    </TouchableOpacity>
  );
}
