import React, { useEffect, useRef, useState } from "react";
import { TextInput } from "react-native";
import { set } from "react-native-reanimated";

/**
 * CustomTextInput Component
 *
 * @param {Object} props - The props object containing various configuration options.
 * @param {Function} props.setValue - Callback function to set the value of the input.
 * @param {string} [props.initialValue] - The initial value of the input field.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {string} [props.placeHolderTextColor="#aaa"] - The color of the placeholder text.
 * @param {Object} [props.style] - Custom styling for the TextInput component.
 */
const CustomTextInput = ({
  setValue,
  initialValue,
  placeholder,
  placeHolderTextColor = "#aaa",
  style,
}) => {
  // Reference to the TextInput component to enable programmatic focus
  const inputRef = useRef(null);

  const [val, setVal] = useState("");

  // UseEffect hook to update the value of the input whenever the initialValue prop changes
  useEffect(() => {
    if (inputRef.current)
      inputRef.current.setNativeProps({ text: initialValue });
  }, [initialValue]);

  /**
   * Handle the text input change.
   *
   * @param {string} text - The new text entered in the input field.
   */
  const handleInputChange = (text) => {
    // Call the provided setValue function with the new text value
    setVal(text);
  };

  return (
    <TextInput
      blurOnSubmit={false}
      onBlur={() => setValue(val)}
      ref={inputRef}
      placeholder={placeholder}
      style={style}
      placeholderTextColor={placeHolderTextColor}
      onChangeText={handleInputChange}
    />
  );
};

export default CustomTextInput;
