import React, { useState, useEffect } from "react";

import { TextInput, View } from "react-native";

import {
  Box,
  Text,
  Button,
  Flex,
  HStack,
  VStack,
  Switch,
  Surface,
} from "@react-native-material/core";

import GoogleLogo from "../graphics/GoogleLogo";
import Logo from "../graphics/Logo";
import { Linking } from "react-native";
import { StyleSheet } from "react-native";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithCredential,
  GoogleAuthProvider,
  updateProfile,
  updateCurrentUser,
} from "firebase/auth";

import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";
import {
  getFirestore,
  doc,
  updateDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { TouchableOpacity } from "react-native";
import { ActivityIndicator } from "react-native";
import CustomAlertDialog from "../data/input/Modals/CustomAlertDialog";

WebBrowser.maybeCompleteAuthSession();

export default function SignUpForm({ small, ...props }) {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [agreeTerms, setAgreeTerms] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [loading, setLoading] = useState(false);

  const auth = getAuth();

  const [token, setToken] = useState("");

  const [request, response, promptAsync] = Google.useAuthRequest({
    androidClientId:
      "475932163263-20hk4kh9mpee8koi8qnnmgr0tpb8scpn.apps.googleusercontent.com",
    iosClientId:
      "475932163263-0co5rjdlmd7fpgaoli26i3c9nkn7oao8.apps.googleusercontent.com",
    expoClientId:
      "475932163263-191sahf99iftkur3ne612mbf2v6nav2u.apps.googleusercontent.com",
    webClientId:
      "475932163263-svabmfdc4gvejk795ovsjp2gp2bo2r4q.apps.googleusercontent.com",
  });

  useEffect(() => {
    if (response?.type === "success") {
      setToken(response.authentication.accessToken);
      try {
        const credential = GoogleAuthProvider.credential(
          response.authentication.idToken,
          response.authentication.accessToken
        );
        signInWithCredential(auth, credential)
          .then((userCredential) => {
            if (userCredential._tokenResponse.isNewUser) {
              // The user is new, update their information in your database
              const { email, uid } = userCredential.user;
              const firstName = userCredential._tokenResponse.firstName;
              const lastName = userCredential._tokenResponse.lastName;

              const userRef = doc(db, "users", uid);
              setDoc(userRef, {
                firstName,
                lastName,
                creationTime: serverTimestamp(),
                lastSignInTime: serverTimestamp(),
                email,
                profilePicture: userCredential.user.photoURL,
                role: null,
              });
            } else {
              // The user is not new, they're signing in again
            }
          })
          .catch((error) => {
            // Handle Errors here.
            const errorMessage = error.message;
            console.error(errorMessage);
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, [response, token]);

  const signUpWithGoogleClicked = () => promptAsync({ useProxy: false });

  const signUpClicked = () => {
    setLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const db = getFirestore();

        const { uid } = userCredential.user;

        const userRef = doc(db, "users", uid);
        const publicUserRef = doc(db, "publicUsers", uid);

        setDoc(userRef, {
          firstName,
          lastName,
          creationTime: userCredential.user.metadata.creationTime,
          lastSignInTime: userCredential.user.metadata.lastSignInTime,
          email,
          role: null,
        });

        setDoc(publicUserRef, {
          firstName,
          lastName,
          creationTime: userCredential.user.metadata.creationTime,
          role: null,
        });
      })
      .catch((error) => {
        setLoading(false);
        const errorCode = error.code;

        switch (errorCode) {
          case `auth/email-already-in-use`:
            setDialogMessage(
              "It appears the email address you've entered is already linked to an existing account. If this is your email, please log in. If you've forgotten your password, you can reset it via the 'Forgot Password' option. If you're new here, please use a different email address to create a new account."
            );
            break;
          default:
            setDialogMessage("An error occured while creating the account");
        }
        setShowDialog(true);
      });
  };

  return (
    <>
      <View
        style={{
          backgroundColor: "#ffecd9",
          maxWidth: 500,
          flex: 1,
          borderRadius: 5,
          borderBottomRightRadius: small ? 5 : 0,
          borderTopRightRadius: small ? 5 : 0,
        }}
      >
        <View style={{ margin: 32, gap: 16 }}>
          <Flex mt={16}>
            <Logo />
          </Flex>
          <Text
            color="#333333"
            style={{
              fontWeight: "bold",
              fontSize: 44,
              paddingTop: 16,
              paddingBottom: 8,
            }}
          >
            Register
          </Text>
          <Button
            onPress={signUpWithGoogleClicked}
            disableElevation
            uppercase={false}
            contentContainerStyle={{ height: 50 }}
            leadingContainerStyle={{ paddingRight: 12 }}
            leading={<GoogleLogo />}
            titleStyle={{ fontWeight: "500" }}
            color="white"
            title="Sign up with Google"
          ></Button>
          <Flex
            direction="row"
            center
            style={{ gap: 8, paddingTop: 8, paddingBottom: 8 }}
          >
            <Box
              style={{
                flex: 1,
                borderBottomWidth: 1,
                borderBottomColor: "#ac9e91",
              }}
            />
            <Text style={{}} color="#ac9e91">
              OR
            </Text>
            <Box
              style={{
                flex: 1,
                borderBottomWidth: 1,
                borderBottomColor: "#ac9e91",
              }}
            />
          </Flex>
          <Text style={[styles.label]}>First Name</Text>
          <Surface
            style={{ borderRadius: 5, backgroundColor: "white" }}
            elevation={1}
          >
            <TextInput
              style={[{ height: 40, borderRadius: 5, paddingLeft: 10 }]}
              autoComplete="name"
              value={firstName}
              onChangeText={(value) => setFirstName(value)}
              placeholder=""
              secureTextEntry={false}
              placeholderTextColor={"#aaa"}
            ></TextInput>
          </Surface>
          <Text style={[styles.label]}>Last Name</Text>
          <Surface
            style={{ borderRadius: 5, backgroundColor: "white" }}
            elevation={1}
          >
            <TextInput
              style={[{ height: 40, borderRadius: 5, paddingLeft: 10 }]}
              autoComplete="name"
              value={lastName}
              onChangeText={(value) => setLastName(value)}
              placeholderTextColor={"#aaa"}
            ></TextInput>
          </Surface>
          <Text style={[styles.label]}>Email</Text>
          <Surface
            style={{ borderRadius: 5, backgroundColor: "white" }}
            elevation={1}
          >
            <TextInput
              style={[{ height: 40, borderRadius: 5, paddingLeft: 10 }]}
              autoComplete="email"
              value={email}
              onChangeText={(value) => setEmail(value)}
              placeholder="mail@website.com"
              placeholderTextColor={"#aaa"}
            ></TextInput>
          </Surface>
          <Text style={[styles.label]}>Password</Text>
          <Surface
            style={{ borderRadius: 5, backgroundColor: "white" }}
            elevation={1}
          >
            <TextInput
              style={[{ height: 40, borderRadius: 5, paddingLeft: 10 }]}
              secureTextEntry
              autoComplete="password"
              value={password}
              onChangeText={(value) => setPassword(value)}
              placeholder="Minimum 8 characters"
              placeholderTextColor={"#aaa"}
            ></TextInput>
          </Surface>
          <HStack spacing={8}>
            <Switch
              value={agreeTerms}
              onValueChange={(value) => setAgreeTerms(value)}
              //thumbColor={{}}
              //trackColor={{ false: "white", true: "#eb9940" }}
            ></Switch>
            <VStack>
              <Text>I agree to the</Text>
              <Button
                onPress={() => {
                  Linking.openURL(
                    "https://sites.google.com/view/get-lit-llc/home"
                  );
                }}
                color="#eb9940"
                style={{ marginLeft: -16 }}
                titleStyle={{ fontWeight: "bold" }}
                variant="text"
                title="Terms & Conditions"
                uppercase={false}
              ></Button>
            </VStack>
          </HStack>
          <TouchableOpacity
            disabled={
              !agreeTerms ||
              firstName.length <= 1 ||
              lastName.length <= 1 ||
              email.length <= 4 ||
              password.length < 8
            }
            onPress={signUpClicked}
            style={[
              {
                backgroundColor: "#eb9940",
                height: 50,
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 16,
                flexDirection: "row",
                gap: 4,
              },
              (!agreeTerms ||
                firstName.length <= 1 ||
                lastName.length <= 1 ||
                email.length <= 4 ||
                password.length < 8 ||
                loading) && {
                opacity: 0.5,
              },
            ]}
          >
            <Text
              style={{
                textAlign: "center",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Signup
            </Text>
            {loading && <ActivityIndicator size={"small"} color={"white"} />}
          </TouchableOpacity>

          <HStack style={{}}>
            <Text style={{ paddingTop: 8 }} color="#ac9e91">
              Already have an Account?
            </Text>
            <Button
              onPress={() => {
                props.navigation.navigate("Login");
              }}
              color="#eb9940"
              style={{}}
              titleStyle={{ fontWeight: "bold" }}
              variant="text"
              title="Login"
              uppercase={false}
            ></Button>
          </HStack>
        </View>
      </View>
      <CustomAlertDialog
        setModalVisible={showDialog}
        onConfirm={() => {}}
        modalVisible={showDialog}
        title={"Whoops!"}
        message={dialogMessage}
        buttons={[
          <TouchableOpacity
            key={"cancel"}
            onPress={() => {
              props.navigation.navigate("Login");
            }}
          >
            <Text style={{ color: "#1677ff" }}>Cancel</Text>
          </TouchableOpacity>,
          <TouchableOpacity
            key={"submit"}
            style={[
              {
                backgroundColor: "#1677ff",
                borderRadius: 5,
                paddingHorizontal: 14,
                paddingVertical: 10,
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
              },
            ]}
            onPress={() => setShowDialog(false)}
          >
            <Text style={{ color: "white" }}>Retry</Text>
          </TouchableOpacity>,
        ]}
      />
    </>
  );
}

const styles = StyleSheet.create({
  input: {
    backgroundColor: "white",
    height: 40,
    borderColor: "#d9d9d9",
    borderBottomWidth: 2,
    borderRadius: 4,
    marginBottom: 15,
    paddingLeft: 5,
    marginTop: -5,
  },
  incorrect: {
    borderColor: "#ff0000",
  },
  label: {
    fontWeight: "500",
    color: "black",
  },
  incorrectLabel: {
    fontWeight: "500",
    color: "#ff0000",
  },
});
