import React from "react";
import { Image } from "react-native";

import imgSrc from "../../assets/general/LogoTemp2.png";

import imgSrcWhite from "../../assets/general/LogoTemp2White.png";

export default function Logo(props) {
  const { white, header } = props;
  return (
    <Image
      style={{
        width: header ? 114 * 0.4 : 114,
        height: header ? 60 * 0.4 : 60,
      }}
      source={white ? imgSrcWhite : imgSrc}
    />
  );
}
