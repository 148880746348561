import React from "react";
import { HStack, VStack, Box, Surface } from "@react-native-material/core";

import {
  Text,
  TouchableOpacity,
  View,
  Share,
  DeviceEventEmitter,
  Platform,
} from "react-native";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Image } from "react-native";
import { LikeDislike } from "../../discussion/IconCount";

import {
  collection,
  getDocs,
  getFirestore,
  query,
  runTransaction,
  setDoc,
  where,
  doc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

export default function CarouselMediaCard({
  borderRadius = 5,
  onCardPress,
  link,
  imgLink,
  index,
  initialDislikeCount = 0,
  initialLikeCount = 0,
  status,
  id,
  boardId,
  initalfavorited,
  classId,
  teacherId,
  title,
  type,
  total = 0,
}) {
  const [loading, setLoading] = React.useState(true);
  const [favorited, setFavorited] = React.useState(initalfavorited);

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: "I found this using GetLit! " + link,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      Alert.alert(error.message);
    }
  };

  const onFavoritePressed = async () => {
    setFavorited(!favorited);

    const db = getFirestore();
    const auth = getAuth();
    const uid = auth.currentUser.uid;
    const userRef = doc(db, "users", uid);
    const boardRef = doc(db, "boards", boardId);
    const modalityRef = doc(db, "modalities", id);
    let classRef;
    if (classId) classRef = doc(db, "classes", classId);

    const modalityFavoriteQ = query(
      collection(db, "modalityFavorites"),
      where("boardId", "==", boardRef),
      where("userId", "==", userRef),
      where("modalityId", "==", modalityRef)
    );

    //todo update favorites count in board

    //update modality favorite in db
    const modalityFavoriteDoc = await getDocs(modalityFavoriteQ);

    //will now be favorites
    if (!favorited) {
      if (modalityFavoriteDoc.empty) {
        let newDoc = {
          boardId: boardRef,
          modalityId: modalityRef,
          userId: userRef,
          timeStamp: serverTimestamp(),
          teacherId: doc(db, "users", teacherId),
          modalityType: type,
        };

        if (classId) {
          newDoc = { ...newDoc, classId: classRef };
        }

        await setDoc(doc(collection(db, "modalityFavorites")), newDoc);

        //run transaction (add favorite to modality)
        try {
          await runTransaction(db, async (transaction) => {
            const modalityDoc = await transaction.get(modalityRef);
            if (!modalityDoc.exists()) {
              throw "Document does not exist!";
            }

            const newFavoriteCount = modalityDoc.data().favCount + 1;
            transaction.update(modalityRef, {
              favCount: newFavoriteCount,
            });
          });
        } catch (e) {
          console.error("Transaction failed: ", e);
        }
      } else {
        //allready exists, do nothing
      }
    } else {
      if (!modalityFavoriteDoc.empty) {
        //run transaction (add favorite to modality)
        try {
          await runTransaction(db, async (transaction) => {
            const modalityDoc = await transaction.get(modalityRef);
            if (!modalityDoc.exists()) {
              throw "Document does not exist!";
            }

            const newFavoriteCount = modalityDoc.data().favCount - 1;
            transaction.update(modalityRef, {
              favCount: newFavoriteCount,
            });
          });
        } catch (e) {
          console.error("Transaction failed: ", e);
        }

        modalityFavoriteDoc.forEach(async (doc) => {
          deleteDoc(doc.ref);
        });
      }
    }
  };

  return (
    <Surface
      key={index}
      elevation={2}
      style={{
        borderRadius: borderRadius,
        backgroundColor: "white",
        color: "black",
        height: 540,
      }}
    >
      <HStack>
        <View
          style={{
            paddingLeft: 12,
            paddingRight: 6,
          }}
        >
          <LikeDislike
            initialDislikeCount={initialDislikeCount}
            initialLikeCount={initialLikeCount}
            spacing={8}
            size={40}
            id={id}
            initalStatus={status}
            boardId={boardId}
            classId={classId}
            teacherId={teacherId}
            modalityType={type}
          />
        </View>
        <VStack id="content" p={12} pl={0} spacing={0} fill={1}>
          <TouchableOpacity
            style={{ zIndex: 999 }}
            onPress={() => {
              if (Platform.OS !== "web") onCardPress(link);
            }}
          >
            <Box
              h={400}
              mb={8}
              bg={imgLink ? "white" : "#aaaaaa"}
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              {imgLink ? (
                <Image
                  source={{ uri: imgLink }}
                  style={{
                    width: "100%",
                    height: 400,
                    backgroundColor: "#eee",
                    borderRadius: 5,
                    opacity: loading ? 0 : 1,
                  }}
                  resizeMode="contain"
                  resizeMethod="resize"
                  onLoadEnd={() => setLoading(false)}
                />
              ) : (
                <MaterialCommunityIcons
                  color={"#888888"}
                  name="image-frame"
                  size={72}
                />
              )}
            </Box>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => onCardPress(link)}
            style={{
              paddingBottom: 5,
              paddingTop: 1,
              paddingHorizontal: 5,
              gap: 4,
              flexDirection: "column",
            }}
          >
            <Text style={{ fontStyle: "italic", fontWeight: "500" }}>
              {title}
            </Text>
            <Text
              selectable
              numberOfLines={2}
              style={{ fontSize: 12, color: "blue", height: 30 }}
            >
              {link}
            </Text>
          </TouchableOpacity>
          <HStack spacing={8} items="center">
            <TouchableOpacity
              onPress={onFavoritePressed}
              style={{
                paddingBottom: 5,
                paddingTop: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <MaterialCommunityIcons
                name="fire"
                size={24}
                color={favorited ? "#ba3b46" : "#888888"}
              />
              <Text
                style={{
                  fontSize: 12,
                  color: favorited ? "#ba3b46" : "#333",
                  fontWeight: favorited ? "900" : "bold",
                }}
              >
                favorite{favorited ? "d" : ""}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={onShare}
              style={{
                paddingBottom: 5,
                paddingTop: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <MaterialCommunityIcons
                name="share-all"
                size={24}
                color="#888888"
              />
              <Text
                style={{
                  fontSize: 12,
                  color: "#333",
                  fontWeight: "bold",
                  paddingLeft: 4,
                }}
              >
                share
              </Text>
            </TouchableOpacity>
          </HStack>
        </VStack>
      </HStack>
      <Text
        style={{
          position: "absolute",
          fontStyle: "italic",
          right: 10,
          bottom: 10,
          fontSize: 12,
          fontWeight: "300",
          color: "#ccc",
        }}
      >
        {index + 1}/{total}
      </Text>
    </Surface>
  );
}
