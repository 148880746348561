import React from "react";
import { Image } from "react-native";

export default function GoogleLogo(props) {
  return (
    <Image
      style={{ width: 20, height: 20 }}
      source={{
        uri: "https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png",
      }}
    />
  );
}
