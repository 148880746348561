import * as React from "react";
import {
  View,
  RefreshControl,
  Platform,
  Linking,
  Text,
  TouchableOpacity,
} from "react-native";

import { Flex, Surface } from "@react-native-material/core";
import BasicTable from "../../components/data/tables/BasicTable";
import BasicPieChart from "../../components/data/graphs/BasicPieChart";
import BasicBarChart from "../../components/data/graphs/BasicBarChart";
import BasicTabContainer from "../../components/data/wrappers/BasicTabContainer";
import TopThreeCard from "../../components/cards/Carousel/TopThreeCard";
import BasicCarousel from "../../components/cards/Carousel/BasicCarousel";
import Accordion from "../../components/data/wrappers/BasicAccordion";
import { useState } from "react";
import { Spinner, VStack } from "native-base";
import FabGroup from "../../components/general/fabs/FabGroup";
import { Ionicons, MaterialCommunityIcons, Octicons } from "@expo/vector-icons";
import { Fragment } from "react";
import AutoPopIn from "../../components/animations/visibility/AutoPopIn";
import CustomSegmentedControl from "../../components/data/input/CustomSegmentedControl";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import SimpleToast from "../../components/animations/toasts/SimpleToast";
import { useEffect } from "react";
import { getAuth } from "firebase/auth";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Image } from "react-native";
import StudentLookupScreen from "./StudentLookup";
import Animated from "react-native-reanimated";

const now = new Date();

// get start of week (Sunday)
const day = now.getDay();
const startOfDay = new Date(now);
startOfDay.setHours(0, 0, 0, 0);

const startOfWeek = new Date(now);
startOfWeek.setDate(now.getDate() - day);
startOfWeek.setHours(0, 0, 0, 0);

// get end of week (Saturday)
const endOfWeek = new Date(startOfWeek);
endOfWeek.setDate(startOfWeek.getDate() + 6);
endOfWeek.setHours(23, 59, 59, 999);

// get start of month
const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

// get end of month
const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
endOfMonth.setHours(23, 59, 59, 999);

// get end of day
const endOfDay = new Date(now);
endOfDay.setHours(23, 59, 59, 999);

const addStudentInfo = async (array) => {
  const db = getFirestore();

  const newArray = await Promise.all(
    array.map(async (student) => {
      const studentSnapshot = await getDoc(
        doc(collection(db, "publicUsers"), student.userId)
      );

      if (studentSnapshot.exists()) {
        const label =
          studentSnapshot.data().firstName +
          " " +
          studentSnapshot.data().lastName.charAt(0) +
          ".";

        if (student.label) {
          return {
            ...student,
            label: student.label + label,
            avatarUrl: studentSnapshot.data().profilePicture,
          };
        }

        return {
          ...student,
          label,
          avatarUrl: studentSnapshot.data().profilePicture,
        };
      } else
        return {
          ...student,
          label: "Unknown",
          avatarUrl: "",
        };
    })
  );

  return newArray;
};

const getTopLikedComments = async (userRef) => {
  const db = getFirestore();

  const commentsQuery = query(
    collection(db, "comments"),
    where("teacherId", "==", userRef),
    orderBy("likeCount", "desc"),
    limit(3)
  );

  const commentsSnapshot = await getDocs(commentsQuery);
  let comments = [];
  commentsSnapshot.forEach((doc) => {
    const data = doc.data();
    if (data.likeCount !== 0)
      comments.push({
        value: data.likeCount,
        userId: data.userId.id,
        label: data.comment + "\n -",
      });
  });

  comments = await addStudentInfo(comments);

  return {
    title: "Top Liked Comments",
    unit: "Likes",
    values: comments,
  };
};

// Get top user by view times
const getTopUserByViewTimes = async (userRef) => {
  const db = getFirestore();

  const viewTimesQuery = query(
    collection(db, "boardViewTimes"),
    where("teacherId", "==", userRef)
  );

  // Fetch all documents from the 'boardViewTimes' collection
  const querySnapshot = await getDocs(viewTimesQuery);

  // Initialize an empty object to store the total view times for each user
  const userViewTimes = {};

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const userId = data.userId.id;

    // If this is the first time we've seen this user, initialize their total view time to 0
    if (!userViewTimes[userId]) {
      userViewTimes[userId] = 0;
    }

    // Add this document's view time to the user's total view time
    userViewTimes[userId] += data.viewTime;
  });

  // Transform the object into an array of {userId, viewTime} objects
  const usersWithViewTimes = Object.entries(userViewTimes).map(
    ([userId, viewTime]) => ({ userId, value: viewTime / 60000 })
  );

  // Sort the array in descending order of view time
  usersWithViewTimes.sort((a, b) => b.viewTime - a.viewTime);

  // Get the top 3 users
  let top3Users = usersWithViewTimes.slice(0, 3);

  top3Users = await addStudentInfo(top3Users);

  return {
    title: "Most Engaged Users",
    unit: "Minutes",
    values: top3Users,
  };
};

const getTopFavoritedModalities = async (userRef) => {
  const db = getFirestore();

  const favoritesQuery = query(
    collection(db, "modalityFavorites"),
    where("teacherId", "==", userRef)
  );

  // Fetch all documents from the 'modalityFavorites' collection
  const querySnapshot = await getDocs(favoritesQuery);

  // Initialize an empty object to store the total favorites for each modality
  const modalityFavorites = {};

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const modalityId = data.modalityId.id;

    // If this is the first time we've seen this modality, initialize their total favorites to 0
    if (!modalityFavorites[modalityId]) {
      modalityFavorites[modalityId] = 0;
    }

    // Increase the favorites count for this modality
    modalityFavorites[modalityId] += 1;
  });

  // Transform the object into an array of {modalityId, favorites} objects
  const modalityFavoritesArray = Object.entries(modalityFavorites).map(
    ([modalityId, value]) => ({ modalityId, value })
  );

  // Sort the array in descending order of favorites
  modalityFavoritesArray.sort((a, b) => b.value - a.value);

  // Get the top 3 modalities
  let top3Modalities = modalityFavoritesArray.slice(0, 3);

  // Fetch the 'link' field from 'modalities' collection for each of the top 3 modalities
  for (let modality of top3Modalities) {
    const modalityDoc = await getDoc(
      doc(db, "modalities", modality.modalityId)
    );
    if (modalityDoc.exists()) {
      const modalityImgLink = modalityDoc?.data().imgLink;
      const modalityLink = modalityDoc?.data().link;

      // Assign the 'link' field to the 'label' field of the modality
      modality.customLabel = (
        <TouchableOpacity
          style={{
            height: 110,
            width: "100%",
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            marginBottom: 10,
          }}
          onPress={() => Linking.openURL(modalityLink)}
        >
          <Surface
            elevation={3}
            style={{
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
            }}
          >
            <Image
              style={{
                height: 110,
                width: "100%",
                minWidth: 50,
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
              }}
              source={{ uri: modalityImgLink }}
            />
          </Surface>
        </TouchableOpacity>
      );
      modality.hideAvatar = true;
    }
  }

  return {
    title: "Most Favorited Modalities",
    unit: "Favorites",
    values: top3Modalities,
  };
};

const getTopClassesByViewTimes = async (teacherRef) => {
  const db = getFirestore();

  // First get all classes that match up to a provided teacher ref
  const classesQuery = query(
    collection(db, "classes"),
    where("teacherId", "==", teacherRef)
  );

  const classSnapshot = await getDocs(classesQuery);

  let classViewTimes = {};

  for (let doc of classSnapshot.docs) {
    if (doc.exists()) {
      const classRef = doc.ref;
      const classData = doc.data();
      const className = classData.className;
      const classColor = classData.color;

      // For each class, retrieve the views from 'boardViewTimes' collection
      const viewTimesQuery = query(
        collection(db, "boardViewTimes"),
        where("classId", "==", classRef)
      );

      const querySnapshot = await getDocs(viewTimesQuery);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const classId = data.classId.id;

        if (!classViewTimes[classId]) {
          classViewTimes[classId] = { className, classColor, viewTime: 0 };
        }

        classViewTimes[classId].viewTime += data.viewTime;
      });
    }
  }

  // Transform the object into an array of {classId, className, viewTime} objects
  const classesWithViewTimes = Object.entries(classViewTimes).map(
    ([classId, { className, classColor, viewTime }]) => ({
      classId,
      label: className,
      color: classColor,
      value: viewTime / 60000,
      fontSize: 16,
    })
  );

  // Sort the array in descending order of view time
  classesWithViewTimes.sort((a, b) => b.value - a.value);

  // Get the top 3 classes
  const top3Classes = classesWithViewTimes.slice(0, 3);

  return {
    title: "Most Engaged Classes",
    unit: "Minutes",
    values: top3Classes,
  };
};

const getTopFavoritedBoards = async (userRef) => {
  const db = getFirestore();

  // Fetch all documents from the 'boards' collection where 'teacherId' matches 'userRef'
  const boardsQuery = query(
    collection(db, "boards"),
    where("teacherId", "==", userRef)
  );

  const boardsSnapshot = await getDocs(boardsQuery);

  let boardFavorites = {};

  boardsSnapshot.forEach((doc) => {
    const boardData = doc.data();
    const boardId = doc.id;
    const boardName = boardData.boardName;
    const boardColor = boardData.color;
    const favoriteCount = boardData.favorites;

    boardFavorites[boardId] = { boardName, favoriteCount, boardColor };
  });

  // Transform the object into an array of {boardId, boardName, favoriteCount} objects
  const boardsWithFavoriteCounts = Object.entries(boardFavorites).map(
    ([boardId, { boardName, favoriteCount, boardColor }]) => ({
      boardId,
      label: boardName,
      value: favoriteCount,
      color: boardColor,
    })
  );

  // Sort the array in descending order of favoriteCount
  boardsWithFavoriteCounts.sort((a, b) => b.value - a.value);

  // Get the top 3 boards
  const top3Boards = boardsWithFavoriteCounts
    .slice(0, 3)
    .filter((board) => board.value !== 0);

  return {
    title: "Top Favorited Boards",
    unit: "Favorites",
    values: top3Boards,
  };
};

const getTopViewedBoards = async (userRef) => {
  const db = getFirestore();

  // Fetch all documents from the 'boards' collection where 'teacherId' matches 'userRef'
  const boardsQuery = query(
    collection(db, "boards"),
    where("teacherId", "==", userRef)
  );

  const boardsSnapshot = await getDocs(boardsQuery);

  let boardFavorites = {};

  boardsSnapshot.forEach((doc) => {
    const boardData = doc.data();
    const boardId = doc.id;
    const boardName = boardData.boardName;
    const boardColor = boardData.color;
    const viewCount = boardData.views;

    boardFavorites[boardId] = { boardName, viewCount, boardColor };
  });

  // Transform the object into an array of {boardId, boardName, favoriteCount} objects
  const boardsWithFavoriteCounts = Object.entries(boardFavorites).map(
    ([boardId, { boardName, viewCount, boardColor }]) => ({
      boardId,
      label: boardName,
      value: viewCount,
      color: boardColor,
    })
  );

  // Sort the array in descending order of favoriteCount
  boardsWithFavoriteCounts.sort((a, b) => b.value - a.value);

  // Get the top 3 boards
  const top3Boards = boardsWithFavoriteCounts
    .slice(0, 3)
    .filter((board) => board.value !== 0);

  return {
    title: "Top Viewed Boards",
    unit: "Views",
    values: top3Boards,
  };
};

const getTopCommentedBoards = async (userRef) => {
  const db = getFirestore();

  // Fetch all documents from the 'boards' collection where 'teacherId' matches 'userRef'
  const boardsQuery = query(
    collection(db, "boards"),
    where("teacherId", "==", userRef)
  );

  const boardsSnapshot = await getDocs(boardsQuery);

  let boardFavorites = {};

  boardsSnapshot.forEach((doc) => {
    const boardData = doc.data();
    const boardId = doc.id;
    const boardName = boardData.boardName;
    const boardColor = boardData.color;
    const commentCount = boardData.replies;

    boardFavorites[boardId] = { boardName, commentCount, boardColor };
  });

  // Transform the object into an array of {boardId, boardName, favoriteCount} objects
  const boardsWithFavoriteCounts = Object.entries(boardFavorites).map(
    ([boardId, { boardName, commentCount, boardColor }]) => ({
      boardId,
      label: boardName,
      value: commentCount,
      color: boardColor,
    })
  );

  // Sort the array in descending order of favoriteCount
  boardsWithFavoriteCounts.sort((a, b) => b.value - a.value);

  // Get the top 3 boards
  const top3Boards = boardsWithFavoriteCounts
    .slice(0, 3)
    .filter((board) => board.value !== 0);

  return {
    title: "Most Replied Boards",
    unit: "Replies",
    values: top3Boards,
  };
};

async function getTableData(
  db,
  userRef,
  setBoardTableData,
  setBoardModalityData
) {
  //--board table data
  const boardsQuery = query(
    collection(db, "boards"),
    where("teacherId", "==", userRef)
  );

  const boardsSnapshot = await getDocs(boardsQuery);

  let boardData = await Promise.all(
    boardsSnapshot.docs.map(async (doc) => {
      const boardViewsQuery = query(
        collection(db, "boardViews"),
        where("boardId", "==", doc.ref)
      );
      const boardFavoritesQuery = query(
        collection(db, "boardFavorites"),
        where("boardId", "==", doc.ref)
      );
      const boardCommentsQuery = query(
        collection(db, "comments"),
        where("boardId", "==", doc.ref)
      );
      const boardRepliesQuery = query(
        collection(db, "replies"),
        where("boardId", "==", doc.ref)
      );

      const [
        boardViewsSnapshot,
        boardFavoritesSnapshot,
        boardCommentsSnapshot,
        boardRepliesSnapshot,
      ] = await Promise.all([
        getDocs(boardViewsQuery),
        getDocs(boardFavoritesQuery),
        getDocs(boardCommentsQuery),
        getDocs(boardRepliesQuery),
      ]);

      const viewCount = boardViewsSnapshot.size;
      const favoriteCount = boardFavoritesSnapshot.size;
      const commentCount = boardCommentsSnapshot.size;
      const replyCount = boardRepliesSnapshot.size;

      const data = doc.data();

      return {
        id: doc.id,
        views: viewCount,
        favorites: favoriteCount,
        comments: commentCount,
        replies: replyCount,
        board: data.boardName,
        key: doc.id,
        created: data.creationTimeStamp.toDate().toLocaleString(),
      };
    })
  );

  setBoardTableData(boardData);

  //--modality table data

  let modalityData = await Promise.all(
    boardsSnapshot.docs.map(async (doc) => {
      const modalitiesQuery = query(
        collection(db, "modalities"),
        where("boardId", "==", doc.ref)
      );

      const modalitiesSnapshot = await getDocs(modalitiesQuery);

      return Promise.all(
        modalitiesSnapshot.docs.map(async (modalityDoc) => {
          const modalityFavoritesQuery = query(
            collection(db, "modalityFavorites"),
            where("modalityId", "==", modalityDoc.ref)
          );

          const modalityLikesQuery = query(
            collection(db, "modalityVotes"),
            where("modalityId", "==", modalityDoc.ref),
            where("type", "==", "like")
          );

          const modalityDislikesQuery = query(
            collection(db, "modalityVotes"),
            where("modalityId", "==", modalityDoc.ref),
            where("type", "==", "dislike")
          );

          const [
            modalityFavoritesSnapshot,
            modalityLikesSnapshot,
            modalityDislikesSnapshot,
          ] = await Promise.all([
            getDocs(modalityFavoritesQuery),
            getDocs(modalityLikesQuery),
            getDocs(modalityDislikesQuery),
          ]);

          const favoriteCount = modalityFavoritesSnapshot.size;
          const likeCount = modalityLikesSnapshot.size;
          const dislikeCount = modalityDislikesSnapshot.size;

          const modalityData = modalityDoc.data();
          const boardData = doc.data();

          return {
            favorites: favoriteCount,
            likes: likeCount,
            dislikes: dislikeCount,
            rating: likeCount - dislikeCount,
            title: modalityData.title ? modalityData.title : modalityData.link,
            link: modalityData.link,
            board: boardData.boardName,
            key: modalityDoc.id,
            created: modalityData.timeStamp?.toDate().toLocaleString(),
          };
        })
      );
    })
  );

  // Flatten the nested arrays
  modalityData = modalityData.flat();

  setBoardModalityData(modalityData);
}

async function getViewsBarChartData(
  db,
  userRef,
  timeIndex,
  setViewBarChartData
) {
  let startTime;
  let endTime;

  if (timeIndex == 0) {
    startTime = startOfDay;
    endTime = endOfDay;
  } else if (timeIndex == 1) {
    startTime = startOfWeek;
    endTime = endOfWeek;
  } else if (timeIndex == 2) {
    startTime = startOfMonth;
    endTime = endOfMonth;
  }

  const startTimestamp = Timestamp.fromDate(startTime);
  const endTimestamp = Timestamp.fromDate(endTime);

  const boardViewsQuery = query(
    collection(db, "boardViewTimes"),
    where("teacherId", "==", userRef),
    where("timeStamp", ">=", startTimestamp),
    where("timeStamp", "<=", endTimestamp)
  );

  const boardViewsSnapshot = await getDocs(boardViewsQuery);

  // Initialize an empty object to store counts
  let counts = {};

  // Iterate over each document
  boardViewsSnapshot.docs.forEach((doc) => {
    const data = doc.data();
    // Format date string depending on timeIndex
    let date;
    if (timeIndex == 0) {
      // For timeIndex 0, get the hour of the day in format "HH AM/PM"
      const dateObj = data.timeStamp.toDate();
      let hour = dateObj.getHours();
      const minutes = dateObj.getMinutes();

      // Round to the nearest hour
      if (minutes >= 30) {
        hour++;
      }

      const period = hour >= 12 ? "PM" : "AM";
      const hour12 = hour % 12 || 12; // convert 0 to 12 for AM
      date = hour12 + " " + period;
    } else {
      // For other timeIndices, get the date in format MM/DD/YYYY
      const dateObj = data.timeStamp.toDate();
      date =
        dateObj.getMonth() +
        1 +
        "/" +
        dateObj.getDate() +
        "/" +
        dateObj.getFullYear();
    }

    // Increment the count for this date or hour
    counts[date] = (counts[date] || 0) + 1;
  });

  // Sort and convert the counts object to the desired array format
  let boardViewData = Object.keys(counts)
    .sort((a, b) => {
      if (timeIndex == 0) {
        const [hourA, periodA] = a.split(" ");
        const [hourB, periodB] = b.split(" ");

        if (periodA === periodB) {
          return parseInt(hourA) - parseInt(hourB);
        } else {
          return periodA === "AM" ? -1 : 1;
        }
      } else {
        return (
          new Date(a.split("/").reverse().join("-")) -
          new Date(b.split("/").reverse().join("-"))
        );
      }
    })
    .map((date) => {
      return { x: date, y: counts[date] };
    });

  setViewBarChartData(boardViewData);
}

async function getTotalViewsBarChartData(
  db,
  userRef,
  timeIndex,
  setViewBarChartData
) {
  let startTime;
  let endTime;

  if (timeIndex == 0) {
    startTime = startOfDay;
    endTime = endOfDay;
  } else if (timeIndex == 1) {
    startTime = startOfWeek;
    endTime = endOfWeek;
  } else if (timeIndex == 2) {
    startTime = startOfMonth;
    endTime = endOfMonth;
  }

  const startTimestamp = Timestamp.fromDate(startTime);
  const endTimestamp = Timestamp.fromDate(endTime);

  const boardViewsQuery = query(
    collection(db, "boardViewTimes"),
    where("teacherId", "==", userRef),
    where("timeStamp", ">=", startTimestamp),
    where("timeStamp", "<=", endTimestamp)
  );

  const boardViewsSnapshot = await getDocs(boardViewsQuery);

  // Initialize an empty object to store counts
  let counts = {};

  // Iterate over each document
  boardViewsSnapshot.docs.forEach((doc) => {
    const data = doc.data();
    // Format date string depending on timeIndex
    let date;
    if (timeIndex == 0) {
      const dateObj = data.timeStamp.toDate();
      let hour = dateObj.getHours();
      const minutes = dateObj.getMinutes();

      if (minutes >= 30) {
        hour++;
      }

      const period = hour >= 12 ? "PM" : "AM";
      const hour12 = hour % 12 || 12; // convert 0 to 12 for AM
      date = hour12 + " " + period;
    } else {
      const dateObj = data.timeStamp.toDate();
      date =
        dateObj.getMonth() +
        1 +
        "/" +
        dateObj.getDate() +
        "/" +
        dateObj.getFullYear();
    }

    // Increment the count for this date or hour
    counts[date] = (counts[date] || 0) + data.viewTime / 60000; // convert milliseconds to minutes
  });

  // Same sorting and conversion as before
  let boardViewData = Object.keys(counts)
    .sort((a, b) => {
      if (timeIndex == 0) {
        const [hourA, periodA] = a.split(" ");
        const [hourB, periodB] = b.split(" ");

        if (periodA === periodB) {
          return parseInt(hourA) - parseInt(hourB);
        } else {
          return periodA === "AM" ? -1 : 1;
        }
      } else {
        return (
          new Date(a.split("/").reverse().join("-")) -
          new Date(b.split("/").reverse().join("-"))
        );
      }
    })
    .map((date) => {
      return { x: date, y: Number(counts[date]).toFixed(2) };
    });

  setViewBarChartData(boardViewData);
}

async function getAverageViewsBarChartData(
  db,
  userRef,
  timeIndex,
  setAvgTimeViewedBarChartData
) {
  let startTime;
  let endTime;

  if (timeIndex === 0) {
    startTime = startOfDay;
    endTime = endOfDay;
  } else if (timeIndex === 1) {
    startTime = startOfWeek;
    endTime = endOfWeek;
  } else if (timeIndex === 2) {
    startTime = startOfMonth;
    endTime = endOfMonth;
  }

  const startTimestamp = Timestamp.fromDate(startTime);
  const endTimestamp = Timestamp.fromDate(endTime);

  const boardViewsQuery = query(
    collection(db, "boardViewTimes"),
    where("teacherId", "==", userRef),
    where("timeStamp", ">=", startTimestamp),
    where("timeStamp", "<=", endTimestamp)
  );

  const boardViewsSnapshot = await getDocs(boardViewsQuery);

  let totalTimes = {};
  let sessionCounts = {};

  boardViewsSnapshot.docs.forEach((doc) => {
    const data = doc.data();

    let date;
    if (timeIndex === 0) {
      const dateObj = data.timeStamp.toDate();
      let hour = dateObj.getHours();
      const minutes = dateObj.getMinutes();

      if (minutes >= 30) {
        hour++;
      }

      const period = hour >= 12 ? "PM" : "AM";
      const hour12 = hour % 12 || 12; // convert 0 to 12 for AM
      date = hour12 + " " + period;
    } else {
      const dateObj = data.timeStamp.toDate();
      date =
        dateObj.getMonth() +
        1 +
        "/" +
        dateObj.getDate() +
        "/" +
        dateObj.getFullYear();
    }

    totalTimes[date] = (totalTimes[date] || 0) + data.viewTime / 60000; // convert milliseconds to minutes
    sessionCounts[date] = (sessionCounts[date] || 0) + 1;
  });

  let averageSessionTimeData = Object.keys(totalTimes)
    .sort((a, b) => {
      if (timeIndex === 0) {
        const [hourA, periodA] = a.split(" ");
        const [hourB, periodB] = b.split(" ");

        if (periodA === periodB) {
          return parseInt(hourA) - parseInt(hourB);
        } else {
          return periodA === "AM" ? -1 : 1;
        }
      } else {
        return (
          new Date(a.split("/").reverse().join("-")) -
          new Date(b.split("/").reverse().join("-"))
        );
      }
    })
    .map((date) => {
      return {
        x: date,
        y: Number(totalTimes[date] / sessionCounts[date]).toFixed(2),
      };
    });

  setAvgTimeViewedBarChartData(averageSessionTimeData);
}

async function getModalityVotesPieData(
  db,
  userRef,
  setVoteData,
  setLikeData,
  setDislikeData
) {
  const modalityVotesQuery = query(
    collection(db, "modalityVotes"),
    where("teacherId", "==", userRef)
  );

  const modalityVotesSnapshot = await getDocs(modalityVotesQuery);

  let likeCount = 0;
  let dislikeCount = 0;

  let tikTokLikes = 0;
  let youtubeLikes = 0;
  let webLikes = 0;
  let tikTokDislikes = 0;
  let youtubeDislikes = 0;
  let webDislikes = 0;

  modalityVotesSnapshot.docs.forEach((doc) => {
    const data = doc.data();
    if (data.type === "like") {
      likeCount++;

      if (data.modalityType == "tiktok") tikTokLikes++;
      else if (data.modalityType == "youtube") youtubeLikes++;
      else webLikes++;
    } else if (data.type === "dislike") {
      dislikeCount++;

      if (data.modalityType == "tiktok") tikTokDislikes++;
      else if (data.modalityType == "youtube") youtubeDislikes++;
      else webDislikes++;
    }
  });

  const voteData = [
    { x: "Likes", y: likeCount },
    { x: "Dislikes", y: dislikeCount },
  ];

  if (likeCount != 0 || dislikeCount != 0) {
    setVoteData(voteData);
  }

  let likeArray = [];

  if (tikTokLikes !== 0) likeArray.push({ x: "Tiktok", y: tikTokLikes });
  if (youtubeLikes !== 0) likeArray.push({ x: "YouTube", y: youtubeLikes });
  if (webLikes !== 0) likeArray.push({ x: "Other", y: webLikes });

  setLikeData(likeArray);

  let dislikeArray = [];

  if (tikTokDislikes !== 0)
    dislikeArray.push({ x: "Tiktok", y: tikTokDislikes });
  if (youtubeDislikes !== 0)
    dislikeArray.push({ x: "YouTube", y:youtubeDislikes });
  if (webDislikes !== 0) dislikeArray.push({ x: "Other", y: webDislikes });

  setDislikeData(dislikeArray);
}

async function getStudentTableData(db, userRef, setStudentData) {
  let studentClassesCollection = collection(db, "studentClasses");
  let studentsQuery = query(
    studentClassesCollection,
    where("teacherId", "==", userRef)
  );
  let studentClassesSnapshot = await getDocs(studentsQuery);

  let studentMap = {};

  let studentDataPromises = studentClassesSnapshot.docs.map(
    async (studentClassDoc) => {
      let studentRef = studentClassDoc.data().studentId;
      let studentId = studentRef.id;

      let studentSnapshot = await getDoc(doc(db, "publicUsers", studentId));

      let classSnapshot = await getDoc(studentClassDoc.data().classId);

      if (!classSnapshot.exists()) {
        return;
      }
      let commentsSnapshot = await getDocs(
        query(
          collection(db, "comments"),
          where("userId", "==", studentRef),
          where("teacherId", "==", userRef)
        )
      );
      let repliesSnapshot = await getDocs(
        query(
          collection(db, "replies"),
          where("userId", "==", studentRef),
          where("teacherId", "==", userRef)
        )
      );
      let viewTimesSnapshot = await getDocs(
        query(
          collection(db, "boardViewTimes"),
          where("teacherId", "==", userRef),
          where("userId", "==", studentRef)
        )
      );

      let studentData = studentSnapshot.data();
      let commentCount = commentsSnapshot.size;
      let replyCount = repliesSnapshot.size;

      let totalViewTime = viewTimesSnapshot.docs.reduce(
        (total, doc) => total + doc.data().viewTime,
        0
      );

      if (!studentMap[studentId]) {
        // first time seeing this student
        studentMap[studentId] = {
          ...studentData,
          id: studentId,
          classIds: [studentClassDoc.data().classId.id],
          name: studentData?.firstName + " " + studentData?.lastName,
          className: [classSnapshot?.data().className],
          comments: commentCount,
          replies: replyCount,
          viewTime: Number((totalViewTime / 60000).toFixed(2)), // convert milliseconds to minutes,
          joined:
            studentClassDoc.data().timeStamp &&
            studentClassDoc.data().timeStamp.toDate().toDateString(),
        };
      } else {
        // seen this student before, so we accumulate
        studentMap[studentId].className.push(classSnapshot.data().className);
        studentMap[studentId].comments += commentCount;
        studentMap[studentId].replies += replyCount;
        studentMap[studentId].viewTime += Number(
          (totalViewTime / 60000).toFixed(2)
        );
        studentMap[studentId].classIds.includes(
          studentClassDoc.data().classId.id
        )
          ? null
          : studentMap[studentId].classIds.push(
              studentClassDoc.data().classId.id
            );
      }
    }
  );

  await Promise.all(studentDataPromises);

  let studentsData = Object.values(studentMap).map((student) => {
    return {
      ...student,
      className: student.className.join(", "),
    };
  });

  setStudentData(studentsData);
}

export default function StaffDashboardScreen({ navigation }) {
  const [selectedBarTimeRangeIndex, setBarSelectedTimeRangeIndex] = useState(1);
  const [toastVisible, setToastVisible] = React.useState(0);
  const [toastMessage, setToastMessage] = React.useState("");
  const [dataLoading, setDataLoading] = React.useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [viewsBarChartData, setViewBarChartData] = useState([]);
  const [totalTimeViewedBarChartData, setTotalTimeViewedBarChartData] =
    useState([]);
  const [avgTimeViewedBarChartData, setAvgTimeViewedBarChartData] = useState(
    []
  );
  const [barChartDataLoading, setBarChartDataLoading] = useState(false);
  const [modalityVotesPieChartData, setModalityVotesPieChartData] = useState(
    []
  );
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [boardTableData, setBoardTableData] = useState([]);
  const [boardModalityData, setBoardModalityData] = useState([]);
  const [boardStudentData, setBoardStudentData] = useState([]);
  const [modalityLikeData, setModalityLikeData] = useState([]);
  const [modalityDislikeData, setModalityDislikeData] = useState([]);

  const db = getFirestore();
  const auth = getAuth();
  const uid = auth.currentUser.uid;
  const userRef = doc(db, "users", uid);

  const renderBoardLink = ({ data, row, value }) => {
    return (
      <TouchableOpacity
        style={{ flexDirection: "row", gap: 4, alignItems: "flex-end" }}
        onPress={() =>
          navigation.navigate("Board", {
            name: value,
            boardId: data[row.index].id,
          })
        }
      >
        <Text style={{ color: "#6395f9" }}>{value}</Text>
        <Octicons
          name="link-external"
          size={10}
          style={{ paddingBottom: 2 }}
          color="#6395f9"
        />
      </TouchableOpacity>
    );
  };

  const renderModalityLink = ({ data, row, value }) => {
    return (
      <TouchableOpacity
        style={{ flexDirection: "row", gap: 4, alignItems: "flex-end" }}
        onPress={() => Linking.openURL(data[row.index].link)}
      >
        <Text style={{ color: "#6395f9" }}>{value}</Text>
        <Octicons
          name="link-external"
          size={10}
          style={{ paddingBottom: 2 }}
          color="#6395f9"
        />
      </TouchableOpacity>
    );
  };

  const boardTableColumns = React.useMemo(
    () => [
      {
        Header: "Board",
        accessor: "board", // accessor is the "key" in the data
        width: 300,
        Cell: renderBoardLink,
      },
      {
        Header: "Views",
        accessor: "views",
        disableFilters: true,
      },
      {
        Header: "Replies",
        accessor: "replies",
        disableFilters: true,
      },
      {
        Header: "Favorites",
        accessor: "favorites",
        disableFilters: true,
      },
      {
        Header: "Created",
        accessor: "created",
      },
    ],
    []
  );

  const modalityTableColumns = React.useMemo(
    () => [
      {
        Header: "Board",
        accessor: "board", // accessor is the "key" in the data
        width: 300,
        Cell: renderBoardLink,
      },
      {
        Header: "Title",
        accessor: "title", // accessor is the "key" in the data
        width: 400,
        Cell: renderModalityLink,
      },
      {
        Header: "Favorites",
        accessor: "favorites",
        disableFilters: true,
      },
      {
        Header: "Rating",
        accessor: "rating",
        disableFilters: true,
      },
      {
        Header: "Likes",
        accessor: "likes",
        disableFilters: true,
      },
      {
        Header: "Dislikes",
        accessor: "dislikes",
        disableFilters: true,
      },
      {
        Header: "Created",
        accessor: "created",
        disableFilters: true,
      },
    ],
    []
  );

  const timeData = [
    {
      name: "Views",
      screen: (
        <BasicBarChart
          minBarWidth={100}
          data={viewsBarChartData}
          colorScale={["#ed9b40", "#ba3b46"]}
        />
      ),
    },
    {
      name: "Total Time Viewed",
      screen: (
        <BasicBarChart
          minBarWidth={100}
          title={"(minutes)"}
          data={totalTimeViewedBarChartData}
          colorScale={["#ed9b40", "#ba3b46"]}
        />
      ),
    },
    {
      name: "Average Session Length",
      screen: (
        <BasicBarChart
          minBarWidth={100}
          title={"(minutes)"}
          data={avgTimeViewedBarChartData}
          colorScale={["#ed9b40", "#ba3b46"]}
        />
      ),
    },
  ];
  const pieData = [
    {
      name: "Modality Feedback",
      screen: (
        <BasicPieChart
          colorScale={["#f7bb51", "#e56853"]}
          data={modalityVotesPieChartData}
        />
      ),
    },
    {
      name: "Modality Likes",
      screen: <BasicPieChart data={modalityLikeData} />,
    },
    {
      name: "Modality Dislikes",
      screen: <BasicPieChart data={modalityDislikeData} />,
    },
  ];
  const tableTabData = [
    {
      name: "Students",
      screen: (
        <StudentLookupScreen
          navigation={navigation}
          studentLookupData={boardStudentData}
          setToastVisible={setToastVisible}
          setToastMessage={setToastMessage}
          toastVisible={toastVisible}
        />
      ),
    },
    {
      name: "Boards",
      screen: (
        <BasicTable
          columns={boardTableColumns}
          data={boardTableData}
        ></BasicTable>
      ),
    },
    {
      name: "Modalities",
      screen: (
        <BasicTable
          columns={modalityTableColumns}
          data={boardModalityData}
        ></BasicTable>
      ),
    },
  ];

  useEffect(() => {
    setDataLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    async function getBarChartData() {
      setBarChartDataLoading(true);

      setViewBarChartData([]);
      setTotalTimeViewedBarChartData([]);
      setAvgTimeViewedBarChartData([]);

      await getViewsBarChartData(
        db,
        userRef,
        selectedBarTimeRangeIndex,
        setViewBarChartData
      );
      await getTotalViewsBarChartData(
        db,
        userRef,
        selectedBarTimeRangeIndex,
        setTotalTimeViewedBarChartData
      );
      await getAverageViewsBarChartData(
        db,
        userRef,
        selectedBarTimeRangeIndex,
        setAvgTimeViewedBarChartData
      );

      setBarChartDataLoading(false);
    }

    getBarChartData();
  }, [selectedBarTimeRangeIndex]);

  const fetchData = async () => {
    setRefreshing(true);

    //fetch top 3 comments from class

    const topLikedComments = await getTopLikedComments(userRef);

    const topUsersByViewTime = await getTopUserByViewTimes(userRef);

    const topClassesByViewTime = await getTopClassesByViewTimes(userRef);

    const topFavoriteBoards = await getTopFavoritedBoards(userRef);

    const topViewedBoards = await getTopViewedBoards(userRef);

    const topRepliedBoards = await getTopCommentedBoards(userRef);

    const topFavoritedModalities = await getTopFavoritedModalities(userRef);

    setLeaderBoardData([
      topLikedComments,
      topUsersByViewTime,
      topClassesByViewTime,
      topFavoriteBoards,
      topViewedBoards,
      topRepliedBoards,
      topFavoritedModalities,
    ]);

    await getTableData(
      db,
      userRef,
      setBoardTableData,
      setBoardModalityData,
      setBoardStudentData
    );

    //-student table data
    await getStudentTableData(db, userRef, setBoardStudentData);

    //----bar chart data

    await getViewsBarChartData(
      db,
      userRef,
      selectedBarTimeRangeIndex,
      setViewBarChartData
    );

    await getTotalViewsBarChartData(
      db,
      userRef,
      selectedBarTimeRangeIndex,
      setTotalTimeViewedBarChartData
    );

    await getAverageViewsBarChartData(
      db,
      userRef,
      selectedBarTimeRangeIndex,
      setAvgTimeViewedBarChartData
    );

    //----pie chart data

    await getModalityVotesPieData(
      db,
      userRef,
      setModalityVotesPieChartData,
      setModalityLikeData,
      setModalityDislikeData
    );

    setDataLoading(false);
    setRefreshing(false);
    setBarChartDataLoading(false);
  };

  const classColor = "#ed9b40";
  const textColor = "white";

  return (
    <Fragment>
      <KeyboardAwareScrollView
        style={{ flex: 1, backgroundColor: "#f0eff4" }}
        refreshControl={
          !dataLoading && (
            <RefreshControl refreshing={refreshing} onRefresh={fetchData} />
          )
        }
      >
        <Flex fill p={16} style={{ gap: 16, paddingBottom: 91 }}>
          {Platform.OS !== "web" && dataLoading && (
            <Spinner size={"lg"} color={"black"} />
          )}
          {Platform.OS === "web" && refreshing && (
            <Spinner size={"lg"} color={"black"} />
          )}
          {!dataLoading && (
            <>
              <AutoPopIn delay={300}>
                <View style={{ width: "100%", alignItems: "center" }}>
                  <View style={{ maxWidth: 1000, width: "100%" }}>
                    <BasicCarousel
                      autoPlay={false}
                      renderItem={(card) => {
                        return <TopThreeCard card={card} />;
                      }}
                      data={leaderBoardData}
                    />
                  </View>
                </View>
              </AutoPopIn>
              <AutoPopIn delay={500}>
                <VStack style={{ gap: 8 }}>
                  <Accordion
                    title="Class Data Tables"
                    content={<BasicTabContainer screens={tableTabData} />}
                    defaultExpanded
                    color={classColor}
                    textColor={textColor}
                  />
                  <Accordion
                    title="Time Analysis"
                    content={
                      <View style={{ flexDirection: "column" }}>
                        <View style={{ marginVertical: 10 }}>
                          <CustomSegmentedControl
                            color={classColor}
                            textColor={textColor}
                            values={["Today", "This Week", "This Month"]}
                            selectedIndex={selectedBarTimeRangeIndex}
                            onValueChange={(index) => {
                              setBarSelectedTimeRangeIndex(index);
                            }}
                          />
                        </View>
                        {barChartDataLoading && (
                          <Spinner
                            style={{ marginBottom: 5 }}
                            size={"sm"}
                            color={"black"}
                          />
                        )}
                        <BasicTabContainer screens={timeData} />
                      </View>
                    }
                    color={classColor}
                    textColor={textColor}
                  />
                  <Accordion
                    title="Modality Usage"
                    content={
                      <View style={{ flexDirection: "column" }}>
                        <BasicTabContainer screens={pieData} />
                      </View>
                    }
                    color={classColor}
                    textColor={textColor}
                  />
                </VStack>
              </AutoPopIn>
            </>
          )}
        </Flex>
      </KeyboardAwareScrollView>
      <Fragment>
        <FabGroup
          buttons={[
            {
              icon: <MaterialCommunityIcons name="bank" size={32} />,
              color: "white",
              onPress: () => navigation.navigate("Vault"),
            },
            {
              icon: <Ionicons name="people" size={32} />,
              color: "white",
              onPress: () => navigation.navigate("Classes"),
            },
            {
              icon: <MaterialCommunityIcons name="table" size={32} />,
              color: "white",
              onPress: () => navigation.navigate("Boards"),
            },
          ]}
        />
      </Fragment>
      <SimpleToast message={toastMessage} visible={toastVisible} />
    </Fragment>
  );
}
