import React, { useEffect, useRef } from "react";
import { Animated, Platform } from "react-native";
import { useState } from "react";

export default function AutoPopIn({ delay = 0, ...props }) {
  const scaleValue = useRef(new Animated.Value(0)).current;
  const opacityValue = useRef(new Animated.Value(0)).current;

  const disable = Platform.OS == "web";

  const popIn = Animated.parallel([
    Animated.spring(scaleValue, {
      delay: delay,
      toValue: 1,
      bounciness: 10, // Controls the bounciness of the animation
      speed: 10, // Controls the speed of the animation
      useNativeDriver: true,
    }),
    Animated.timing(opacityValue, {
      toValue: 1,
      duration: 200, // Controls the duration of the opacity animation
      useNativeDriver: true,
    }),
  ]);

  useEffect(() => {
    popIn.start();
  }, []);

  return (
    <Animated.View
      style={{
        transform: [!disable ? { scale: scaleValue } : {}],
        opacity: disable ? 1 : opacityValue ? opacityValue : 1,
      }}
    >
      {props.children}
    </Animated.View>
  );
}
