import React, { useEffect, useRef } from "react";

import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  TextInput,
  Image,
  Platform,
  DeviceEventEmitter,
} from "react-native";
import { TextInputMask } from "react-native-masked-text";

import * as Clipboard from "expo-clipboard";

import {
  getAuth,
  sendPasswordResetEmail,
  signOut,
  updateProfile,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  query,
  collection,
  where,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { ActivityIndicator, HStack } from "@react-native-material/core";
import * as ExpoImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { generateCode } from "../../components/data/input/Modals/AddClassPopup";
import { Divider, Select } from "native-base";
import DateOfBirthInput from "../../components/data/input/DateOfBirthInput";
import CustomAlertDialog from "../../components/data/input/Modals/CustomAlertDialog";
import SimpleToast from "../../components/animations/toasts/SimpleToast";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import CustomTextInput from "../../components/data/input/CustomTextInput";
import CustomPhoneInput from "../../components/data/input/CustomPhoneInput";

export default function StaffAccountScreen({ navigation }) {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneNumberValid, setPhoneNumberValid] = React.useState(true);
  const [savedFirstName, setSavedFirstName] = React.useState("");
  const [savedLastName, setSavedLastName] = React.useState("");
  const [savedEmail, setSavedEmail] = React.useState("");
  const [savedPhoneNumber, setSavedPhoneNumber] = React.useState("");
  const [avatarSrc, setAvatarSrc] = React.useState({ uri: null });
  const [savedAvatarSrc, setSavedAvatarSrc] = React.useState({ uri: null });
  const [saveDisabled, setSaveDisabled] = React.useState(true);
  const [savedDob, setSavedDob] = React.useState(new Date());
  const [savedEthnicity, setSavedEthnicity] = React.useState("");
  const [savedGender, setSavedGender] = React.useState("");
  const [dataLoading, setDataLoading] = React.useState(true);
  const [savingChanges, setSavingChanges] = React.useState(false);
  const [accountType, setAccountType] = React.useState("");
  const [newAvatar, setNewAvatar] = React.useState(false);
  const [referralCode, setReferralCode] = React.useState();
  const [ethnicity, setEthnicity] = React.useState("");
  const [dob, setDob] = React.useState();
  const [gender, setGender] = React.useState("");
  const [showDialog, setShowDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [toastVisible, setToastVisible] = React.useState(0);
  const [toastMessage, setToastMessage] = React.useState("");

  const [needToSignIn, setNeedToSignIn] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const auth = getAuth();
  const uid = auth.currentUser.uid;
  const db = getFirestore();
  const docRef = doc(db, "users", uid);

  const validatePhoneNumber = (text) => {
    const reg = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
    return reg.test(text);
  };

  const copyCodeToClipboard = async () => {
    Clipboard.setString(referralCode);
    setToastMessage("Code copied to clipboard");
    setToastVisible(Math.random());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    DeviceEventEmitter.emit("showLoadingIndicator");
    setDataLoading(true);
    try {
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();

      if (data.firstName) {
        setFirstName(data.firstName);
        setSavedFirstName(data.firstName);
      }

      if (data.lastName) {
        setLastName(data.lastName);
        setSavedLastName(data.lastName);
      }
      if (data.email) {
        setEmail(data.email);
        setSavedEmail(data.email);
      }
      if (data.ethnicity) {
        setEthnicity(data.ethnicity);
        setSavedEthnicity(data.ethnicity);
      }
      if (data.gender) {
        setGender(data.gender);
        setSavedGender(data.gender);
      }
      if (data.dob && data.dob.split("/").length == 3) {
        const _dob = new Date(
          data.dob.split("/")[2],
          data.dob.split("/")[0] - 1,
          data.dob.split("/")[1]
        );

        if (!isNaN(_dob)) {
          setDob(_dob);
          setSavedDob(_dob);
        }
      }

      if (data.phoneNumber) {
        setPhoneNumber(data.phoneNumber);
        setSavedPhoneNumber(data.phoneNumber);
      }
      if (data.role) setAccountType(data.role);
      if (data.profilePicture) {
        setAvatarSrc({ uri: data.profilePicture });
        setSavedAvatarSrc({ uri: data.profilePicture });
      } else {
        setAvatarSrc({ uri: auth.currentUser.photoURL });
        setSavedAvatarSrc({ uri: auth.currentUser.photoURL });
      }

      const publicDoc = await getDoc(doc(db, "publicUsers", uid));

      if (publicDoc.exists()) setReferralCode(publicDoc.data().referralCode);
    } catch (err) {
      console.error(err);
    }
    DeviceEventEmitter.emit("hideLoadingIndicator");
    setDataLoading(false);
    setSavingChanges(false);
  };

  function promptAreYouSure() {
    setDialogMessage(
      "By refreshing your personal referral code, the existing code will be deactivated. Please confirm that you would like to proceed, as this action cannot be undone."
    );
    setShowDialog(true);
  }

  async function createUniqueReferralCode() {
    const newCode = await new Promise(async (resolve, reject) => {
      let code;
      let codeIsUnique = false;

      while (!codeIsUnique) {
        code = generateCode(10); // Assuming you have a separate function to generate the code

        const q = query(
          collection(getFirestore(), "publicUsers"),
          where("referralCode", "==", code)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size === 0) {
          codeIsUnique = true;
          resolve(code);
        }
      }
    });

    await updateDoc(doc(db, "users", uid), { referralCode: newCode });
    await updateDoc(doc(db, "publicUsers", uid), { referralCode: newCode });

    setReferralCode(newCode);

    setToastMessage("New code generated");
    setToastVisible(Math.random());
  }

  const formatDate = (date) => {
    if (!date) return null;
    return (
      String(date.getMonth() + 1).padStart(2, "0") +
      "/" +
      String(date.getDate()).padStart(2, "0") +
      "/" +
      date.getFullYear()
    );
  };

  const checkDisabled = () => {
    const isDobEqual =
      savedDob && dob ? formatDate(savedDob) === formatDate(dob) : false;
    const areDetailsEqual =
      firstName === savedFirstName &&
      lastName === savedLastName &&
      email === savedEmail &&
      phoneNumber === savedPhoneNumber &&
      avatarSrc.uri === savedAvatarSrc.uri &&
      isDobEqual &&
      ethnicity === savedEthnicity &&
      gender === savedGender;

    const shouldBeDisabled = areDetailsEqual || !phoneNumberValid;

    if (saveDisabled !== shouldBeDisabled) {
      setSaveDisabled(shouldBeDisabled);
    }
  };

  useEffect(() => {
    checkDisabled();
  }, [
    firstName,
    lastName,
    email,
    phoneNumber,
    avatarSrc,
    ethnicity,
    dob,
    gender,
    savedFirstName,
    savedLastName,
    savedEmail,
    savedPhoneNumber,
    savedAvatarSrc,
    savedGender,
    savedEthnicity,
    savedDob,
  ]);

  const saveChangedClicked = async () => {
    setSavingChanges(true);

    try {
      const avatarUrl = newAvatar
        ? await uploadImage(avatarSrc.uri)
        : avatarSrc.uri;
      setSavedAvatarSrc({ uri: avatarUrl, ...avatarSrc });

      await updateDoc(docRef, {
        firstName,
        lastName,
        email,
        phoneNumber: phoneNumber.replace(/\D+/g, ""),
        profilePicture: avatarUrl,
        ethnicity,
        dob:
          (dob?.getMonth() + 1 < 10
            ? "0" + (dob?.getMonth() + 1)
            : dob?.getMonth() + 1) +
          "/" +
          (dob?.getDate() < 10 ? "0" + dob?.getDate() : dob?.getDate()) +
          "/" +
          dob?.getFullYear(),
        gender,
      });

      setSavedFirstName(firstName);
      setSavedLastName(lastName);
      setSavedEmail(email);
      setSavedPhoneNumber(phoneNumber);
      setSavedEthnicity(ethnicity);
      setSavedDob(dob);
      setSavedGender(gender);

      const user = auth.currentUser;

      if (user) {
        await updateProfile(user, {
          displayName: firstName + " " + lastName,
          photoURL: avatarUrl,
        })
          .then(() => {
            // Profile updated successfully!
            // "NewDisplayName" is the new display name
          })
          .catch((error) => {
            // An error happened.
            console.error("Error updating display name", error);
          });
      } else {
        console.error("No user is signed in.");
      }
    } catch (err) {
      console.error(err);
    }
    setSavingChanges(false);
  };

  const pickImage = async () => {
    await ExpoImagePicker.requestMediaLibraryPermissionsAsync();

    const status = await ExpoImagePicker.getMediaLibraryPermissionsAsync();

    if (status.status !== "granted") {
      alert(
        "You must enable Photo Library Permssion in the settings to use this feature."
      );
      return;
    }

    try {
      let result = await ExpoImagePicker.launchImageLibraryAsync({
        mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 1,
      });

      if (!result.canceled) {
        const manipulatorResult = await ImageManipulator.manipulateAsync(
          result.assets[0].uri,
          [{ resize: { width: 300 } }], // resize to width 300 and keep aspect ratio
          { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG } // compress the image and save as JPEG
        );

        setAvatarSrc(manipulatorResult);
        setNewAvatar(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  async function deleteAccountPressed() {
    setDeleting(true);
    try {
      try {
        auth.currentUser.delete();

        const userPostsQ = query(
          collection(db, "posts"),
          "posts",
          where("authorId", "==", auth.currentUser.uid)
        );

        const userPostsSnapshot = await getDocs(userPostsQ);

        if (!userPostsSnapshot.empty) {
          userPostsSnapshot.forEach((post) => {
            deleteDoc(post.ref);
          });
        }

        const publicDoc = doc(db, "publicUsers", uid);
        const userDoc = doc(db, "users", uid);

        deleteDoc(publicDoc);
        deleteDoc(userDoc);
      } catch (e) {
        setNeedToSignIn(true);
      }
    } catch (e) {
      console.error(e);
    }

    setDeleting(false);
  }

  async function uploadImage(uri) {
    const response = await fetch(uri);
    const blob = await response.blob();

    const storage = getStorage();
    const storageRef = ref(storage, `users/${uid}/profilePicture`);

    await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(storageRef);

    return url;
  }

  return (
    <>
      <KeyboardAwareScrollView
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.headerText}>
                Set your account information down below
              </Text>
            </View>
            <View style={styles.avatarContainer}>
              <TouchableOpacity style={styles.avatar} onPress={pickImage}>
                {avatarSrc.uri !== null ? (
                  <Image
                    source={avatarSrc}
                    style={{ width: "100%", height: "100%", borderRadius: 50 }}
                  />
                ) : (
                  <MaterialCommunityIcons
                    name="account"
                    size={40}
                    color="#fff"
                  />
                )}
              </TouchableOpacity>
              <View style={styles.avatarHeader}>
                <Text style={styles.avatarHeaderText}>Avatar</Text>
                <Text style={styles.avatarText}>
                  Min 200x200px .PNG or .JPEG
                </Text>
              </View>
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.labelText}>First Name</Text>
              <CustomTextInput
                placeholder="Abraham"
                initialValue={savedFirstName}
                style={styles.textInput}
                setValue={setFirstName}
              />
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.labelText}>Last Name</Text>
              <CustomTextInput
                placeholder="Lincoln"
                initialValue={savedLastName}
                style={styles.textInput}
                setValue={setLastName}
              />
            </View>
            <View style={[styles.inputContainer, { zIndex: 9999 }]}>
              <Text style={styles.labelText}>Date of Birth</Text>
              {dob ? (
                <DateOfBirthInput date={dob} setDate={setDob} />
              ) : (
                <TouchableOpacity onPress={() => setDob(new Date())}>
                  <Text style={{ color: "#ccc" }}>Enter date...</Text>
                </TouchableOpacity>
              )}
            </View>
            <Divider
              style={{
                backgroundColor: "#eee",
                marginTop: 5,
                marginBottom: 10,
              }}
            />
            <View style={styles.inputContainer}>
              <Text style={styles.labelText}>Email</Text>
              <CustomTextInput
                placeholder="example@email.com"
                initialValue={savedEmail}
                style={styles.textInput}
                setValue={setEmail}
              />
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.labelText}>Phone</Text>
              <CustomPhoneInput
                initialValue={savedPhoneNumber}
                setValue={(val) => {
                  setPhoneNumber(val);
                  setPhoneNumberValid(validatePhoneNumber(val));
                }}
              />
            </View>
            <Divider
              style={{
                backgroundColor: "#eee",
                marginTop: 5,
                marginBottom: 10,
              }}
            />
            <View style={styles.inputContainer}>
              <Text style={styles.labelText}>Ethnicity</Text>
              <Select
                onValueChange={(value) => setEthnicity(value)}
                placeholder="Select Ethnicity"
                selectedValue={ethnicity}
                borderColor={"#eee"}
                height={10}
                fontWeight={"500"}
              >
                <Select.Item
                  label="American Indian or Alaska Native"
                  value="American Indian or Alaska Native"
                />
                <Select.Item label="Asian" value={"Asian"} />
                <Select.Item
                  label="Hispanic or Latino"
                  value={"Hispanic or Latino"}
                />
                <Select.Item
                  label="Black or African American"
                  value={"Black or African American"}
                />
                <Select.Item
                  label="Native Hawaiian or Other Pacific Islander"
                  value={"Native Hawaiian or Other Pacific Islander"}
                />
                <Select.Item label="White" value={"White"} />
                <Select.Item
                  label="Two or More Races"
                  value={"Two or More Races"}
                />
                <Select.Item
                  label="Prefer not to say"
                  value={"Prefer not to say"}
                />
              </Select>
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.labelText}>Gender</Text>
              <Select
                onValueChange={(value) => setGender(value)}
                placeholder="Select Gender"
                selectedValue={gender}
                borderColor={"#eee"}
                height={10}
                fontWeight={"500"}
              >
                <Select.Item label="Male" value="male" />
                <Select.Item label="Female" value="female" />
                <Select.Item label="Other" value="other" />
                <Select.Item
                  label="Prefer not to say"
                  value="Prefer not to say"
                />
              </Select>
            </View>
            <TouchableOpacity
              disabled={saveDisabled}
              style={[
                styles.button,
                (saveDisabled || savingChanges) && styles.disabledButton,
              ]}
              onPress={saveChangedClicked}
            >
              {savingChanges && (
                <ActivityIndicator size="small" color="white" />
              )}
              <Text style={styles.buttonText}>
                {dataLoading
                  ? "Loading..."
                  : savingChanges
                  ? "Saving..."
                  : saveDisabled
                  ? "No changes"
                  : "Save changes"}
              </Text>
            </TouchableOpacity>
            <Divider
              style={{
                backgroundColor: "#eee",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <View style={styles.inputContainer}>
              <Text style={styles.labelText}>Account Type</Text>
              <Text style={[styles.accountTypeText, { marginLeft: 0 }]}>
                {accountType}
              </Text>
            </View>
            {accountType == "Teacher" && (
              <View style={{}}>
                <Text style={styles.labelText}>Referral Code</Text>
                <View
                  style={{
                    flexDirection: "row",
                    gap: 5,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity onPress={copyCodeToClipboard}>
                    <MaterialCommunityIcons
                      color={"#333"}
                      name="content-copy"
                      size={28}
                    />
                  </TouchableOpacity>
                  <Text
                    onPress={copyCodeToClipboard}
                    style={[
                      styles.accountTypeText,
                      {
                        marginLeft: 0,
                        color: "#333",
                        //fontWeight: "900",
                        fontSize: 32,
                        fontFamily: "hack-font",
                      },
                    ]}
                  >
                    {referralCode ? referralCode : "none"}
                  </Text>
                  <TouchableOpacity
                    style={{
                      marginTop: 0,
                      borderColor: "#f5222d",
                      borderWidth: 1,
                      padding: 5,
                      borderRadius: 5,
                      flex: 1,
                    }}
                    onPress={promptAreYouSure}
                  >
                    <Text
                      selectable
                      style={[
                        styles.accountTypeText,
                        {
                          color: "#f5222d",
                          marginLeft: 0,
                          textAlign: "center",
                          fontStyle: "italic",
                          fontSize: 10,
                        },
                      ]}
                    >
                      {referralCode ? "REFRESH" : "GENERATE"} CODE
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
            <View
              style={{
                flexDirection: "row",
                gap: 10,
                width: "100%",
              }}
            >
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    backgroundColor: "#fff",
                    borderColor: "#f5222d",
                    borderWidth: 1,
                  },
                ]}
                onPress={() => sendPasswordResetEmail(getAuth(), savedEmail)}
              >
                <Text style={[styles.buttonText, { color: "#f5222d" }]}>
                  Send Password Reset Email
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    backgroundColor: "#fff",
                    borderColor: "#f5222d",
                    borderWidth: 1,
                    flex: 1,
                  },
                ]}
                onPress={() => signOut(auth)}
              >
                <Text style={[styles.buttonText, { color: "#f5222d" }]}>
                  Log out
                </Text>
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              style={[
                styles.button,
                {
                  backgroundColor: "#f5222d",
                  marginBottom: 10,
                },
              ]}
              onPress={() => setIsModalVisible(true)}
            >
              <Text style={[styles.buttonText, { color: "#fff" }]}>
                Delete Account
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.loadingContainer}>
            {dataLoading && (
              <ActivityIndicator size={"large"} color="#1677ff" />
            )}
          </View>
        </View>
      </KeyboardAwareScrollView>
      <CustomAlertDialog
        setModalVisible={setShowDialog}
        onConfirm={() => {}}
        modalVisible={showDialog}
        title={"Are you sure?"}
        message={dialogMessage}
        buttons={[
          <TouchableOpacity
            key={"cancel"}
            onPress={() => {
              setShowDialog(false);
            }}
          >
            <Text style={{ color: "#1677ff" }}>Cancel</Text>
          </TouchableOpacity>,
          <TouchableOpacity
            key={"submit"}
            style={[
              {
                backgroundColor: "#f5222d",
                borderRadius: 5,
                paddingHorizontal: 14,
                paddingVertical: 10,
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
              },
            ]}
            onPress={() => {
              setShowDialog(false);
              createUniqueReferralCode();
            }}
          >
            <Text style={{ color: "white" }}>Confirm</Text>
          </TouchableOpacity>,
        ]}
      />
      <CustomAlertDialog
        modalVisible={isModalVisible}
        setModalVisible={setIsModalVisible}
        title={"Are you sure?"}
        buttons={[
          <TouchableOpacity
            key={"CANCEL"}
            onPress={() => {
              setIsModalVisible(false);
            }}
          >
            <Text style={{ color: "#043ece" }}>Cancel</Text>
          </TouchableOpacity>,
          <TouchableOpacity
            key={"CONFIRM"}
            onPress={needToSignIn ? signOut : deleteAccountPressed}
            style={{
              backgroundColor: "#f5222d",
              padding: 10,
              paddingHorizontal: 20,
              borderRadius: 8,
              opacity: deleting ? 0.5 : 1,
              flexDirection: "row",
              gap: 4,
            }}
          >
            {deleting && <ActivityIndicator color={"#fff"} size={"small"} />}
            <Text style={{ color: "#fff" }}>
              {needToSignIn ? "Logout" : "Delete Account"}{" "}
            </Text>
          </TouchableOpacity>,
        ]}
        message={
          needToSignIn
            ? "You must sign in again to delete your account."
            : "This will delete all of your posts and all of your personal information from our database."
        }
      />
      <SimpleToast message={toastMessage} visible={toastVisible} />
    </>
  );
}

const styles = StyleSheet.create({
  container: { maxWidth: 350, width: "100%" },
  centeredView: {
    alignItems: "center",
  },
  avatarContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  avatar: {
    backgroundColor: "#ccc",
    width: 50,
    height: 50,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  avatarHeader: {
    flexDirection: "column",
    marginLeft: 10,
    justifyContent: "center",
  },
  avatarHeaderText: { fontWeight: "bold" },
  avatarText: {},
  inputContainer: {
    width: "100%",
    flexDirection: "column",
    marginBottom: 10,
  },
  textInput: {
    paddingLeft: 10,
    height: 40,
    borderRadius: 3,
    padding: 10,
    flex: 1,
    borderWidth: 1,
    borderColor: "#eee",
  },
  labelText: { fontWeight: "bold", marginBottom: 5 },
  accountTypeText: { color: "#999", marginLeft: 10 },
  header: { marginVertical: 20 },
  headerText: { color: "#888" },
  button: {
    borderRadius: 5,
    padding: 10,
    backgroundColor: "#1677ff",
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "center",
    gap: 4,
  },
  disabledButton: {
    opacity: 0.5,
  },
  loadingContainer: {
    marginTop: 20,
  },
  buttonText: { textAlign: "center", color: "#fff" },
});
