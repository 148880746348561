import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from "@react-navigation/drawer";
import { getAuth, signOut } from "firebase/auth";

import Logo from "../graphics/Logo";

import { View, Text } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

function SignOutDrawerItem(props) {
  const auth = getAuth();

  return (
    <DrawerItem
      icon={({ focused, color, size }) => (
        <MaterialCommunityIcons name="logout" size={size} color={color} />
      )}
      labelStyle={{
        marginLeft: -20,
        paddingLeft: 1.5,
        color: "grey",
        fontWeight: "600",
      }}
      label="Logout"
      onPress={() => signOut(auth)}
    />
  );
}

function LogoDrawerItem(props) {
  return (
    <DrawerItem
      inactiveBackgroundColor="#ed9b40"
      label={() => (
        <View
          style={{
            backgroundColor: "#ed9b40",
            flexDirection: "row",
          }}
        >
          <Logo white header />
        </View>
      )}
      labelStyle={{ fontWeight: "bold" }}
      style={{}}
    ></DrawerItem>
  );
}

export function CustomDrawerContent(props) {
  return (
    <DrawerContentScrollView {...props}>
      <LogoDrawerItem />
      <DrawerItemList {...props} />
      <SignOutDrawerItem />
    </DrawerContentScrollView>
  );
}
