import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useWindowDimensions } from "react-native";
import StudentClassScreen from "../../screens/students/StudentClass";
import StudentClassesScreen from "../../screens/students/StudentClasses";
import StudentBoardScreen from "../../screens/students/StudentBoard";

const Stack = createNativeStackNavigator();

export default function StudentClassesNavigator({ navigation }) {
  const { height, width } = useWindowDimensions();
  const large = width >= 1008;
  const medium = width < 1008 && width >= 641;
  const small = width < 641;

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="My Classes"
        options={{ headerShown: false }}
        component={StudentClassesScreen}
      />
      <Stack.Screen
        name="Class"
        options={({ route }) => ({ title: route.params.name })}
        component={StudentClassScreen}
      ></Stack.Screen>
      <Stack.Screen
        name="Board"
        options={({ route }) => ({ title: route.params.name })}
        component={StudentBoardScreen}
      ></Stack.Screen>
    </Stack.Navigator>
  );
}
