import React from "react";

import { Flex, Switch, HStack, Surface } from "@react-native-material/core";
import { ScrollView, Text } from "react-native";
import SettingsCard from "../../components/cards/SettingsCard";
import SettingsGroup from "../../components/cards/SettingsGroup";
import CustomConfettiCannon from "../../components/animations/confetti/CustomConfettiCannon";
import { useSettings } from "../../components/contexts/SettingsContext";

export default function StaffSettingsScreen({ navigation }) {
  const { settings, setSettings } = useSettings();

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: "#f0eff4",
      }}
    >
      <SettingsGroup title="GENERAL">
        <SettingsCard
          title="Board tile size"
          type="number"
          minimumValue={0.75}
          maximumValue={1.5}
          step={0.05}
          value={settings.boardTileSize}
          setValue={(value) =>
            setSettings((prev) => ({ ...prev, boardTileSize: value }))
          }
        />
        <SettingsCard
          title="Class tile size"
          type="number"
          minimumValue={0.6}
          maximumValue={1.5}
          step={0.05}
          value={settings.classTileSize}
          setValue={(value) =>
            setSettings((prev) => ({ ...prev, classTileSize: value }))
          }
        />
      </SettingsGroup>
      <SettingsGroup title="VAULT OPTIONS">
        <SettingsCard
          value={settings.boardPublicByDefault}
          setValue={(value) =>
            setSettings((prev) => ({ ...prev, boardPublicByDefault: value }))
          }
          title="Board public by default"
        />
      </SettingsGroup>
      <SettingsGroup
        value={settings.getLitHidden}
        setValue={(value) =>
          setSettings((prev) => ({ ...prev, getLitHidden: value }))
        }
        title="BOARD OPTIONS"
      >
        <SettingsCard
          value={settings.animationsEnabled}
          setValue={(value) =>
            setSettings((prev) => ({ ...prev, animationsEnabled: value }))
          }
          title="GetLit animations enabled"
        />
        <SettingsCard
          title="Discussion comment max characters"
          type="number"
          step={50}
          minimumValue={50}
          maximumValue={2500}
          showDecimals={0}
          value={settings.maxCommentCharCount}
          setValue={(value) =>
            setSettings((prev) => ({ ...prev, maxCommentCharCount: value }))
          }
        />
        <SettingsCard
          title="Discussion reply max characters"
          type="number"
          step={50}
          minimumValue={50}
          maximumValue={2500}
          showDecimals={0}
          value={settings.maxReplyCharCount}
          setValue={(value) =>
            setSettings((prev) => ({ ...prev, maxReplyCharCount: value }))
          }
        />
      </SettingsGroup>
    </ScrollView>
  );
}
