import React from "react";

import {
  Box,
  Text,
  Button,
  Flex,
  TextInput,
  HStack,
  VStack,
  Switch,
} from "@react-native-material/core";

import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import Logo from "../graphics/Logo";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function PasswordResetForm(props) {
  const [email, setEmail] = React.useState(props.email ? props.email : "");
  const [code, setCode] = React.useState("");

  return (
    <Flex
      style={{ flex: props.medium ? "" : 1 }}
      fill={!props.small ? false : true}
      center
      bg={"#ffecd9"}
    >
      <VStack grow={1} maxW={500} w="100%" spacing={16} p={32}>
        <Flex mt={16}>
          <Logo />
        </Flex>
        <Text
          color="#333333"
          style={{
            fontWeight: "bold",
            fontSize: 44,
            paddingTop: 16,
            paddingBottom: 8,
          }}
        >
          Password Reset
        </Text>
        <Text
          color="#333333"
          style={{
            marginTop: -16,
            fontWeight: "500",
            paddingBottom: 16,
          }}
        >
          If an account exists, we sent a code to{" "}
          <Text color="#eb9940" style={{ fontWeight: "bold" }}>
            {email}
          </Text>
        </Text>
        <TextInput
          label="Enter the 6 digit code"
          style={{ marginTop: -4 }}
          inputStyle={{ backgroundColor: "white", borderRadius: 4.6 }}
          color="#eb9940"
          autoComplete="sms-otp"
          value={code}
          onChangeText={(value) => setCode(value)}
          placeholder="123456"
          keyboardType="numeric"
          placeholderTextColor={"#aaa"}
        ></TextInput>
        <Button
          disabled={code.length != 6}
          disableElevation
          tintColor="white"
          contentContainerStyle={{ height: 50 }}
          color="#eb9940"
          title="Continue"
          onPress={() => {
            props.navigation.navigate("New Password");
          }}
        ></Button>
        <HStack style={{}}>
          <Text style={{ paddingTop: 8 }} color="#ac9e91">
            Didn't recieve it?
          </Text>
          <Button
            onPress={() => {
              sendPasswordResetEmail(getAuth(), email);
            }}
            color="#eb9940"
            style={{}}
            titleStyle={{ fontWeight: "bold" }}
            variant="text"
            title="Resend Email"
            uppercase={false}
          ></Button>
        </HStack>
        <Button
          leading={<MaterialCommunityIcons name="arrow-left" size={18} />}
          titleStyle={{ fontSize: 12, color: "black" }}
          color="#eb9940"
          variant="text"
          title="Back to login"
          onPress={() => {
            props.navigation.navigate("Login");
          }}
        ></Button>
      </VStack>
    </Flex>
  );
}
