import React, { useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  Image,
  DeviceEventEmitter,
  TextInput,
} from "react-native";
import { Flex } from "@react-native-material/core";
import imgSrc from "../../assets/general/logo-big.png";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  updateDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";

export default function ChooseRoleForm({ small, navigation }) {
  const [role, setRole] = useState("");
  const [referralCode, setReferralCode] = useState("");

  const auth = getAuth();
  const db = getFirestore();

  const handleRoleSubmit = async () => {
    const user = auth.currentUser;
    const chosenRole = role;

    DeviceEventEmitter.emit("showLoadingIndicator");

    await updateDoc(doc(db, "users", user.uid), {
      role: chosenRole,
    });

    setTimeout(() => {
      DeviceEventEmitter.emit("hideLoadingIndicator");
      user.getIdToken(true);
      DeviceEventEmitter.emit("user-role-changed", chosenRole);
    }, 5000);
  };

  async function validateReferralCode() {
    const db = getFirestore();

    const codeQ = query(
      collection(db, "publicUsers"),
      where("referralCode", "==", referralCode.toLocaleUpperCase())
    );

    const codeSnapshot = await getDocs(codeQ);

    if (codeSnapshot.docs.length !== 0) {
      handleRoleSubmit();
    } else {
      alert("You have entered an incorrect referral code. Please try again.");
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <Flex center mt={16} w={"100%"} h={200} mb={16}>
          <Image
            resizeMode="contain"
            style={{
              width: "100%",
              height: "100%",
            }}
            source={imgSrc}
          />
        </Flex>
        <Text style={styles.title}>I am a...</Text>
        <TouchableOpacity
          style={role === "Student" ? styles.selectedButton : styles.button}
          onPress={() => setRole("Student")}
        >
          <Text
            style={
              role === "Student" ? styles.selectedButtonText : styles.buttonText
            }
          >
            Student
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            role === "Teacher" ? styles.selectedButton : styles.button,
            role == "Teacher" && {
              backgroundColor: "#722ed1",
            },
          ]}
          onPress={() => setRole("Teacher")}
        >
          <Text
            style={
              role === "Teacher" ? styles.selectedButtonText : styles.buttonText
            }
          >
            Teacher
          </Text>
        </TouchableOpacity>
        {role == "Teacher" && (
          <View
            style={{
              flexDirection: "column",
              paddingTop: 10,
              marginBottom: -10,
            }}
          >
            <Text
              style={{
                paddingBottom: 5,
                fontWeight: "bold",
              }}
            >
              Referral Code
            </Text>
            <Text
              style={{
                color: "#888",
                paddingBottom: 15,
              }}
            >
              Need a valid referral code? Request one today from{" "}
              <Text
                style={{ textDecorationLine: "underline", fontStyle: "italic" }}
              >
                go@goye.com
              </Text>
              .
            </Text>
            <TextInput
              maxLength={10}
              style={[styles.input]}
              inputStyle={{ backgroundColor: "white", borderRadius: 4.6 }}
              autoComplete="off"
              value={referralCode}
              onChangeText={(value) => setReferralCode(value)}
              placeholder="Enter a valid 10 digit referral code here..."
              placeholderTextColor={"#aaa"}
            ></TextInput>
          </View>
        )}
        <TouchableOpacity
          style={role ? styles.confirmButton : styles.disabledButton}
          onPress={() => {
            if (role == "Teacher") {
              validateReferralCode();
            } else if (role == "Student") {
              handleRoleSubmit();
            }
          }}
          disabled={!role}
        >
          <Text style={styles.selectedButtonText}>Confirm</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    //backgroundColor: "#ffecd9",
    flex: 1,
    maxWidth: 400,
    borderRadius: 5,
    padding: 32,
    justifyContent: "center",
    alignItems: "center",
  },
  innerContainer: {
    width: "100%",
  },
  input: {
    backgroundColor: "white",
    height: 40,
    borderColor: "#d9d9d9",
    borderWidth: 1,
    borderRadius: 4,
    marginBottom: 15,
    paddingLeft: 5,
    marginTop: -5,
  },
  title: {
    fontWeight: "bold",
    fontSize: 36,
    paddingVertical: 8,
    textAlign: "center",
    color: "#333333",
  },
  button: {
    marginVertical: 10,
    padding: 15,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#333333",
  },
  selectedButton: {
    marginVertical: 10,
    padding: 15,
    borderRadius: 5,
    backgroundColor: "#1677ff",
  },
  buttonText: {
    fontSize: 18,
    textAlign: "center",
    color: "#333333",
  },
  selectedButtonText: {
    fontSize: 18,
    textAlign: "center",
    color: "#ffffff",
  },
  confirmButton: {
    marginTop: 20,
    padding: 15,
    borderRadius: 5,
    backgroundColor: "#eb9940",
  },
  disabledButton: {
    marginTop: 20,
    padding: 15,
    borderRadius: 5,
    backgroundColor: "#cccccc",
  },
});
