import * as Font from "expo-font";

export const loadFonts = async () => {
  await Font.loadAsync({
    "chalkboard-font": require("../assets/fonts/Chalkboard-Regular.ttf"),
    "neon-font": require("../assets/fonts/marquee-moon.otf"),
    "hack-font": require("../assets/fonts/Hack-Regular.ttf"),
    "eb-garamond": require("../assets/fonts/EBGaramond-ExtraBold.ttf"),
  });
};
