import React, { useState } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  LayoutAnimation,
  Platform,
  UIManager,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import PopIn from "../../animations/visibility/PopIn";
import { changeColorAlpha } from "../../../utils/generateColor";
import { useEffect } from "react";
import { Surface } from "@react-native-material/core";

const Accordion = ({
  textColor = "#fff",
  title,
  content,
  defaultExpanded = false,
  color = "#ed9b40",
  style = {},
  delay = 0,
  innerStyle = {},
  surfaceStyle = {},
}) => {
  const [expanded, setExpanded] = useState(
    delay == 0 ? defaultExpanded : false
  );

  if (Platform.OS === "android") {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  const toggleExpand = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (delay > 0) {
      setTimeout(() => {
        setExpanded(defaultExpanded);
      }, delay);
    }
  }, []);

  return (
    <View style={[{ flexDirection: "column" }, style]}>
      <Surface elevation={2} style={[{ borderRadius: 5 }, surfaceStyle]}>
        <TouchableOpacity
          style={[
            styles.row,
            {
              backgroundColor: color,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            },
            innerStyle,
          ]}
          activeOpacity={0.8}
          onPress={toggleExpand}
        >
          <Text style={[styles.title, { color: textColor }]}>{title}</Text>
          <MaterialCommunityIcons
            name={expanded ? "chevron-up" : "chevron-down"}
            size={30}
            color={textColor}
          />
        </TouchableOpacity>
      </Surface>
      <PopIn isVisible={expanded}>{content}</PopIn>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 56,
    paddingLeft: 16,
    paddingRight: 18,
    fontWeight: "bold",
    alignItems: "center",
    backgroundColor: "#ed9b40",
    borderBottomColor: changeColorAlpha("#000000", 0.1),
    borderBottomWidth: 1,
  },
  parentHr: {
    height: 1,
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  content: {
    //backgroundColor: "#F8F8F8",
  },
});

export default Accordion;
