import * as React from "react";
import { useWindowDimensions } from "react-native";

import { Surface } from "@react-native-material/core";

import LoginHero from "./LoginHero";

import { ScrollView } from "react-native";
import { SafeAreaView } from "react-native";
import ChooseRoleForm from "../../components/account/ChooseRoleForm";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

export default function ChooseRoleScreen({ navigation }) {
  const { height, width } = useWindowDimensions();
  const [small, setSmall] = React.useState(null);

  const handleLayout = (event) => {
    const { width: layoutWidth } = event.nativeEvent.layout;
    setSmall(layoutWidth < 640);
  };

  return (
    <SafeAreaView
      onLayout={handleLayout}
      style={{
        backgroundColor: "white",
        minHeight: height,
      }}
    >
      <KeyboardAwareScrollView
        contentContainerStyle={{
          padding: 24,
          minHeight: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
        enableOnAndroid={true}
        extraScrollHeight={100} // (when scroll)to have extra height between keyboard and text input
        extraHeight={80} // make some height so the keyboard wont cover other component
      >
        <Surface
          elevation={5}
          style={{
            borderRadius: 5,
            margin: 12,
            flexDirection: "row",
            maxWidth: 1200,
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <ChooseRoleForm small={small} navigation={navigation} />
          <LoginHero small={small} />
        </Surface>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}
