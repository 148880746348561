import { HStack } from "@react-native-material/core";
import { getAuth } from "firebase/auth";
import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Modal,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import ColorPicker from "react-native-wheel-color-picker";
import {
  getFirestore,
  doc,
  addDoc,
  collection,
  updateDoc,
  query,
  where,
  getDoc,
  getDocs,
} from "firebase/firestore";

import { getPastelColor, pastelColors } from "../../../../utils/generateColor";

export function generateCode(length) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export async function createUniqueClassCode(db) {
  return new Promise(async (resolve, reject) => {
    let code;
    let codeIsUnique = false;

    while (!codeIsUnique) {
      code = generateCode(6); // Assuming you have a separate function to generate the code

      const q = query(collection(db, "classes"), where("code", "==", code));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.size === 0) {
        codeIsUnique = true;
        resolve(code);
      }
    }
  });
}

const AddClassPopup = ({
  visible,
  onSubmit,
  onClose,
  setToastMessage,
  setToastVisible,
  edit = false,
  initalClassName = "",
  initialStudentCount = "30",
  initialColor = "",
  editClassId = "",
}) => {
  const [className, setClassName] = useState(initalClassName);
  const [studentCount, setStudentCount] = useState(initialStudentCount);
  const [color, setColor] = useState(
    initialColor == "" ? getPastelColor() : initialColor
  );
  const [isLoading, setIsLoading] = useState(false);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  useEffect(() => {
    setColorPickerVisible(false);
    setTimeout(() => {
      setColorPickerVisible(true);
    }, 1000);
    setClassName(initalClassName);
    setStudentCount(initialStudentCount);
    setColor(initialColor);
  }, [visible]);

  async function addClass() {
    if (className && studentCount) {
      setIsLoading(true);

      const db = getFirestore();
      const auth = getAuth();

      const uid = auth.currentUser.uid;

      const teacherRef = doc(db, "users", uid);

      try {
        if (!edit) {
          const code = await createUniqueClassCode(db, className);

          await addDoc(collection(db, "classes"), {
            className,
            teacherId: teacherRef,
            color,
            studentCount,
            status: "active",
            code,
            activeStudents: 0,
          });
        } else {
          await updateDoc(doc(db, "classes", editClassId), {
            className,
            color,
            studentCount,
          });
        }

        setClassName("");
        setStudentCount("30");
        setColor(pastelColors[Math.floor(Math.random() * pastelColors.length)]);
        onSubmit(className, studentCount, color);
        setIsLoading(false);
        setToastMessage(`Class ${edit ? "updated" : "added"} successfully`);
        setToastVisible(Math.random());
      } catch (e) {
        console.error("Error adding document: ", e);

        setIsLoading(false);
        setToastMessage("An error occured, please try again later.");
        setToastVisible(Math.random());
      }
    }
  }

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View style={styles.header}>
            <Text style={styles.headerText}>
              {edit ? "Edit Class" : "Add a Class"}
            </Text>
            <TouchableOpacity onPress={onClose}>
              <Text style={styles.closeButton}>×</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.content}>
            <Text style={styles.label}>Class name</Text>
            <TextInput
              style={styles.input}
              onChangeText={setClassName}
              value={className}
              placeholderTextColor={"#aaa"}
            />
            <Text style={styles.label}>Student count</Text>
            <TextInput
              style={styles.input}
              onChangeText={setStudentCount}
              value={studentCount}
              keyboardType="number-pad"
              placeholderTextColor={"#aaa"}
            />
            <Text style={styles.label}>Color</Text>
            <View style={{ width: "100%", alignItems: "center" }}>
              {!colorPickerVisible && (
                <View style={{ paddingVertical: 132 }}>
                  <ActivityIndicator size="large" color="black" />
                </View>
              )}
              {colorPickerVisible && (
                <View style={{ width: "100%", maxWidth: 400, height: 300 }}>
                  <ColorPicker
                    color={color}
                    onColorChange={(color) => setColor(color)}
                    thumbSize={visible ? 45 : 50}
                    sliderSize={30}
                    noSnap={true}
                    row={false}
                    sliderHidden
                    swatches={false}
                  />
                  <View
                    style={{ flexDirection: "row", flexWrap: "wrap", gap: 5 }}
                  >
                    {pastelColors.map((_color, i) => (
                      <TouchableOpacity
                        onPressOut={() => setColor(_color)}
                        key={i}
                        style={{
                          width: 26,
                          height: 26,
                          backgroundColor: _color,
                          borderRadius: 2,
                        }}
                      />
                    ))}
                  </View>
                </View>
              )}
            </View>
          </View>
          <View style={styles.footer}>
            <TouchableOpacity
              style={[styles.button, styles.cancelButton]}
              onPress={onClose}
            >
              <Text style={styles.cancelButtonText}>Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={className === "" || studentCount === ""}
              style={[
                styles.button,
                styles.submitButton,
                (className === "" || studentCount === "") &&
                  !isLoading && {
                    opacity: 0.5,
                  },
                isLoading && {
                  opacity: 0.5,
                },
              ]}
              onPress={addClass}
            >
              <HStack style={[{ gap: 4, alignItems: "center" }]}>
                {isLoading && <ActivityIndicator size="small" color="white" />}
                <Text style={styles.buttonText}>
                  {edit ? "Confirm changes" : "Add Class"}
                </Text>
              </HStack>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 5,
    width: "100%",
    paddingHorizontal: 10,
    maxWidth: 800,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "#f0f0f0",
    paddingVertical: 10,
  },
  headerText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  closeButton: {
    fontSize: 22,
    fontWeight: "bold",
  },
  content: {
    paddingHorizontal: 0,
    paddingVertical: 20,
  },
  label: {
    fontWeight: "bold",
    marginBottom: 5,
  },
  input: {
    height: 40,
    borderColor: "#d9d9d9",
    borderWidth: 1,
    borderRadius: 4,
    marginBottom: 15,
    paddingLeft: 5,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    borderTopWidth: 1,
    borderColor: "#f0f0f0",
    padding: 15,
  },
  button: {
    borderRadius: 4,
    paddingHorizontal: 15,
    paddingVertical: 8,
  },
  cancelButton: {
    borderColor: "#ba3b46",
    borderWidth: 1,
    marginRight: 10,
  },
  submitButton: {
    backgroundColor: "#1890ff",
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
  },
  cancelButtonText: {
    color: "#ba3b46",
    fontWeight: "bold",
  },
  colorWheel: {
    width: 200,
    height: 200,
    marginBottom: 15,
  },
  palette: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  color: {
    width: 30,
    height: 30,
  },
  selectedColor: {
    borderWidth: 2,
    borderColor: "black",
  },
  colorPicker: {
    width: "100%",
    height: 300,
    marginBottom: 15,
    borderWidth: 2,
    borderColor: "transparent",
  },
  customColorButton: {
    marginBottom: 15,
  },
  customColorButtonText: {
    color: "#1890ff",
    textDecorationLine: "underline",
  },
});

export default AddClassPopup;
