import { initializeApp, getApps } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAbZL_cJcS-Lo7gd7NDrTFHkYRPMe79H3s",
  authDomain: "getlit-163b8.firebaseapp.com",
  projectId: "getlit-163b8",
  storageBucket: "getlit-163b8.appspot.com",
  messagingSenderId: "475932163263",
  appId: "1:475932163263:web:f63dd58c6dad789ee11a0a",
  measurementId: "G-9FMG1Z1CXS",
};

export function initializeFirebase() {
  if (getApps().length === 0) {
    initializeApp(firebaseConfig);
  }
}
