import React, { useEffect } from "react";

import { Flex, HStack, Surface, VStack } from "@react-native-material/core";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Fragment } from "react";
import FabGroup from "../../components/general/fabs/FabGroup";
import SquareGrid from "../../components/cards/Grid/SquareGrid";

import {
  getFirestore,
  doc,
  query,
  collection,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import CustomSegmentedControl from "../../components/data/input/CustomSegmentedControl";
import { Image, View } from "react-native";
import AutoPopIn from "../../components/animations/visibility/AutoPopIn";
import { Text } from "react-native";
import { useSettings } from "../../components/contexts/SettingsContext";

export default function StaffVaultAccountScreen({ navigation }) {
  const [boardData, setBoardData] = React.useState([]);
  const [reboardData, setReboardData] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [dataLoading, setDataLoading] = React.useState(true);
  const [refreshing, setRefreshing] = React.useState(false);
  const [reboards, setReboards] = React.useState("...");
  const [authorJoinDate, setAuthorJoinDate] = React.useState("");
  const [authorName, setAuthorName] = React.useState("");

  const authorDateObject = new Date(authorJoinDate).toLocaleDateString();

  const db = getFirestore();
  const auth = getAuth();
  const uid = auth.currentUser.uid;
  const userRef = doc(db, "users", uid);

  const authorAvatar = auth.currentUser.photoURL;

  useEffect(() => {
    setDataLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    setRefreshing(true);

    try {
      const authorQuery = await getDoc(doc(db, "publicUsers", uid));

      setAuthorJoinDate(
        authorQuery.data()?.creationTime
          ? authorQuery.data()?.creationTime
          : Date()
      );
      setReboards(authorQuery.data()?.reboards);

      const boardQuery = query(
        collection(db, "boards"),
        where("teacherId", "==", userRef),
        where("isPublic", "==", true)
      );

      const boardsSnapshot = await getDocs(boardQuery);

      const boards = boardsSnapshot.docs.map((doc) => {
        let tags = [
          `${doc.data()?.reboards ? doc.data()?.reboards : 0} reboard${
            doc.data()?.reboards !== 1 ? "s" : ""
          }$black$bold`,
          `${doc.data().vaultViews ? doc.data().vaultViews : 0} view${
            doc.data().vaultViews !== 1 ? "s" : ""
          }$black$300`,
          `${doc.data()?.sections ? doc.data()?.sections : 0} section${
            doc.data()?.sections !== 1 ? "s" : ""
          }$black$300`,
          `${doc.data().creationTimeStamp?.toDate().toDateString()}`,
        ];

        if (doc.data().modalityCount) {
          tags = [
            ...tags.slice(0, 2),
            `${doc.data().modalityCount} modalities$#666$300`,
            ...tags.slice(2),
          ];
        }

        return {
          title: doc.data().boardName,
          id: doc.id,
          backgroundColor: doc.data().color,
          tags,
          authorId: doc.data().teacherId.id,
        };
      });

      const reboardsQuery = query(
        collection(db, "reboards"),
        where("userId", "==", userRef)
      );

      const reboardsSnapshot = await getDocs(reboardsQuery);

      const boardPromises = reboardsSnapshot.docs.map(async (reboardDoc) => {
        const boardRef = reboardDoc.data().boardId;
        const boardDoc = await getDoc(boardRef);

        if (boardDoc.exists()) {
          let tags = [
            `${
              boardDoc.data()?.reboards ? boardDoc.data()?.reboards : 0
            } reboard${boardDoc.data()?.reboards !== 1 ? "s" : ""}$black$bold`,
            `${
              boardDoc.data().sections ? boardDoc.data().sections : 0
            } section${boardDoc.data().sections !== 1 ? "s" : ""}$black$300`,
            `${boardDoc.data().creationTimeStamp?.toDate().toDateString()}`,
          ];

          if (boardDoc.data().modalityCount) {
            tags = [
              ...tags.slice(0, 2),
              `${boardDoc.data().modalityCount} modalities$#666$300`,
              ...tags.slice(2),
            ];
          }

          return {
            title: boardDoc.data().boardName,
            id: boardDoc.id,
            backgroundColor: boardDoc.data().color,
            tags,
            authorId: boardDoc.data().teacherId.id,
          };
        }
      });

      const reboards = await Promise.all(boardPromises);

      setReboardData(reboards.filter((board) => board != null));
      setBoardData(boards);
    } catch (e) {
      console.error(e);
    }

    setRefreshing(false);
    setDataLoading(false);
  };

  const renderProfilePicture = React.useMemo(() => {
    if (authorAvatar !== null)
      return (
        <Image
          source={{ uri: authorAvatar }}
          style={{ width: 32, height: 32, borderRadius: 32 }}
        />
      );
    else
      return (
        <MaterialCommunityIcons
          name="account-circle"
          size={44}
          color={"#ccc"}
          style={{ marginLeft: 0 }}
        />
      );
  }, [authorAvatar]);

  const { settings } = useSettings();
  const boardTileSize = settings.boardTileSize ? settings.boardTileSize : 1;

  return (
    <Fragment>
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, backgroundColor: "#f0eff4" }}
      >
        <Flex fill p={16} style={{ gap: 16, paddingBottom: 91 }}>
          {
            <AutoPopIn>
              <Surface
                elevation={2}
                style={{
                  padding: 10,
                  backgroundColor: "white",
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                  alignItems: "center",
                  opacity: 1,
                  marginBottom: 5,
                }}
              >
                <HStack style={{ alignItems: "center", gap: 4 }}>
                  <VStack>
                    {authorJoinDate && (
                      <Text
                        numberOfLines={1}
                        style={{
                          fontSize: 12,
                          color: "#666",
                          fontStyle: "italic",
                        }}
                      >
                        Joined {authorDateObject}
                      </Text>
                    )}
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "right",
                        fontSize: 12,
                        color: "#444",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                    >
                      {reboards} reboards
                    </Text>
                  </VStack>
                  {renderProfilePicture}
                </HStack>
              </Surface>
            </AutoPopIn>
          }
          <View
            style={{ width: "100%", paddingHorizontal: 5, marginBottom: 5 }}
          >
            <CustomSegmentedControl
              color={"#eb9940"}
              textColor={"white"}
              values={["My Boards", "Reboards"]}
              selectedIndex={selectedIndex}
              onValueChange={(index) => {
                setSelectedIndex(index);
              }}
            />
          </View>
          <View style={{ display: selectedIndex == 0 ? "flex" : "none" }}>
            <SquareGrid
              key={boardTileSize}
              columnWidth={200 * boardTileSize}
              numberOfLines={200 * boardTileSize < 170 ? 1 : 3}
              canAdd={false}
              onCardPress={(item, e) => {
                navigation.navigate("Board", {
                  name: item.title,
                  boardId: item.id,
                  authorId: item.authorId,
                  color: item.backgroundColor,
                });
              }}
              data={boardData}
            />
          </View>
          <View style={{ display: selectedIndex == 1 ? "flex" : "none" }}>
            <SquareGrid
              canAdd={false}
              key={boardTileSize}
              columnWidth={200 * boardTileSize}
              numberOfLines={200 * boardTileSize < 170 ? 1 : 3}
              onCardPress={(item, e) => {
                navigation.navigate("Board", {
                  name: item.title,
                  boardId: item.id,
                  authorId: item.authorId,
                  color: item.backgroundColor,
                });
              }}
              data={reboardData}
            />
          </View>
        </Flex>
      </KeyboardAwareScrollView>
      <Fragment>
        <FabGroup
          buttons={[
            {
              icon: (
                <MaterialCommunityIcons
                  name="keyboard-return"
                  size={24}
                  color="black"
                />
              ),
              color: "white",
              onPress: () => navigation.goBack(),
            },
          ]}
        />
      </Fragment>
    </Fragment>
  );
}
