import {
  Chip,
  Divider,
  HStack,
  Surface,
  Switch,
} from "@react-native-material/core";
import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Modal,
  StyleSheet,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import BasicCarousel from "../../../cards/Carousel/BasicCarousel";
import CarouselModalityReviewCard from "../../../cards/Carousel/CarouselModalityReviewCard";

import BasicAccordion from "../../wrappers/BasicAccordion";

import {
  getFirestore,
  doc,
  collection,
  writeBatch,
  serverTimestamp,
  runTransaction,
  setDoc,
  query,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";

import { HttpsCallable, getFunctions, httpsCallable } from "firebase/functions";

import { Linking } from "react-native";
import ColorPicker from "react-native-wheel-color-picker";
import { getContrastTextColor } from "../../../../utils/generateColor";

import { pastelColors } from "../../../../utils/generateColor";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Spinner } from "native-base";
import { useSettings } from "../../../contexts/SettingsContext";

const AddBoardPopup = ({
  visible,
  onSubmit,
  onClose,
  classes,
  setToastMessage,
  setToastVisible,
  edit = false,
  initialBoardName = "",
  initialColor = "",
  initialClasses = [],
  initialSections = [],
  initialBoardId = "",
  initialIsPublic = false,
  initialTags = [],
  reboard = false,
  reboardId,
  reboardAuthorId,
  duplicateReboard = false,
  initialEditReboard = false,
}) => {
  const [boardName, setBoardName] = useState(initialBoardName);
  const [selectedClasses, setSelectedClasses] = useState(initialClasses);
  const [sections, setSections] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [color, setColor] = useState(initialColor);
  const [modalitiesToDelete, setModalitiesToDelete] = useState([]);
  const [sectionsToDelete, setSectionsToDelete] = useState([]);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [isPublic, setIsPublic] = useState(initialIsPublic);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [editReboard, setEditReboard] = useState(false);

  const { settings } = useSettings();
  const publicByDefault =
    settings.boardPublicByDefault == null
      ? true
      : settings.boardPublicByDefault;

  const sortedClasses = classes.sort((a, b) => a.title.localeCompare(b.title));

  useEffect(() => {
    resetVariables();
    setColorPickerVisible(false);
    setTimeout(() => {
      setColorPickerVisible(true);
    }, 1000);
  }, [visible, edit]);

  const resetVariables = () => {
    if (reboard) {
      setSections(
        initialSections.map((section) => ({ ...section, newModalities: [] }))
      );
      setSelectedClasses(initialClasses);
      setColor(initialColor);
      setBoardName(initialBoardName);
      setIsPublic(false);
      setCurrentPage(0);
      setTags([]);
      setTagInput("");
      setSelectedClasses([]);
    } else {
      if (edit) {
        setSections(
          initialSections.map((section) => ({ ...section, newModalities: [] }))
        );
        setSelectedClasses(initialClasses);
        setColor(initialColor);
        setBoardName(initialBoardName);
        setIsPublic(initialIsPublic);
        setCurrentPage(0);
        setTags(initialTags);
        setTagInput("");
        setEditReboard(initialEditReboard);
      } else {
        setSections([]);
        setSelectedClasses(initialClasses);
        setColor(pastelColors[Math.floor(Math.random() * pastelColors.length)]);
        setBoardName("");
        setCurrentPage(0);
        setIsPublic(publicByDefault);
        setTags([]);
        addSection();
        setTagInput("");
        setEditReboard(false);
      }
    }

    setIsLoading(false);
  };

  const handleClassToggle = (_newClass) => {
    setSelectedClasses((prevSelectedClasses) => {
      const newval = prevSelectedClasses
        .map((_class) => _class.title)
        .includes(_newClass.title)
        ? prevSelectedClasses.filter(
            (_class) => _class.title !== _newClass.title
          )
        : [...prevSelectedClasses, _newClass];
      return newval;
    });
  };

  const addSection = () => {
    setSections((prevSections) => [
      ...prevSections,
      {
        title: "",
        text: "",
        id: Date.now(),
        modalities: [],
        newModalities: [],
        isNew: true,
      },
    ]);
  };

  const removeSection = (id) => {
    setSections((prevSections) => prevSections.filter((s) => s.id !== id));
    if (!sections.find((s) => s.id === id).isNew) {
      setSectionsToDelete((prevSections) => [...prevSections, id]);
      let modalitiesToDelete = [];
      sections
        .find((s) => s.id === id)
        .modalities.forEach((m) => {
          if (!m.isNew) modalitiesToDelete.push(m.id);
        });

      setModalitiesToDelete((prevModalities) => [
        ...prevModalities,
        ...modalitiesToDelete,
      ]);
    }
  };

  const updateSection = (id, field, value) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      )
    );
  };

  const handleNextPage = () => {
    if (currentPage < 2) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const db = getFirestore();
    const auth = getAuth();

    const uid = auth.currentUser.uid;

    const teacherRef = doc(db, "users", uid);

    let modalityCount = 0;
    sections.forEach(
      (_section) => (modalityCount += _section.modalities.length)
    );

    try {
      if (!edit) {
        try {
          //create a batch
          const batch = writeBatch(db);

          const boardRef = doc(collection(db, "boards"));

          let sectionIds = [];

          sections.forEach((section, i) => {
            const sectionRef = doc(collection(db, "sections"));

            let modalityIds = [];

            section.modalities.forEach((modality, i) => {
              // Get a new reference for each new modality
              const modalityRef = doc(collection(db, "modalities"));

              modalityIds.push(modalityRef.id);

              // Set the data for the new document in the batch
              batch.set(modalityRef, {
                link: modality.link,
                imgLink: modality.imgLink,
                dislikeCount: 0,
                type: modality.type ? modality.type : "web",
                likeCount: 0,
                favCount: 0,
                sectionId: doc(db, "sections", sectionRef.id),
                boardId: doc(db, "boards", boardRef.id),
                timeStamp: serverTimestamp(),
                title: modality.title ? modality.title : "",
                teacherId: teacherRef,
              });
            });

            sectionIds.push(sectionRef.id);

            batch.set(sectionRef, {
              title: section.title == "" ? "Section " + (i + 1) : section.title,
              index: i,
              text: section.text,
              boardId: doc(db, "boards", boardRef.id),
              comments: 0,
              teacherId: teacherRef,
            });
          });

          batch.set(boardRef, {
            boardName,
            engagement: 0,
            teacherId: teacherRef,
            classIds: sortedClasses.map((c) => doc(db, "classes", c.id)),
            favorites: 0,
            views: 0,
            replies: 0,
            reboards: 0,
            creationTimeStamp: serverTimestamp(),
            lastUpdatedTimeStamp: serverTimestamp(),
            isPublic,
            tags,
            color,
            sections: sections.length,
            isReboard: reboard,
            modalityCount,
            lastAccessed: serverTimestamp(),
          });

          if (reboard) {
            const reboardRef = doc(db, "boards", reboardId);
            if (!duplicateReboard) {
              try {
                await runTransaction(db, async (transaction) => {
                  const reBoardDoc = await transaction.get(reboardRef);
                  if (!reBoardDoc.exists()) {
                    throw "Document does not exist!";
                  }

                  const newReboardCount = reBoardDoc.data().reboards
                    ? reBoardDoc.data().reboards + 1
                    : 1;
                  transaction.update(reboardRef, {
                    reboards: newReboardCount,
                  });
                });
              } catch (e) {
                console.error("Transaction failed: ", e);
              }
              const authorRef = doc(db, "users", reboardAuthorId);
              try {
                await runTransaction(db, async (transaction) => {
                  const authorDoc = await transaction.get(authorRef);
                  if (!authorDoc.exists()) {
                    throw "Document does not exist!";
                  }

                  const newReboardCount = authorDoc.data().reboards
                    ? authorDoc.data().reboards + 1
                    : 1;
                  transaction.update(authorRef, {
                    reboards: newReboardCount,
                  });
                });
              } catch (e) {
                console.error("Transaction failed: ", e);
              }
            }

            const userReboardRef = doc(collection(db, "reboards"));
            const reboardDoc = {
              userId: doc(db, "users", uid),
              boardId: reboardRef,
              teacherId: doc(db, "users", reboardAuthorId),
              timeStamp: serverTimestamp(),
            };

            await setDoc(userReboardRef, reboardDoc);
          }

          // Commit the batch
          batch
            .commit()
            .then(() => {
              //console.("Batched write completed successfully");
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error during batch write: ", error);
            });
        } catch (e) {
          console.error(e);
          setIsLoading(false);
        }
      } else {
        try {
          //create a batch
          const batch = writeBatch(db);

          const boardRef = doc(db, "boards", initialBoardId);

          sections.forEach((section, i) => {
            const sectionRef = section.isNew
              ? doc(collection(db, "sections"))
              : doc(db, "sections", section.id);

            let modalityIds = [];

            section.modalities.forEach((modality, i) => {
              if (modality.isNew) {
                // Get a new reference for each new modality
                const modalityRef = doc(collection(db, "modalities"));

                modalityIds.push(modalityRef.id);

                // Set the data for the new document in the batch
                batch.set(modalityRef, {
                  teacherId: teacherRef,
                  link: modality.link,
                  imgLink: modality.imgLink,
                  type: modality.type ? modality.type : "web",
                  dislikeCount: 0,
                  likeCount: 0,
                  favCount: 0,
                  sectionId: doc(db, "sections", sectionRef.id),
                  boardId: doc(db, "boards", boardRef.id),
                  timeStamp: serverTimestamp(),
                  title: modality.title ? modality.title : "",
                });
              }
            });

            if (section.isNew)
              batch.set(sectionRef, {
                title:
                  section.title == "" ? "Section " + (i + 1) : section.title,
                index: i,
                text: section.text,
                boardId: doc(db, "boards", boardRef.id),
                teacherId: teacherRef,
              });
            else
              batch.update(sectionRef, {
                title: section.title,
                index: i,
                text: section.text,
              });
          });

          batch.update(boardRef, {
            boardName,
            classIds: sortedClasses.map((c) => doc(db, "classes", c.id)),
            lastUpdatedTimeStamp: serverTimestamp(),
            isPublic,
            tags,
            color,
            sections: sections.length,
            modalityCount,
            lastAccessed: serverTimestamp(),
          });

          //delete old modelities marked for removal
          modalitiesToDelete.forEach((id) => {
            const modalityRef = doc(db, "modalities", id);
            batch.delete(modalityRef);
          });

          //delete old sections marked for removal
          sectionsToDelete.forEach((id) => {
            const sectionRef = doc(db, "sections", id);
            batch.delete(sectionRef);
          });

          // Commit the batch
          batch
            .commit()
            .then(() => {
              //console.("Batched write completed successfully");
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error during batch write: ", error);
            });
        } catch (e) {
          console.error(e);
          setIsLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
    }

    onSubmit();
    resetVariables();
    setIsLoading(false);
    if (!reboard)
      setToastMessage(
        "Board " + (edit ? "edited" : "created") + " successfully"
      );
    else
      setToastMessage(
        initialBoardName +
          " sucessfully reboarded and added to your boards page"
      );
    setToastVisible(Math.random());
  };

  function handleCardPress(link) {
    if (Linking.canOpenURL(link)) Linking.openURL(link);
    else alert("Link is not valid.");
  }

  function handleCardApprovePress(sectionIndex, modalityId) {
    setSections((prevSections) =>
      prevSections.map((section, i) => {
        return i === sectionIndex
          ? {
              ...section,
              modalities: [
                ...section.modalities,
                section.newModalities.find((m, i) => m.id == modalityId),
              ],
              newModalities: section.newModalities.filter(
                (m, i) => m.id !== modalityId
              ),
            }
          : section;
      })
    );
  }

  function handleCardDeletePress(sectionIndex, modalityId, isNew) {
    setSections((prevSections) =>
      prevSections.map((section, i) => {
        return i === sectionIndex
          ? {
              ...section,
              newModalities: section.newModalities.filter(
                (m, i) => m.id !== modalityId
              ),
              modalities: section.modalities.filter(
                (m, i) => m.id !== modalityId
              ),
            }
          : section;
      })
    );

    if (!isNew) {
      setModalitiesToDelete((prevModalities) => [
        ...prevModalities,
        modalityId,
      ]);
    }
  }

  async function generateMoreModalities(sectionId) {
    setSections((prevSections) =>
      prevSections.map((section, i) => {
        return section.id === sectionId
          ? {
              ...section,
              modalitiesLoading: true,
            }
          : section;
      })
    );

    const section = sections.find((_section) => _section.id == sectionId);

    const functionsInstance = getFunctions();

    try {
      const getModalities = httpsCallable(functionsInstance, "modalityAPI");

      const result = await getModalities({
        title: section.title,
        content: section.text,
      });

      const data = result.data.data;

      setTags((prev) => {
        const allTags = new Set([...prev, ...result.data.allTags]);
        return [...allTags];
      });

      setSections((prevSections) =>
        prevSections.map((section, i) => {
          return section.id === sectionId
            ? {
                ...section,
                modalitiesLoading: false,
                newModalities: [
                  ...section.newModalities,
                  ...data
                    .map((value) => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value)
                    .map((modality, index) => ({
                      ...modality,
                      id: index * Math.random() * 99999,
                    })),
                ],
              }
            : section;
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  const addTag = () => {
    if (tagInput.length > 0) {
      setTags([...tags, ...tagInput.split(",").map((tag) => tag.trim())]);
      setTagInput("");
    }
  };

  const removeTag = (index) => {
    setTags([...tags.slice(0, index), ...tags.slice(index + 1)]);
  };

  const textColor = getContrastTextColor(color);

  const tagInputRef = useRef(null);

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={() => {
        onClose();
      }}
    >
      <View style={styles.centeredView}>
        {currentPage == 0 && (
          <View style={styles.modalView}>
            <View style={styles.header}>
              <Text style={styles.headerText}>
                {edit ? "Edit" : "Create"} Board
              </Text>
              <TouchableOpacity
                onPress={() => {
                  onClose();
                }}
              >
                <Text style={styles.closeButton}>×</Text>
              </TouchableOpacity>
            </View>
            <Divider style={{ width: "100%" }} />
            <KeyboardAwareScrollView
              style={{ width: "100%" }}
              contentContainerStyle={styles.content}
              scrollEnabled={scrollEnabled}
            >
              <Text style={styles.label}>Board Name</Text>
              <View style={{ flex: 1 }}>
                <TextInput
                  placeholderTextColor={"#aaa"}
                  placeholder="Enter a fitting name for the board..."
                  autoFocus
                  style={styles.input}
                  value={boardName}
                  onChangeText={setBoardName}
                />
              </View>
              <Text style={styles.label}>Classes</Text>
              <View style={styles.classes}>
                {sortedClasses.map((_class) => {
                  return (
                    <TouchableOpacity
                      key={_class.id}
                      style={[
                        styles.classButton,
                        selectedClasses
                          .map((c) => c.title)
                          .includes(_class.title) && styles.selectedClass,
                      ]}
                      onPress={() => handleClassToggle(_class)}
                    >
                      <Text
                        style={
                          selectedClasses
                            .map((c) => c.title)
                            .includes(_class.title)
                            ? styles.selectedClassButtonText
                            : styles.classButtonText
                        }
                      >
                        {_class.title}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </View>
              <View style={{ width: "100%", alignItems: "center" }}>
                {!colorPickerVisible && (
                  <View style={{ paddingVertical: 132 }}>
                    <ActivityIndicator size="large" color="black" />
                  </View>
                )}
                {colorPickerVisible && (
                  <View style={{ width: "100%", maxWidth: 400, height: 300 }}>
                    <ColorPicker
                      color={color}
                      onColorChange={(color) => setColor(color)}
                      thumbSize={visible ? 45 : 50}
                      sliderSize={30}
                      noSnap={true}
                      row={false}
                      sliderHidden
                      swatches={false}
                      onColorChangeComplete={() => setScrollEnabled(true)}
                      onInteractionStart={() => setScrollEnabled(false)}
                    />
                    <View
                      style={{ flexDirection: "row", flexWrap: "wrap", gap: 5 }}
                    >
                      {pastelColors.map((_color, i) => (
                        <TouchableOpacity
                          onPressOut={() => setColor(_color)}
                          key={i}
                          style={{
                            width: 26,
                            height: 26,
                            backgroundColor: _color,
                            borderRadius: 2,
                          }}
                        />
                      ))}
                    </View>
                  </View>
                )}
              </View>
              <Text style={[styles.label, { paddingBottom: 5, marginTop: 20 }]}>
                Sections
              </Text>
              <Divider style={{ width: "100%", marginBottom: 10 }} />
              {sections.map((section, index) => {
                return (
                  <BasicAccordion
                    key={section.id}
                    color={color}
                    textColor={textColor}
                    style={{ paddingBottom: 5 }}
                    title={`Section ${index + 1}`}
                    defaultExpanded={index == 0 || section.isNew}
                    content={
                      <Surface
                        elevation={2}
                        key={section.id}
                        style={styles.sectionCard}
                      >
                        <HStack justify="between" items="center" pb={5}>
                          <Text style={styles.label}>Title</Text>
                          <TouchableOpacity
                            onPress={() => removeSection(section.id)}
                          >
                            <Text style={styles.removeSectionText}>
                              Remove Section
                            </Text>
                          </TouchableOpacity>
                        </HStack>
                        <TextInput
                          style={styles.input}
                          value={section.title}
                          placeholderTextColor={"#aaa"}
                          onChangeText={(text) =>
                            updateSection(section.id, "title", text)
                          }
                          placeholder="Enter a fitting title for the section..."
                        />
                        <Text style={styles.label}>Text</Text>
                        <TouchableWithoutFeedback>
                          <TextInput
                            multiline
                            placeholder="Write or paste content for this section here..."
                            style={styles.multilineInput}
                            value={section.text}
                            onChangeText={(text) =>
                              updateSection(section.id, "text", text)
                            }
                            placeholderTextColor={"#aaa"}
                          />
                        </TouchableWithoutFeedback>
                        <Divider style={styles.divider} />
                      </Surface>
                    }
                  />
                );
              })}
              <TouchableOpacity style={[styles.addButton]} onPress={addSection}>
                <Text style={[styles.addButtonText]}>Add new section</Text>
              </TouchableOpacity>
              <View style={{ height: 5 }} />
            </KeyboardAwareScrollView>
            <Divider style={{ width: "100%" }} />
            <View style={styles.actions}>
              <TouchableOpacity
                style={[
                  styles.button,
                  styles.cancelButton,
                  {
                    backgroundColor: "white",
                    borderColor: "#f5222d",
                    borderWidth: 1,
                  },
                ]}
                onPress={() => {
                  resetVariables();
                  onClose();
                }}
              >
                <Text style={[styles.buttonText, { color: "#f5222d" }]}>
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                disabled={
                  sections.filter((section) => section.text == "").length > 0 ||
                  boardName == "" ||
                  sections.length == 0
                }
                style={[
                  styles.button,
                  styles.addBoardButton,
                  (sections.filter((section) => section.text == "").length >
                    0 ||
                    boardName == "" ||
                    sections.length == 0) && { opacity: 0.5 },
                ]}
                onPress={handleNextPage}
              >
                <Text style={styles.continueButtonText}>Continue</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {currentPage == 1 && (
          // Page 2
          <View style={styles.modalView}>
            <View style={styles.header}>
              <Text style={styles.headerText}>
                {edit ? "Edit" : "Create"} Board
              </Text>
              <TouchableOpacity
                onPress={() => {
                  resetVariables();
                  onClose();
                }}
              >
                <Text style={styles.closeButton}>×</Text>
              </TouchableOpacity>
            </View>
            <KeyboardAwareScrollView
              style={{ width: "100%" }}
              contentContainerStyle={styles.content}
            >
              <Text style={[styles.label, { paddingBottom: 10 }]}>
                Choose modalities
              </Text>
              <Divider style={{ width: "100%", marginBottom: 20 }} />
              {sections.map((section, index) => {
                return (
                  <BasicAccordion
                    color={color}
                    textColor={textColor}
                    key={section.id}
                    defaultExpanded={index == 0}
                    title={`Section ${index + 1}`}
                    style={{ paddingBottom: 5 }}
                    content={
                      <Surface
                        elevation={2}
                        key={section.id}
                        style={styles.sectionCard}
                      >
                        <HStack justify="between" items="center" pb={5}>
                          <Text style={styles.label}>Title</Text>
                          <TouchableOpacity
                            onPress={() => removeSection(section.id)}
                          >
                            <Text style={[styles.removeSectionText]}>
                              Remove section
                            </Text>
                          </TouchableOpacity>
                        </HStack>
                        <TextInput
                          style={styles.input}
                          value={
                            section.title == ""
                              ? "Section " + (index + 1)
                              : section.title
                          }
                          onChangeText={(text) =>
                            updateSection(section.id, "title", text)
                          }
                          placeholderTextColor={"#aaa"}
                        />
                        <HStack justify="between" items="center" pb={10}>
                          <Text style={[styles.label]}>
                            {"(" + section.newModalities?.length + ")"} Review
                            new modalities
                          </Text>{" "}
                          {section.newModalities?.length !== 0 && (
                            <TouchableOpacity
                              onPress={() => removeSection(section.id)}
                            >
                              <Text
                                onPress={() =>
                                  section.newModalities?.forEach(
                                    (modality, i) =>
                                      handleCardDeletePress(
                                        index,
                                        modality.id,
                                        modality.isNew
                                      )
                                  )
                                }
                                style={[styles.removeSectionText]}
                              >
                                Reject all
                              </Text>
                            </TouchableOpacity>
                          )}
                        </HStack>
                        {section.newModalities?.length != 0 ? (
                          section.newModalities?.length > 1 ? (
                            <View
                              style={{
                                width: "100%",
                                alignItems: "center",
                                height: 520,
                              }}
                            >
                              <View style={{ maxWidth: 1000, width: "100%" }}>
                                <BasicCarousel
                                  autoPlay={false}
                                  height={520}
                                  data={section.newModalities}
                                  renderItem={(card, i) => (
                                    <CarouselModalityReviewCard
                                      index={i}
                                      id={card.id}
                                      key={i}
                                      {...card}
                                      section={index}
                                      onCardPress={handleCardPress}
                                      onCardDeletePress={handleCardDeletePress}
                                      onCardApprovePress={
                                        handleCardApprovePress
                                      }
                                      needsReview={true}
                                    />
                                  )}
                                />
                              </View>
                            </View>
                          ) : (
                            <View
                              style={{
                                flex: 1,
                              }}
                            >
                              <View
                                style={{
                                  minWidth: 300,
                                  maxWidth: 1000,
                                  paddingBottom: 20,
                                }}
                              >
                                <CarouselModalityReviewCard
                                  index={0}
                                  id={0}
                                  {...section.newModalities[0]}
                                  section={index}
                                  onCardPress={handleCardPress}
                                  onCardDeletePress={handleCardDeletePress}
                                  onCardApprovePress={handleCardApprovePress}
                                  needsReview={true}
                                />
                              </View>
                            </View>
                          )
                        ) : (
                          <TouchableOpacity
                            style={[
                              styles.button,
                              styles.addBoardButton,
                              {
                                marginBottom: 10,
                                backgroundColor: "white",
                                borderWidth: 1,
                                borderStyle: "dashed",
                                borderColor: "#1890ff",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 4,
                              },
                            ]}
                            onPress={() => generateMoreModalities(section.id)}
                          >
                            <Text
                              style={[
                                styles.continueButtonText,
                                { color: "#1890ff" },
                              ]}
                            >
                              {section.modalitiesLoading
                                ? "Generating"
                                : section.modalities.length == 0
                                ? "✨Generate modalities✨"
                                : "✨Generate more modalities✨"}
                            </Text>
                            {section.modalitiesLoading && <Spinner />}
                          </TouchableOpacity>
                        )}
                        <HStack justify="between" items="center" pb={10}>
                          <Text style={[styles.label]}>
                            {"(" + section.modalities.length + ")"} Confirmed
                            modalities
                          </Text>{" "}
                          <TouchableOpacity
                            onPress={() => removeSection(section.id)}
                          >
                            <Text
                              onPress={() =>
                                section.modalities.forEach((modality, i) =>
                                  handleCardDeletePress(
                                    index,
                                    modality.id,
                                    modality.isNew
                                  )
                                )
                              }
                              style={[styles.removeSectionText]}
                            >
                              Remove all
                            </Text>
                          </TouchableOpacity>
                        </HStack>
                        {section.modalities.length != 0 ? (
                          section.modalities.length > 1 ? (
                            <View
                              style={{
                                width: "100%",
                                alignItems: "center",
                                height: 520,
                              }}
                            >
                              <View style={{ maxWidth: 1000, width: "100%" }}>
                                <BasicCarousel
                                  autoPlay={false}
                                  height={520}
                                  data={section.modalities}
                                  renderItem={(card, i) => (
                                    <CarouselModalityReviewCard
                                      index={i}
                                      id={card.id}
                                      {...card}
                                      section={index}
                                      onCardPress={handleCardPress}
                                      onCardDeletePress={handleCardDeletePress}
                                      showApprove={false}
                                    />
                                  )}
                                />
                              </View>
                            </View>
                          ) : (
                            <View
                              style={{
                                flex: 1,
                              }}
                            >
                              <View
                                style={{
                                  minWidth: 300,
                                  maxWidth: 1000,
                                  paddingBottom: 20,
                                }}
                              >
                                <CarouselModalityReviewCard
                                  index={0}
                                  id={0}
                                  {...section.modalities[0]}
                                  section={index}
                                  onCardPress={handleCardPress}
                                  onCardDeletePress={handleCardDeletePress}
                                  showApprove={false}
                                />
                              </View>
                            </View>
                          )
                        ) : null}
                      </Surface>
                    }
                  ></BasicAccordion>
                );
              })}
              {sections.length == 0 && (
                <Text
                  style={[
                    {
                      paddingBottom: 10,
                      fontStyle: "italic",
                      fontWeight: "400",
                      color: "red",
                      paddingLeft: 10,
                    },
                  ]}
                >
                  No Sections
                </Text>
              )}
            </KeyboardAwareScrollView>
            <View style={styles.actions}>
              <TouchableOpacity
                style={[
                  styles.button,
                  styles.cancelButton,
                  {
                    backgroundColor: "white",
                    borderColor: "#f5222d",
                    borderWidth: 1,
                  },
                ]}
                onPress={() => {
                  resetVariables();
                  onClose();
                }}
              >
                <Text style={[styles.buttonText, { color: "#f5222d" }]}>
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.backButton]}
                onPress={handlePrevPage}
              >
                <Text style={styles.backButtonText}>Back</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.addBoardButton]}
                onPress={handleNextPage}
              >
                <HStack style={[{ gap: 4, alignItems: "center" }]}>
                  {isLoading && (
                    <ActivityIndicator size="small" color="white" />
                  )}
                  <Text style={styles.continueButtonText}>Continue</Text>
                </HStack>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {currentPage == 2 && (
          // Page 3
          <View style={styles.modalView}>
            <View style={styles.header}>
              <Text style={styles.headerText}>
                {edit ? "Edit" : "Create"} Board
              </Text>
              <TouchableOpacity
                onPress={() => {
                  resetVariables();
                  onClose();
                }}
              >
                <Text style={styles.closeButton}>×</Text>
              </TouchableOpacity>
            </View>
            <KeyboardAwareScrollView
              style={{ width: "100%" }}
              contentContainerStyle={styles.content}
            >
              <Text
                style={[
                  styles.label,
                  { paddingBottom: 10, paddingHorizontal: 2 },
                ]}
              >
                Vault options
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  maxWidth: 500,
                  width: "100%",
                  marginBottom: 10,
                  marginHorizontal: 2,
                  paddingRight: 5,
                }}
              >
                <Text style={{}}>
                  Discoverable in Vault {"("}public{")"}
                </Text>
                <Switch
                  disabled={reboard || editReboard}
                  value={isPublic}
                  onValueChange={setIsPublic}
                />
              </View>
              {(reboard || editReboard) && (
                <Text style={{ color: "#ff4d4f", fontStyle: "italic" }}>
                  Reboards are not available for publicization.
                </Text>
              )}
              {!(reboard || editReboard) && (
                <Surface
                  style={{
                    borderRadius: 5,
                    backgroundColor: "white",
                    width: "100%",
                    marginHorizontal: 2,
                  }}
                  elevation={2}
                >
                  <TextInput
                    ref={tagInputRef}
                    inputMode="text"
                    style={[{ height: 40, borderRadius: 5, paddingLeft: 10 }]}
                    placeholderTextColor={"#aaa"}
                    value={tagInput}
                    onChangeText={setTagInput}
                    onSubmitEditing={() => {
                      addTag();
                      setTimeout(() => {
                        tagInputRef.current.focus();
                      }, 100);
                    }}
                    placeholder="Add a tag..."
                  ></TextInput>
                  <View
                    style={{
                      flexWrap: "wrap",
                      flexDirection: "row",
                      paddingBottom: 5,
                    }}
                  >
                    {tags.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag + " x"}
                        style={{ margin: 4 }}
                        onPress={() => removeTag(index)}
                      />
                    ))}
                  </View>
                </Surface>
              )}
              <Divider
                style={{ width: "100%", marginBottom: 20, marginTop: 10 }}
              />
              {edit && (
                <Text
                  style={{
                    width: "100%",
                    textAlign: "right",
                    color: "#444",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  {0} reboards
                </Text>
              )}
            </KeyboardAwareScrollView>
            <View style={styles.actions}>
              <TouchableOpacity
                style={[
                  styles.button,
                  styles.cancelButton,
                  {
                    backgroundColor: "white",
                    borderColor: "#f5222d",
                    borderWidth: 1,
                  },
                ]}
                onPress={() => {
                  resetVariables();
                  onClose();
                }}
              >
                <Text style={[styles.buttonText, { color: "#f5222d" }]}>
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.backButton]}
                onPress={handlePrevPage}
              >
                <Text style={styles.backButtonText}>Back</Text>
              </TouchableOpacity>
              <TouchableOpacity
                disabled={sections.length == 0 || boardName == ""}
                style={[
                  styles.button,
                  styles.addBoardButton,
                  (sections.length == 0 && !isLoading) ||
                    ((sections.length == 0 || boardName == "") && {
                      opacity: 0.5,
                    }),
                  isLoading && {
                    opacity: 0.5,
                  },
                ]}
                onPress={handleSubmit}
              >
                <HStack style={[{ gap: 4, alignItems: "center" }]}>
                  {isLoading && (
                    <ActivityIndicator size="small" color="white" />
                  )}
                  <Text style={styles.continueButtonText}>
                    {reboard ? "Reboard" : !edit ? "Create board" : "Confirm"}
                  </Text>
                </HStack>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalView: {
    width: "100%",
    margin: 0,
    maxWidth: 800,
    backgroundColor: "white",
    borderRadius: 4,
    paddingHorizontal: 10,
    paddingVertical: 20,
    justifyContent: "space-between",
    alignItems: "center",
    height: "90%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "#f0f0f0",
    padding: 0,
    width: "100%",
  },
  headerText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  closeButton: {
    fontSize: 22,
    fontWeight: "bold",
  },
  label: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 15,
  },
  input: {
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 10,
    marginTop: 5,
    width: "100%",
  },
  multilineInput: {
    minHeight: 200,
    height: "auto",
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 10,
    marginTop: 5,
    width: "100%",
    paddingVertical: 8,
  },
  content: {
    marginHorizontal: 0,
  },
  classes: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 5,
  },
  classButton: {
    backgroundColor: "#f0f0f0",
    borderRadius: 4,
    paddingHorizontal: 10,
    paddingVertical: 5,
    margin: 5,
  },
  selectedClass: {
    backgroundColor: "#1890ff",
  },
  classButtonText: {
    color: "black",
  },
  selectedClassButtonText: {
    color: "white",
    fontWeight: "500",
  },
  section: {
    marginTop: 20,
  },
  sectionIndex: {
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 5, // Lessen the padding below the section number
  },
  addButton: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#ed9b40",
    borderStyle: "dashed",
    paddingHorizontal: 15,
    paddingVertical: 15,
    marginTop: 5,
  },
  addButtonText: {
    color: "#ed9b40",
    fontWeight: "700",
  },
  actions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: 15,
  },
  button: {
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 4,
  },
  cancelButton: {
    backgroundColor: "#f0f0f0",
    marginRight: 10,
  },
  continueButton: {
    backgroundColor: "#ed9b40",
  },
  addBoardButton: {
    backgroundColor: "#1890ff",
  },
  buttonText: {
    color: "black",
  },
  continueButtonText: {
    color: "white",
    fontWeight: "600",
  },
  divider: {
    marginTop: 10,
  },
  removeSectionText: {
    color: "#ff4d4f",
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 15,
  },
  backButton: {
    backgroundColor: "#f0f0f0",
    marginRight: 10,
  },
  backButtonText: {
    color: "black",
  },
  sectionCard: {
    margin: 2,
    padding: 10,
    backgroundColor: "white",
  },
  blackBox: {
    width: "100%",
    height: 100,
    backgroundColor: "black",
  },
});

export default AddBoardPopup;
