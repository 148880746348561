import * as React from "react";
import {
  Text,
  RefreshControl,
  useWindowDimensions,
  DeviceEventEmitter,
  Platform,
} from "react-native";

import ConfettiCannon from "react-native-confetti-cannon";

import { Flex } from "@react-native-material/core";
import BoardSection from "../../components/discussion/BoardSection";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import CustomConfettiCannon from "../../components/animations/confetti/CustomConfettiCannon";
import FabGroup from "../../components/general/fabs/FabGroup";
import FlameAnimation from "../../components/animations/favorite/FlameAnimation";
import { Dimensions } from "react-native";
import SimpleToast from "../../components/animations/toasts/SimpleToast";
import AddBoardPopup from "../../components/data/input/Modals/AddBoardPopup";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect } from "react";

import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  getDocs,
  orderBy,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { Spinner } from "native-base";
import { KeyboardAwareFlatList } from "react-native-keyboard-aware-scroll-view";
import { useSettings } from "../../components/contexts/SettingsContext";

export default function StaffBoardScreen({ navigation }) {
  const [dataLoading, setDataLoading] = React.useState(true);
  const [getLitOn, setGetLitOn] = React.useState(false);
  const [showConfetti, setShowConfetti] = React.useState(true);
  const [confettiOn, setConfettiOn] = React.useState(0);
  const [favorited, setFavorited] = React.useState(false);
  const [toastVisible, setToastVisible] = React.useState(0);
  const [toastMessage, setToastMessage] = React.useState("");
  const [showEditBoard, setShowEditBoard] = React.useState(false);
  const [parentWidth, setParentWidth] = React.useState(
    Dimensions.get("window").width
  );
  const [sectionData, setSectionData] = React.useState([]);
  const [classesData, setClassesData] = React.useState([]);
  const [boardColor, setBoardColor] = React.useState("");
  const [initialSelectedClasses, setInitialSelectedClasses] = React.useState(
    []
  );
  const [initialSections, setInitialSections] = React.useState([]);
  const [initalColor, setInitalColor] = React.useState("");
  const [initalBoardName, setInitalBoardName] = React.useState("");
  const [refreshing, setRefreshing] = React.useState(false);
  const [teacherId, setTeacherId] = React.useState(null);
  const [initialEditReboard, setInitialEditReboard] = React.useState(false);
  const [initialIsPublic, setInitialIsPublic] = React.useState(false);
  const [initialTags, setInitialTags] = React.useState([]);

  const { settings } = useSettings();
  const getLitAnimationsEnabled =
    settings.animationsEnabled !== null ? settings.animationsEnabled : true;

  const confettiRef = React.useRef(null);

  const { width } = useWindowDimensions();

  function toggleGetLit() {
    if (!getLitOn) {
      confettiRef?.current?.start();

      if (getLitAnimationsEnabled) setConfettiOn(confettiOn + 1);

      setTimeout(() => {
        if (getLitAnimationsEnabled) setShowConfetti(false);
      }, 5000);
    }

    setGetLitOn(!getLitOn);
  }

  function toggleFavorited() {
    setFavorited(!favorited);

    if (!favorited) {
      setToastMessage("Added board to favorites");
    } else {
      setToastMessage("Removed board from favorites");
    }

    setToastVisible(toastVisible + 1);
  }

  const updateLayout = (e) => {
    setParentWidth(e.nativeEvent.layout.width);
  };

  const route = useRoute();

  useEffect(() => {
    setDataLoading(true);
    fetchData();

    if (route.params.showEdit) showEditBoardClicked();
  }, []);

  const fetchData = async () => {
    try {
      setRefreshing(true);

      await new Promise((resolve) => setTimeout(resolve, 500));

      setSectionData([]);

      const db = getFirestore();

      const auth = getAuth();
      const uid = auth.currentUser.uid;

      // Get a reference to the teacher document
      const boardRef = doc(db, "boards", route.params.boardId);
      const userRef = doc(db, "users", uid);

      const boardDoc = await getDoc(boardRef);

      setBoardColor(boardDoc.data().color);
      setTeacherId(boardDoc.data().teacherId.id);

      //get section modalities and data
      const sectionQ = query(
        collection(db, "sections"),
        where("boardId", "==", boardRef),
        orderBy("index", "asc")
      );

      const modalitiesQ = query(
        collection(db, "modalities"),
        where("boardId", "==", boardRef)
      );

      const modalityVotesQ = query(
        collection(db, "modalityVotes"),
        where("boardId", "==", boardRef),
        where("userId", "==", userRef)
      );

      const modalityFavoritesQ = query(
        collection(db, "modalityFavorites"),
        where("boardId", "==", boardRef),
        where("userId", "==", userRef)
      );

      const sectionData = await getDocs(sectionQ);
      const modalitiesData = await getDocs(modalitiesQ);
      const modalityVotesData = await getDocs(modalityVotesQ);
      const modalityFavoritesData = await getDocs(modalityFavoritesQ);

      const _sections = await Promise.all(
        sectionData.docs.map(async (section, i) => {
          return {
            text: section.data().text,
            title: section.data().title,
            modalities: await Promise.all(
              modalitiesData.docs
                .filter(
                  (modality) => modality.data().sectionId.id == section.id
                )
                .sort((a, b) => {
                  const valueA = a.data().likeCount - a.data().dislikeCount;
                  const valueB = b.data().likeCount - b.data().dislikeCount;
                  return valueB - valueA;
                })
                .map(async (modality) => {
                  const modalityVote = modalityVotesData.docs.find(
                    (modalityVote) =>
                      modalityVote.data().modalityId.id == modality.id
                  );

                  let status = null;
                  if (modalityVote)
                    if (modalityVote.data().type == "like") {
                      status = "caret-up";
                    } else if (modalityVote.data().type == "dislike") {
                      status = "caret-down";
                    }

                  const modalityFavorite = modalityFavoritesData.docs.find(
                    (modalityFavorite) =>
                      modalityFavorite.data().modalityId.id == modality.id
                  );

                  let initalfavorited = false;
                  if (modalityFavorite) initalfavorited = true;

                  let imgLink = modality.data().imgLink;

                  if (imgLink.includes("tiktokcdn")) {
                    const response = await fetch(
                      `https://www.tiktok.com/oembed?url=${
                        modality.data().link
                      }`
                    ).catch((error) => console.error(error));
                    const data = await response
                      .json()
                      .catch((error) => console.error(error));

                    imgLink = data.thumbnail_url;
                  }

                  return {
                    id: modality.id,
                    link: modality.data().link,
                    imgLink,
                    favCount: modality.data().favCount,
                    initialDislikeCount: modality.data().dislikeCount,
                    initialLikeCount: modality.data().likeCount,
                    status,
                    initalfavorited,
                    boardId: boardRef.id,
                    title: modality.data().title,
                    type: modality.data().type,
                  };
                })
            ),
            index: i,
            isNew: false,
            id: section.id,
          };
        })
      );

      // Get a reference to the teacher document

      const classesQ = query(
        collection(db, "classes"),
        where("teacherId", "==", userRef),
        where("status", "==", "active")
      );

      const classesDocs = await getDocs(classesQ);

      const _classData = classesDocs.docs.map((doc) => ({
        title: doc.data().className,
        id: doc.id,
      }));

      updateDoc(boardRef, {
        seenReplies: boardDoc.data().replies,
        lastAccessed: serverTimestamp(),
      });

      setClassesData(_classData);
      setSectionData(_sections);
    } catch (e) {
      console.error(e);
    }
    setDataLoading(false);
    setRefreshing(false);
  };

  const showEditBoardClicked = async () => {
    try {
      DeviceEventEmitter.emit("showLoadingIndicator");
      const db = getFirestore();

      const boardRef = doc(db, "boards", route.params.boardId);

      // Get a reference to the board document
      const boardDoc = await getDoc(boardRef);

      setInitalBoardName(boardDoc.data().boardName);
      setInitalColor(boardDoc.data().color);
      setInitialSections(boardDoc.data().sections);

      setInitialIsPublic(
        boardDoc.data().isPublic ? boardDoc.data().isPublic : false
      );
      setInitialEditReboard(
        boardDoc.data().isReboard ? boardDoc.data().isReboard : false
      );
      setInitialTags(boardDoc.data().tags ? boardDoc.data().tags : []);

      //get section modalities and data
      const sectionQ = query(
        collection(db, "sections"),
        where("boardId", "==", boardRef),
        orderBy("index", "asc")
      );

      const modalitiesQ = query(
        collection(db, "modalities"),
        where("boardId", "==", boardRef)
      );

      const sectionData = await getDocs(sectionQ);
      const modalitiesData = await getDocs(modalitiesQ);

      const _sections = sectionData.docs.map((section, i) => {
        return {
          text: section.data().text,
          title: section.data().title,
          modalities: modalitiesData.docs
            .filter((modality) => modality.data().sectionId.id == section.id)
            .sort((a, b) => {
              const valueA = a.data().likeCount - a.data().dislikeCount;
              const valueB = b.data().likeCount - b.data().dislikeCount;
              return valueB - valueA;
            })
            .map((modality) => {
              return {
                id: modality.id,
                link: modality.data().link,
                imgLink: modality.data().imgLink,
                favCount: modality.data().favCount,
                initialDislikeCount: modality.data().dislikeCount,
                initialLikeCount: modality.data().likeCount,
                title: modality.data().title,
                isNew: false,
                type: modality.data().type ? modality.data().type : "web",
              };
            }),
          index: i,
          isNew: false,
          id: section.id,
        };
      });

      setInitialSections(_sections);

      const _initialSelectedClasses = boardDoc
        .data()
        .classIds.map((classId) => {
          const _class = classesData.find(
            (classData) => classData.id === classId.id
          );
          return _class;
        });

      setInitialSelectedClasses(
        _initialSelectedClasses.filter((c) => c !== undefined)
      );

      setShowEditBoard(true);
    } catch (e) {
      console.error(e);
    }
    DeviceEventEmitter.emit("hideLoadingIndicator");
  };

  const editBoardConfirmed = () => {
    setShowEditBoard(false);
    fetchData();
  };

  return (
    <Flex
      fill
      style={{
        backgroundColor: "#f0eff4",
        width: "100%",
        overflow: "hidden",
      }}
      onLayout={updateLayout}
    >
      {Platform.OS === "web" && refreshing && (
        <Spinner marginTop={5} size={"lg"} color={"black"} />
      )}
      <KeyboardAwareFlatList
        contentContainerStyle={{
          padding: 16,
          gap: 16,
          display: "flex",
          flexDirection: "column",
          columnGap: 16,
          width: "100%",
          marginHorizontal: "auto",
          paddingBottom: 91,
        }}
        refreshControl={
          !dataLoading && (
            <RefreshControl refreshing={refreshing} onRefresh={fetchData} />
          )
        }
        extraHeight={80}
        enableAutomaticScroll={true}
        extraScrollHeight={80}
        refreshing={dataLoading}
        data={sectionData}
        keyExtractor={(item) => item.id}
        renderItem={({ item: section, index }) => (
          <BoardSection
            boardColor={boardColor}
            key={section.id}
            index={index + 1}
            getLitOn={getLitOn}
            name={section.title}
            mediaData={section.modalities}
            initialComments={section.comments}
            id={section.id}
            boardId={route.params.boardId}
            isStaff
            teacherId={teacherId}
          >
            <Text>{section.text}</Text>
          </BoardSection>
        )}
        ListHeaderComponent={
          Platform.OS !== "web" &&
          dataLoading && <Spinner size={"lg"} color={"black"} />
        }
      />
      {showConfetti && getLitAnimationsEnabled && (
        <ConfettiCannon
          ref={confettiRef}
          autoStart={false}
          fadeOut={true}
          count={125}
          explosionSpeed={350}
          fallSpeed={2500}
          origin={{ x: width, y: 0 }}
        />
      )}
      {showConfetti && getLitAnimationsEnabled && (
        <CustomConfettiCannon
          fireConfetti={confettiOn}
          duration={3000}
          durationRandomOffset={500}
          startingYOffset={100}
          startingYRandomOffset={300}
        />
      )}
      {!dataLoading && (
        <>
          {Platform.OS == "web" && (
            <FabGroup
              left={20}
              buttons={[
                {
                  icon: (
                    <MaterialCommunityIcons
                      name="refresh-auto"
                      color={"black"}
                      size={32}
                    />
                  ),
                  onPress: () => fetchData(),
                  color: "white",
                },
              ]}
            />
          )}
          <FabGroup
            buttons={[
              {
                icon: (
                  <MaterialCommunityIcons
                    name="keyboard-return"
                    size={24}
                    color="black"
                  />
                ),
                onPress: () => navigation.goBack(),
                color: "white",
              },
              {
                icon: (
                  <MaterialCommunityIcons
                    name="pencil"
                    size={32}
                    color="black"
                  />
                ),
                onPress: showEditBoardClicked,
                color: "white",
              },
              false && {
                icon: (
                  <MaterialCommunityIcons
                    name="fire"
                    color={"white"}
                    size={48}
                  />
                ),
                onPress: () => toggleFavorited(),
                color: !favorited ? "#ccc" : "#ba3b46",
              },
              {
                icon: getLitOn ? (
                  <MaterialCommunityIcons
                    name="water"
                    color={"white"}
                    size={48}
                  />
                ) : (
                  <MaterialCommunityIcons
                    name="fire"
                    color={"white"}
                    size={48}
                  />
                ),
                onPress: () => toggleGetLit(),
                color: !getLitOn ? "#ba3b46" : "#3b82f6",
              },
            ]}
          />
        </>
      )}
      <AddBoardPopup
        onClose={() => setShowEditBoard(false)}
        onSubmit={editBoardConfirmed}
        visible={showEditBoard}
        classes={classesData}
        setToastMessage={setToastMessage}
        setToastVisible={setToastVisible}
        initialColor={initalColor}
        initialBoardName={initalBoardName}
        initialClasses={initialSelectedClasses}
        initialSections={initialSections}
        initialBoardId={route.params.boardId}
        initialIsPublic={initialIsPublic}
        initialTags={initialTags}
        initialEditReboard={initialEditReboard}
        edit={true}
      />
      <FlameAnimation parentWidth={parentWidth} triggered={favorited} />
      <SimpleToast message={toastMessage} visible={toastVisible} />
    </Flex>
  );
}
