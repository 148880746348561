import React from "react";

import AutoPopIn from "../../components/animations/visibility/AutoPopIn";

import { Text } from "@react-native-material/core";

import { Image, View } from "react-native";

import heroImgSrc from "../../assets/general/LoginHero.png";

export default function LoginHero({ small }) {
  const [width, setWidth] = React.useState(621);

  const onLayoutChange = (e) => {
    setWidth(e.nativeEvent.layout.width);
  };

  return (
    <View
      style={{
        backgroundColor: "#eb9940",
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        flex: 1,
        display: small ? "none" : "flex",
      }}
      onLayout={onLayoutChange}
    >
      <View style={{ padding: 30, alignItems: "center" }}>
        <AutoPopIn>
          <Image
            style={{
              width: width - 60,
              height: width - 60,
              maxHeight: 400,
              maxWidth: 400,
            }}
            resizeMode="center"
            source={heroImgSrc}
          ></Image>
        </AutoPopIn>
      </View>
      <Text
        color="white"
        style={{
          paddingBottom: 12,
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 30,
          paddingHorizontal: 30,
        }}
      >
        Don't just read the literature, get it.
      </Text>
      <Text
        color="white"
        style={{
          textAlign: "center",
          paddingHorizontal: 30,
          fontSize: 14,
          paddingBottom: 30,
        }}
      >
        Find culturally relevent media to transmediate your text
      </Text>
    </View>
  );
}
