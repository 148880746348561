import React from "react";

import { TextInput, StyleSheet } from "react-native";

import { Text, Button, Flex, VStack } from "@react-native-material/core";

import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import Logo from "../graphics/Logo";

export default function NewPasswordForm(props) {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  return (
    <Flex fill center bg={"#ffecd9"}>
      <VStack grow={1} maxW={500} w="100%" spacing={16} p={32}>
        <Flex pt={32}>
          <Logo />
        </Flex>
        <Text
          color="#333333"
          style={{
            fontWeight: "bold",
            fontSize: 44,
            paddingTop: 16,
            paddingBottom: 8,
          }}
        >
          Set New Password
        </Text>
        <Text
          color="#333333"
          style={{
            marginTop: -16,
            fontWeight: "500",
            paddingBottom: 16,
          }}
        >
          Must be at least 8 characters.
        </Text>
        <Text style={[styles.label]}>Password</Text>
        <TextInput
          style={[styles.input]}
          inputStyle={{ backgroundColor: "white", borderRadius: 4.6 }}
          color="#eb9940"
          autoComplete="email"
          value={confirmPassword}
          onChangeText={(value) => setConfirmPassword(value)}
          placeholder="Must match above input"
          secureTextEntry={true}
          placeholderTextColor={"#aaa"}
        ></TextInput>
        <Text style={[styles.label]}>Confirm password</Text>
        <TextInput
          style={[styles.input]}
          inputStyle={{ backgroundColor: "white", borderRadius: 4.6 }}
          color="#eb9940"
          autoComplete="email"
          value={confirmPassword}
          onChangeText={(value) => setConfirmPassword(value)}
          placeholder="Must match above input"
          secureTextEntry={true}
          placeholderTextColor={"#aaa"}
        ></TextInput>
        <Button
          disabled={password !== confirmPassword || password.length <= 8}
          disableElevation
          tintColor="white"
          contentContainerStyle={{ height: 50 }}
          color="#eb9940"
          title="Reset password"
          onPress={() => {
            props.navigation.navigate("Login");
          }}
          placeholderTextColor={"#aaa"}
        ></Button>

        <Button
          leading={<MaterialCommunityIcons name="arrow-left" size={18} />}
          titleStyle={{ fontSize: 12, color: "black" }}
          color="#eb9940"
          variant="text"
          title="Back to login"
          onPress={() => {
            props.navigation.navigate("Login");
          }}
        ></Button>
      </VStack>
    </Flex>
  );
}

const styles = StyleSheet.create({
  input: {
    backgroundColor: "white",
    height: 40,
    borderColor: "#d9d9d9",
    borderWidth: 1,
    borderRadius: 4,
    marginBottom: 15,
    paddingLeft: 5,
    marginTop: -5,
  },
  incorrect: {
    borderColor: "#ff0000",
  },
  label: {
    fontWeight: "500",
  },
  incorrectLabel: {
    fontWeight: "500",
    color: "#ff0000",
  },
});
