import React, { useState } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Text,
  Dimensions,
} from "react-native";
import PopIn from "../../animations/visibility/PopIn";

const DropdownMenu = ({ icon, options, optionWidth }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={styles.menuItem}
      onPress={() => {
        setShowDropdown(false);
        item.onSelect();
      }}
    >
      <Text numberOfLines={1}>{item.label}</Text>
    </TouchableOpacity>
  );

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => setShowDropdown(!showDropdown)}>
        {icon}
      </TouchableOpacity>
      {showDropdown && (
        <View
          style={styles.dropdownOverlay}
          onStartShouldSetResponder={closeDropdown}
        ></View>
      )}
      <PopIn isVisible={showDropdown}>
        <View
          style={[styles.menuContainer, optionWidth && { width: optionWidth }]}
        >
          <FlatList
            data={options}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
            style={styles.menuList}
          />
        </View>
      </PopIn>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
  },
  dropdownOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    width: Dimensions.get("window").width - 50,
    height: Dimensions.get("window").height - 50,
    color: "rgba(0,0,0,0,0.5)",
  },
  menuContainer: {
    position: "absolute",
    top: -5,
    width: 150,
    right: 16,
    //maxWidth: "94%",
    borderRadius: 50,
    borderTopRightRadius: 0,
    backgroundColor: "white",
    borderRadius: 5,
    borderColor: "#ddd",
    borderWidth: 1,
    zIndex: 1,
  },
  menuItem: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
  },
});

export default DropdownMenu;
