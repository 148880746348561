import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import StudentClassScreen from "../../screens/students/StudentClass";
import StudentBoardScreen from "../../screens/students/StudentBoard";
import StudentDashboardScreen from "../../screens/students/StudentDashboard";

const Stack = createNativeStackNavigator();

export default function StudentDashboardNavigator({ navigation }) {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Home"
        options={{ headerShown: false }}
        component={StudentDashboardScreen}
      />
      <Stack.Screen
        name="Board"
        options={({ route }) => ({ title: route.params.name })}
        component={StudentBoardScreen}
      ></Stack.Screen>
      <Stack.Screen
        name="Class"
        options={({ route }) => ({ title: route.params.name })}
        component={StudentClassScreen}
      ></Stack.Screen>
    </Stack.Navigator>
  );
}
