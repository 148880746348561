import React from "react";
import { TouchableOpacity, Text, View } from "react-native";
import { HStack } from "@react-native-material/core";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import {
  WriteBatch,
  collection,
  getDoc,
  getDocs,
  getFirestore,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
  doc,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

const IconCount = ({
  name,
  color = "#626262",
  activeColor = "#3b82f6",
  initialCount = 0,
  size = 20,
  spacing = 4,
  id,
}) => {
  const [active, setActive] = React.useState(false);
  const [count, setCount] = React.useState(initialCount);

  return (
    <TouchableOpacity
      onPress={() => {
        if (!active) setCount(count + 1);
        else setCount(count - 1);
        setActive(!active);
      }}
    >
      <HStack spacing={spacing} items="center">
        <MaterialCommunityIcons
          name={name}
          size={size}
          color={active ? activeColor : color}
        />
        <Text style={{ fontWeight: "500", fontSize: 18 }}>{count}</Text>
      </HStack>
    </TouchableOpacity>
  );
};

const ExclusiveIconCount = ({
  name,
  color = "#626262",
  activeColor = "#3b82f6",
  count,
  onPress,
  size = 20,
  spacing = 4,
  active,
  id,
}) => {
  return (
    <TouchableOpacity
      onPress={() => {
        onPress && onPress();
      }}
    >
      <HStack spacing={spacing}>
        <FontAwesome
          name={name}
          size={size}
          color={active === name ? activeColor : color}
        />
        <Text style={{ fontWeight: "500", fontSize: 18 }}>{count}</Text>
      </HStack>
    </TouchableOpacity>
  );
};

const LikeDislike = ({
  initialLikeCount = 0,
  initialDislikeCount = 0,
  size,
  id,
  initalStatus,
  boardId,
  classId,
  teacherId,
  modalityType,
}) => {
  const [active, setActive] = React.useState(initalStatus);
  const [likeCount, setLikeCount] = React.useState(initialLikeCount);
  const [dislikeCount, setDislikeCount] = React.useState(initialDislikeCount);

  const handleLike = async () => {
    const db = getFirestore();
    const auth = getAuth();
    const uid = auth.currentUser.uid;
    const userRef = doc(db, "users", uid);
    const boardRef = doc(db, "boards", boardId);
    const modalityRef = doc(db, "modalities", id);

    const modalityVotesQ = query(
      collection(db, "modalityVotes"),
      where("boardId", "==", boardRef),
      where("userId", "==", userRef),
      where("modalityId", "==", modalityRef)
    );

    let newStatus = "null";
    if (active === "caret-up") {
      setLikeCount((prevCount) => prevCount - 1);
      setActive(null);

      //run transaction (remove like from modality)
      try {
        await runTransaction(db, async (transaction) => {
          const modalityDoc = await transaction.get(modalityRef);
          if (!modalityDoc.exists()) {
            throw "Document does not exist!";
          }

          const newLikeCount = modalityDoc.data().likeCount - 1;
          transaction.update(modalityRef, { likeCount: newLikeCount });
        });
      } catch (e) {
        console.error("Transaction failed: ", e);
      }
    } else {
      newStatus = "like";

      setLikeCount((prevCount) => prevCount + 1);

      const prevDislike = active === "caret-down";

      if (prevDislike) {
        setDislikeCount((prevCount) => prevCount - 1);
      }

      setActive("caret-up");

      //run transaction (remove like from modality)
      try {
        await runTransaction(db, async (transaction) => {
          const modalityDoc = await transaction.get(modalityRef);
          if (!modalityDoc.exists()) {
            throw "Document does not exist!";
          }

          const newLikeCount = modalityDoc.data().likeCount + 1;
          if (!prevDislike)
            transaction.update(modalityRef, { likeCount: newLikeCount });
          else
            transaction.update(modalityRef, {
              likeCount: newLikeCount,
              dislikeCount: modalityDoc.data().dislikeCount - 1,
            });
        });
      } catch (e) {
        console.error("Transaction failed: ", e);
      }
    }

    //update modality vote in db
    const modalityVoteDoc = await getDocs(modalityVotesQ);

    if (!modalityVoteDoc.empty) {
      if (newStatus == "null") await deleteDoc(modalityVoteDoc.docs[0].ref);
    } else {
      let classRef;

      if (classId) classRef = doc(db, "classes", classId);

      let newDoc = {
        boardId: boardRef,
        modalityId: modalityRef,
        type: newStatus,
        userId: userRef,
        timeStamp: serverTimestamp(),
        teacherId: doc(db, "users", teacherId),
        modalityType: modalityType ? modalityType : "web",
      };

      if (classId) {
        newDoc = { ...newDoc, classId: classRef };
      }

      await setDoc(doc(collection(db, "modalityVotes")), newDoc);
    }
  };

  const handleDislike = async () => {
    const db = getFirestore();
    const auth = getAuth();
    const uid = auth.currentUser.uid;
    const userRef = doc(db, "users", uid);
    const boardRef = doc(db, "boards", boardId);
    const modalityRef = doc(db, "modalities", id);

    const modalityVotesQ = query(
      collection(db, "modalityVotes"),
      where("boardId", "==", boardRef),
      where("userId", "==", userRef),
      where("modalityId", "==", modalityRef)
    );

    let newStatus = "null";
    if (active === "caret-down") {
      setDislikeCount((prevCount) => prevCount - 1);
      setActive(null);

      //run transaction (remove dislike from modality)
      try {
        await runTransaction(db, async (transaction) => {
          const modalityRef = doc(db, "modalities", id);
          const modalityDoc = await transaction.get(modalityRef);
          if (!modalityDoc.exists()) {
            throw "Document does not exist!";
          }

          const newDislikeCount = modalityDoc.data().dislikeCount - 1;
          transaction.update(modalityRef, { likeCount: newDislikeCount });
        });
      } catch (e) {
        console.error("Transaction failed: ", e);
      }
    } else {
      newStatus = "dislike";

      setDislikeCount((prevCount) => prevCount + 1);
      const prevLike = active === "caret-up";
      if (prevLike) {
        setLikeCount((prevCount) => prevCount - 1);
      }
      setActive("caret-down");

      //run transaction (remove like from modality)
      try {
        await runTransaction(db, async (transaction) => {
          const modalityRef = doc(db, "modalities", id);
          const modalityDoc = await transaction.get(modalityRef);
          if (!modalityDoc.exists()) {
            throw "Document does not exist!";
          }

          const newDislikeCount = modalityDoc.data().dislikeCount + 1;
          if (!prevLike)
            transaction.update(modalityRef, { dislikeCount: newDislikeCount });
          else
            transaction.update(modalityRef, {
              likeCount: modalityDoc.data().likeCount - 1,
              dislikeCount: newDislikeCount,
            });
        });
      } catch (e) {
        console.error("Transaction failed: ", e);
      }
    }

    //update modality vote in db
    const modalityVoteDoc = await getDocs(modalityVotesQ);

    if (!modalityVoteDoc.empty) {
      if (newStatus == "null") await deleteDoc(modalityVoteDoc.docs[0].ref);
    } else {
      let classRef;

      if (classId) classRef = doc(db, "classes", classId);

      let newDoc = {
        boardId: boardRef,
        modalityId: modalityRef,
        type: newStatus,
        modalityType: modalityType ? modalityType : "web",
        userId: userRef,
        timeStamp: serverTimestamp(),
        teacherId: doc(db, "users", teacherId),
      };

      if (classId) {
        newDoc = { ...newDoc, classId: classRef };
      }

      await setDoc(doc(collection(db, "modalityVotes")), newDoc);
    }
  };

  return (
    <View
      style={{
        flexDirection: "column",
      }}
    >
      <ExclusiveIconCount
        name="caret-up"
        count={""}
        onPress={handleLike}
        size={size}
        active={active}
        setActive={setActive}
        activeColor="#3b82f6"
        color="#999"
      />
      <Text
        style={{
          marginTop: -8,
          marginBottom: -10,
          textAlign: "center",
          marginLeft: -4,
          color: active
            ? active == "caret-down"
              ? "#ba3b46"
              : "#3b82f6"
            : "#333",
          fontWeight: active ? "bold" : "normal",
        }}
      >
        {likeCount - dislikeCount}
      </Text>
      <ExclusiveIconCount
        name="caret-down"
        count={""}
        onPress={handleDislike}
        size={size}
        active={active}
        activeColor="#ba3b46"
        setActive={setActive}
        color="#999"
      />
    </View>
  );
};

export { ExclusiveIconCount, LikeDislike, IconCount };
