import React, { useState, useEffect, useRef } from "react";
import { Text, View, Image } from "react-native";
import { Surface, HStack, Box } from "@react-native-material/core";
import { clamp } from "../../../utils/SortUtils";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { VStack } from "native-base";
import NoDataFound from "../../animations/data/NoDataFound";

const defaultObject = {
  value: -1,
  label: "No Data",
  avatarUrl: null,
  fontSize: 12,
};

const renderObject = (object, position, unit, total, index, maxWidth) => (
  <View
    key={index}
    style={{
      flex: 1,
      height: "100%",
      justifyContent: "flex-end",
      alignItems: "center",
      maxWidth,
    }}
  >
    {!object.customLabel ? (
      <View style={{ alignItems: "center" }}>
        {object.hideAvatar ? null : object.avatarUrl ? (
          <Image
            source={{ uri: object.avatarUrl }}
            style={{ width: 32, height: 32, borderRadius: 100 }}
          />
        ) : (
          <MaterialCommunityIcons
            name={object.icon ? object.icon : "account-circle"}
            size={32}
            color={object.color ? object.color : "#999"}
          />
        )}
        <View
          style={{
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <Text
            numberOfLines={2}
            style={{
              fontSize: object.fontSize,
              textAlign: "center",
              // maxHeight: `${clamp((object.value / total) * 100 + 15, 20, 60)}%`,
            }}
            selectable
          >
            {object.label?.split("-")[0]}
          </Text>
          {object.label?.split("-")[1] && (
            <Text
              style={{
                fontSize: object.fontSize,
                textAlign: "center",
              }}
              selectable
            >
              {"-" + object.label.split("-")[1]}
            </Text>
          )}
        </View>
      </View>
    ) : (
      <View style={{ width: "100%", paddingHorizontal: 5 }}>
        {object.customLabel}
      </View>
    )}
    <Box
      topLeftRadius={25}
      topRightRadius={25}
      w={"100%"}
      bg={getColor(position)}
      style={{
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        maxHeight: `${clamp((object.value / total) * 100 + 15, 20, 65)}%`,
      }}
    >
      <Text style={{ fontSize: 16, paddingVertical: 8, fontWeight: "500" }}>
        {getPosition(position)}
      </Text>
      <Box
        p={6}
        mb={8}
        bg={"rgba(255,255,255,0.5)"}
        style={{ borderRadius: 12 }}
      >
        <Text style={{ fontSize: 14 }}>
          {Math.round(object.value * 100) / 100} {unit}
        </Text>
      </Box>
    </Box>
  </View>
);

const getColor = (position) =>
  position === 1 ? "#efefef" : position === 2 ? "#ffebc2" : "#dfd0cc";

const getPosition = (position) =>
  position === 1 ? "1st" : position === 2 ? "2nd" : "3rd";

export default function TopThreeCard({ card }) {
  const [showNoData, setShowNoData] = useState(false);

  const maxWidth =
    card.values.length === 1
      ? "100%"
      : card.values.length === 2
      ? "50%"
      : "33%";

  const objects = card.values
    .sort((a, b) => b.value - a.value)
    .map(
      (val, i) =>
        ({ ...val, position: i + 1 } || { ...defaultObject, position: i + 1 })
    );

  const total = objects.reduce((acc, curr) => acc + curr.value, 0);
  const animationRef = useRef(null);

  useEffect(() => {
    if (total <= 0) {
      setShowNoData(true);
      setTimeout(() => {
        if (animationRef.current) {
          animationRef.current.reset();
          animationRef.current.play();
        }
      }, 100);
    } else {
      setShowNoData(false);
    }
  }, [total]);

  return (
    <Surface
      elevation={3}
      style={{
        borderRadius: 5,
        backgroundColor: "white",
        color: "black",
        width: "100%",
        height: "100%",
        padding: 12,
      }}
    >
      <VStack h={400}>
        {!showNoData ? (
          <HStack fill gap={4} pl={8} pr={8}>
            {objects.map((object, index) =>
              object.value !== -1
                ? renderObject(
                    object,
                    object.position,
                    card.unit,
                    total,
                    index,
                    maxWidth
                  )
                : null
            )}
          </HStack>
        ) : (
          <NoDataFound/>
        )}
        <VStack alignItems={"center"} p={4}>
          <Text style={{ fontWeight: "600" }}>{card.title}</Text>
          {card.subTitle && (
            <Text style={{ fontWeight: "400" }}>{card.subTitle}</Text>
          )}
        </VStack>
      </VStack>
    </Surface>
  );
}
