import React, { useEffect } from "react";
import { useWindowDimensions } from "react-native";

import { Surface } from "@react-native-material/core";

import { debounce } from "lodash";

import LoginForm from "../../components/account/LoginForm";
import LoginHero from "./LoginHero";

import { SafeAreaView } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

export default function LoginScreen({ navigation }) {
  const { height, width } = useWindowDimensions();
  const [small, setSmall] = React.useState(true);

  const handleLayout = (event) => {
    const { width: layoutWidth } = event.nativeEvent.layout;
    setSmall(layoutWidth < 640);
  };

  return (
    <SafeAreaView
      onLayout={handleLayout}
      style={{
        backgroundColor: "white",
        minHeight: height,
      }}
    >
      <KeyboardAwareScrollView
        contentContainerStyle={{
          padding: 24,
          minHeight: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        style={{ overflow: "visible" }}
        persistentScrollbar={false}
        
      >
        <Surface
          elevation={5}
          style={{
            borderRadius: 5,
            flexDirection: "row",
            maxWidth: 1200,
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <LoginForm small={small} navigation={navigation} />
          <LoginHero small={small} />
        </Surface>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}
