import React from "react";

import { Switch, HStack, Surface } from "@react-native-material/core";
import { Text, View, TextInput } from "react-native";
import Slider, { SliderBase } from "@react-native-community/slider";

export default function SettingsCard({
  type = "switch",
  labelWidth = 200,
  placeHolder,
  autoComplete,
  value,
  setValue,
  maximumValue = 1,
  minimumValue = 0,
  step = 0,
  showDecimals = 2,
  ...props
}) {
  return (
    <Surface
      elevation={0}
      style={{
        padding: 16,
        backgroundColor: "white",
        alignItems: "center",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          maxWidth: 500,
          width: "100%",
        }}
      >
        <Text style={{ fontWeight: 500, width: labelWidth }}>
          {props.title}
        </Text>
        {type == "switch" && (
          <Switch value={value} onValueChange={setValue}></Switch>
        )}
        {type == "input" && (
          <TextInput
            style={[
              {
                height: 40,
                borderRadius: 5,
                paddingLeft: 10,
                flex: 1,
                borderWidth: 1,
                borderColor: "#aaa",
              },
            ]}
            placeholder={placeHolder}
            autoComplete={autoComplete}
            placeholderTextColor={"#aaa"}
            value={value}
            onChangeText={(value) => setValue(value)}
            secureTextEntry={false}
          ></TextInput>
        )}
        {type == "number" && (
          <View
            style={{ flexDirection: "row", width: 200, alignItems: "center" }}
          >
            <Slider
              style={{ width: 100 }}
              maximumValue={maximumValue}
              minimumValue={minimumValue}
              value={value}
              onValueChange={(value) => setValue(value)}
              step={step}
            />
            <Text style={{ paddingLeft: 5, color: "#ccc" }}>
              {Number(value).toFixed(showDecimals)}
            </Text>
          </View>
        )}
      </View>
    </Surface>
  );
}
