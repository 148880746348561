import * as React from "react";
import { useWindowDimensions } from "react-native";

import { Surface } from "@react-native-material/core";

import ForgotPasswordForm from "../../components/account/ForgotPasswordForm";
import LoginHero from "./LoginHero";

import { ScrollView } from "react-native";
import { SafeAreaView } from "react-native";

export default function LoginScreen({ navigation }) {
  const { height, width } = useWindowDimensions();
  const small = width < 500;

  return (
    <SafeAreaView style={{ backgroundColor: "white", height: height }}>
      <ScrollView
        contentContainerStyle={{ margin: "auto" }}
        style={{ overflow: "visible" }}
      >
        <Surface
          elevation={5}
          style={{
            borderRadius: 5,
            margin: 12,
            flexDirection: "row",
            maxWidth: 1200,
          }}
        >
          <ForgotPasswordForm small={small} navigation={navigation} />
          {!small && <LoginHero small={small} />}
        </Surface>
      </ScrollView>
    </SafeAreaView>
  );
}
