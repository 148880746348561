import React, { useEffect, useState } from "react";
import { ScrollView, TouchableOpacity, Text, View } from "react-native";

import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  onSnapshot,
  getDocs,
  orderBy,
} from "firebase/firestore";

import { Flex } from "@react-native-material/core";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Fragment } from "react";
import FabGroup from "../../components/general/fabs/FabGroup";
import SquareGrid from "../../components/cards/Grid/SquareGrid";
import { useRoute } from "@react-navigation/native";
import { getAuth } from "firebase/auth";
import { changeColorAlpha } from "../../utils/generateColor";
import { useSettings } from "../../components/contexts/SettingsContext";

export default function StudentClassScreen({ navigation }) {
  const [boardData, setBoardData] = React.useState([]);

  const { settings } = useSettings();
  const boardTileSize = settings.boardTileSize ? settings.boardTileSize : 1;

  useEffect(() => {
    fetchData();
  }, []);

  const route = useRoute();
  const auth = getAuth();
  const db = getFirestore();
  const uid = auth.currentUser.uid;
  const userRef = doc(db, "users", uid);
  const classId = route.params.classId;

  const fetchData = async () => {
    const classRef = doc(db, "classes", classId);

    const boardsQ = query(
      collection(db, "boards"),
      where("classIds", "array-contains", classRef),
      orderBy("creationTimeStamp", "desc")
    );

    const boardFavoritesQ = query(
      collection(db, "boardFavorites"),
      where("userId", "==", userRef)
    );

    const boardFavoritesData = await getDocs(boardFavoritesQ);

    onSnapshot(boardsQ, (querySnapshot) => {
      const _boardsData = querySnapshot.docs.map((doc) => {
        const showCustomBadge = boardFavoritesData.docs.some(
          (favorite) => favorite.data().boardId.id === doc.id
        );

        let tags = [
          `${
            doc.data().views != 1
              ? doc.data().views + " views"
              : doc.data().views + " view"
          }`,
          `${doc.data().replies} ${
            doc.data().replies != 1 ? "replies" : "reply"
          }`,
          `${doc.data().favorites} ${
            doc.data().favorites != 1 ? "faves" : "fave"
          }`,
          `${doc.data().sections ? doc.data().sections : 0} section${
            doc.data().sections == 1 ? "" : "s"
          }$#666$300`,
          `${
            doc.data().creationTimeStamp &&
            doc.data().creationTimeStamp.toDate().toDateString()
          }`,
        ];

        if (doc.data().modalityCount) {
          tags = [
            ...tags.slice(0, 4),
            `${doc.data().modalityCount} modalities$#666$300`,
            ...tags.slice(4),
          ];
        }

        return {
          title: doc.data().boardName,
          id: doc.id,
          notificationCount: 0,
          backgroundColor: doc.data().color,
          showCustomBadge,
          tags,
        };
      });

      setBoardData(_boardsData);
    });
  };

  return (
    <Fragment>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, backgroundColor: "#f0eff4" }}
      >
        <Flex fill p={16} style={{ gap: 16, paddingBottom: 91 }}>
          <SquareGrid
            canAdd={false}
            key={boardTileSize}
            columnWidth={200 * boardTileSize}
            numberOfLines={200 * boardTileSize < 170 ? 2 : 3}
            onCardPress={(item) =>
              navigation.navigate("Board", {
                name: item.title,
                boardId: item.id,
                classId,
              })
            }
            data={boardData}
            customBadge={
              <>
                <MaterialCommunityIcons
                  name="fire"
                  size={40}
                  style={{ position: "absolute", right: -6, top: -7 }}
                  color={changeColorAlpha("#5c0011", 0.75)}
                />
                <MaterialCommunityIcons
                  name="fire"
                  size={36}
                  style={{ position: "absolute", right: -5, top: -6.5 }}
                  color={changeColorAlpha("#f5222d", 0.75)}
                />
              </>
            }
          />
        </Flex>
      </ScrollView>
      <Fragment>
        <FabGroup
          buttons={[
            {
              icon: (
                <MaterialCommunityIcons
                  name="keyboard-return"
                  size={24}
                  color="black"
                />
              ),
              color: "white",
              onPress: () => navigation.goBack(),
            },
          ]}
        />
      </Fragment>
    </Fragment>
  );
}
