import {
  View,
  DeviceEventEmitter,
  useWindowDimensions,
  SafeAreaView,
} from "react-native";
import React, { useState, useEffect } from "react";
import Lottie from "lottie-react-native";
import { useRef } from "react";

const fireLoadingAnimation = require("../../../assets/animations/fire-loading.json");

function LoadingIndicator({}) {
  const [showAnimation, setShowAnimation] = useState(true);
  const [stopRequested, setStopRequested] = useState(true);
  const [animationFinished, setAnimationFinished] = useState(false);
  const animationRef = useRef(null);

  const dimensions = useWindowDimensions();

  const height = dimensions.height;
  const width = dimensions.width;

  useEffect(() => {
    DeviceEventEmitter.addListener("showLoadingIndicator", () => {
      setStopRequested(false);
      setShowAnimation(true);
      setAnimationFinished(false);
      setTimeout(() => {
        if (animationRef.current) {
          animationRef.current.play();
        }
      }, 100);
    });

    DeviceEventEmitter.addListener("hideLoadingIndicator", () => {
      setStopRequested(true);
    });

    setTimeout(() => {
      if (animationRef.current) {
        animationRef.current.reset();
        animationRef.current.play();
      }
    }, 100);

    return () => {
      DeviceEventEmitter.removeAllListeners("showLoadingIndicator");
      DeviceEventEmitter.removeAllListeners("hideLoadingIndicator");
    };
  }, [animationRef]);

  useEffect(() => {
    if (animationFinished) {
      if (stopRequested) {
        setShowAnimation(false);
        setAnimationFinished(false);
        setStopRequested(false);
        animationRef.current.reset();
      } else {
        setAnimationFinished(false);
        if (animationRef.current) {
          animationRef.current.reset();
          animationRef.current.play();
        }
      }
    }
  }, [animationFinished]);

  if (showAnimation)
    return (
      <View
        elevation={3}
        style={{
          position: "absolute",
          backgroundColor: "rgba(255,255,255,1)",
          height,
          width,
          flexDirection: "column",
          display: showAnimation ? "flex" : "none",
          overflow: "hidden",
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <SafeAreaView
          style={{
            alignItems: "center",
          }}
        >
          <Lottie
            style={{
              width: width * 0.8 > 800 ? 800 : width * 0.8,
              height: height * 0.8 > 800 ? 800 : height * 0.8,
            }}
            autoPlay
            loop={false}
            ref={animationRef}
            source={fireLoadingAnimation}
            onAnimationFinish={(value) => {
              setAnimationFinished(true);
            }}
          />
        </SafeAreaView>
      </View>
    );
}

export default LoadingIndicator;
