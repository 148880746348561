import React, { useEffect, useState } from "react";

import { Flex } from "@react-native-material/core";

import BasicCarousel from "../../components/cards/Carousel/BasicCarousel";
import TopThreeCard from "../../components/cards/Carousel/TopThreeCard";
import Accordion from "../../components/data/wrappers/BasicAccordion";
import AutoPopIn from "../../components/animations/visibility/AutoPopIn";
import { Fragment } from "react";
import FabGroup from "../../components/general/fabs/FabGroup";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import SquareGrid from "../../components/cards/Grid/SquareGrid";
import StudentClassesScreen from "./StudentClasses";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { RefreshControl, Platform, View, Image } from "react-native";
import { Spinner } from "native-base";
import {
  collection,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { doc } from "firebase/firestore";
import { changeColorAlpha } from "../../utils/generateColor";
import { formatDate } from "../../utils/numberUtils";
import BasicTabContainer from "../../components/data/wrappers/BasicTabContainer";
import { Linking } from "react-native";
import { Text } from "react-native";
import { useSettings } from "../../components/contexts/SettingsContext";

async function getRecentBoardsData(studentRef) {
  const db = getFirestore();

  // Get all classes the user is in
  const studentClassesQ = query(
    collection(db, "studentClasses"),
    where("studentId", "==", studentRef),
    where("hidden", "==", false)
  );

  const classesSnapshot = await getDocs(studentClassesQ);

  const classIds = classesSnapshot.docs.map((_doc) => _doc.data().classId); // Assuming classId is the document id

  const flatClassIds = classIds.map((classId) => classId.id);

  const boardPromises = classIds.map((classId) => {
    // Get all boards that are related to the found class
    const boardsQ = query(
      collection(db, "boards"),
      where("classIds", "array-contains", classId)
    );

    return getDocs(boardsQ);
  });

  const boardSnapshots = await Promise.all(boardPromises);

  // Flatten the snapshots into one array
  const boardDocs = boardSnapshots.flatMap((snapshot) => snapshot.docs);

  const boardFavoritesCollection = collection(db, "boardFavorites");

  // Map over each board and retrieve additional information (favorite status and view times)
  const promises = boardDocs
    .sort(
      (a, b) =>
        b.data().creationTimeStamp.seconds - a.data().creationTimeStamp.seconds
    )
    .slice(0, 10)
    .map(async (boardDoc) => {
      const board = boardDoc.data();

      // Check if the board is favorited
      const favoriteSnapshot = await getDocs(
        query(
          boardFavoritesCollection,
          where("userId", "==", studentRef),
          where("boardId", "==", boardDoc.ref)
        )
      );
      const favorited = !favoriteSnapshot.empty;

      const teacherSnapshot = await getDoc(
        doc(db, "publicUsers", board.teacherId.id)
      );

      const classId = board.classIds.find((id) =>
        flatClassIds.includes(id.id)
      )?.id;

      // Return data for table row
      return {
        id: boardDoc.id,
        title: board.boardName,
        backgroundColor: board.color,
        showCustomBadge: favorited,
        classId,
        tags: [
          `${teacherSnapshot.data()?.lastName}$$600$italic`,
          `${board.views} views`,
          `${board.replies} replies`,
          `${board.favorites} faves`,
          `${board.sections ? board.sections : 0} section${
            board.sections == 1 ? "" : "s"
          }$#666$300`,
          board.creationTimeStamp?.toDate().toDateString(),
        ],
      };
    });

  const tableData = await Promise.all(promises);

  return tableData;
}

async function getCaroselData(studentRef) {
  const db = getFirestore();

  //----get top liked comments
  const commentsCollection = collection(db, "comments");

  // Define the query
  const likesQ = query(
    commentsCollection,
    where("userId", "==", studentRef),
    orderBy("likeCount", "desc"),
    limit(3)
  );

  // Execute the query and get the documents
  const likesSnapshot = await getDocs(likesQ);

  // Map the documents to an array of comments
  const rawLikedComments = likesSnapshot.docs.map((doc) => ({
    label:
      doc.data().comment.trim() +
      "-" +
      formatDate(doc.data().timeStamp?.toDate()),
    value: doc.data().likeCount,
    userId: studentRef.id,
    avatarUrl: getAuth().currentUser.photoURL,
    hideAvatar: false,
  }));
  const likedCommentsObj = {
    title: "Your top liked comment(s)",
    unit: "Likes",
    values: rawLikedComments,
  };

  //----get top replied comments

  // Define the query
  const repliesQ = query(
    commentsCollection,
    where("userId", "==", studentRef),
    orderBy("replies", "desc"),
    limit(3)
  );

  // Execute the query and get the documents
  const repliesSnapshot = await getDocs(repliesQ);

  // Map the documents to an array of comments
  const rawRepliedComments = repliesSnapshot.docs.map((doc) => ({
    label:
      doc.data().comment.trim() +
      "-" +
      formatDate(doc.data().timeStamp?.toDate()),
    value: doc.data().replies,
    userId: studentRef.id,
    avatarUrl: getAuth().currentUser.photoURL,
    hideAvatar: false,
  }));
  const commentsObj = {
    title: "Your top replied comment(s)",
    unit: "Likes",
    values: rawRepliedComments,
  };

  return [likedCommentsObj, commentsObj];
}

async function getStudentBoardFavorites(studentRef) {
  const db = getFirestore();

  // Get all favorite board documents for this student
  const favoritesQ = query(
    collection(db, "boardFavorites"),
    where("userId", "==", studentRef)
  );

  const favoritesSnapshot = await getDocs(favoritesQ);
  const boardIds = favoritesSnapshot.docs.map((doc) => ({
    boardId: doc.data().boardId,
    classId: doc.data().classId.id, // Get classId from the document reference
  }));

  // Fetch all the favorited boards data
  const promises = boardIds.map(({ boardId }) => {
    return getDoc(boardId);
  });

  const boardSnapshots = await Promise.all(promises);

  // Map the data into the desired format for the card grid
  const tableData = boardSnapshots.map((boardSnapshot, index) => {
    const board = boardSnapshot.data();
    if (boardSnapshot.exists())
      return {
        id: boardSnapshot.id,
        title: board.boardName,
        tags: [
          `${board.favorites} faves`,
          `${board.views} views`,
          `${board.replies} replies`,
          `${board.sections ? board.sections : 0} section${
            board.sections == 1 ? "" : "s"
          }`,
        ],
        notificationCount: 0,
        backgroundColor: board.color,
        showCustomBadge: true,
        classId: boardIds[index].classId, // Use the correct classId for each board
      };
  });

  return tableData.filter((_board) => _board != null);
}

async function getStudentModalityFavorites(studentRef) {
  const db = getFirestore();

  // Get all favorite board documents for this student
  const favoritesQ = query(
    collection(db, "modalityFavorites"),
    where("userId", "==", studentRef)
  );

  const favoritesSnapshot = await getDocs(favoritesQ);
  const modalityIds = favoritesSnapshot.docs.map(
    (doc) => doc.data().modalityId
  );

  // Fetch all the favorited modalities data
  const promises = modalityIds.map((modalityId) => {
    return getDoc(modalityId);
  });

  const modalitySnapshots = await Promise.all(promises);

  // Map the data into the desired format for the card grid
  const promises2 = modalitySnapshots.map(async (modalitySnapshot) => {
    const modality = modalitySnapshot.data();
    if (modality) {
      let imgLink = modality.imgLink;

      if (imgLink.includes("tiktokcdn")) {
        const response = await fetch(
          `https://www.tiktok.com/oembed?url=${modality.link}`
        ).catch((error) => console.error(error));
        const data = await response
          .json()
          .catch((error) => console.error(error));

        imgLink = data.thumbnail_url;
      }

      return {
        id: modalitySnapshot.id,
        title: modality.title,
        tags: [imgLink],
        showCustomBadge: true,
        link: modality.link,
        imgLink: imgLink,
      };
    }
  });

  const tableData = await Promise.all(promises2);

  return tableData;
}

export default function StudentDashboardScreen({ navigation }) {
  const [dataLoading, setDataLoading] = React.useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [recentBoardData, setRecentBoardData] = useState([]);
  const [carouselData, setCarouselData] = useState([]);
  const [boardFavoriteData, setBoardFavoriteData] = useState([]);
  const [modalityFavoriteData, setModalityFavoriteData] = useState([]);

  const db = getFirestore();
  const auth = getAuth();
  const uid = auth.currentUser.uid;
  const studentRef = doc(db, "users", uid);

  useEffect(() => {
    setDataLoading(true);
    fetchData();
  }, []);

  async function fetchData() {
    setRefreshing(true);

    try {
      setRecentBoardData(await getRecentBoardsData(studentRef));
      setCarouselData(await getCaroselData(studentRef));
      setModalityFavoriteData(await getStudentModalityFavorites(studentRef));
      setBoardFavoriteData(await getStudentBoardFavorites(studentRef));
    } catch (e) {
      console.error(e);
    }

    setDataLoading(false);
    setRefreshing(false);
  }

  const { settings } = useSettings();
  const boardTileSize = settings.boardTileSize ? settings.boardTileSize : 1;

  return (
    <Fragment>
      <KeyboardAwareScrollView
        style={{ flex: 1, backgroundColor: "#f0eff4" }}
        refreshControl={
          !dataLoading && (
            <RefreshControl refreshing={refreshing} onRefresh={fetchData} />
          )
        }
      >
        <Flex fill p={16} style={{ gap: 16, paddingBottom: 91 }}>
          {Platform.OS !== "web" && dataLoading && (
            <Spinner size={"lg"} color={"black"} />
          )}
          {Platform.OS === "web" && refreshing && (
            <Spinner size={"lg"} color={"black"} />
          )}
          {!dataLoading && (
            <>
              <AutoPopIn delay={300}>
                <BasicCarousel
                  renderItem={(card) => {
                    return <TopThreeCard card={card} />;
                  }}
                  data={carouselData}
                />
              </AutoPopIn>
              <AutoPopIn delay={500}>
                <Accordion
                  defaultExpanded
                  title={"Recent Boards"}
                  content={
                    <View
                      style={recentBoardData.length !== 0 && { paddingTop: 16 }}
                    >
                      <SquareGrid
                        key={boardTileSize}
                        columnWidth={200 * boardTileSize}
                        numberOfLines={200 * boardTileSize < 170 ? 1 : 3}
                        hideSearch
                        showAnimation={false}
                        canAdd={false}
                        onCardPress={(item) => {
                          navigation.navigate("Board", {
                            name: item.title,
                            boardId: item.id,
                            classId: item.classId,
                          });
                        }}
                        showNoData
                        data={recentBoardData}
                        customBadge={
                          <>
                            <MaterialCommunityIcons
                              name="fire"
                              size={40}
                              style={{
                                position: "absolute",
                                right: -6,
                                top: -7,
                              }}
                              color={changeColorAlpha("#5c0011", 0.75)}
                            />
                            <MaterialCommunityIcons
                              name="fire"
                              size={36}
                              style={{
                                position: "absolute",
                                right: -5,
                                top: -6.5,
                              }}
                              color={changeColorAlpha("#f5222d", 0.75)}
                            />
                          </>
                        }
                      />
                    </View>
                  }
                />
              </AutoPopIn>
              <AutoPopIn delay={700}>
                <Accordion
                  title={"Browse Classes"}
                  content={
                    <StudentClassesScreen
                      navigation={navigation}
                      showJoin={false}
                      hidePadding
                    />
                  }
                />
              </AutoPopIn>
              <AutoPopIn delay={500}>
                <Accordion
                  title="Your Favorites"
                  color="#ba3b46"
                  textColor={"white"}
                  content={
                    <BasicTabContainer
                      accentColor="#ba3b46"
                      hideAnim={true}
                      screens={[
                        {
                          name: "Boards",
                          screen: (
                            <SquareGrid
                              hideOverflow
                              canAdd={false}
                              columnWidth={170}
                              onCardPress={(item) =>
                                navigation.navigate("Board", {
                                  name: item.title,
                                  boardId: item.id,
                                  classId: item.classId,
                                })
                              }
                              data={boardFavoriteData}
                              customBadge={
                                <>
                                  <MaterialCommunityIcons
                                    name="fire"
                                    size={40}
                                    style={{
                                      position: "absolute",
                                      right: -6,
                                      top: -7,
                                    }}
                                    color={changeColorAlpha("#5c0011", 0.75)}
                                  />
                                  <MaterialCommunityIcons
                                    name="fire"
                                    size={36}
                                    style={{
                                      position: "absolute",
                                      right: -5,
                                      top: -6.5,
                                    }}
                                    color={changeColorAlpha("#f5222d", 0.75)}
                                  />
                                </>
                              }
                            />
                          ),
                        },
                        {
                          name: "Modalities",
                          screen: (
                            <SquareGrid
                              hideOverflow
                              canAdd={false}
                              columnWidth={170}
                              onCardPress={(item) => Linking.openURL(item.link)}
                              data={modalityFavoriteData}
                              hideSearch
                              customBadge={
                                <>
                                  <MaterialCommunityIcons
                                    name="fire"
                                    size={40}
                                    style={{
                                      position: "absolute",
                                      right: -6,
                                      top: -7,
                                    }}
                                    color={changeColorAlpha("#5c0011", 0.75)}
                                  />
                                  <MaterialCommunityIcons
                                    name="fire"
                                    size={36}
                                    style={{
                                      position: "absolute",
                                      right: -5,
                                      top: -6.5,
                                    }}
                                    color={changeColorAlpha("#f5222d", 0.75)}
                                  />
                                </>
                              }
                              customRender={(item, altItem) => {
                                return (
                                  <View>
                                    <Image
                                      source={{ uri: item }}
                                      style={{
                                        height: 150,
                                        width: 150,
                                        flexGrow: 1,
                                        borderRadius: 5,
                                        zIndex: 0,
                                      }}
                                    />
                                    <View
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "absolute",
                                        zIndex: 9999,
                                      }}
                                    >
                                      <View
                                        style={{
                                          backgroundColor:
                                            "rgba(255,255,255,0.75)",
                                          borderRadius: 5,
                                          padding: 5,
                                          margin: 5,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 12,
                                            fontWeight: "600",
                                          }}
                                        >
                                          {altItem}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                );
                              }}
                            />
                          ),
                        },
                      ]}
                      elevation={0}
                    />
                  }
                />
              </AutoPopIn>
            </>
          )}
        </Flex>
      </KeyboardAwareScrollView>
      <Fragment>
        <FabGroup
          buttons={[
            {
              icon: <Ionicons name="people" size={32} />,
              color: "white",
              onPress: () => navigation.navigate("Classes"),
            },
          ]}
        />
      </Fragment>
    </Fragment>
  );
}
