import React, { useEffect, useRef, useState } from "react";
import { TextInputMask } from "react-native-masked-text";

/**
 * CustomTextInput Component
 *
 * @param {Object} props - The props object containing various configuration options.
 * @param {Function} props.setValue - Callback function to set the value of the input.
 * @param {string} [props.initialValue] - The initial value of the input field.
 */
const CustomPhoneInput = ({ setValue, initialValue }) => {
  // Reference to the TextInput component to enable programmatic focus
  const inputRef = useRef(null);

  const [phoneNumber, setPhoneNumber] = useState(initialValue);

  // UseEffect hook to update the value of the input whenever the initialValue prop changes
  useEffect(() => {
    setPhoneNumber(initialValue);
  }, [initialValue]);

  /**
   * Handle the text input change.
   *
   * @param {string} text - The new text entered in the input field.
   */
  const handleInputChange = (text) => {
    setPhoneNumber(text);
  };

  return (
    <TextInputMask
      style={{
        paddingLeft: 10,
        height: 40,
        borderRadius: 3,
        padding: 10,
        flex: 1,
        borderWidth: 1,
        borderColor: "#eee",
      }}
      type={"cel-phone"}
      options={{
        maskType: "BRL",
        withDDD: true,
        dddMask: "(999) 999-9999",
      }}
      onBlur={() => setValue(phoneNumber)}
      value={phoneNumber}
      blurOnSubmit={false}
      onChangeText={(text) => handleInputChange(text)}
      ref={inputRef}
      placeholder="(999) 999-9999"
      placeholderTextColor={"#aaa"}
      maxLength={14}
    />
  );
};

export default CustomPhoneInput;
