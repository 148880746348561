import * as Haptics from "expo-haptics";
import { Platform } from "react-native";

export function lightHaptic() {
  if (Platform.OS == "ios" || Platform.OS == "android")
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
}

export function mediumHaptic() {
  if (Platform.OS == "ios" || Platform.OS == "android")
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
}

export function heavyHaptic() {
  if (Platform.OS == "ios" || Platform.OS == "android")
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
}
