import * as React from "react";
import { Text, View, TouchableOpacity, Image } from "react-native";

import { Flex } from "@react-native-material/core";
import BasicTable from "../../components/data/tables/BasicTable";
import AdvancedTabContainer from "../../components/data/wrappers/AdvancedTabContainer";
import AutoPopIn from "../../components/animations/visibility/AutoPopIn";
import { MaterialCommunityIcons, Octicons } from "@expo/vector-icons";

import { getAuth } from "firebase/auth";
import StudentDataTab from "../../components/data/misc/StudentDataTab";

export default function StudentLookupScreen({
  navigation,
  studentLookupData = [],
  setToastVisible,
  setToastMessage,
  toastVisible,
}) {
  const [addTab, setAddTab] = React.useState();

  const auth = getAuth();
  const uid = auth.currentUser.uid;

  function SelectStudentButton(props) {
    const studentId = `${props.data[props.row.index].id}`;

    return (
      <TouchableOpacity
        style={{
          flexDirection: "row",
          gap: 5,
          alignItems: "center",
        }}
        onPress={() => {
          if (toastVisible == 0) {
            setToastMessage("Tab opened for " + props.value + " below table.");
            setToastVisible(Math.random());
          }
          setAddTab({
            name: props.value,
            id: studentId,
            screen: (
              <AutoPopIn delay={0}>
                <StudentDataTab
                  navigation={navigation}
                  teacherId={uid}
                  studentId={studentId}
                />
              </AutoPopIn>
            ),
          });
        }}
      >
        {props.data[props.row.index].profilePicture && (
          <Image
            style={{
              height: 25,
              width: 25,
              borderRadius: 25,
            }}
            source={{ uri: props.data[props.row.index].profilePicture }}
          />
        )}
        <Text style={{ color: "#6395f9" }}>{props.value}</Text>
      </TouchableOpacity>
    );
  }

  const renderClassLink = ({ data, row, value }) => {
    return (
      <View
        style={{
          flexDirection: "row",
          gap: 4,
          alignItems: "flex-end",
          flexWrap: 1,
        }}
      >
        {value.split(",").map((value, index) => (
          <TouchableOpacity
            key={index}
            style={{ flexDirection: "row", gap: 4, alignItems: "flex-end" }}
            onPress={() =>
              navigation.navigate("Class", {
                name: value,
                classId: data[row.index].classIds[index],
              })
            }
          >
            <Text style={{ color: "#6395f9" }}>{value}</Text>
            <Octicons
              name="link-external"
              size={10}
              style={{ paddingBottom: 2 }}
              color="#6395f9"
            />
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  const studentLookupColumns = React.useMemo(
    () => [
      {
        Header: "Student",
        accessor: "name",
        Cell: SelectStudentButton,
      },
      {
        Header: "Last Name",
        accessor: "lastName", // accessor is the "key" in the data
        width: 150,
      },
      {
        Header: "First Name",
        accessor: "firstName", // accessor is the "key" in the data
        width: 150,
      },
      {
        Header: "Class",
        accessor: "className", // accessor is the "key" in the data
        width: 150,
        Cell: renderClassLink,
      },
      {
        Header: "Comments",
        accessor: "comments",
        disableFilters: true,
      },
      {
        Header: "Replies",
        accessor: "replies",
        disableFilters: true,
      },
      {
        Header: "Engagement (minutes)",
        accessor: "viewTime",
        disableFilters: true,
        width: 200,
      },
      {
        Header: "Joined",
        accessor: "joined",
        disableFilters: true,
      },
    ],
    []
  );

  return (
    <>
      <Flex fill style={{ gap: 16 }}>
        <AutoPopIn delay={100}>
          <BasicTable
            title={"Tap name to open data tab below"}
            columns={studentLookupColumns}
            data={studentLookupData}
          ></BasicTable>
        </AutoPopIn>
        <AutoPopIn delay={300}>
          <AdvancedTabContainer
            noDataText="No students selected. To view a student's data select their highlighted name in the table above."
            addTab={addTab}
            selectNewTab
            navigation={navigation}
          />
        </AutoPopIn>
      </Flex>
    </>
  );
}
