import {
  DeviceEventEmitter,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { CustomDrawerContent } from "./CustomDrawerContent";
import CustomHeaderRight from "./CustomHeaderRight";
import StaffDashboardScreen from "../../screens/staff/StaffDashboard";

import React, { useEffect, useState } from "react";
import StaffClassesNavigator from "./StaffClassesNavigator";
import StaffBoardsNavigator from "./StaffBoardsNavigator";
import StaffSettingsScreen from "../../screens/staff/StaffSettings";
import StaffAccountScreen from "../../screens/staff/StaffAcount";
import { changeColorAlpha } from "../../utils/generateColor";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import StaffDashboardNavigator from "./StaffDashboardNavigator";
import StaffVaultNavigator from "./StaffVaultNavigator";
import AdminDashboardNavigator from "./AdminDashboardNavigator";

const Drawer = createDrawerNavigator();

export default function TeacherNavigator({
  userType,
  orientation,
  defaultRoute = "Admin Console",
  isAdmin = false,
}) {
  const { height, width } = useWindowDimensions();
  const large = width >= 1008;
  const medium = width < 1008 && width >= 641;
  const small = width < 641;

  useEffect(() => {
    DeviceEventEmitter.addListener("user-role-changed", (role) => {
      setNewAccount(true);
    });

    return () => {};
  }, []);

  const [newAccount, setNewAccount] = useState(false);

  return (
    <Drawer.Navigator
      key={newAccount}
      initialRouteName={defaultRoute}
      screenOptions={({ navigation, route }) => ({
        drawerStyle: { width: 200, backgroundColor: "white" },
        drawerLabelStyle: { fontWeight: "500", color: "black" },
        drawerInactiveTintColor: "rgba(255,255,255,0.66)",
        drawerActiveBackgroundColor: changeColorAlpha("#ed9b40", 0.1),
        drawerType: large ? "permanent" : "front",
        headerLeftContainerStyle: {
          display: large ? "none" : "flex",
          maxWidth: 30,
        },
        headerRight: (props) => (
          <CustomHeaderRight
            {...props}
            navigation={navigation}
            userType={userType}
          />
        ),
        drawerLabel: ({ focused, color }) => {
          const focusedColor = "#ed9b40"; // change to your bright color
          const unfocusedColor = "grey"; // change to your default color
          return (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: 220,
                position: "relative",
                marginLeft: -20,
              }}
            >
              <Text
                style={{
                  color: focused ? focusedColor : unfocusedColor,
                  fontWeight: focused ? "500" : "600",
                }}
              >
                {route.name}
              </Text>
              {focused && (
                <View
                  style={{
                    height: 50,
                    width: 3,
                    backgroundColor: focusedColor,
                    position: "absolute",
                    right: 0,
                  }}
                />
              )}
            </View>
          );
        },
        headerStyle: {
          height: orientation == 4 ? 58 : 100,
          backgroundColor: "white",
        },
      })}
      drawerContent={CustomDrawerContent}
    >
      {isAdmin && (
        <Drawer.Screen
          options={{
            drawerIcon: ({ focused, size, color }) => (
              <MaterialCommunityIcons
                color={focused ? "#ed9b40" : "grey"}
                name="console"
                size={size}
              />
            ),
          }}
          name="Admin Console"
          component={AdminDashboardNavigator}
        />
      )}
      <Drawer.Screen
        options={{
          drawerIcon: ({ focused, size, color }) => (
            <MaterialCommunityIcons
              color={focused ? "#ed9b40" : "grey"}
              name="monitor-dashboard"
              size={size}
            />
          ),
        }}
        name="Dashboard"
        component={StaffDashboardNavigator}
      />
      <Drawer.Screen
        options={{
          drawerIcon: ({ focused, size, color }) => (
            <MaterialCommunityIcons
              color={focused ? "#ed9b40" : "grey"}
              name="table"
              size={size}
            />
          ),
        }}
        name="Boards"
        component={StaffBoardsNavigator}
      />
      <Drawer.Screen
        options={{
          drawerIcon: ({ focused, size, color }) => (
            <MaterialCommunityIcons
              color={focused ? "#ed9b40" : "grey"}
              name="bank"
              size={size}
            />
          ),
        }}
        name="Vault"
        component={StaffVaultNavigator}
      />
      <Drawer.Screen
        options={{
          drawerIcon: ({ focused, size, color }) => (
            <Ionicons
              color={focused ? "#ed9b40" : "grey"}
              name="people"
              size={size}
            />
          ),
        }}
        name="Classes"
        component={StaffClassesNavigator}
      />
      <Drawer.Screen
        options={{
          drawerIcon: ({ focused, size, color }) => (
            <MaterialCommunityIcons
              color={focused ? "#ed9b40" : "grey"}
              name="account"
              size={size}
            />
          ),
        }}
        name="Account"
        component={StaffAccountScreen}
      />
      <Drawer.Screen
        options={{
          drawerIcon: ({ focused, size, color }) => (
            <MaterialCommunityIcons
              color={focused ? "#ed9b40" : "grey"}
              name="cog"
              size={size}
            />
          ),
        }}
        name="Settings"
        component={StaffSettingsScreen}
      />
    </Drawer.Navigator>
  );
}
