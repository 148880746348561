import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import StaffVaultFeedScreen from "../../screens/staff/StaffVaultFeed";
import StaffVaultAccountScreen from "../../screens/staff/StaffVaultAccount";
import StaffVaultTeacherScreen from "../../screens/staff/StaffVaultTeacher";
import StaffVaultBoardScreen from "../../screens/staff/StaffVaultBoard";

const Stack = createNativeStackNavigator();

export default function StaffVaultNavigator({ navigation }) {
  return (
    <Stack.Navigator
      screenOptions={({ route }) => ({
        headerShown: true,
      })}
    >
      <Stack.Screen
        name="Feed"
        options={{ headerShown: false, title: "Vault" }}
        component={StaffVaultFeedScreen}
      />
      <Stack.Screen
        name="My Boards"
        options={{ title: "My Vault" }}
        component={StaffVaultAccountScreen}
      ></Stack.Screen>
      <Stack.Screen
        name="Board"
        options={({ route }) => ({ title: route.params.name })}
        component={StaffVaultBoardScreen}
      ></Stack.Screen>
      <Stack.Screen
        name="Teacher"
        options={({ route }) => ({
          title: route.params.name ? route.params.name : "...",
        })}
        component={StaffVaultTeacherScreen}
      ></Stack.Screen>
    </Stack.Navigator>
  );
}
