import * as React from "react";
import {
  Text,
  RefreshControl,
  useWindowDimensions,
  Platform,
} from "react-native";

import ConfettiCannon from "react-native-confetti-cannon";

import { Flex } from "@react-native-material/core";
import BoardSection from "../../components/discussion/BoardSection";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import CustomConfettiCannon from "../../components/animations/confetti/CustomConfettiCannon";
import FabGroup from "../../components/general/fabs/FabGroup";
import FlameAnimation from "../../components/animations/favorite/FlameAnimation";
import { Dimensions } from "react-native";
import SimpleToast from "../../components/animations/toasts/SimpleToast";
import { useRoute } from "@react-navigation/native";
import { useEffect } from "react";

import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  getDocs,
  orderBy,
  getDoc,
  setDoc,
  runTransaction,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { Spinner } from "native-base";
import { deleteDoc } from "firebase/firestore";
import { KeyboardAwareFlatList } from "react-native-keyboard-aware-scroll-view";
import { changeColorAlpha } from "../../utils/generateColor";
import { useSettings } from "../../components/contexts/SettingsContext";

export default function StudentBoardScreen({ navigation }) {
  const [dataLoading, setDataLoading] = React.useState(true);
  const [getLitOn, setGetLitOn] = React.useState(false);
  const [showConfetti, setShowConfetti] = React.useState(true);
  const [confettiOn, setConfettiOn] = React.useState(0);
  const [favorited, setFavorited] = React.useState(false);
  const [toastVisible, setToastVisible] = React.useState(0);
  const [toastMessage, setToastMessage] = React.useState("");
  const [parentWidth, setParentWidth] = React.useState(
    Dimensions.get("window").width
  );
  const [sectionData, setSectionData] = React.useState([]);
  const [boardColor, setBoardColor] = React.useState("");
  const [boardFavoriteRef, setBoardFavoriteRef] = React.useState(null);
  const [teacherId, setTeacherId] = React.useState(null);
  const [refreshing, setRefreshing] = React.useState(false);
  const [discussionMuted, setDiscussionMuted] = React.useState(false);

  const confettiRef = React.useRef(null);

  const { width } = useWindowDimensions();

  const route = useRoute();

  const db = getFirestore();
  const auth = getAuth();
  const uid = auth.currentUser.uid;
  const boardRef = doc(db, "boards", route.params.boardId);
  const userRef = doc(db, "users", uid);

  const classId = route.params.classId;

  const classRef = doc(db, "classes", classId);

  const { settings } = useSettings();
  const getLitAnimationsEnabled =
    settings.animationsEnabled !== null ? settings.animationsEnabled : true;

  function toggleGetLit() {
    if (!getLitOn) {
      confettiRef?.current?.start();

      if (getLitAnimationsEnabled) setConfettiOn(confettiOn + 1);

      setTimeout(() => {
        if (getLitAnimationsEnabled) setShowConfetti(false);
      }, 5000);
    }

    setGetLitOn(!getLitOn);
  }

  const updateLayout = (e) => {
    setParentWidth(e.nativeEvent.layout.width);
  };

  useEffect(() => {
    if (!teacherId) {
      setDataLoading(true);
      fetchData();
    }

    const enterTime = Date.now();

    return async () => {
      if (teacherId) {
        // Calculate the time difference when leaving the screen
        const viewTime = Date.now() - enterTime;

        // Write the viewTime to Firestore
        const viewDocRef = doc(collection(db, "boardViewTimes"));
        await setDoc(viewDocRef, {
          boardId: boardRef,
          viewTime: viewTime,
          timeStamp: serverTimestamp(),
          classId: classRef,
          userId: userRef,
          teacherId: doc(db, "users", teacherId),
        });

        runTransaction(db, async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists()) {
            throw "Document does not exist!";
          }
          const newEngagement = userDoc.data().engagement
            ? userDoc.data().engagement + viewTime
            : viewTime;
          transaction.update(userRef, { engagement: newEngagement });
        });

        const boardViewsQ = query(
          collection(db, "boardViews"),
          where("boardId", "==", boardRef),
          where("userId", "==", userRef)
        );

        const boardViewsData = await getDocs(boardViewsQ);

        runTransaction(db, async (transaction) => {
          const boardDoc = await transaction.get(boardRef);
          if (!boardDoc.exists()) {
            throw "Document does not exist!";
          }
          const newEngagement = boardDoc.data().engagement
            ? boardDoc.data().engagement + viewTime
            : viewTime;
          transaction.update(boardRef, { engagement: newEngagement });
        });

        if (boardViewsData.empty) {
          try {
            setDoc(doc(collection(db, "boardViews")), {
              boardId: boardRef,
              userId: userRef,
              timeStamp: serverTimestamp(),
              classId: classRef,
              teacherId: doc(db, "users", teacherId),
            });

            runTransaction(db, async (transaction) => {
              const boardDoc = await transaction.get(boardRef);
              if (!boardDoc.exists()) {
                throw "Document does not exist!";
              }
              const newViewCount = boardDoc.data().views + 1;
              transaction.update(boardRef, { views: newViewCount });
            });

            runTransaction(db, async (transaction) => {
              const boardDoc = await transaction.get(boardRef);
              if (!boardDoc.exists()) {
                throw "Document does not exist!";
              }
              const newViewCount = boardDoc.data().views + 1;
              transaction.update(boardRef, { views: newViewCount });
            });
          } catch (e) {
            console.error("Transaction failed 0: ", e);
          }
        }
      }
    };
  }, [teacherId]);

  const fetchData = async () => {
    try {
      setRefreshing(true);
      const boardDoc = await getDoc(boardRef);

      setTeacherId(boardDoc.data().teacherId.id);

      setBoardColor(boardDoc.data().color);

      //get section modalities and data
      const sectionQ = query(
        collection(db, "sections"),
        where("boardId", "==", boardRef),
        orderBy("index", "asc")
      );

      const modalitiesQ = query(
        collection(db, "modalities"),
        where("boardId", "==", boardRef)
      );

      const modalityVotesQ = query(
        collection(db, "modalityVotes"),
        where("boardId", "==", boardRef),
        where("userId", "==", userRef)
      );

      const modalityFavoritesQ = query(
        collection(db, "modalityFavorites"),
        where("boardId", "==", boardRef),
        where("userId", "==", userRef)
      );

      const boardFavoritesQ = query(
        collection(db, "boardFavorites"),
        where("boardId", "==", boardRef),
        where("userId", "==", userRef)
      );

      let muteSnapshot = await getDocs(
        query(
          collection(db, "studentClassMutes"),
          where("classId", "==", classRef),
          where("userId", "==", userRef)
        )
      );

      if (muteSnapshot.docs.length !== 0) setDiscussionMuted(true);

      const sectionData = await getDocs(sectionQ);
      const modalitiesData = await getDocs(modalitiesQ);
      const modalityVotesData = await getDocs(modalityVotesQ);
      const modalityFavoritesData = await getDocs(modalityFavoritesQ);
      const boardFavoritesData = await getDocs(boardFavoritesQ);

      const _sections = await Promise.all(
        sectionData.docs.map(async (section, i) => {
          return {
            text: section.data().text,
            title: section.data().title,
            modalities: await Promise.all(
              modalitiesData.docs
                .filter(
                  (modality) => modality.data().sectionId.id == section.id
                )
                .sort((a, b) => {
                  const valueA = a.data().likeCount - a.data().dislikeCount;
                  const valueB = b.data().likeCount - b.data().dislikeCount;
                  return valueB - valueA;
                })
                .map(async (modality) => {
                  const modalityVote = modalityVotesData.docs.find(
                    (modalityVote) =>
                      modalityVote.data().modalityId.id == modality.id
                  );

                  let status = null;
                  if (modalityVote)
                    if (modalityVote.data().type == "like") {
                      status = "caret-up";
                    } else if (modalityVote.data().type == "dislike") {
                      status = "caret-down";
                    }

                  const modalityFavorite = modalityFavoritesData.docs.find(
                    (modalityFavorite) =>
                      modalityFavorite.data().modalityId.id == modality.id
                  );

                  let initalfavorited = false;
                  if (modalityFavorite) initalfavorited = true;

                  let imgLink = modality.data().imgLink;

                  if (imgLink.includes("tiktokcdn")) {
                    const response = await fetch(
                      `https://www.tiktok.com/oembed?url=${
                        modality.data().link
                      }`
                    ).catch((error) => console.error(error));
                    const data = await response
                      .json()
                      .catch((error) => console.error(error));

                    imgLink = data.thumbnail_url;
                  }

                  return {
                    id: modality.id,
                    link: modality.data().link,
                    imgLink,
                    favCount: modality.data().favCount,
                    initialDislikeCount: modality.data().dislikeCount,
                    initialLikeCount: modality.data().likeCount,
                    status,
                    initalfavorited,
                    boardId: boardRef.id,
                    title: modality.data().title,
                    teacherId,
                    type: modality.data().type,
                  };
                })
            ),
            index: i,
            isNew: false,
            id: section.id,
          };
        })
      );

      if (!boardFavoritesData.empty) {
        setFavorited(true);
        setBoardFavoriteRef(boardFavoritesData.docs[0].id);
      }

      setSectionData(_sections);
    } catch (e) {
      console.error(e);
    }

    setDataLoading(false);
    setRefreshing(false);
  };

  async function toggleFavorited() {
    setFavorited(!favorited);

    const amount = !favorited ? 1 : -1;

    if (!favorited) {
      setToastMessage("Added board to favorites");

      const newBoardFavoriteRef = doc(collection(db, "boardFavorites"));

      await setDoc(newBoardFavoriteRef, {
        boardId: boardRef,
        userId: userRef,
        timeStamp: serverTimestamp(),
        classId: classRef,
        teacherId: doc(db, "users", teacherId),
      });
    } else {
      setToastMessage("Removed board from favorites");
      if (boardFavoriteRef) {
        await deleteDoc(doc(db, "boardFavorites", boardFavoriteRef));
      }
    }

    setToastVisible(toastVisible + 1);

    //run transaction (add/sub 1 to board favorites)
    try {
      await runTransaction(db, async (transaction) => {
        const boardDoc = await transaction.get(boardRef);
        if (!boardDoc.exists()) {
          throw "Document does not exist!";
        }

        const newFavCount = boardDoc.data().favorites + amount;
        transaction.update(boardRef, { favorites: newFavCount });
      });
    } catch (e) {
      console.error("Transaction failed 1: ", e);
    }
  }

  return (
    <Flex
      fill
      style={{
        backgroundColor: "#f0eff4",
        overflow: "hidden",
        width: "100%",
      }}
      onLayout={updateLayout}
    >
      {Platform.OS === "web" && refreshing && (
        <Spinner marginTop={5} size={"lg"} color={"black"} />
      )}
      <KeyboardAwareFlatList
        contentContainerStyle={{
          padding: 16,
          gap: 16,
          display: "flex",
          flexDirection: "column",
          columnGap: 16,
          width: "100%",
          marginHorizontal: "auto",
          paddingBottom: 91,
        }}
        refreshControl={
          !dataLoading && (
            <RefreshControl refreshing={refreshing} onRefresh={fetchData} />
          )
        }
        extraHeight={80}
        enableAutomaticScroll={true}
        extraScrollHeight={80}
        refreshing={dataLoading}
        data={sectionData}
        keyExtractor={(item) => item.id}
        renderItem={({ item: section, index }) => (
          <BoardSection
            boardColor={boardColor}
            key={section.id}
            index={index + 1}
            getLitOn={getLitOn}
            name={section.title}
            mediaData={section.modalities}
            initialComments={section.comments}
            id={section.id}
            boardId={route.params.boardId}
            classId={classId}
            teacherId={teacherId}
            discussionMuted={discussionMuted}
          >
            <Text>{section.text}</Text>
          </BoardSection>
        )}
        ListHeaderComponent={
          Platform.OS !== "web" &&
          dataLoading && <Spinner size={"lg"} color={"black"} />
        }
      />
      {showConfetti && getLitAnimationsEnabled && (
        <ConfettiCannon
          ref={confettiRef}
          autoStart={false}
          fadeOut={true}
          count={125}
          explosionSpeed={350}
          fallSpeed={2500}
          origin={{ x: width, y: 0 }}
        />
      )}
      {showConfetti && getLitAnimationsEnabled && (
        <CustomConfettiCannon
          fireConfetti={confettiOn}
          duration={3000}
          durationRandomOffset={500}
          startingYOffset={100}
          startingYRandomOffset={300}
        />
      )}
      {!dataLoading && (
        <FabGroup
          buttons={[
            {
              width: 48,
              height: 48,
              icon: (
                <MaterialCommunityIcons
                  name="keyboard-return"
                  size={24}
                  color="black"
                />
              ),
              onPress: () => navigation.goBack(),
              color: "white",
            },
            getLitOn && {
              width: 48,
              height: 48,
              icon: favorited ? (
                <>
                  <MaterialCommunityIcons
                    name="fire"
                    size={40}
                    style={{ position: "absolute", right: 5, top: 4 }}
                    color={changeColorAlpha("#5c0011", 0.75)}
                  />
                  <MaterialCommunityIcons
                    name="fire"
                    size={36}
                    style={{ position: "absolute", right: 5, top: 5 }}
                    color={changeColorAlpha("#f5222d", 0.75)}
                  />
                </>
              ) : (
                <MaterialCommunityIcons
                  name="fire"
                  size={36}
                  color={changeColorAlpha("#f5222d", 0.5)}
                />
              ),
              onPress: () => toggleFavorited(),
              color: !favorited ? "white" : "#ba3b46",
            },
            {
              width: 48,
              height: 48,
              icon: getLitOn ? (
                <MaterialCommunityIcons
                  name="water"
                  color={"white"}
                  size={36}
                />
              ) : (
                <MaterialCommunityIcons name="fire" color={"white"} size={36} />
              ),
              onPress: () => toggleGetLit(),
              color: !getLitOn ? "#ba3b46" : "#3b82f6",
            },
          ]}
        />
      )}
      <FlameAnimation
        parentWidth={parentWidth}
        triggered={favorited && getLitOn}
      />
      <SimpleToast message={toastMessage} visible={toastVisible} />
    </Flex>
  );
}
