import React, { useEffect, useRef } from "react";
import { Animated } from "react-native";
import { useState } from "react";

export default function PopIn({
  isVisible,
  autoStart,
  preload = false,
  preloadPadding = 0,
  ...props
}) {
  const scaleValue = useRef(new Animated.Value(isVisible ? 1 : 0)).current;
  const opacityValue = useRef(new Animated.Value(isVisible ? 1 : 0)).current;

  const popIn = Animated.parallel([
    Animated.spring(scaleValue, {
      toValue: 1,
      bounciness: 10, // Controls the bounciness of the animation
      speed: 10, // Controls the speed of the animation
      useNativeDriver: true,
    }),
    Animated.timing(opacityValue, {
      toValue: 1,
      duration: 200, // Controls the duration of the opacity animation
      useNativeDriver: true,
    }),
  ]);

  const popOut = Animated.parallel([
    Animated.spring(scaleValue, {
      toValue: 0,
      bounciness: 10, // Controls the bounciness of the animation
      speed: 10, // Controls the speed of the animation
      useNativeDriver: true,
    }),
    Animated.timing(opacityValue, {
      toValue: 0,
      duration: 200, // Controls the duration of the opacity animation
      useNativeDriver: true,
    }),
  ]);

  useEffect(() => {
    if (isVisible) {
      popOut.stop();
      popIn.start();
    } else {
      popIn.stop();
      popOut.start();
    }
  }, [isVisible]);

  return (
    <Animated.View
      style={{
        transform: [{ scale: scaleValue }],
        opacity: opacityValue,
        display: preload ? "flex" : isVisible ? "flex" : "none",
        height: preload ? (isVisible ? "auto" : 0) : "auto",
        marginTop: preload ? (isVisible ? 0 : -preloadPadding) : 0,
      }}
    >
      {props.children}
    </Animated.View>
  );
}
