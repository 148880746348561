import React from "react";

import {
  Flex,
  Switch,
  HStack,
  Surface,
  VStack,
} from "@react-native-material/core";
import { ScrollView, Text } from "react-native";

export default function SettingsGroup(props) {
  return (
    <VStack mt={36} style={{ width: "100%" }}>
      <Text style={{ paddingLeft: 16, paddingBottom: 8, color: "grey" }}>
        {props.title}
      </Text>
      {props.children}
    </VStack>
  );
}
