import React, { useState, useEffect, useRef } from "react";
import Lottie from "lottie-react-native";
import { View } from "react-native";

const noDataFoundAnimation = require("../../../assets/animations/no-data-found.json");

function NoDataFound({ visible = true, customStyle, delay = 0 }) {
  const [loading, setLoading] = useState(true);
  const animationRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (animationRef.current) {
        animationRef.current.reset();
        animationRef.current.play();
      }
    }, 100);

    setTimeout(() => {
      setLoading(false);
    }, delay);
  }, []);

  if (!visible) return;

  return (
    <View
      style={[
        {
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          marginTop: -12,
        },
        loading && { opacity: 0 },
        customStyle && customStyle,
      ]}
    >
      <Lottie
        style={{
          height: 300,
          width: 300,
        }}
        autoPlay
        loop={true}
        source={noDataFoundAnimation}
        ref={animationRef}
      />
    </View>
  );
}

export default NoDataFound;
