import React, { useEffect } from "react";

import { Chip, Flex, HStack, Surface } from "@react-native-material/core";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Fragment } from "react";
import FabGroup from "../../components/general/fabs/FabGroup";
import SquareGrid from "../../components/cards/Grid/SquareGrid";
import { connectHighlight } from "react-instantsearch-core";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import CustomSegmentedControl from "../../components/data/input/CustomSegmentedControl";
import {
  Image,
  Keyboard,
  Platform,
  RefreshControl,
  Text,
  TextInput,
  View,
} from "react-native";
import { Spinner } from "native-base";
import Accordion from "../../components/data/wrappers/BasicAccordion";
import CustomAlertDialog from "../../components/data/input/Modals/CustomAlertDialog";
import { TouchableOpacity } from "react-native";
import AlgoliaSearch from "../../components/data/input/AlgoliaSearch";
import {
  changeColorAlpha,
  getBlackWhiteTextColor,
  getContrastTextColor,
} from "../../utils/generateColor";
import { useSettings } from "../../components/contexts/SettingsContext";

const now = new Date();

// get start of week (Sunday)
const day = now.getDay();
const startOfDay = new Date(now);
startOfDay.setHours(0, 0, 0, 0);

const startOfWeek = new Date(now);
startOfWeek.setDate(now.getDate() - day);
startOfWeek.setHours(0, 0, 0, 0);

// get end of week (Saturday)
const endOfWeek = new Date(startOfWeek);
endOfWeek.setDate(startOfWeek.getDate() + 6);
endOfWeek.setHours(23, 59, 59, 999);

// get start of month
const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

// get end of month
const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
endOfMonth.setHours(23, 59, 59, 999);

// get end of day
const endOfDay = new Date(now);
endOfDay.setHours(23, 59, 59, 999);

//begining of time
const startOfTime = new Date("08/01/2022");
const endOfTime = new Date("08/01/2029");

const algoliaLogo = require("../../assets/general/Algolia-logo-blue.png");

export default function StaffVaultFeedScreen({ navigation }) {
  const [boardData, setBoardData] = React.useState([]);
  const [filteredBoardData, setFilteredBoardData] = React.useState([]);
  const [selectedTimeIndex, setSelectedTimeIndex] = React.useState(1);
  const [selectedSortIndex, setSelectedSortIndex] = React.useState(0);
  const [dataLoading, setDataLoading] = React.useState(true);
  const [refreshing, setRefreshing] = React.useState(false);
  const [searchPromptVisible, setSearchPromptVisible] = React.useState(false);
  const [selectedSearchIndex, setSelectedSearchIndex] = React.useState(0);

  const { settings } = useSettings();
  const boardTileSize = settings.boardTileSize ? settings.boardTileSize : 1;

  useEffect(() => {
    setDataLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    async function getData() {
      let startTime;
      let endTime;

      if (selectedTimeIndex === 0) {
        startTime = startOfDay;
        endTime = endOfDay;
      } else if (selectedTimeIndex === 1) {
        startTime = startOfWeek;
        endTime = endOfWeek;
      } else if (selectedTimeIndex === 2) {
        startTime = startOfMonth;
        endTime = endOfMonth;
      } else if (selectedTimeIndex === 3) {
        startTime = null;
        endTime = null;
      }

      fetchData(startTime, endTime);
    }

    getData();
  }, [selectedTimeIndex, selectedSortIndex]);

  const fetchData = async (startTime = startOfWeek, endTime = endOfWeek) => {
    setRefreshing(true);
    setBoardData([]);

    try {
      const db = getFirestore();

      let boardQuery;

      if (!startTime || !endTime) {
        boardQuery = query(
          collection(db, "boards"),
          where("isPublic", "==", true),
          orderBy("creationTimeStamp", "desc")
        );
      } else {
        const startTimestamp = Timestamp.fromDate(startTime);
        const endTimestamp = Timestamp.fromDate(endTime);

        boardQuery = query(
          collection(db, "boards"),
          where("isPublic", "==", true),
          where("creationTimeStamp", ">=", startTimestamp),
          where("creationTimeStamp", "<=", endTimestamp),
          orderBy("creationTimeStamp", "desc")
        );
      }

      const boardsSnapshot = await getDocs(boardQuery);

      let boardDocs = boardsSnapshot.docs;

      const boardPromises = boardDocs.map(async (boardDoc) => {
        const boardData = boardDoc.data();

        let authorName = "Unknown"; // Default value if author name is not found

        if (boardData.teacherId && boardData.teacherId.id) {
          try {
            const authorDocRef = doc(db, "publicUsers", boardData.teacherId.id);
            const authorDocSnap = await getDoc(authorDocRef);

            if (authorDocSnap.exists()) {
              authorName =
                authorDocSnap.data().firstName +
                " " +
                authorDocSnap.data().lastName;
            }
          } catch (error) {
            console.error("Error fetching author data:", error);
          }
        }

        let tags;

        if (selectedSortIndex == 0)
          tags = [
            `${boardData.reboards ? boardData.reboards : 0} reboard${
              boardData.reboards !== 1 ? "s" : ""
            }$black$bold`,
            `${boardData.vaultViews ? boardData.vaultViews : 0} view${
              boardData.vaultViews !== 1 ? "s" : ""
            }$black$300`,
            `${boardData.sections ? boardData.sections : 0} section${
              boardData.sections !== 1 ? "s" : ""
            }`,
            `${boardData.creationTimeStamp?.toDate().toDateString()}`,
            `${authorName}`, // Include the author's name in the tags array
          ];
        else if (selectedSortIndex == 1)
          tags = [
            `${boardData.reboards ? boardData.reboards : 0} reboard${
              boardData.reboards !== 1 ? "s" : ""
            }$black$300`,
            `${boardData.vaultViews ? boardData.vaultViews : 0} view${
              boardData.vaultViews !== 1 ? "s" : ""
            }$black$bold`,
            `${boardData.sections ? boardData.sections : 0} section${
              boardData.sections !== 1 ? "s" : ""
            }`,
            `${boardData.creationTimeStamp?.toDate().toDateString()}`,
            `${authorName}`, // Include the author's name in the tags array
          ];
        else if (selectedSortIndex == 2 || selectedSortIndex == 3)
          tags = [
            `${boardData.reboards ? boardData.reboards : 0} reboard${
              boardData.reboards !== 1 ? "s" : ""
            }$black$300`,
            `${boardData.vaultViews ? boardData.vaultViews : 0} view${
              boardData.vaultViews !== 1 ? "s" : ""
            }$black$300`,
            `${boardData.sections ? boardData.sections : 0} section${
              boardData.sections !== 1 ? "s" : ""
            }`,
            `${boardData.creationTimeStamp
              ?.toDate()
              .toDateString()}$black$bold`,
            `${authorName}`, // Include the author's name in the tags array
          ];

        if (boardData.modalityCount) {
          tags = [
            ...tags.slice(0, 2),
            `${boardData.modalityCount} modalities$#666$300`,
            ...tags.slice(2),
          ];
        }

        return {
          title: boardData.boardName,
          id: boardDoc.id,
          backgroundColor: boardData.color,
          reboards: boardData.reboards ? boardData.reboards : 0,
          views: boardData.vaultViews ? boardData.vaultViews : 0,
          creationTimeStamp: boardData.creationTimeStamp,
          tags,
          authorId: boardData.teacherId?.id || "",
        };
      });

      const boards = await Promise.all(boardPromises);

      setBoardData(boards);

      updateSort(boards);
    } catch (e) {
      console.error(e);
    }

    setRefreshing(false);
    setDataLoading(false);
  };

  const updateSort = async (_boardData) => {
    setFilteredBoardData([]);

    setTimeout(() => {
      if (selectedSortIndex == 0) {
        setFilteredBoardData(
          _boardData.sort((a, b) => b.reboards - a.reboards)
        );
      } else if (selectedSortIndex == 1) {
        setFilteredBoardData(_boardData.sort((a, b) => b.views - a.views));
      } else if (selectedSortIndex == 2) {
        setFilteredBoardData(
          _boardData.sort((a, b) => b.creationTimeStamp - a.creationTimeStamp)
        );
      } else if (selectedSortIndex == 3) {
        setFilteredBoardData(
          _boardData.sort((a, b) => a.creationTimeStamp - b.creationTimeStamp)
        );
      }
    }, 250);
  };

  const teacherHit = ({ hit }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setSearchPromptVisible(false);
          navigation.navigate("Teacher", {
            name: hit.firstName + " " + hit.lastName,
            authorId: hit.path.split("/")[1],
            authorReboards: hit.reboards ? hit.reboards : 0,
            authorAvatar: hit.profilePicture,
            authorJoinDate: hit.creationTime,
            authorName: hit.firstName + " " + hit.lastName,
          });
        }}
        style={{
          flexDirection: "row",
          gap: 6,
          alignItems: "flex-start",
          padding: 5,
        }}
      >
        <Image
          style={{ width: 30, height: 30, borderRadius: 20 }}
          source={{ uri: hit.profilePicture }}
        />
        <View style={{ flexDirection: "column" }}>
          <Text>
            <CustomHighlight attribute="firstName" hit={hit} />{" "}
            <CustomHighlight attribute="lastName" hit={hit} />
          </Text>
          <Text style={{ color: "#666", fontWeight: "300" }}>
            {hit.reboards ? hit.reboards : 0} reboards
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const boardHit = ({ hit }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("Board", {
            name: hit.boardName,
            boardId: hit.path.split("/")[1],
            authorId: hit.teacherId.split("/")[1],
            color: hit.backgroundColor,
          });
          setSearchPromptVisible(false);
        }}
        style={{
          flexDirection: "column",
          gap: 4,
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
          paddingVertical: 20,
          marginBottom: 5,
          borderRadius: 5,
          backgroundColor: hit.color,
        }}
      >
        <CustomHighlight
          style={{ textDecorationLine: "underline", textAlign: "center" }}
          bold
          attribute="boardName"
          hit={hit}
        />
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 4,
            marginTop: 4,
            justifyContent: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "rgba(255,255,255,0.9)",
              borderRadius: 5,
              padding: 3,
              paddingHorizontal: 5,
            }}
          >
            <Text
              style={{
                fontWeight: "600",
              }}
            >
              {hit.reboards} reboard{hit.reboards != 1 && "s"}
            </Text>
          </View>
          <View
            style={{
              backgroundColor: "rgba(255,255,255,0.9)",
              borderRadius: 5,
              padding: 3,
              paddingHorizontal: 5,
            }}
          >
            <Text style={{ fontWeight: "500" }}>
              {hit.views ? hit.sections : 0} view
              {hit.views != 1 && "s"}
            </Text>
          </View>
          <View
            style={{
              backgroundColor: "rgba(255,255,255,0.9)",
              borderRadius: 5,
              padding: 3,
              paddingHorizontal: 5,
            }}
          >
            <Text style={{ fontWeight: "500" }}>
              {hit.sections ? hit.sections : 0} section
              {hit.sections != 1 && "s"}
            </Text>
          </View>
          <View
            style={{
              backgroundColor: "rgba(255,255,255,0.9)",
              borderRadius: 5,
              padding: 3,
              paddingHorizontal: 5,
            }}
          >
            <Text style={{ fontWeight: "500" }}>
              {hit.sections ? hit.modalityCount : 0} modalities
            </Text>
          </View>
          {hit._highlightResult.tags?.map((tag, index) => {
            if (tag.matchLevel !== "none")
              return (
                <View
                  key={index}
                  style={{
                    backgroundColor: "rgba(255,255,255,0.9)",
                    borderRadius: 5,
                    padding: 3,
                    paddingHorizontal: 5,
                  }}
                >
                  <CustomTagHighlight light highlightedText={tag.value} />
                </View>
              );
          })}
        </View>
      </TouchableOpacity>
    );
  };

  const viewBoardClicked = (boardId, board) => {
    navigation.navigate("Board", {
      name: board.title,
      boardId: boardId,
      authorId: board.authorId,
      color: board.backgroundColor,
    });
  };

  const viewAuthorClicked = (boardId, board) => {
    navigation.push("Teacher", {
      authorId: board.authorId,
    });
  };

  const reboardClicked = (boardId, board) => {
    navigation.navigate("Board", {
      name: board.title,
      boardId: boardId,
      authorId: board.authorId,
      color: board.backgroundColor,
      showReboard: true,
    });
  };

  return (
    <Fragment>
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, backgroundColor: "#f0eff4" }}
        refreshControl={
          !dataLoading && (
            <RefreshControl refreshing={refreshing} onRefresh={fetchData} />
          )
        }
      >
        <Flex fill p={16} style={{ gap: 16, paddingBottom: 91 }}>
          {Platform.OS !== "web" && dataLoading && (
            <Spinner size={"lg"} color={"black"} />
          )}
          {Platform.OS === "web" && refreshing && (
            <Spinner size={"lg"} color={"black"} />
          )}
          <Accordion
            delay={500}
            title={"Filter"}
            style={{ marginBottom: -18 }}
            surfaceStyle={{ margin: 5 }}
            innerStyle={{ height: 35 }}
            defaultExpanded
            content={
              <View
                style={{
                  padding: 5,
                  flexDirection: "column",
                  gap: 10,
                  marginBottom: 5,
                }}
              >
                <CustomSegmentedControl
                  color={"#f7b86a"}
                  textColor={"white"}
                  values={["Today", "Weekly", "Monthly", "All Time"]}
                  selectedIndex={selectedTimeIndex}
                  onValueChange={(index) => {
                    setSelectedTimeIndex(index);
                  }}
                />
                <CustomSegmentedControl
                  color={"#f7b86a"}
                  textColor={"white"}
                  values={["Reboards", "Views", "Newest", "Oldest"]}
                  selectedIndex={selectedSortIndex}
                  onValueChange={(index) => {
                    setSelectedSortIndex(index);
                  }}
                />
              </View>
            }
          />
          <SquareGrid
            hideSearch
            showNoData
            canAdd={false}
            key={boardTileSize}
            columnWidth={200 * boardTileSize}
            onCardPress={(item, e) => {
              navigation.navigate("Board", {
                name: item.title,
                boardId: item.id,
                authorId: item.authorId,
                color: item.backgroundColor,
              });
            }}
            loading={dataLoading}
            noDataStyle={{ margin: 5, borderRadius: 5, marginTop: 7 }}
            data={filteredBoardData}
            numberOfLines={200 * boardTileSize < 170 ? 1 : 3}
            options={[
              {
                label: "Reboard",
                icon: (
                  <MaterialCommunityIcons
                    name="briefcase-download"
                    size={20}
                    color="#1890ff"
                  />
                ),
                onSelect: reboardClicked,
              },
              {
                label: "View author",
                icon: (
                  <MaterialCommunityIcons
                    name="account"
                    size={20}
                    color="black"
                  />
                ),
                onSelect: viewAuthorClicked,
              },
              {
                label: "View board",
                icon: (
                  <MaterialCommunityIcons
                    name="arrow-right-bold"
                    size={20}
                    color="black"
                  />
                ),
                onSelect: viewBoardClicked,
              },
            ]}
          />
        </Flex>
      </KeyboardAwareScrollView>
      <Fragment>
        <FabGroup
          buttons={[
            {
              icon: (
                <MaterialCommunityIcons
                  name="keyboard-return"
                  size={24}
                  color="black"
                />
              ),
              color: "white",
              onPress: () => navigation.goBack(),
            },
            {
              icon: <MaterialCommunityIcons name="account" size={32} />,
              color: "white",
              onPress: () => navigation.navigate("My Boards"),
            },
            {
              icon: <MaterialCommunityIcons name="magnify" size={32} />,
              color: "white",
              onPress: () => setSearchPromptVisible(true),
            },
          ]}
        />
        <CustomAlertDialog
          setModalVisible={setSearchPromptVisible}
          onConfirm={() => setSearchPromptVisible(false)}
          modalVisible={searchPromptVisible}
          title="Search"
          customMessageComponent={
            <View
              style={{
                flexDirection: "column",
                gap: 10,
                width: "100%",
                paddingBottom: 10,
                minHeight: 400,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: -15,
                  marginBottom: -5,
                }}
              >
                <Text style={{ fontSize: 10 }}>powered by </Text>
                <Image
                  resizeMode="contain"
                  style={{ width: 50, height: 30 }}
                  source={algoliaLogo}
                />
              </View>
              <CustomSegmentedControl
                color={"#eb9940"}
                textColor={"white"}
                values={["Boards", "Teachers"]}
                selectedIndex={selectedSearchIndex}
                onValueChange={(index) => {
                  setSelectedSearchIndex(index);
                }}
              />
              {selectedSearchIndex == 0 && (
                <Surface
                  style={{
                    borderRadius: 5,
                    backgroundColor: "white",
                    width: "100%",
                  }}
                  elevation={1}
                >
                  <AlgoliaSearch
                    filters={"isPublic:true"}
                    indexName={"boardNameSearch"}
                    Hit={boardHit}
                  />
                </Surface>
              )}
              {selectedSearchIndex == 1 && (
                <Surface
                  style={{
                    borderRadius: 5,
                    backgroundColor: "white",
                    width: "100%",
                  }}
                  elevation={1}
                >
                  <AlgoliaSearch
                    filters={"role:Teacher"}
                    indexName={"userInfo"}
                    Hit={teacherHit}
                  />
                </Surface>
              )}
            </View>
          }
          buttons={[
            <TouchableOpacity
              key={"cancel"}
              onPress={() => setSearchPromptVisible(false)}
            >
              <Text style={{ color: "red" }}>Cancel</Text>
            </TouchableOpacity>,
          ]}
        />
      </Fragment>
    </Fragment>
  );
}

const CustomHighlight = ({ bold, attribute, hit, style }) => {
  const highlightedText = hit?._highlightResult[attribute].value;

  const parts = highlightedText.split(/<mark>|<\/mark>/);

  return (
    <Text style={[style && style]}>
      {parts.map((part, index) =>
        index % 2 === 0 ? (
          <Text style={[bold && { fontWeight: "bold" }]} key={index}>
            {part}
          </Text>
        ) : (
          <Text
            key={index}
            style={[
              { backgroundColor: "#ffff99" },
              bold && { fontWeight: "bold" },
            ]}
          >
            {part}
          </Text>
        )
      )}
    </Text>
  );
};

const CustomTagHighlight = ({ light, highlightedText }) => {
  const parts = highlightedText.split(/<mark>|<\/mark>/);

  return (
    <Text>
      {parts.map((part, index) =>
        index % 2 === 0 ? (
          <Text
            style={[light && { fontWeight: 300, color: "#666" }]}
            key={index}
          >
            {part}
          </Text>
        ) : (
          <Text
            key={index}
            style={[
              { backgroundColor: "#ffff99" },
              light && { fontWeight: 300, color: "#666" },
            ]}
          >
            {part}
          </Text>
        )
      )}
    </Text>
  );
};
