import React, { useState, useEffect } from "react";
import { View, Picker } from "react-native";

export default function DateOfBirthInput({ date = new Date(), setDate }) {
  const [month, setMonth] = useState(date.getMonth());
  const [day, setDay] = useState(date.getDate());
  const [year, setYear] = useState(date.getFullYear());

  // Check if the date is valid and set it if it's valid
  useEffect(() => {
    const isValidDate = (d) => d instanceof Date && !isNaN(d);
    const selectedDate = new Date(year, month, day);

    if (isValidDate(selectedDate)) {
      setDate(selectedDate);
    }
  }, [day, month, year]);

  const monthItems = [
    { label: "January", value: 0 },
    { label: "February", value: 1 },
    { label: "March", value: 2 },
    { label: "April", value: 3 },
    { label: "May", value: 4 },
    { label: "June", value: 5 },
    { label: "July", value: 6 },
    { label: "August", value: 7 },
    { label: "September", value: 8 },
    { label: "October", value: 9 },
    { label: "November", value: 10 },
    { label: "December", value: 11 },
  ].map((item) => <Picker.Item label={item.label} value={item.value} />);

  // Populating days considering the selected month and year
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const dayItems = Array.from({ length: daysInMonth }, (_, index) => (
    <Picker.Item label={String(index + 1)} value={index + 1} />
  ));

  // Populating years (for example from 1900 to current year)
  const yearItems = Array.from(
    { length: new Date().getFullYear() - 1900 + 1 },
    (_, index) => (
      <Picker.Item label={String(1900 + index)} value={1900 + index} />
    )
  );

  return (
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        height: 40,
      }}
    >
      <Picker
        style={{
          flex: 1,
          borderWidth: 1,
          borderColor: "#eee",
          borderTopLeftRadius: 3,
          borderBottomLeftRadius: 3,
        }}
        onValueChange={(value) => setMonth(value)}
        selectedValue={month}
      >
        {monthItems}
      </Picker>
      <Picker
        style={{
          flex: 0.5,
          borderColor: "#eee",
          borderRadius: 0,
          borderWidth: 1,
        }}
        onValueChange={(value) => setDay(value)}
        selectedValue={day}
      >
        {dayItems}
      </Picker>
      <Picker
        style={{
          flex: 0.75,
          borderColor: "#eee",
          borderTopRightRadius: 3,
          borderWidth: 1,
          borderBottomRightRadius: 3,
        }}
        onValueChange={(value) => setYear(value)}
        selectedValue={year}
      >
        {yearItems}
      </Picker>
    </View>
  );
}
